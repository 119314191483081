import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import { allShopsEnv } from "../../../../utils/shop"

const BasicInfo = ({ validationType }) => {
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Basic Info</CardTitle>
                <div>
                  <div className="mb-3">
                    <Label className="form-label">Name</Label>
                    <Input
                      name="name"
                      placeholder="Enter Name"
                      type="text"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.name || ""}
                      invalid={
                        validationType.touched.name &&
                        validationType.errors.name
                          ? true
                          : false
                      }
                    />
                    {validationType.touched.name &&
                    validationType.errors.name ? (
                      <FormFeedback type="invalid">
                        {validationType.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <select
                      name="isActive"
                      className="form-select"
                      onChange={e => {
                        const isActive = e.target.value === "true"
                        validationType.setFieldValue("isActive", isActive)
                      }}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.isActive.toString()}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Shop</Label>
                    <select
                      name="shop"
                      className="form-select"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.shop}
                    >
                      {allShopsEnv[process.env.REACT_APP_ENV].map(option => {
                        return (
                          <option value={option.value} key={option.value}>
                            {option.label}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BasicInfo
