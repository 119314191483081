const storeMap = {
  "shapees": "shapermint",
  "shapermint": "shapermint",
  "revel-dev": "revel",
  "revel-stage": "revel",
  "revel": "revel",
  "thespadr-dev": "the-spa-dr",
  "the-spa-dr": "the-spa-dr",
  "truekind-beta": "truekind",
  "truekind-store": "truekind"
}

export function getStoredKey(storeName) {
  const store = storeMap[storeName]
  if (store) {
    return store
  }
  throw new Error(`Store name ${storeName} not found`)
}

