const ExclusiveText = ({blockManager, color, fontWeight}) => {
    blockManager.add("exclusive-text", {
        id: "exclusive-text",
        label: "Exclusive Text",
        category: "Afrodita Components",
        activate: true,
        content: `<div>
        <div style="max-width: 420px; width: 100%; margin: 0 auto 7px;">
          <h6 style="margin: 0; color: #292929; font-size: 18px; line-height: 28px; font-weight: 600; text-align: center; font-family: 'Arial'">
            <span>Yes! That's only $21.00 each</span>
          </h6>
        </div>
        <div style="max-width: 420px; width: 100%; margin: 0 auto 5px;">
          <p style="margin: 0; color: ${color}; font-weight: ${fontWeight}; font-size: 14px; text-align: center; line-height: 1; font-family: 'Arial'">
            <span>*Exclusive only on this page*</span>
          </p>
        </div>
      </div>
      `,
      })
}

export default ExclusiveText