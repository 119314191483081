
import { forwardRef } from 'react'
import RemoveBtn from './RemoveBtn'
import { QUESTION_TYPES } from '../constants'

const CardContainer = {
    display: 'block',
    padding: '10px 10px',
    background: 'rgba(255, 255, 255, 0.1)',
    border: '1px dashed rgba(255, 255, 255, 0.3)',
    fontSize: '14px',
    fontWeight: '400',
    minWidth: '250px',
    whiteSpace: 'nowrap',
    transition: '0.3s ease-in-out 0s',
    marginTop: 10,
    cursor: 'move',
    color: 'inherit',
    position: 'relative',
    inputStyle: {
        minWidth: '50%',
        width: 'auto',
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        height: '100%',
        color: 'inherit',
    }
}

const {inputStyle, ...containerStyle} = CardContainer

const Card = forwardRef(({
  title,
  update,
  remove = null,
  click = () => void 0,
  selected = null,
  opacity = 1,
  handlerId = null,
  type = QUESTION_TYPES.MULTIPLE_CHOICE
}, ref) => (
  <div className='sortable-list-item' onClick={click} ref={ref} style={{ ...containerStyle, opacity, backgroundColor: selected ? 'rgba(0, 0, 0, 0.1)' : containerStyle.background }} data-handler-id={handlerId}>
      <input
        placeholder={type === QUESTION_TYPES.MULTIPLE_CHOICE ? '' : 'Placeholder'}
        style={inputStyle}
        value={title}
        onChange={update}
      />
      {remove ? <RemoveBtn onClick={remove} /> : null}
  </div>
))

export default Card