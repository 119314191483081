import { useEffect, useState } from "react"
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import swal from "sweetalert"
import { callGetApi, callPatchApi } from "utils/api"
import {
  showErrorToastr,
  showSuccessToastr,
} from "../../../../../components/Common/toastr"
import { errorHandler } from "../../../../../utils/error"
import "./shipping-address.scss"

const getSubscriptionShippingAddress = (
  subId,
  onSuccessCallback,
  onFailCallback
) => {
  callGetApi(
    true,
    `/subscriptions/${subId}/shipping-address`,
    onSuccessCallback,
    onFailCallback,
    "auth"
  )
}

const patchSubscriptionShippingAddress = (
  subId,
  payload,
  onSuccessCallback,
  onFailCallback
) => {
  callPatchApi(
    true,
    `/subscriptions/${subId}/shipping-address`,
    payload,
    onSuccessCallback,
    onFailCallback,
    "auth"
  )
}

const onUpdateFail = error => {
  showErrorToastr("Failed to update the status")

  errorHandler(error)
}

const onUpdateSuccess = () => {
  showSuccessToastr("The status was updated successfully")
}

const onSubmitChanges = async (formShippingAddress, dismissModal, subId) => {
  const confirm = await swal({
    title: "Are you sure?",
    text: "Are you sure that you want to update this subscription shipping address?",
    icon: "warning",
    buttons: [true, true],
    dangerMode: true,
  })

  if (!confirm) {
    return
  }

  try {
    patchSubscriptionShippingAddress(
      subId,
      formShippingAddress,
      onUpdateSuccess,
      onUpdateFail
    )
    dismissModal()
  } catch (error) {
    console.error(error)
  }
}

const onFieldChange = (fieldName, setFormValue) => e => {
  setFormValue(prevState => ({
    ...prevState,
    [fieldName]: e.target.value,
  }))
}

const inputFields = [
  { label: "Address 1:", inputName: "address1" },
  { label: "Address 2:", inputName: "address2" },
  { label: "Country", inputName: "country" },
  { label: "State:", inputName: "state" },
  { label: "City:", inputName: "city" },
  { label: "Zip:", inputName: "zip" },
  { label: "Phone:", inputName: "phone" },
  { label: "First Name:", inputName: "first_name" },
  { label: "Last Name:", inputName: "last_name" },
]

const InputField = ({ label, value, onFieldChangeHandler, disabled }) => (
  <div className="item">
    <span>{label}</span>
    <Input
      type="text"
      value={value}
      onChange={onFieldChangeHandler}
      disabled={disabled}
    />
  </div>
)

const SubscriptionShippingAddressModal = ({
  isOpen,
  dismissModal,
  subscriptionValue: subscription,
}) => {
  const [shippingAddress, setShippingAddress] = useState(null)
  const [formValue, setFormValue] = useState({
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    phone: "",
    first_name: "",
    last_name: "",
  })

  useEffect(() => {
    if (!subscription?.id) {
      return
    }

    getSubscriptionShippingAddress(
      subscription.id,
      shippingAddress => {
        setShippingAddress(shippingAddress)
        const { longitude, latitude, ...formValues } = shippingAddress
        setFormValue({ ...formValues })
      },
      () => showErrorToastr("Couldn't retrieve subscription shipping address")
    )
  }, [subscription?.id])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="subscription-shipping-address-modal"
      tabIndex="-1"
      toggle={dismissModal}
    >
      <ModalHeader toggle={dismissModal}>Shipping Address</ModalHeader>
      <ModalBody>
        <div className="flex-container wrap">
          {inputFields.map(({ label, inputName }) => (
            <InputField
              key={inputName}
              label={label}
              value={formValue?.[inputName] || ""}
              onFieldChangeHandler={onFieldChange(inputName, setFormValue)}
              disabled={!shippingAddress}
            />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="container-footer">
          <Button
            className="btn-accept"
            onClick={() =>
              onSubmitChanges(formValue, dismissModal, subscription?.id)
            }
            disabled={!shippingAddress}
          >
            Accept
          </Button>
          <Button className="btn-cancel" onClick={dismissModal}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default SubscriptionShippingAddressModal
