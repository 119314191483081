import PropTypes from "prop-types"
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const ConfirmModal = ({
  show,
  onConfirmClick,
  onCloseClick,
  message,
  iconClass,
  isDelete,
}) => {
  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div
              className={
                "avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3" +
                isDelete
                  ? " text-danger "
                  : ""
              }
              style={
                isDelete
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 50,
                      height: 50,
                      borderRadius: 50,
                      backgroundColor: "#d3d3d37a",
                    }
                  : {}
              }
            >
              <i
                className={iconClass ?? "mdi mdi-sticker-check"}
                style={{ fontSize: 46 }}
              ></i>
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">{message}</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-danger"
              onClick={onConfirmClick}
            >
              {isDelete ? "Delete" : "Confirm"}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                backgroundColor: "#EFEFEF",
                color: "#767676",
                border: "none",
              }}
              onClick={onCloseClick}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.string,
  iconClass: PropTypes.string,
  isDelete: PropTypes.bool,
}

export default ConfirmModal
