import { CTAText } from "../utils"

export const DRAG_YOUR_COMPONENT_CTA_TEXT = "Drag your component here"

const DragYourComponent = editor => {
  editor.DomComponents.addType("drag-your-component", {
    isComponent: el => el.classList?.contains("drag-your-component"),
    model: {
      defaults: {
        ctaText: DRAG_YOUR_COMPONENT_CTA_TEXT,
        styles: `
            .cta-text {
                color: #ccc;
            }
            .drag-your-component {
                display: flex;
                align-items: flex-start;
            }
        `,
      },
      handleContentChange() {
        const components = this.components()
        const el = this.view.el
        if (
          !components.length ||
          !components.models.length ||
          (components.length === 1 &&
            components.models[0].get("tagName") === "")
        ) {
          el.innerHTML = CTAText(this.get("ctaText"))
          el.style.border = "1px dashed #ccc"
          el.style.alignItems = "center"
        } else {
          el.querySelector(".cta-text")?.remove()
          el.style.border = "none"
          el.style.alignItems = "flex-start"
        }
      },
      init() {
        this.on("change:ctaText", this.handleContentChange)
        this.on("component:update:components", this.handleContentChange)
      },
    },
    view: {
      onRender({ el }) {
        el.style.justifyContent = "center"
        el.style.alignItems = "center"
        if (!el.innerHTML.trim()) {
          el.style.border = "1px dashed #ccc"
          el.innerHTML = CTAText(this.model.get("ctaText"))
        }
      },
    },
  })
}

export default DragYourComponent
