import React, { useEffect, useState } from "react"
import { Container, Label } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { callGetApi, callPutApi } from "utils/api"
import { PageBuilderDefaultContainer } from "components/PageBuilder"
import { useParams } from "react-router-dom"
import { allShopsEnv } from "utils/shop"
import { getFriendlyErrorMessage } from "utils/errorHandling"
import { NodeTypeEnum } from "constants/page-builder"
import usePageBuilder from "hooks/usePageBuilder"
import { showErrorToastr, showSuccessToastr } from "components/Common/toastr"

import "grapesjs/dist/css/grapes.min.css"
import "./funnel-offer.scss"
import { toTitleCaseWithSpacing } from "utils/bpmn"

const OfferDnd = () => {
  document.title = "Funnel Offer Dnd"
  const [shop, setShop] = useState()
  const { funnelId } = useParams()
  const [funnel, setFunnel] = useState(null)
  const { editorInstance } = usePageBuilder(shop?.value, null, funnelId)

  const fetchFunnels = () => {
    const queryParams = new URLSearchParams({
      is_active: true,
      id: funnelId,
    })

    const paramsToSend = queryParams.toString()

    callGetApi(
      true,
      `/funnel?${paramsToSend}`,
      data => {
        setFunnel(data?.content[0])
        setShop(
          allShopsEnv[process.env.REACT_APP_ENV].find(
            x => x?.value === data?.content[0]?.store
          )
        )
      },
      errorMessage => {
        showErrorToastr(getFriendlyErrorMessage(errorMessage))
        setFunnel(null)
      }
    )
  }

  const fetchPages = async () => {
    const successCb = async projectData => {
      if (!projectData) {
        showErrorToastr("An error ocurred")
        return
      }

      const pages = projectData.pages.map(page => {
        return {
          ...page,
          frames: page.data,
          name: toTitleCaseWithSpacing(page.node),
          fromFunnel: true,
        }
      })

      let stylesText = ``
      const pagesModule = editorInstance?.Pages
      pages?.forEach(p => {
        stylesText += `div[data-key="${p.id}"] .page-close { display: none; } div[data-key="${p.id}"] .page-edit { display: none; }`
        pagesModule?.add(p)
      })

      pagesModule?.getAll().forEach(p => {
        if (!p?.get("fromFunnel")) {
          pagesModule?.remove(p)
        }
      })

      const style = document.createElement("style")
      style.innerHTML = stylesText
      document.querySelector(".gjs-pn-panel").appendChild(style)

      editorInstance?.on("page:select", modifyBlocksByPage)
      pagesModule?.select(pages?.find(p => p.isIndex)?.id)
      editorInstance.loadProjectData({
        pages: pages,
        styles: projectData.styles,
        assets: projectData.assets,
      })
    }

    const queryParams = new URLSearchParams({
      funnelId,
    })

    const paramsToSend = queryParams.toString()

    callGetApi(
      true,
      `/page-builder/pages?${paramsToSend}`,
      successCb,
      errorMessage => {
        console.error("Failed to fetch pages:", errorMessage)
        showErrorToastr(getFriendlyErrorMessage(errorMessage))
      }
    )
  }

  const modifyBlocksByPage = page => {
    if (!page) {
      showErrorToastr("An error ocurred")
      return
    }

    const quizCategories = ["Quiz", "Extra", "Basic"]
    const offerCategories = ["Offer", "Afrodita Components", "Extra", "Basic"]

    console.log("page:select", page)
    const bm = editorInstance?.BlockManager
    const blocks = bm?.getAll()
    let filtered = []

    if (page.get("node") === NodeTypeEnum.QUIZ)
      filtered = blocks.filter(block =>
        quizCategories.some(category => block.get("category").id === category)
      )
    else
      filtered = blocks.filter(block =>
        offerCategories.some(category => block.get("category").id === category)
      )

    bm.render(filtered)
  }

  const handleSave = () => {
    try {
      const data = editorInstance.getProjectData()
      callPutApi(
        true,
        `/page-builder/${funnelId}`,
        data,
        () => {
          editorInstance?.store?.().trigger?.("storage:store")
          showSuccessToastr(`Project saved successfully`)
        },
        errorMessage => {
          showErrorToastr(`Failed to save model: ${errorMessage}`)
        }
      )
    } catch (error) {
      console.log(error)
      showErrorToastr(error)
    }
  }

  useEffect(() => {
    fetchFunnels()
  }, [])

  useEffect(() => {
    editorInstance?.onReady(() => {
      fetchPages()
    })
  }, [editorInstance])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Offer"} breadcrumbItem={"Drag and Drop"} />
          <div className="mb-3">
            <Label className="form-label">Shop</Label>
            <p>{shop?.label}</p>
          </div>
          <div className="mb-3">
            <Label className="form-label">Funnel</Label>
            <p>{funnel?.title}</p>
          </div>
          <div className="save-project-container">
            <button
              className="create-btn create-funnel-btn"
              onClick={() => handleSave()}
            >
              Save Project
            </button>
          </div>
          <PageBuilderDefaultContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OfferDnd
