const Banner = ({blockManager, bgColor, color}) => {
    blockManager.add("banner", {
        id: "banner",
        label: "Banner",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="font-family: 'Arial';font-weight: bold; color: ${color}; background: ${bgColor}; max-width: 500px; width: 100%; margin: 0 auto; padding: 10px 0; text-align: center; border-radius: 10px; font-size: 18px;">
        THIS IS A ONE-TIME SUMMER SALE
      </div>`,
    })
}

export default Banner