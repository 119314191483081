const NewText = ({blockManager, color}) => {
    blockManager.add("remember-text", {
        id: "remember-text",
        label: "Remember Text",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="max-width: 420px; width: 100%; margin: 0 auto;">
        <p style="margin: 0; color: #292929; font-size: 14px; line-height: 22px; text-align: center; font-family: 'Arial'">
          <span>
            <b>Remember!</b>
            <span style="color: ${color};">
              <br />
              This offer disappears once you leave this page.
              <br />
              Click the button to claim this exclusive deal
            </span>
          </span>
        </p>
      </div>
      `,
      })
}

export default NewText