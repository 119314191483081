import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { useEffect, useMemo, useState } from "react"
import { BsFillPauseFill, BsFillPlayFill } from "react-icons/bs"
import { FaTimes } from "react-icons/fa"
import { Card, CardBody, Col, Row } from "reactstrap"
import swal from "sweetalert"
import { abortRequest, callGetApi, callPatchApi } from "utils/api"
import { isSubscriptionAdminUser } from "../../utils/auth"
import Forbidden from "../Forbidden/forbidden"
import {
  CreatedAtColumn,
  NextPaymentDate,
  Price,
  SchedulePaymentStatus,
  Status,
  SubscriptionId,
  SubscriptionTypeName,
  UpdatedAtColumn,
  UserEmail,
} from "./columns"
import { SubscriptionDetailsModal, SubscriptionItemsModal } from "./modals"
import SubscriptionModifyModal from "./modals/modify/modify"

const SubscriptionStatus = Object.freeze({
  paused: "paused",
  active: "active",
  cancelled: "cancelled",
})

const SubscriptionPaymentSource = Object.freeze({
  subscriptions: "subscriptions",
  orders: "orders",
})

const canUpdateSubscriptionStatus = status =>
  [SubscriptionStatus.active, SubscriptionStatus.paused].includes(status)

const openCancellationModal = (subscriptionId, store) =>
  new Promise((res, rej) => {
    const isTSD = ["thespadr-dev", "the-spa-dr"].includes(store)

    const divElement = document.createElement("div")
    const inputElement = document.createElement("textarea")
    inputElement.placeholder = "Explain the reason why you want to cancel"
    inputElement.style.width = "100%"
    divElement.appendChild(inputElement)

    function createOptions(values) {
      let options = []

      values.forEach(value => {
        const option = document.createElement("option")
        option.value = value
        option.textContent = value
        options.push(option)
      })

      return options
    }

    function populateSelectOptions(selectElement, options) {
      selectElement.innerHTML = "" // Clear previous options

      options.forEach(option => {
        selectElement.appendChild(option)
      })
    }

    const selectElement1 = document.createElement("select")
    selectElement1.setAttribute(
      "style",
      "width: 100%; box-sizing: border-box; padding: 8px;"
    )

    const selectElement2 = document.createElement("select")
    selectElement2.setAttribute(
      "style",
      "width: 100%; box-sizing: border-box; padding: 8px;"
    )

    function addSselectFields() {
      const issues = {
        "Product Dissatisfaction": [
          "Allergic Reaction / Skin Irritation",
          "Acne Breakout Issue",
          "Makes Face Oily / Greasy",
          "Drying",
          "Unpleasant smell",
          "Product Didn't Work",
          "Product is Expired",
          "Damaged Product Received",
        ],
        "Product Consumption": [
          "Unable to consume all products - before recurring order [7 to 30 days]",
          "Unable to consume all products - recurring order created [within 7days]",
        ],
        "Failed Payment": [
          "New payment method",
          "Funded current payment method",
        ],
        "Not aware of the subscription": ["Not aware of the subscription"],
        "Pricing Concern": [
          "Not aware of price increase",
          "Cannot afford subscription",
        ],
        "Multiple Subscription": [
          "Multiple subscription for the same product",
          "Multiple subscription for different products",
        ],
        "Fraud Claim": ["Unathorized used of payment method"],
        "Order Management": [
          "Incorrect Order / Item Received",
          "Backorder",
          "Address issue",
          "Gift not added / wrong gift received",
          "Item Fulfillment Delay",
          "Unshipped - Delay in WH",
        ],
        "Shipping Experience": [
          "Order Status [no delay]",
          "Stuck in Preshipment",
          "Stuck in Transit",
          "Delivered but Delay",
          "Delivered not Received",
          "Alert/Missent/Misshipped/Return to Sender",
        ],
        Inquiry: [
          "How to use / Usage Tips",
          "Product Ingredients",
          "Medical Advice",
          "Brand / Product / Subscription General Inquiry",
        ],
        Others: [
          "Spam / Marketing Emails",
          "Waive Shipping Fee (VIP Customers)",
          "Discount Code not Applied",
          "Cannot place an order",
          "Others",
        ],
      }

      selectElement1.addEventListener("change", function () {
        const selectedCategory = this.value
        const selectedIssues = issues[selectedCategory]

        const options = createOptions(selectedIssues)
        populateSelectOptions(selectElement2, options)
      })

      const distinctKeys = [...new Set(Object.keys(issues))]
      populateSelectOptions(selectElement1, createOptions(distinctKeys))
      populateSelectOptions(
        selectElement2,
        createOptions(issues[distinctKeys[0]])
      )

      divElement.classList = "d-flex flex-column gap-2"
      divElement.insertBefore(selectElement1, inputElement)
      divElement.insertBefore(selectElement2, inputElement)
    }

    if (isTSD) {
      addSselectFields()
    }

    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to cancel this subscription '${subscriptionId}'`,
      icon: "warning",
      content: divElement,
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Ok",
          closeModal: true,
          className: "btn-confirm-subscription-cancellation",
          disabled: true,
        },
      },
      dangerMode: true,
    })
      .then(value => {
        let textReponse = inputElement.value.trim()
        if (isTSD) {
          textReponse = `${selectElement1.value} | ${selectElement2.value} | ${textReponse}`
        }

        res({ confirm: value, text: textReponse })
      })
      .catch(err => {
        rej(err)
      })

    const confirmButton = document.querySelector(
      ".btn-confirm-subscription-cancellation"
    )
    confirmButton.disabled = true

    inputElement.addEventListener("input", () => {
      const inputText = inputElement.value.trim()
      const disableButton = inputText.length === 0

      if (confirmButton.disabled !== disableButton) {
        confirmButton.disabled = disableButton
      }
    })
  })

const Subscriptions = () => {
  document.title = "Subscriptions | Backoffice"

  const [isAdminUser, setIsAdminUser] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [isLoading, setIsLoading] = useState(false)
  const [showSubscriptionDetails, setShowSubscriptionDetails] = useState(false)
  const [showSubscriptionItems, setShowSubscriptionItems] = useState(false)
  const [showSubscriptionModify, setShowSubscriptionModify] = useState(false)
  const [filters, setFilters] = useState({})
  const [subscriptionHistory, setSubscriptionHistory] = useState(null)
  const [subscriptionDetails, setSubscriptionDetails] = useState(null)
  const [isSubscriptionDetailsReady, setIsSubscriptionDetailsReady] = useState({
    modalName: "",
    subscriptionId: null,
    subscriptionHistory: false,
    subscriptionDetails: false,
    refetch: false,
  })
  const [subscription, setSubscription] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])
  const [isUpdated, setIsUpdated] = useState(false)

  function parseArrayToQueryParams(params) {
    const omitQueryCaseSensitive = (key, value) =>
      key === "user_email" && typeof value === "string"
        ? value.toLowerCase()
        : value

    return Object.entries(params)
      .filter(
        ([key, value]) =>
          key !== "limit" && key !== "page" && key !== "" && value !== ""
      )
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            omitQueryCaseSensitive(key, value)
          )}`
      )
      .join("&")
  }

  const onRequestFail = error => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          throw new Error(
            "Sorry! the page you are looking for could not be found"
          )
        case 500:
          throw new Error(
            "Sorry! something went wrong, please contact our support team"
          )
        case 401:
          throw new Error("Invalid credentials")

        default:
          throw new Error(
            `Status: ${error.response.status} Error: ${error.message}`
          )
      }
    }
    setIsLoading(false)
    throw error
  }

  const onGetSubscriptionsSuccess = data => {
    setSubscriptions(data)
    setIsLoading(false)
  }

  const getSubscriptions = () => {
    abortRequest()

    callGetApi(
      true,
      `/subscriptions?page=${page}&limit=${limit}&${parseArrayToQueryParams(
        filters
      )}`,
      onGetSubscriptionsSuccess,
      onRequestFail,
      "auth"
    )
  }

  const onRefetchSubscriptions = () => {
    console.log("Refetching subscriptions...")
    getSubscriptions()
    setIsUpdated(false)
  }

  useEffect(() => {
    if (!isUpdated || subscription == null) {
      return
    }

    const updatedSubscription = subscriptions?.rows?.find(
      sub => sub.id === subscription.id
    )

    setSubscription(updatedSubscription)

    setIsSubscriptionDetailsReady(prevState => ({
      ...prevState,
      refetch: true,
    }))

    toggleIsUpdated()
  }, [subscriptions])

  useEffect(() => {
    if (!isSubscriptionAdminUser()) {
      return
    }

    setIsAdminUser(true)

    getSubscriptions()
  }, [page, limit, filters])

  const onCancelSubscriptionFail = (error, subscriptionId) => {
    swal({
      title: "Oops!",
      text: `Something went wrong cancelling subscription '${subscriptionId}'!`,
      icon: "error",
    })
    onRequestFail(error)
    setIsLoading(false)
  }

  const onCancelSubscriptionSuccess = () => {
    swal({
      title: "Cancelled!",
      text: "The subscription has been cancelled!",
      icon: "success",
    })
    onRefetchSubscriptions()
    setIsLoading(false)
  }

  const cancelSubscription = async (subscriptionId, cancellationReason) => {
    callPatchApi(
      true,
      `/subscriptions/cancel`,
      {
        subscriptions_ids: [subscriptionId],
        cancellation_reason: cancellationReason,
      },
      onCancelSubscriptionSuccess,
      error => onCancelSubscriptionFail(error, subscriptionId),
      "auth"
    )
  }

  const handleCancelSubscription = async (subscriptionId, store) => {
    const { confirm, text: cancellationReason } = await openCancellationModal(
      subscriptionId,
      store
    )
    if (!confirm) {
      setIsLoading(false)
      return
    }

    cancelSubscription(subscriptionId, cancellationReason)
  }

  const onUpdateStatusSubscriptionFail = (error, newStatus, subscriptionId) => {
    swal({
      title: "Oops!",
      text: `Something went wrong ${
        newStatus === SubscriptionStatus.paused ? "pausing" : "activating"
      } subscription '${subscriptionId}'!`,
      icon: "error",
    })

    onRequestFail(error)
    setIsLoading(false)
  }

  const onUpdateStatusSubscriptionSuccess = (newStatus, subscriptionId) => {
    console.log(
      `${
        newStatus === SubscriptionStatus.paused ? "Pausing" : "Activating"
      } subscription ${subscriptionId}...`
    )

    swal({
      title: `${
        newStatus === SubscriptionStatus.paused ? "Paused" : "Activated"
      }!`,
      text: `The subscription has been ${
        newStatus === SubscriptionStatus.paused ? "paused" : "activated"
      }!`,
      icon: "success",
    })

    onRefetchSubscriptions()
    setIsLoading(false)
  }

  const updateStatusSubscription = async (
    subscriptionId,
    newStatus,
    orderId
  ) => {
    callPatchApi(
      true,
      `/subscriptions`,
      {
        status: newStatus,
        subscription_ids: [subscriptionId],
        order_id: orderId ?? "",
      },
      () => onUpdateStatusSubscriptionSuccess(newStatus, subscriptionId),
      error => onUpdateStatusSubscriptionFail(error, newStatus, subscriptionId),
      "auth"
    )
  }

  const handleUpdateSubscriptionStatus = async (
    subscriptionId,
    newStatus,
    orderId
  ) => {
    const confirm = await swal({
      title: "Are you sure?",
      text: `Are you sure that you want to ${
        newStatus === SubscriptionStatus.paused ? "pause" : "activate"
      } this subscription '${subscriptionId}'`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: false,
    })

    if (!confirm) {
      setIsLoading(false)
      return
    }

    updateStatusSubscription(subscriptionId, newStatus, orderId)
  }

  const onGetSubscriptionDetailsSuccess = data => {
    setIsSubscriptionDetailsReady(prevState => ({
      ...prevState,
      subscriptionDetails: true,
    }))

    setSubscriptionDetails(data)
  }

  const getSubscriptionDetails = id => {
    callGetApi(
      true,
      `/backoffice/subscriptions/${id}`,
      onGetSubscriptionDetailsSuccess,
      onRequestFail,
      "auth"
    )
  }

  const onGetSubscriptionHistorySuccess = data => {
    setIsSubscriptionDetailsReady(prevState => ({
      ...prevState,
      subscriptionHistory: true,
    }))

    setSubscriptionHistory(data)
  }

  const getSubscriptionHistory = id => {
    callGetApi(
      true,
      `/subscriptions/logger_updates?resource_id=${id}`,
      onGetSubscriptionHistorySuccess,
      onRequestFail,
      "auth"
    )
  }

  const isSubscriptionDetailsBeingSet = (
    subscription,
    isModalOpen,
    modalName
  ) => {
    if (!isModalOpen) {
      setIsSubscriptionDetailsReady(prevState => ({
        ...prevState,
        modalName: modalName,
        subscriptionId: subscription.id,
      }))
      return true
    }

    setIsSubscriptionDetailsReady(prevState => ({
      ...prevState,
      modalName: "",
      subscriptionId: null,
      subscriptionHistory: false,
      subscriptionDetails: false,
      refetch: false,
    }))
    return false
  }

  const toggleShowSubscriptionDetails = subscription => {
    setSubscription(!showSubscriptionDetails ? subscription : null)

    if (
      isSubscriptionDetailsBeingSet(
        subscription,
        showSubscriptionDetails,
        "View Details"
      )
    ) {
      return
    }

    setShowSubscriptionDetails(!showSubscriptionDetails)
  }

  useEffect(() => {
    const {
      modalName,
      subscriptionId,
      subscriptionHistory,
      subscriptionDetails,
      refetch,
    } = isSubscriptionDetailsReady

    if (subscriptionId === null) {
      return
    }

    if (refetch || !subscriptionDetails) {
      getSubscriptionDetails(subscriptionId)
      return
    }

    if (modalName === "Edit Subscription") {
      setShowSubscriptionModify(!showSubscriptionModify)
      return
    }

    if (!subscriptionHistory) {
      getSubscriptionHistory(subscriptionId)
      return
    }

    if (modalName === "View Details") {
      setShowSubscriptionDetails(!showSubscriptionDetails)
      return
    }

    setIsLoading(false)
  }, [isSubscriptionDetailsReady])

  const toggleShowSubscriptionItems = subscription => {
    setSubscription(!showSubscriptionItems ? subscription : null)
    setShowSubscriptionItems(!showSubscriptionItems)
  }

  const toggleShowSubscriptionModify = subscription => {
    setSubscription(!showSubscriptionModify ? subscription : null)

    if (
      isSubscriptionDetailsBeingSet(
        subscription,
        showSubscriptionModify,
        "Edit Subscription"
      )
    ) {
      return
    }

    setShowSubscriptionModify(!showSubscriptionModify)
  }

  const toggleIsUpdated = () => {
    setIsUpdated(!isUpdated)
  }

  const createCustomFilterHandler = field => filterVal => {
    console.log(`Setting filter ${field} to ${filterVal}...`)
    setFilters({
      ...filters,
      [field]: filterVal,
    })
    getSubscriptions()
  }

  const fetchDataHandler = (pageVal, limitVal, filtersData) => {
    setPage(pageVal)
    setLimit(limitVal)
    setFilters({
      ...filters,
      ...filtersData,
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => (
          <div className="d-flex gap-2">
            <button
              className={`btn btn-sm btn-rounded btn-danger ${
                !cellProps.row.original?.id ||
                cellProps.row.original?.status === SubscriptionStatus.cancelled
                  ? "d-none"
                  : ""
              }`}
              disabled={
                cellProps.row.original.subscription_type.payment_source ===
                SubscriptionPaymentSource.subscriptions
              }
              onClick={async () => {
                if (
                  !cellProps.row.original?.id ||
                  cellProps.row.original?.status ===
                    SubscriptionStatus.cancelled
                ) {
                  return
                }
                await handleCancelSubscription(
                  cellProps.row.original?.id,
                  cellProps.row.original?.store
                )
              }}
            >
              <FaTimes />
            </button>
            <button
              className={`btn btn-sm btn-rounded ${
                cellProps.row.original?.status === SubscriptionStatus.active
                  ? "btn-secondary"
                  : "btn-primary"
              } ${
                !cellProps.row.original?.id ||
                !canUpdateSubscriptionStatus(cellProps.row.original?.status)
                  ? "d-none"
                  : ""
              }`}
              disabled={
                cellProps.row.original.subscription_type.payment_source ===
                SubscriptionPaymentSource.subscriptions
              }
              onClick={async () => {
                if (
                  !cellProps.row.original?.id ||
                  !canUpdateSubscriptionStatus(cellProps.row.original?.status)
                ) {
                  return
                }
                await handleUpdateSubscriptionStatus(
                  cellProps.row.original?.id,
                  cellProps.row.original?.status === SubscriptionStatus.active
                    ? SubscriptionStatus.paused
                    : SubscriptionStatus.active
                )
              }}
            >
              {cellProps.row.original?.status === SubscriptionStatus.active ? (
                <BsFillPauseFill />
              ) : (
                <BsFillPlayFill />
              )}
            </button>
          </div>
        ),
      },
      {
        Header: "Id",
        accessor: "id",
        filterable: true,
        hasCustomFilterData: true,
        customValue: filters?.id || "",
        customFilterHandler: createCustomFilterHandler("id"),
        Cell: SubscriptionId,
      },
      {
        Header: "User Email",
        accessor: "user.email",
        filterable: true,
        hasCustomFilterData: true,
        customValue: filters?.user_email || "",
        customFilterHandler: createCustomFilterHandler("user_email"),
        Cell: UserEmail,
      },
      {
        Header: "Subscription Type",
        accessor: "subscription_type.name",
        filterable: true,
        hasCustomFilterData: true,
        customValue: filters?.subscription_type_name || "",
        customFilterHandler: createCustomFilterHandler(
          "subscription_type_name"
        ),
        Cell: SubscriptionTypeName,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        hasCustomFilterData: true,
        customValue: filters?.status || "",
        customFilterHandler: createCustomFilterHandler("status"),
        Cell: Status,
      },
      {
        Header: "Price",
        accessor: "price",
        filterable: true,
        hasCustomFilterData: true,
        customValue: filters?.price || "",
        customFilterHandler: createCustomFilterHandler("price"),
        Cell: Price,
      },
      {
        Header: "Next Payment Date",
        accessor: "current_schedule.due_date",
        filterable: false,
        canFilter: false,
        isFilterHidden: true,
        Cell: NextPaymentDate,
      },
      {
        Header: "S. Status",
        accessor: "schedule_status",
        disableFilters: true,
        Cell: SchedulePaymentStatus,
      },
      {
        Header: "Created At",
        filterable: true,
        hasCustomFilterData: true,
        customValue: filters?.created_at || "",
        customFilterHandler: createCustomFilterHandler("created_at"),
        accessor: "created_at",
        Cell: CreatedAtColumn,
      },
      {
        Header: "Updated At",
        filterable: true,
        hasCustomFilterData: true,
        customValue: filters?.updated_at || "",
        customFilterHandler: createCustomFilterHandler("updated_at"),
        accessor: "updated_at",
        Cell: UpdatedAtColumn,
      },
      {
        Header: "View Details",
        accessor: "view",
        disableFilters: true,
        Cell: cellProps => (
          <div className="d-flex gap-2">
            <span className="subscription-actions">
              <box-icon
                name="detail"
                className="icon-button"
                onClick={() =>
                  toggleShowSubscriptionDetails(cellProps.row.original)
                }
              ></box-icon>

              <box-icon
                name="package"
                type="solid"
                className="icon-button"
                disabled={
                  cellProps.row.original.subscription_type.payment_source ===
                  SubscriptionPaymentSource.subscriptions
                }
                onClick={() =>
                  toggleShowSubscriptionItems(cellProps.row.original)
                }
              ></box-icon>
            </span>
          </div>
        ),
      },
      {
        Header: "Edit",
        accessor: "edit",
        disableFilters: true,
        Cell: cellProps => (
          <div className="d-flex gap-2">
            <button
              className="btn btn-sm btn-rounded btn-primary"
              onClick={() =>
                toggleShowSubscriptionModify(cellProps.row.original)
              }
            >
              Modify
            </button>
          </div>
        ),
      },
    ],
    [filters]
  )

  return !isAdminUser ? (
    <Forbidden />
  ) : (
    <>
      <SubscriptionDetailsModal
        isOpen={showSubscriptionDetails}
        toggle={toggleShowSubscriptionDetails}
        subscription={subscription}
        subscriptionHistory={subscriptionHistory}
        subscriptionDetails={subscriptionDetails}
        onRefetchSubscriptions={onRefetchSubscriptions}
      />

      <SubscriptionItemsModal
        isOpen={showSubscriptionItems}
        toggle={toggleShowSubscriptionItems}
        subscription={subscription}
        onRefetchSubscriptions={onRefetchSubscriptions}
      />

      <SubscriptionModifyModal
        isOpen={showSubscriptionModify}
        toggle={toggleShowSubscriptionModify}
        subscription={subscription}
        subscriptionDetails={subscriptionDetails}
        onRefetchSubscriptions={onRefetchSubscriptions}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Backoffice" breadcrumbItem="Subscriptions" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={subscriptions.rows ?? []}
                    customPageCount={subscriptions.total_pages}
                    customPageIndex={subscriptions.page}
                    isAddOptions={true}
                    customPageSize={10}
                    isManualPagination={true}
                    fetchDataHandler={fetchDataHandler}
                    filtersData={filters}
                    isLoading={isLoading}
                    areDropdownsHidden={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Subscriptions
