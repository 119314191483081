import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

const Countries = ({ validationType, allCountries }) => {
  const handleCountrySelection = selectedCountry => {
    let countryCodes = validationType.values.countryCodes || ""
    let paymentMethods = validationType.values.paymentMethods || []

    if (selectedCountry.checked) {
      if (!countryCodes.includes(selectedCountry.country)) {
        countryCodes += countryCodes
          ? `, ${selectedCountry.country}`
          : selectedCountry.country
        const filteredPayment = []
        let isInclude = false
        paymentMethods.map(el => {
          if (el.country == selectedCountry.country && el.id) {
            isInclude = true
            filteredPayment.push({
              ...el,
              active: true,
              paymentMethods: "CreditCard",
            })
          } else {
            filteredPayment.push(el)
          }
        })
        if (!isInclude) {
          filteredPayment.push({
            country: selectedCountry.country,
            paymentMethods: "CreditCard",
            active: true,
          })
        }
        validationType.setValues({
          ...validationType.values,
          countryCodes: countryCodes,
          paymentMethods: filteredPayment,
        })
      }
    } else {
      countryCodes = countryCodes
        .split(", ")
        .filter(code => code !== selectedCountry.country)
        .join(", ")
      const filteredPayment = []
      paymentMethods.map(el => {
        if (el.country == selectedCountry.country && el.id) {
          filteredPayment.push({
            ...el,
            active: false,
          })
        } else if (el.country !== selectedCountry.country) {
          filteredPayment.push(el)
        }
      })
      validationType.setValues({
        ...validationType.values,
        paymentMethods: filteredPayment,
        countryCodes: countryCodes,
      })
    }
  }

  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Countries</CardTitle>
                <div className="d-flex">
                  {allCountries.map(country => (
                    <div
                      className="form-check countries-checkbox"
                      key={country}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={country}
                        name={country}
                        checked={
                          !!validationType.values.countryCodes.includes(country)
                        }
                        onChange={e =>
                          handleCountrySelection({
                            checked: e.target.checked,
                            country,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`${country}`}
                      >
                        {country}
                      </label>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Countries
