import { useState, useEffect, useRef } from "react"
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Label,
  Spinner,
} from "reactstrap"
import { useLocation } from "react-router-dom"
import { callGetApi } from "../../utils/api"
import { VARIANT_SELECTOR_MODE } from "constants/variantSelectorMode"

const ProductSpinner = ({ style }) => (
  <div className="spinner-loader" style={style}>
    <Spinner className="ms-2" color="primary" />
  </div>
)

export const ProductList = ({
  onSelectedChange,
  multiProducts,
  shop,
  prevSelection = [],
  variantSelectorMode = VARIANT_SELECTOR_MODE.NO_SELECT_VARIANT,
  maxSelection = Infinity,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingVariant, setIsLoadingVariant] = useState(null)
  const [allProducts, setAllProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState(prevSelection)
  let store = "shapees"
  let env = process.env.REACT_APP_ENV
  if (shop.includes("truekind")) {
    if (env === "prod") {
      store = "truekind-store"
    } else if (env === "staging") {
      store = "truekind-beta"
    } else {
      store = "truekind-dev"
    }
  } else if (shop.includes("revel")) {
    if (env === "prod") {
      store = "revel"
    } else if (env === "staging") {
      store = "revel-stage"
    } else {
      store = "revel-dev"
    }
  } else if (shop.includes("spa")) {
    if (env === "prod") {
      store = "the-spa-dr"
    } else if (env === "staging") {
      store = "thespadr-dev"
    } else {
      store = "thespadr-dev"
    }
  } else {
    if (env === "prod") {
      store = "shapermint"
    } else if (env === "staging") {
      store = "shapees"
    } else {
      store = "shapees"
    }
  }
  const [searchParam, setSearchParam] = useState({
    query: "",
    store_id: store,
  })
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 200,
    total: 0,
  })

  const getProductDetail = async (productId, successCb, errorCb) => {
    callGetApi(
      true,
      `/upselling/products/${productId}`,
      data => {
        const { title, slug, images, variations_definition } = data
        const { variatons_summary, product_variations } = variations_definition
        const defaultAttributes = product_variations[0].variation_attributes
        let { product_dimensions } = variatons_summary

        if (product_dimensions) {
          product_dimensions = product_dimensions.map(dim => {
            const { value } = defaultAttributes.find(
              attribute => attribute.name === dim.name
            )
            return {
              ...dim,
              selected: value,
            }
          })
        } else {
          product_dimensions = []
        }

        successCb({
          title,
          slug,
          images,
          product_variations,
          product_dimensions,
        })
      },
      errorCb
    )
  }

  const addProductAndVariant = product => {
    const newProduct = {
      ...product,
      selectVariant: false,
    }

    if (multiProducts) {
      setSelectedProducts([...selectedProducts, newProduct])
    } else {
      setSelectedProducts([newProduct])
    }

    if (variantSelectorMode === VARIANT_SELECTOR_MODE.SHOULD_SELECT_VARIANT) {
      showVariantSelection(true, product)
    }

    if (variantSelectorMode === VARIANT_SELECTOR_MODE.SHOULD_SELECT_VARIANT) {
      showVariantSelection(true, product)
    }
  }

  const handleAddProducts = (e, product) => {
    if (e.target.checked) {
      addProductAndVariant(product)
    } else {
      if (multiProducts && selectedProducts.length < maxSelection) {
        setSelectedProducts(selectedProducts => {
          const updatedProducts = selectedProducts.filter(
            el => product.id !== el.id
          )
          return updatedProducts
        })
      } else {
        setSelectedProducts([])
      }
    }
  }

  const handleSearchProducts = event => {
    const searchParam0 = {
      ...searchParam,
      query: event.target.value,
    }
    setSearchParam(searchParam0)
  }

  const handlePaginationClick = pageNumber => {
    const paginationParam0 = {
      ...paginationParam,
      currentPage: pageNumber,
    }
    loadProducts(paginationParam0, searchParam)
  }

  const onGetProductsSuccess = (data, isShopifyId) => {
    if (isShopifyId) {
      setPaginationParam({
        ...paginationParam,
        currentPage: 1,
        total: 0,
      })
      setAllProducts([
        {
          ...data,
        },
      ])
      setIsLoading(false)
    } else {
      setPaginationParam({
        ...paginationParam,
        currentPage: data.pagination.page_number,
        total: data.pagination.total_pages,
      })
      setAllProducts(data.products)
      setIsLoading(false)
    }
  }

  const onGetProductsFail = () => {
    setIsLoading(false)
    setAllProducts([])
  }

  const loadProducts = async (paginationParam0, searchParam0) => {
    setIsLoading(true)
    if (!isNaN(searchParam0.query) && searchParam0.query) {
      callGetApi(
        true,
        `/upselling/vendors/shopify/stores/${searchParam0.store_id}/products/${searchParam0.query}`,
        data => onGetProductsSuccess(data, true),
        onGetProductsFail,
        "products"
      )
    } else {
      let params
      if (searchParam0.query) {
        params = {
          ...searchParam0,
          page_number: paginationParam0.currentPage,
          page_size: paginationParam0.perPage,
        }
      } else {
        params = {
          store_id: searchParam0.store_id,
          page_number: paginationParam0.currentPage,
          page_size: paginationParam0.perPage,
        }
      }
      const query = Object.keys(params)
        .map(k => k + "=" + params[k])
        .join("&")
      callGetApi(
        true,
        `/upselling/search/products?${query}`,
        onGetProductsSuccess,
        onGetProductsFail,
        "products"
      )
    }
  }

  useEffect(() => {
    setSearchParam(prev => ({
      ...prev,
      store_id: store,
    }))
  }, [store])

  useEffect(() => {
    const timer = setTimeout(() => {
      const paginationParam0 = {
        ...paginationParam,
        currentPage: 1,
      }
      loadProducts(paginationParam0, searchParam)
    }, 1000)

    return () => clearTimeout(timer)
  }, [searchParam])

  const handleChangeVariant = (event, product) => {
    const { value, name } = event.target

    const updatedProducts = prev =>
      prev.map(el => {
        if (el.id === product.id) {
          return {
            ...el,
            product_dimensions: el.product_dimensions.map(dim => {
              if (dim.name === name) {
                return {
                  ...dim,
                  selected: value,
                }
              }
              return dim
            }),
          }
        }
        return el
      })

    setSelectedProducts(updatedProducts)
  }

  const showVariantSelection = (selectVariant, product) => {
    if (selectVariant) {
      setIsLoadingVariant(product.id)
      getProductDetail(
        product.id,
        data => {
          setSelectedProducts(prev =>
            prev.map(el =>
              el.id === product.id ? { ...el, ...data, selectVariant } : el
            )
          )
          setIsLoadingVariant(null)
        },
        () => setIsLoadingVariant(null)
      )
    } else {
      setSelectedProducts(prev =>
        prev.map(el =>
          el.id === product.id
            ? {
                ...el,
                product_variations: undefined,
                product_dimensions: undefined,
                selectVariant,
              }
            : el
        )
      )
    }
  }

  useEffect(() => {
    onSelectedChange(selectedProducts)
  }, [selectedProducts])

  return (
    <div>
      <div className="mb-3">
        <Input
          type="text"
          placeholder="Search Products here..."
          value={searchParam.query}
          onChange={handleSearchProducts}
        />
      </div>
      {selectedProducts?.length > 0 && (
        <div>
          <h6 className="modal-title mt-0 mb-2">Selected Products</h6>
          {selectedProducts.map((product, index) => (
            <div className="selected-product-item" key={index}>
              <img src={product.images?.[0]?.src} />

              <div>
                <Label className="form-label">{product.title}</Label>
                {variantSelectorMode ===
                  VARIANT_SELECTOR_MODE.CAN_SELECT_VARIANT ||
                VARIANT_SELECTOR_MODE.SHOULD_SELECT_VARIANT ? (
                  <>
                    <div className="mb-2 d-flex" style={{ gap: ".5rem" }}>
                      <input
                        className="form-check-input display-checkbox"
                        type="checkbox"
                        onChange={e =>
                          showVariantSelection(e.target.checked, product)
                        }
                        checked={product.selectVariant}
                        disabled={isLoadingVariant || isLoading}
                      />
                      <span>Select variant</span>
                    </div>
                  </>
                ) : null}

                {isLoadingVariant === product.id ? (
                  <div className="w-100 d-flex justify-content-center">
                    <ProductSpinner />
                  </div>
                ) : product?.selectVariant &&
                  product?.product_dimensions?.length ? (
                  <div>
                    <div className="d-flex gap-2" style={{ maxWidth: 300 }}>
                      {product?.product_dimensions.map((variant, i) => (
                        <Input
                          key={i}
                          name={variant.name}
                          type="select"
                          bsSize="sm"
                          onChange={event =>
                            handleChangeVariant(event, product)
                          }
                          value={variant.selected}
                          disabled={variant.values.length < 2}
                        >
                          {variant.values.map(option => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Input>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      )}
      <h6 className="modal-title mt-0 mb-2">Products</h6>
      <div className="search-product-list">
        {isLoading ? (
          <ProductSpinner style={{ minHeight: 300 }} />
        ) : (
          <div>
            {allProducts.map((product, index) => {
              return (
                <div className="search-product-item" key={index}>
                  <div className="search-checkbox">
                    <input
                      disabled={isLoadingVariant || isLoading}
                      className="form-check-input display-checkbox"
                      type="checkbox"
                      onChange={e => handleAddProducts(e, product)}
                      checked={
                        selectedProducts?.filter(el => el.id == product.id)
                          ?.length > 0
                      }
                    />
                  </div>
                  <img src={product.images?.[0]?.src} />
                  <Label className="form-label">{product.title}</Label>
                </div>
              )
            })}
          </div>
        )}
      </div>
      {paginationParam.total > 0 && (
        <div className="mt-3">
          <Pagination listClassName="justify-content-center">
            <PaginationItem disabled={paginationParam.currentPage === 1}>
              <PaginationLink
                previous
                onClick={() =>
                  handlePaginationClick(paginationParam.currentPage - 1)
                }
              />
            </PaginationItem>
            {/* Render pagination links dynamically */}
            {Array.from({ length: paginationParam.total }).map((_, index) => (
              <PaginationItem
                key={index}
                active={paginationParam.currentPage === index + 1}
              >
                <PaginationLink
                  onClick={() => handlePaginationClick(index + 1)}
                >
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem
              disabled={paginationParam.currentPage === paginationParam.total}
            >
              <PaginationLink
                next
                onClick={() =>
                  handlePaginationClick(paginationParam.currentPage + 1)
                }
              />
            </PaginationItem>
          </Pagination>
        </div>
      )}
    </div>
  )
}

const SearchProducts = ({ returnSaveProducts, setIsOpen, multiProducts }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const shop = queryParams.get("shop")
  const selectedProducts = useRef([])

  const handleSelectedChange = value => {
    selectedProducts.current = value
  }

  const saveProducts = () => {
    returnSaveProducts(selectedProducts.current)
    setIsOpen(false)
  }

  return (
    <div>
      <ProductList
        onSelectedChange={handleSelectedChange}
        multiProducts={multiProducts}
        shop={shop}
      />
      <div className="modal-footer pb-0 px-0">
        <button
          type="button"
          className="btn btn-primary"
          onClick={saveProducts}
        >
          Save changes
        </button>
      </div>
    </div>
  )
}

export default SearchProducts
