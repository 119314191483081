const DealPrice = ({blockManager, bgColor, bgOffColor}) => {
  return (
    blockManager.add("deal-price", {
        id: "deal-price",
        label: "Deal Price",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="text-align: center; margin-top: 30px; font-family: 'Arial'">
        <span style="font-size: 16px; color: #808080a6; font-weight: normal; text-decoration: line-through; margin-bottom: 10px;">
          Regular price $40.00
        </span>
        <br />
        <p style="display: flex; justify-content: center; font-weight: 800; font-size: 24px; line-height: 32px; color: ${bgColor}; margin-bottom: 10px; margin-block-start: 0; margin-block-end: 0;">
          This Deal $12.00
          <span style="margin-left: 8px; align-items: center; padding: 0 12px; border-radius: 8px; font-weight: 800; line-height: 18px; font-size: 14px; text-transform: uppercase; display: inline-flex; height: 28px; color: #fff; background: ${bgOffColor};">
            70% Off
          </span>
        </p>
      </div>`,
      })
  )
}

export default DealPrice