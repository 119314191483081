export const subscriptionStatuses = [{
  label: "Active", value: "active"
}, {
  label: "Paused", value: "paused"
}, {
  label: "Pending", value: "pending"
}, {
  label: "Cancelled", value: "cancelled"
}, {
  label: "No Existing Items", value: "no_existing_items"
}, {
  label: "Cancelled Due Order Fail", value: "cancelled_due_order_fail"
}]

export function normalizeStatus(status) {
  if (status.includes(" ")) {
    return status.split(" ").join("_").toLowerCase()
  }
  return status.toLowerCase()
}
