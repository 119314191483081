import React, { useEffect, useState } from "react"
import {
  Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap"
import "./due-date.scss"
import swal from "sweetalert"
import { callPatchApi } from "../../../../../utils/api"
import { showErrorToastr, showSuccessToastr } from "../../../../../components/Common/toastr"
import { errorHandler } from "../../../../../utils/error"
import { getCategoryAndSubcategoryReasons, REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED } from "../modify"
import "react-datepicker/dist/react-datepicker.css"
import { getFormattedDate } from "../../../../../utils/date"

const DropdownComponent = ({
                             index,
                             title,
                             valueKey,
                             dropdownOpen,
                             toggleDropdown,
                             selectedDropdownValues,
                             handleSelectedDropdownValues,
                             handleFormInput,
                             defaultValues,
                             handleCategoryChange,
                             categoriesKeysMap
                           }) => (<>
  <span>{title}</span>
  <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
    <DropdownToggle caret>
      {selectedDropdownValues[index] || "Select"} {}
    </DropdownToggle>
    <DropdownMenu>
      {defaultValues[valueKey] && defaultValues[valueKey].map(value => (<DropdownItem
        key={value}
        onClick={() => {
          handleSelectedDropdownValues(index, value)
          handleFormInput(valueKey, (valueKey === "reasonCategory" ? categoriesKeysMap[value] : value))
          if (handleCategoryChange) {
            handleCategoryChange(value)
          }
        }}
      >
        {value}
      </DropdownItem>))}
    </DropdownMenu>
  </Dropdown>
</>)

function SubscriptionDueDateModal({ isOpen, toggle, subscription, toggleIsUpdated, subscriptionDetails }) {
  const [defaultValues, setDefaultValues] = useState({
    reasonCategory: [], reasonSubcategory: [], dueDate: ""
  })
  let [formValues, setFormValues] = useState({
    reasonCategory: "", reasonSubcategory: "", dueDate: ""
  })

  const [isDropdownOpen, setIsDropdownOpen] = useState({})
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({})
  const [subcategoriesLabelsMap, setSubcategoriesLabelsMap] = useState({})

  const [categoriesKeysMap, setCategoriesKeysMap] = useState({})
  const [subcategoriesKeysMap, setSubcategoriesKeysMap] = useState({})

  const [store, setStore] = useState({})
  let [isFormValid, setIsFormValid] = useState(false)

  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState(false)
  const [nextDueDate, setNextDueDate] = useState({})

  useEffect(() => {
    if (!isOpen) {
      return
    }

    setCategoryAndSubcategoryReasons()
    toggleNextDueDate()
    toggleIsSubscriptionCancelled()
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      return
    }
    setDefaultValues({
      reasonCategory: [], reasonSubcategory: [], dueDate: ""
    })
    setFormValues({
      reasonCategory: "", reasonSubcategory: "", dueDate: ""
    })

    setIsDropdownOpen({})
    setSelectedDropdownValues({})
    setSubcategoriesLabelsMap({})
    setIsFormValid(false)
    setIsSubscriptionCancelled(true)
    setNextDueDate(null)
  }, [isOpen])

  useEffect(() => {
    const isDueDateValid = formValues.dueDate !== null && formValues.dueDate !== ""

    isFormValid = isDueDateValid

    if (formValues.reasonSubcategory !== "") {
      const isReasonCategoryValid = !!store[formValues.reasonCategory]
      const isReasonSubcategoryValid = isReasonCategoryValid && store[formValues.reasonCategory]["subcategories"].some(subcategoryMap => subcategoryMap[formValues.reasonSubcategory])

      isFormValid = isFormValid && isReasonCategoryValid && isReasonSubcategoryValid
    }

    setIsFormValid(isFormValid)
  }, [formValues])

  const setCategoryAndSubcategoryReasons = () => {
    const result = getCategoryAndSubcategoryReasons(subscription)

    const store = result[0]
    const categoriesKeysMap = result[1]
    const subcategoriesKeysMap = result[2]
    const categoriesLabels = result[3]
    const subcategoriesLabels = result[4]

    setStore(store)
    setCategoriesKeysMap(categoriesKeysMap)
    setSubcategoriesKeysMap(subcategoriesKeysMap)
    setSubcategoriesLabelsMap(subcategoriesLabels)

    setDefaultValues(prevState => ({
      ...prevState, reasonCategory: categoriesLabels
    }))
  }

  const onUpdateFail = (error) => {
    showErrorToastr("Failed to update the due date")

    errorHandler(error)
  }

  const onUpdateSuccess = () => {
    showSuccessToastr("The due date was updated successfully")

    toggleIsUpdated()
  }

  const updateSubscriptionModificationFields = async () => {
    const payload = getSubscriptionModificationFieldsPayload(subscriptionDetails, formValues)

    callPatchApi(true, `/subscriptions/${subscription.id}`, payload, onUpdateSuccess, onUpdateFail, "auth")
  }

  const updateSubscription = async (payload) => {
    callPatchApi(true, `/schedules/${payload.id}`, payload, updateSubscriptionModificationFields, onUpdateFail, "auth")
  }

  const toggleNextDueDate = () => {
    if (!isSchedulePresent(subscriptionDetails)) {
      return
    }

    setNextDueDate(getFormattedDate(subscriptionDetails.due_date, "short"))
  }

  const toggleIsSubscriptionCancelled = async () => {
    if (!isSchedulePresent(subscriptionDetails)) {
      return
    }

    setIsSubscriptionCancelled(false)
  }

  const toggleDropdown = (index) => {
    setIsDropdownOpen(prevState => ({ ...prevState, [index]: !prevState[index] }))
  }

  const handleSelectedDropdownValues = (index, value) => {
    setSelectedDropdownValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleFormInput = (index, value) => {
    if (index === "reasonSubcategory") {
      value = subcategoriesKeysMap[formValues.reasonCategory][value]
    }

    setFormValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleCategoryChange = (selectedCategory) => {
    const categoryKey = categoriesKeysMap[selectedCategory]
    const subcategories = subcategoriesLabelsMap[categoryKey]

    setDefaultValues(prevState => ({
      ...prevState, reasonSubcategory: subcategories
    }))

    handleSelectedDropdownValues(2, null)
  }

  const handleDateChange = event => {
    const date = getFormattedDate(event.target.value, "short")

    setNextDueDate(date)
    setFormValues(prevState => ({ ...prevState, dueDate: date }))
  }

  const handleUpdateSubscription = async () => {
    if (!isFormValid) {
      return
    }

    if (isSubscriptionCancelled) {
      showErrorToastr("The subscription cannot be updated")
      return
    }

    const payload = getSubscriptionDueDatePayload(subscription, formValues)

    const confirm = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to update this subscription?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true
    })

    if (!confirm) {
      return
    }

    await updateSubscription(payload)

    toggle()
  }


  const handleSubmit = async () => {
    await handleUpdateSubscription()
  }

  return (<Modal
    isOpen={isOpen}
    role="dialog"
    autoFocus={true}
    centered={true}
    className="subscription-due-date-modal"
    tabIndex="-1"
    toggle={toggle}
  >
    <ModalHeader toggle={toggle}>Due Date</ModalHeader>
    <ModalBody>
      <div className="flex-container wrap">
        <div className="item">
          <DropdownComponent
            index={0}
            title="Reason Category"
            valueKey="reasonCategory"
            dropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            selectedDropdownValues={selectedDropdownValues}
            handleSelectedDropdownValues={handleSelectedDropdownValues}
            defaultValues={defaultValues}
            handleFormInput={handleFormInput}
            handleCategoryChange={handleCategoryChange}
            categoriesKeysMap={categoriesKeysMap}
          />
        </div>

        <div className="item">
          <DropdownComponent
            index={1}
            title="Reason Subcategory"
            valueKey="reasonSubcategory"
            dropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            selectedDropdownValues={selectedDropdownValues}
            handleSelectedDropdownValues={handleSelectedDropdownValues}
            defaultValues={defaultValues}
            handleFormInput={handleFormInput}
            categoriesKeysMap={categoriesKeysMap}
          />
        </div>

        <div className="item">
          <span>Due Date</span>
          <input
            placeholder="Next Due Date"
            type="date"
            className="form-control"
            name="nextPaymentDate"
            value={nextDueDate}
            onChange={handleDateChange}
          />
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <div className="container-footer">
        <Button
          className="btn-accept"
          onClick={handleSubmit}
          disabled={!isFormValid}
        >
          Accept
        </Button>

        <Button
          className="btn-cancel"
          onClick={toggle}
        >
          Cancel
        </Button>
      </div>
    </ModalFooter>
  </Modal>)
}

export default SubscriptionDueDateModal

function isSchedulePresent(subscriptionDetails) {
  return subscriptionDetails.due_date !== null && subscriptionDetails.due_date !== ""
}

function getOldAndNewValues(oldValue, newValue) {
  return {
    old: oldValue, new: newValue
  }
}

function getModificationsFields(subscriptionDetails, form) {
  const oldDueDate = getFormattedDate(subscriptionDetails.due_date, "short")
  return {
    due_date: getOldAndNewValues(oldDueDate, form.dueDate)
  }
}

function getSubscriptionModificationFieldsPayload(subscriptionDetails, form) {
  let modificationsReason = REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED
  if (form.reasonCategory !== "" && form.reasonSubcategory !== "") {
    modificationsReason = form.reasonCategory + " - " + form.reasonSubcategory
  }

  return {
    due_date: form.dueDate,
    modifications_reason: modificationsReason,
    modifications_fields: getModificationsFields(subscriptionDetails, form)
  }
}

function getSubscriptionDueDatePayload(subscription, form) {
  const schedule = subscription.current_schedule
  return {
    id: schedule.id, subscription_id: schedule.subscription_id, due_date: form.dueDate
  }
}

