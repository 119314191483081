import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Label, Input } from "reactstrap"

const Quantity = ({ validationType }) => {
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Quantity</CardTitle>
                <div>
                  <div className="mb-3">
                    <Label className="form-label">Display quantity</Label>
                    <select
                      name="displayQuantity"
                      className="form-select"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.displayQuantity}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {validationType.values.displayQuantity == "Yes" && (
                    <div className="mb-3">
                      <Label className="form-label quantity-checkbox">
                        <input
                          className="form-check-input display-checkbox"
                          type="checkbox"
                          id="limitQuantity"
                          onChange={validationType.handleChange}
                          checked={validationType.values.limitQuantity}
                        />
                        Limit quantity
                      </Label>
                      {validationType.values.limitQuantity && (
                        <Input
                          name="quantity"
                          type="number"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.quantity}
                        />
                      )}
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Quantity
