import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import React from "react"
import { SubscriptionDiscounts } from "./SubscriptionDiscounts"

function SubscriptionDiscountsModal({
  isOpen,
  toggle,
  subscriptionType,
}) {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      toggle={toggle}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Subscription Discounts Details</ModalHeader>
      <ModalBody>
        <p className="mb-4">
          Subscription Type ID:{" "}
          <span className="text-primary">{subscriptionType?.id}</span>
        </p>
        {subscriptionType?.id ? (
          <SubscriptionDiscounts
            subscriptionType={subscriptionType}
          />
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SubscriptionDiscountsModal
