import { useState } from "react"
import { Input, Label, Form, Col, Row, Spinner, FormFeedback } from "reactstrap"
import { useFormik } from "formik"
import { showSuccessToastr, showErrorToastr } from "components/Common/toastr"
import { callPutApi } from "utils/api"
import "./index.scss"
import { discountTypes, discountTargets } from "utils/coupons"

import "./index.scss"
import { formatDate } from "utils/coupons"
import * as Yup from "yup"

const EditCouponModal = ({ setIsOpen, coupon, setCodeFilter, setCoupon }) => {
  const [isLoading, setIsLoading] = useState(false)

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: coupon.code,
      store: coupon.store,
      discount_type: coupon.discount_type,
      discount_target: coupon.discount_target,
      discount_value: coupon.discount_value,
      start_date: formatDate(coupon.start_date),
      end_date: coupon.end_date ? formatDate(coupon.end_date) : "",
      owner: coupon.owner ? coupon.owner : "",
      rules: JSON.stringify(coupon.rules, null, 2),
      status: coupon.status,
    },
    validationSchema: Yup.object().shape({
      start_date: Yup.date().required("Please enter a start date."),
      discount_value: Yup.number()
        .moreThan(0, "The value must be greater than 0.")
        .required("Please enter a value."),
      rules: Yup.string()
        .test("is-json", "Invalid JSON format.", value => {
          try {
            JSON.parse(value)
            return true
          } catch {
            return false
          }
        })
        .required("Please enter a JSON string."),
    }),
    onSubmit: values => {
      setIsLoading(true)
      if (values.owner === "") {
        values.owner = null
      }
      if (values.end_date === "") {
        values.end_date = null
      }
      const formattedValues = { ...values, rules: JSON.parse(values.rules) }
      callPutApi(
        true,
        `/coupons/${coupon.id}`,
        formattedValues,
        onEditCouponSuccess,
        onEditCouponFail
      )
    },
  })

  const onEditCouponSuccess = value => {
    setCodeFilter(value.code)
    if (value.discount_type == "percentage") {
      value.discount_value = value.discount_value * 100
    }
    setCoupon(value)
    showSuccessToastr("Coupon edited Successfully!")
    setIsLoading(false)
    setIsOpen(false)
  }

  const onEditCouponFail = e => {
    setIsLoading(false)
    showErrorToastr(e)
  }

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validationType.handleSubmit()
          return false
        }}
      >
        <div>
          <div className="mt-2 coupon-form">
            <Row>
              <Col md={12}>
                <div className="mt-2 coupon-form">
                  <Label className="mb-1">Discount type</Label>
                  <select
                    className="form-control"
                    name={"discount_type"}
                    value={validationType.values.discount_type}
                    onChange={e => {
                      validationType.setFieldValue(
                        "discount_type",
                        e.target.value
                      )
                    }}
                  >
                    {discountTypes.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mt-2 coupon-form">
                  <Label className="mb-1">Discount target</Label>
                  <select
                    className="form-control"
                    name={"discount_target"}
                    value={validationType.values.discount_target}
                    onChange={e => {
                      validationType.setFieldValue(
                        "discount_target",
                        e.target.value
                      )
                    }}
                  >
                    {discountTargets.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <Label className="form-label">Discount Value</Label>
                  <Input
                    name="discount_value"
                    type="text"
                    value={validationType.values.discount_value}
                    onChange={e => {
                      validationType.setFieldValue(
                        "discount_value",
                        isNaN(Number(e.target.value))
                          ? 0
                          : Number(e.target.value)
                      )
                    }}
                    invalid={
                      validationType.touched.discount_value &&
                      validationType.errors.discount_value
                        ? true
                        : false
                    }
                  />
                  {validationType.touched.discount_value &&
                  validationType.errors.discount_value ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.discount_value}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Start Date</Label>
                  <Input
                    name="start_date"
                    type="date"
                    value={validationType.values.start_date}
                    onChange={validationType.handleChange}
                    invalid={
                      validationType.touched.start_date &&
                      validationType.errors.start_date
                        ? true
                        : false
                    }
                  />
                  {validationType.touched.start_date &&
                  validationType.errors.start_date ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.start_date}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">End Date</Label>
                  <Input
                    name="end_date"
                    type="date"
                    value={validationType.values.end_date}
                    onChange={validationType.handleChange}
                  />
                </div>

                <div className="mb-3">
                  <Label className="form-label">Owner</Label>
                  <Input
                    name="owner"
                    type="text"
                    value={validationType.values.owner}
                    onChange={validationType.handleChange}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Rules</Label>
                  <Input
                    name="rules"
                    type="textarea"
                    style={{ height: "150px" }}
                    value={validationType.values.rules}
                    onChange={validationType.handleChange}
                    invalid={
                      validationType.touched.rules &&
                      validationType.errors.rules
                        ? true
                        : false
                    }
                  />
                  {validationType.touched.rules &&
                  validationType.errors.rules ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.rules}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="modal-footer pb-0 px-0">
          <button type="submit" className="btn btn-primary">
            {isLoading ? (
              <Spinner size={"sm"} className="mx-3 mb-0 mt-1" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default EditCouponModal
