/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { SubscriptionItem } from "./SubscriptionItem"
import { SubscriptionItemForm } from "./SubscriptionItemForm"
import swal from "sweetalert"
import { callGetApi, callPatchApi } from "utils/api"

function SubscriptionItems(props) {
  const { subscription, onRefetchSubscriptions, isDisabled = false } = props

  const [recordItemsUpdated, setRecordItemsUpdated] = useState({})
  const [itemsUpdated, setItemsUpdated] = useState([])
  const [isUpdatingProductModificationPeriod, setIsUpdatingProductModificationPeriod] = useState(false)
  const [productModificationPeriod, setProductModificationPeriod] = useState(subscription?.product_modification_period || "")
  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionItems, setSubscriptionItems] = useState([])

  const onRequestFail = (error) => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          throw new Error("Sorry! the page you are looking for could not be found")
        case 500:
          throw new Error("Sorry! something went wrong, please contact our support team")
        case 401:
          throw new Error("Invalid credentials")

        default:
          throw new Error(`Status: ${error.response.status} Error: ${error.message}`)
      }
    }
    setIsLoading(false)
    throw error
  }

  const onGetSubscriptionItemsSuccess = data => {
    setSubscriptionItems(data)
    setIsLoading(false)
  }

  const getSubscriptionItems = (subscriptionId) => {
    callGetApi(true, `/subscriptions/${subscriptionId}/subscription_items`, onGetSubscriptionItemsSuccess, onRequestFail, "auth")
  }

  const onRefetchSubscriptionItems = (subscriptionId) => {
    console.log("Refetching subscriptions items...")
    getSubscriptionItems(subscriptionId)
  }

  useEffect(() => {
    getSubscriptionItems(subscription?.id)
  }, [])

  useEffect(() => {
    setProductModificationPeriod(subscription?.product_modification_period || "")
  }, [subscription?.product_modification_period])

  const handleSetItemsUpdated = (oldItem, newItem) => {
    setRecordItemsUpdated({
      ...recordItemsUpdated, [newItem?.id || ""]: {
        old: oldItem, new: newItem
      }
    })
  }

  const handleSetItemRemoved = (item) => {
    setRecordItemsUpdated({
      ...recordItemsUpdated, [item?.id || ""]: null
    })
  }

  useEffect(() => {
    setItemsUpdated(Object.keys(recordItemsUpdated)
      .filter(itemId => recordItemsUpdated[itemId])
      .map(itemId => recordItemsUpdated[itemId]))
  }, [recordItemsUpdated])

  const handleChangeProductModificationPeriod = (event) => {
    setProductModificationPeriod(Number(event.target.value) > 0 ? Number(event.target.value) : "")
  }

  const onUpdateSubscriptionFail = (error) => {
    swal({
      title: "Oops!", text: "Something went wrong updating price modification period!", icon: "error"
    })

    onRequestFail(error)
    setIsLoading(false)
    setIsUpdatingProductModificationPeriod(false)
  }

  const onUpdateSubscriptionSuccess = () => {
    if (typeof onRefetchSubscriptions === "function") {
      onRefetchSubscriptions()
    }

    swal({
      title: "Updated!",
      text: `The price modification price has been updated to ${productModificationPeriod}!`,
      icon: "success"
    })

    setIsLoading(false)
    setIsUpdatingProductModificationPeriod(false)
  }

  const updateSubscription = async (subscriptionId, subscription) => {
    callPatchApi(true, `/subscriptions/${subscriptionId}`, subscription, onUpdateSubscriptionSuccess, onUpdateSubscriptionFail, "auth")
  }

  const handleUpdateProductModificationPeriod = async (event) => {
    event.preventDefault()
    if (!productModificationPeriod) {
      return
    }

    setIsUpdatingProductModificationPeriod(true)
    const confirm = await swal({
      title: "Are you sure?",
      text: `Are you sure that you want to update product modification price by ${productModificationPeriod}?`,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true
    })

    if (!confirm) {
      setIsLoading(false)
      return
    }

    updateSubscription(subscription.id, {
      product_modification_period: productModificationPeriod
    })
  }

  return (<div className="d-flex flex-column gap-4">
    <SubscriptionItemForm
      subscriptionId={subscription?.id}
      onRefetchItems={onRefetchSubscriptionItems}
      onItemCreated={(newItem) => handleSetItemsUpdated(null, newItem)}
    />
    <div className="d-flex align-items-center gap-4">
      <h3>Product Modification Period:</h3>
      <h6>{productModificationPeriod || "Not Assigned"}</h6>
      <h3>Product Modification Date:</h3>
      <h6>{subscription?.product_modification_date || "Not Assigned"}</h6>
    </div>
    <div className="position-relative mt-2">
      {isLoading ? (<div className="spinner-loader" style={{ minHeight: 300 }}>
        <Spinner className="ms-2" color="primary" />
      </div>) : (<>
        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <thead>
            <tr>
              <th scope="col">Actions</th>
              <th scope="col">ID</th>
              <th scope="col">Product Name</th>
                    <th scope="col" className="px-5">
                Price
              </th>
              <th scope="col" className="px-5">
                Compare at Price
              </th>
              <th scope="col">SKU</th>
              <th scope="col" className="px-5">
                Quantity
              </th>
              <th scope="col">Product ID</th>
              <th scope="col">Variant ID</th>
            </tr>
            </thead>
            <tbody>
            {subscriptionItems?.map((item) => (<SubscriptionItem
              key={item?.id}
              item={item}
              onRefetchItems={onRefetchSubscriptionItems}
              onItemUpdated={handleSetItemsUpdated}
              onItemRemoved={handleSetItemRemoved}
              isDisabled={isDisabled}
            />))}
            </tbody>
          </Table>
        </div>
        {itemsUpdated.length ? (<div className="d-flex flex-column gap-4 mt-4">
          <form onSubmit={handleUpdateProductModificationPeriod}>
            <label>
              These products will have modification with this period
            </label>
            <div className="d-flex gap-4">
              <input
                placeholder="Product Modification Period"
                value={productModificationPeriod}
                type="number"
                onChange={handleChangeProductModificationPeriod}
                className="form-control w-50"
              />
              <button
                type="submit"
                className="btn btn-success"
                disabled={isUpdatingProductModificationPeriod}
              >
                Update
              </button>
            </div>
          </form>
          <div className="d-flex flex-column gap-4">
            <h4>Subscription Items updated</h4>
            {itemsUpdated.map(({ new: newItem, old: oldItem }, index) => (
              <div key={index} className="card border border-secondary ounded-3">
                <div className="card-header text-center">
                  <h5>Subscription Item ID: {newItem?.id}</h5>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table className="table align-middle table-nowrap">
                      <thead>
                      <tr>
                        <th scope="col">Property</th>
                        <th scope="col">Old</th>
                        <th scope="col">New</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>Product ID</td>
                        <td>{oldItem?.product_id}</td>
                        <td>{newItem?.product_id}</td>
                      </tr>
                      <tr>
                        <td>Variant ID</td>
                        <td>{oldItem?.variant_id}</td>
                        <td>{newItem?.variant_id}</td>
                      </tr>
                      <tr>
                        <td>Price</td>
                        <td>{oldItem?.price}</td>
                        <td>{newItem?.price}</td>
                      </tr>
                      <tr>
                        <td>Quantity</td>
                        <td>{oldItem?.quantity}</td>
                        <td>{newItem?.quantity}</td>
                      </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>))}
          </div>
        </div>) : null}
      </>)}
    </div>
  </div>)
}

export default SubscriptionItems
