/* eslint-disable no-undef */
const ProductViewer = editor => {
  editor.DomComponents.addType("product-viewer", {
    isComponent: el => el.classList?.contains("product-viewer"),
    model: {
      defaults: {
        tagName: "div",
        classes: ["product-viewer"],
        resizable: true,
        images: [],
        "script-props": ["loadExternalResource"],
        styles: `
        .product-viewer {
          margin: 0;
          padding: 0;
          position: relative;
          max-height: 45rem;
          display: flex;
          flex-shrink: 0;
        }

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .swiper-slide img {
          display: block;
          width: 100%;
          height: 100%;
        }
    
        .swiper {
          height: 300px;
          margin-left: auto;
          margin-right: auto;
        }
    
        .swiper-slide {
          background-size: cover;
          background-position: center;
        }
    
        .product-viewer-swiper-2 {
          max-width: 100vw;
          height: 100%;
        }
    
        .product-viewer-swiper {
          height: 100%;
          box-sizing: border-box;
          flex-shrink: 0;
          display: none;
        }
    
        .product-viewer-swiper .swiper-slide {
          padding-right: 10px;
          height: 100%;
          opacity: 0.4;
        }
    
        .product-viewer-swiper .swiper-slide-thumb-active {
          opacity: 1;
        }

        @media (min-width: 768px) {
          .product-viewer {
            height: 100vh;
          }

          .product-viewer-swiper {
            display: block;
          }

          .product-viewer-swiper-2 {
            flex-shrink: 0;
          }

          .swiper-pagination {
            display: none;
          }
        }

        @media (min-width: 522px) {
          .product-viewer-swiper-2 {
            max-width: 522px;
          }
        }
        `,
        components: `
          <div thumbsSlider="" class="swiper product-viewer-swiper">
            <div class="swiper-wrapper"></div>
          </div>
          <div class="swiper product-viewer-swiper-2">
            <div class="swiper-wrapper"></div>
            <div class="swiper-pagination"></div>
          </div>
        `,
        script: function () {
          window.productViewerSwiperController = null
          function initSwiper() {
            // eslint-disable-next-line no-undef
            const productViewerSwiper = new Swiper(".product-viewer-swiper", {
              spaceBetween: 15,
              slidesPerView: 6,
              freeMode: true,
              watchSlidesProgress: true,
              direction: "vertical",
              scrollbar: true,
              mousewheel: true,
            })
            // eslint-disable-next-line no-undef
            productViewerSwiperController = new Swiper(
              ".product-viewer-swiper-2",
              {
                spaceBetween: 10,
                thumbs: {
                  // eslint-disable-next-line no-undef
                  swiper: productViewerSwiper,
                },
                pagination: {
                  el: ".swiper-pagination",
                  enabled: true,
                  clickable: true,
                },
              }
            )

            if (window.location !== window.parent.location) {
              const events = ["DOMNodeInserted", "DOMNodeRemoved"]
              events.forEach(event => {
                document
                  .querySelector(".product-viewer-swiper")
                  .addEventListener(
                    event,
                    () => productViewerSwiper && productViewerSwiper.update()
                  )
              })
              events.forEach(event => {
                document
                  .querySelector(".product-viewer-swiper-2")
                  // eslint-disable-next-line no-undef
                  .addEventListener(
                    event,
                    () =>
                      productViewerSwiperController &&
                      productViewerSwiperController.update()
                  )
              })
            }
          }

          initSwiper()
        },
      },
      handleImagesChange(_, images) {
        this.components().models.forEach((model, index) => {
          model.components().models[0].components(`
            ${images
              .map(item => {
                return `
                <div class="swiper-slide" ${index === 1 ? `id=${item.id}` : ""}>
                  <img src="${item.src}" />
                </div>
              `
              })
              .join("")}
          `)
        })
      },
      setOfferFlag(value) {
        const currentPage = editor.Pages.getSelected()
        currentPage.set("hasOfferGallery", value)
      },
      init() {
        this.on("change:images", this.handleImagesChange)
        this.setOfferFlag(true)
      },
      removed() {
        this.setOfferFlag(false)
      },
    },
  })
}

export default ProductViewer
