import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input,
  InputGroup,
} from "reactstrap"

const Shipping = ({ validationType, shippingTypes }) => {
  return (
    <div>
      <div>
        <CardTitle>Headless Checkout Settings</CardTitle>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Shipping</CardTitle>
                <div>
                  <div className="mb-3">
                    <Label className="form-label">Shipping option</Label>
                    <select
                      name="shippingTypeId"
                      className="form-select"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.shippingTypeId}
                    >
                      {shippingTypes?.map((type, index) => {
                        return (
                          <option key={index} value={type.id}>
                            {type.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  {validationType.values.shippingTypeId ==
                    shippingTypes?.filter(e => e.name == "flat")?.[0]?.id && (
                    <div className="mb-3">
                      <Label className="form-label">Shipping value</Label>
                      <InputGroup className="input-grp">
                        <div className="input-group-text">$</div>
                        <Input
                          name="shippingValue"
                          type="number"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.shippingValue}
                        />
                      </InputGroup>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Shipping
