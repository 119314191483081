const YesButton = ({blockManager, borderRadius, hoverBgColor, bgColor, color}) => {
    blockManager.add("yes-button", {
        id: "yes-button",
        label: "Yes Button",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="max-width: 420px; width: 100%; margin: 0 auto;">
        <button
          type="button"
          style="display: flex; justify-content: center; font-family: 'Arial'; align-items: center; text-decoration: none; text-transform: uppercase; box-sizing: border-box; width: 100%; font-size: 0.875rem; padding: 0.688rem 2rem; font-weight: 600; border-radius: ${borderRadius}; cursor: pointer; background-color: ${bgColor}; color: ${color}; border: none; line-height: normal; letter-spacing: normal; min-width: unset; min-height: unset; transition: background-color 0.3s ease-in-out;"
          onmouseover="this.style.backgroundColor='${hoverBgColor}'"
          onmouseout="this.style.backgroundColor='${bgColor}'"
        >
          Yes, I want this special offer
        </button>
      </div>
      `,
      })
}

export default YesButton