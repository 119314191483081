import React, { useEffect } from "react"
import { Input, Label, InputGroup, Form } from "reactstrap"
import * as Yup from "yup"
import { callPutApi } from "../../../../utils/api"
import { useFormik } from "formik"
import { showSuccessToastr } from "../../../../components/Common/toastr"

const OffersSetModal = ({
  setIsOpen,
  editOfferSetData,
  validationType,
  shop,
}) => {
  const offerSetValidationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      priority: "",
      name: "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: () => {
      let payload = {
        ...editOfferSetData,
        ...offerSetValidationType.values,
      }
      callPutApi(
        true,
        `/upselling/funnels/offer-sets/${editOfferSetData.id}?shop=${shop}`,
        payload,
        () => onUpdateOfferSetSuccess(payload),
        onUpdateOfferSetFail
      )
    },
  })

  const handlePriorityChange = event => {
    const { value } = event.target
    const newPriority = value ? parseInt(value, 10) : ""
    const currentSum = validationType.values.offerSets.reduce(
      (sum, offer) =>
        sum + (offer.id !== editOfferSetData.id ? offer.priority : 0),
      0
    )
    const remainingSpace = 100 - currentSum
    if (newPriority <= remainingSpace) {
      offerSetValidationType.setFieldValue("priority", newPriority)
    } else {
      offerSetValidationType.setFieldValue("priority", remainingSpace)
    }
  }

  const onUpdateOfferSetSuccess = data => {
    const currentOfferSets = validationType.values.offerSets
    const updatedOfferSets = currentOfferSets.map(offer => {
      if (data.id == offer.id) {
        return data
      } else {
        return offer
      }
    })
    validationType.setFieldValue(`offerSets`, updatedOfferSets)
    showSuccessToastr("Offer Set Updated Successfully!")
    setIsOpen(false)
  }

  const onUpdateOfferSetFail = () => {}

  useEffect(() => {
    if (editOfferSetData) {
      offerSetValidationType.setValues({
        priority: editOfferSetData?.priority,
        name: editOfferSetData?.name,
      })
    }
  }, [editOfferSetData])

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          offerSetValidationType.handleSubmit()
          return false
        }}
      >
        <div>
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="name"
              type="text"
              onChange={offerSetValidationType.handleChange}
              onBlur={offerSetValidationType.handleBlur}
              value={offerSetValidationType.values.name}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Percentage</Label>
            <InputGroup className="input-grp">
              <div className="input-group-text">%</div>
              <Input
                name="priority"
                type="number"
                onChange={handlePriorityChange}
                onBlur={offerSetValidationType.handleBlur}
                value={offerSetValidationType.values.priority}
              />
            </InputGroup>
          </div>
        </div>
        <div className="modal-footer pb-0 px-0">
          <button type="submit" className="btn btn-primary">
            Save changes
          </button>
        </div>
      </Form>
    </div>
  )
}

export default OffersSetModal
