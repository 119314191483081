import React, { useEffect } from "react"
import withRouter from "components/Common/withRouter"
import Header from "./Header"
import Sidebar from "./Sidebar"

const Layout = props => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar
          theme={"dark"}
          type={"dark"}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {}

export default withRouter(Layout)
