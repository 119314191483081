/* eslint-disable react/no-unknown-property */
const DropdownSelector = editor => {
    editor.DomComponents.addType("dropdown-selector", {
      isComponent: el => el.classList?.contains("dropdown-selector"),
      model: {
        defaults: {
          tagName: "div",
          style: { display: 'inline-flex' },
          classes: ["dropdown-selector"],
          data: {options: [], name: ''},
					styles: `
						.dropdown {
              cursor: pointer;
              border-radius: 0.375rem;
              box-sizing: border-box;
              font-size: 0.75rem;
              background: #fff;
              color: #292929;
              border: 0.063rem solid #a6a6a6;
              font-weight: 600;
              height: 36px;
              min-width: 4rem;
          	}
					`
        },

        handleOptionsChange(_, {options, name}) {
          this.components(
						<div style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
							<p style="margin: 0px 0px 5px 0px; font-size: 14px; line-height: 22px; padding: 0; color: black; text-align: center;  font-weight: 800;font-family: 'Arial'">
                {name}
              </p>
							<select class="dropdown" value="1">
								{options.map((value, i) =>
									<option key={i} value={value}>{value}</option>
								)}
							</select>
						</div>
          )
        },
        init() {
          this.on("change:data", this.handleOptionsChange)
        },
      },
    })
  }
  
  export default DropdownSelector