import axios from "axios"
import store from "../epics/store"
import {
  getIdToken,
  getToken,
  saveStorage,
  getUser,
  removeStorage,
} from "./auth"

const authEndpoint = process.env.REACT_APP_AUTH_API_URL
const endpoint = process.env.REACT_APP_API_URL
let source

export const abortRequest = () => {
  if (!source) return

  source.cancel("Request canceled by other request")
}

const callApi = (
  method,
  needAuth,
  path,
  data,
  doneCallback,
  failCallback,
  urlFor,
  options = {}
) => {
  source = axios.CancelToken.source()

  const isFormData = data instanceof FormData

  const baseUrl = urlFor === "auth" ? authEndpoint : endpoint

  const idToken = getIdToken()
  if (needAuth) {
    const token = getToken()
    if (!token) {
      if (failCallback) failCallback("Token not exists.")
      store.dispatch({
        type: "api.API_FAILED",
        payload: { errorMsg: "Token not exists" },
      })
      return
    }
    options = {
      ...options,
      cancelToken: source.token,
      headers: {
        Authorization: "Bearer " + token,
        LoginID: idToken,
        ...(!isFormData && { "Content-Type": "application/json" }),
        ...options.headers,
      },
    }
  } else {
    options = {
      ...options,
      cancelToken: source.token,
      headers: {
        LoginID: idToken,
        ...(!isFormData && { "Content-Type": "application/json" }),
        ...options.headers,
      },
    }
  }
  store.dispatch({ type: "api.API_STARTED" })

  let axiosCall
  if (method === "POST") {
    axiosCall = axios.post(`${baseUrl}${path}`, data, options)
  } else if (method === "GET") {
    axiosCall = axios.get(`${baseUrl}${path}`, options)
  } else if (method === "PUT") {
    axiosCall = axios.put(`${baseUrl}${path}`, data, options)
  } else if (method === "DELETE") {
    axiosCall = axios.delete(`${baseUrl}${path}`, options)
  } else if (method === "PATCH") {
    axiosCall = axios.patch(`${baseUrl}${path}`, data, options)
  }
  axiosCall.then(
    res => {
      if (doneCallback) doneCallback(res.data)
      store.dispatch({ type: "api.API_DONE" })
    },
    err => {
      let errorMessage = "Network error."
      let status = 503
      if (err.response) {
        errorMessage = err.response.data.message
        status = err.response.status
      }
      if (failCallback) failCallback(errorMessage)
      if (status === 401) {
        let refreshToken = getUser()?.refresh_token
        if (refreshToken) {
          let axiosCall_ = axios.post(
            `${authEndpoint}/auth/login/refresh/token/${refreshToken}`,
            {},
            {
              cancelToken: source.token,
              headers: {
                LoginID: idToken,
                ...(!isFormData && { "Content-Type": "application/json" }),
                ...options.headers,
              },
            }
          )
          axiosCall_.then(
            res_ => {
              saveStorage(res_.data)
              window.location.reload()
            },
            err_ => {
              if (err_.response) {
                removeStorage()
                if (window?.location?.pathname) {
                  window.location.pathname = "/login"
                }
              }
            }
          )
        } else {
          removeStorage()
          if (window?.location?.pathname) {
            window.location.pathname = "/login"
          }
        }
      }
    }
  )
}

export const callGetApi = (
  needAuth = true,
  path,
  doneCallback,
  failCallback,
  urlFor
) => {
  callApi("GET", needAuth, path, undefined, doneCallback, failCallback, urlFor)
}

export const callPostApi = (
  needAuth = true,
  path,
  data,
  doneCallback,
  failCallback,
  urlFor,
  options
) => {
  callApi(
    "POST",
    needAuth,
    path,
    data,
    doneCallback,
    failCallback,
    urlFor,
    options
  )
}

export const callPutApi = (
  needAuth = true,
  path,
  data,
  doneCallback,
  failCallback,
  urlFor
) => {
  callApi("PUT", needAuth, path, data, doneCallback, failCallback, urlFor)
}

export const callPutPricingApi = (
  needAuth = true,
  path,
  data,
  doneCallback,
  failCallback,
  urlFor,
  options
) => {
  callApi(
    "PUT",
    needAuth,
    path,
    data,
    doneCallback,
    failCallback,
    urlFor,
    options
  )
}

export const callDeleteApi = (
  needAuth = true,
  path,
  doneCallback,
  failCallback,
  urlFor
) => {
  callApi(
    "DELETE",
    needAuth,
    path,
    undefined,
    doneCallback,
    failCallback,
    urlFor
  )
}

export const callPatchApi = (
  needAuth = true,
  path,
  data,
  doneCallback,
  failCallback,
  urlFor,
  options
) => {
  callApi(
    "PATCH",
    needAuth,
    path,
    data,
    doneCallback,
    failCallback,
    urlFor,
    options
  )
}
