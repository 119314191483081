import React, { useState } from "react"
import { Modal, Button } from "reactstrap"
import { allShopsEnv } from "../../../utils/shop"
import { showErrorToastr } from "../../../components/Common/toastr"
import { useNavigate } from "react-router-dom"
import "./shop-selection.scss"

const AllFunnels = () => {
  document.title = "Shop Selection"
  const [shop, setShop] = useState("")
  const history = useNavigate()

  const handleShopSelection = () => {
    if (shop) {
      history(`/create-funnel?shop=${shop}`)
    } else {
      showErrorToastr("Please select shop!")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Modal size="xl" isOpen={true} className="modal-fullscreen">
          <div className="modal-body">
            <h3 className="shop-selection-title">Select Your Shop</h3>
            <div className="shop-selection-container">
              {allShopsEnv[process.env.REACT_APP_ENV].map(option => {
                return (
                  <Button
                    color="primary"
                    className={
                      option.value == shop
                        ? "selected-shop-button"
                        : "shop-button"
                    }
                    type="button"
                    key={option.value}
                    onClick={() => {
                      setShop(option.value)
                    }}
                  >
                    {option.label}
                  </Button>
                )
              })}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShopSelection}
            >
              Save
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default AllFunnels
