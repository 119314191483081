import { v4 as uuidv4 } from "uuid"
import {
  SET_STATE,
  SET_QUESTIONS,
  ADD_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  SET_ANSWERS,
  ADD_ANSWER,
  UPDATE_ANSWER,
  DELETE_ANSWER,
  SELECT_QUESTION,
  SET_SIDECAR_QUESTION,
} from "./actionTypes"

import { QUESTION_TYPES } from "../constants"
import { QUESTIONS_KEY } from "../../utils"

const generateGroupAnswers = () => [
  {
    title: "Answer 1",
    id: uuidv4(),
  },
  {
    title: "Answer 2",
    id: uuidv4(),
  },
  {
    title: "Answer 3",
    id: uuidv4(),
  },
]

const generateSingleAnswer = () => [
  {
    title: "Placeholder",
    id: uuidv4(),
  },
]

function reducer(state, action) {
  switch (action.type) {
    case SET_STATE: {
      return action.state
    }
    case SET_QUESTIONS: {
      return {
        ...state,
        questions: action.questions,
      }
    }
    case ADD_QUESTION: {
      return {
        ...state,
        questions: [
          ...state.questions,
          {
            id: uuidv4(),
            title: action.title,
            type: QUESTION_TYPES.MULTIPLE_CHOICE,
            answers: generateGroupAnswers(),
            sidecar: false,
          },
        ],
      }
    }
    case UPDATE_QUESTION: {
      const { question } = action
      return {
        ...state,
        questions: state.questions.map(q => {
          if (q.id === question.id) {
            let answers = q.answers

            if (question.type !== q.type) {
              answers =
                question.type === QUESTION_TYPES.TEXT
                  ? generateSingleAnswer()
                  : generateGroupAnswers()
            }

            return {
              ...q,
              ...question,
              answers,
            }
          }
          return q
        }),
      }
    }
    case DELETE_QUESTION: {
      return {
        ...state,
        questions: state.questions.filter(q => q.id !== action.questionId),
      }
    }
    case SET_ANSWERS: {
      return {
        ...state,
        questions: state.questions.map(q => {
          if (q.id === action.questionId) {
            return {
              ...q,
              answers: action.answers,
            }
          }
          return q
        }),
      }
    }
    case ADD_ANSWER: {
      return {
        ...state,
        questions: state.questions.map(q => {
          if (q.id === action.questionId) {
            return {
              ...q,
              answers: [
                ...q.answers,
                {
                  title: action.title,
                  id: uuidv4(),
                },
              ],
            }
          }
          return q
        }),
      }
    }
    case UPDATE_ANSWER: {
      const newState = {
        ...state,
        questions: state.questions.map(q => {
          if (q.id === action.questionId) {
            return {
              ...q,
              answers: q.answers.map(a => {
                if (a.id === action.answerId) {
                  return {
                    ...a,
                    title: action.title,
                  }
                }
                return a
              }),
            }
          }
          return q
        }),
      }
      return newState
    }
    case DELETE_ANSWER: {
      return {
        ...state,
        questions: state.questions.map(q => {
          if (q.id === action.questionId) {
            return {
              ...q,
              answers: q.answers.filter(a => a.id !== action.answerId),
            }
          }
          return q
        }),
      }
    }
    case SELECT_QUESTION: {
      return {
        ...state,
        selected: action.questionId,
      }
    }
    case SET_SIDECAR_QUESTION: {
      const { question } = action
      return {
        ...state,
        questions: state.questions.map(q => {
          if (q.id === question.id) {
            return {
              ...q,
              sidecar: question.value,
            }
          }

          return {
            ...q,
            sidecar: false,
          }
        }),
      }
    }
    default: {
      throw Error("Unknown action: " + action.type)
    }
  }
}

const quizEditorReducer = (state, action) => {
  const newState = reducer(state, action)
  localStorage.setItem(QUESTIONS_KEY, JSON.stringify(newState.questions))
  return newState
}

export default quizEditorReducer
