import { useState } from "react"

import { Container, Modal, Spinner } from "reactstrap"
import { FileUploader } from "react-drag-drop-files"

import { showSuccessToastr } from "components/Common/toastr"
import { showErrorToastr } from "components/Common/toastr"
import { callPostApi } from "utils/api"
import csvIcon from "assets/images/csv.png"

import "./index.scss"

const UploadFileModal = ({ isOpen, setOpen }) => {
  const fileTypes = ["CSV"]
  const [file, setFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = file => {
    setFile(file)
  }

  const fileSizeText = bytes => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    if (bytes === 0) return "0 Byte"
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i]
  }

  const handleSaveCoupon = () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append("file", file)

    callPostApi(
      true,
      "/coupons/bulk",
      formData,
      () => {
        setIsLoading(false)
        setFile(null)
        showSuccessToastr("CSV uploaded succesfully!")
        setOpen(false)
      },
      () => {
        setIsLoading(false)
        showErrorToastr("Something went wrong!")
      },
      undefined,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  }

  return (
    <Modal isOpen={isOpen} size="m" className="upload-modal">
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Upload CSV File
        </h5>

        <button
          type="button"
          onClick={() => {
            setOpen(false)
            setFile(null)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body page-content create-tax pt-3 pb-3">
        <Container fluid={true}>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            style={{ width: "100%", height: "100%" }}
            multiple={false}
          >
            <div id="uploader-box">
              <h4>
                <i className="mdi mdi-cloud-upload" style={{ fontSize: 45 }} />
              </h4>
              <h3>Drop file here or click to upload</h3>
            </div>
          </FileUploader>
          {file && (
            <div className="icon-wrapper">
              <img className="icon" src={csvIcon} alt="csv-icon" />
              <div className="file-name">
                <p>
                  {file.name}
                  <br />
                  <b>{fileSizeText(file.size)}</b>
                </p>
              </div>
            </div>
          )}
        </Container>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setFile(null)
            setOpen(false)
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSaveCoupon}
          disabled={!file}
        >
          {isLoading ? (
            <Spinner size={"sm"} className="mx-3 mb-0 mt-0" />
          ) : (
            "Save"
          )}
        </button>
      </div>
    </Modal>
  )
}

export default UploadFileModal
