const Spacing = ({blockManager}) => {
    blockManager.add("spacing", {
        id: "spacing",
        label: "Spacing",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="margin: 25px 0px"></div>`,
      })
}

export default Spacing