/**
 * Translates error messages from API responses to user-friendly text.
 * @param {string} errorMessage The error message from the API response.
 * @return {string} The translated or formatted error message.
 */
export const getFriendlyErrorMessage = errorMessage => {
  if (errorMessage.includes("Network Error")) {
    return "There was a problem connecting to the server. Please check your internet connection and try again."
  }

  return errorMessage || "An unexpected error occurred. Please try again."
}
