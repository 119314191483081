import { CAN_EDIT } from "../shared/ProductSelector/constants"

export const modalButtons = (editor, webComponent) => {
  const modal = editor.Modal
  const content = document.createElement("div")
  content.style = "height: 100%; width: 100%;"
  content.innerHTML = `
      <${webComponent}></${webComponent}>
      <div style="display: flex; justify-content: flex-end; gap: 1rem; align-items: center;">
          <button class="gjs-btn-prim quiz-btn-accept">Accept</button>
          <button class="gjs-btn-prim quiz-btn-cancel">Cancel</button>
      </div>
    `

  content.querySelector(".quiz-btn-accept").addEventListener("click", () => {
    localStorage.setItem(CAN_EDIT, true)
    modal.close.call(editor.Modal)
  })
  content.querySelector(".quiz-btn-cancel").addEventListener("click", () => {
    localStorage.setItem(CAN_EDIT, false)
    modal.close.call(editor.Modal)
  })

  return { modal, content }
}
