/* eslint-disable no-undef */
const OfferProduct = editor => {
  const loadExternalResource = window.loadExternalResource

  editor.DomComponents.addType("unique-variant-offer", {
    isComponent: el => el.classList?.contains("unique-variant-offer"),
    model: {
      defaults: {
        tagName: "div",
        classes: ["unique-variant-offer"],
        style: {
          width: "fit-content",
          margin: "24px auto",
          fontFamily: "Arial, sans-serif",
        },
        loadExternalResource: loadExternalResource,
        product: null,
        offerData: {
          product: null,
          variation: null,
          slug: null,
          hasDimensions: true,
        },
        styles: `
          .offer-title {
            max-width: 700px;
            font-size: 1.8rem;
            text-align: center;
          }
          .price-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: .5rem;
            margin-top: 0;
          }

          .price {
            font-weight: bold;
            font-size: 1.6rem;
          }

          .compare-price {
            font-weight: 400;
            color: #9e9e9e;
            text-decoration: line-through;
            font-size: 1.3rem;
          }

          .buyNow {
            background-color: #f7a08b;
            color: black;
            padding: 0.875rem 2rem;
            border: none;
            border-radius: 0.5rem;
            font-size: 1rem;
            font-weight: 600;
            transition: background-color 0.3s ease-in-out;
            width: 100%;
          }

          .buyNow:hover {
            background-color: #fcd9d1;
          }
        `,
        "script-props": ["offerData", "loadExternalResource"],
        script: function ({ offerData, loadExternalResource }) {
          const { slug, variation, hasDimensions } = offerData

          if (!slug) return

          const baseConfig = {
            currency: "USD",
            domain: "shapees.com",
            vendor: "shopify",
            store: "shapees",
            environment: "development",
          }

          function initVariantSelectorLib() {
            // eslint-disable-next-line no-undef
            VariantSelector({
              base: baseConfig,
              product: {
                slug: slug,
                quantity: 1,
                color: () => "color",
                size: () => "size",
              },
              triggers: {
                buyNow: ".buyNow",
              },
            })
          }

          function initProductSelectorLib() {
            // eslint-disable-next-line no-undef
            ProductSelector.init({
              base: baseConfig,
              products: {
                slugs: [slug],
                triggers: [".product"],
              },
              triggered_by: ".buyNow",
            })
          }

          function initLib() {
            if (hasDimensions) initVariantSelectorLib()
            else initProductSelectorLib()
          }

          if (
            (hasDimensions && typeof VariantSelector !== "undefined") ||
            (!hasDimensions && typeof ProductSelector !== "undefined")
          ) {
            initLib()
            return
          }

          const loadExternalResourceFn = eval(loadExternalResource)
          loadExternalResourceFn?.(
            "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@1.7.0/js/products-script.js",
            "script",
            () =>
              loadExternalResourceFn?.(
                "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@1.7.0/js/checkout-script.js",
                "script",
                () =>
                  loadExternalResourceFn?.(
                    `https://cdn.jsdelivr.net/gh/trafilea/storefront-static@1.7.0/js/${
                      hasDimensions ? "variant" : "product"
                    }-selector-script.js`,
                    "script",
                    initLib
                  )
              )
          )
        },
      },
      init() {
        const product = this.get("product")
        if (product) this.renderComponent(null, product)

        this.on("change:product", this.renderComponent)
      },
      renderComponent(_, productData) {
        const { title, slug, images, product_variations, product_dimensions } =
          productData

        let variation = null

        if (product_variations.length === 1) {
          variation = product_variations[0]
        } else {
          variation = product_variations.find(v =>
            product_dimensions.every(dim =>
              v.variation_attributes.some(
                item => dim.selected === item.value && dim.name === item.name
              )
            )
          )
        }

        if (!variation) {
          console.error("No variation found")
          return
        }

        const { price, compare_at_price, images_id } = variation
        const comparePrice = compare_at_price
          ? `<strong class="compare-price">$${compare_at_price}</strong>`
          : ""

        let imageSrc = images[0].src
        if (images_id.length)
          imageSrc = images.find(img => images_id.includes(img.id))?.src

        const imageTag = imageSrc
          ? `<img style="max-height: 21rem;" src=${imageSrc} >`
          : ""

        this.components(`
          <div style="display: flex; flex-direction: column; justify-content: flex-start; align-items: center">
            ${imageTag}
            <h1 class="offer-title">${title}</h1>
            <p class="price-container">
              ${comparePrice}
              <strong class="price">$${price}</strong>
            </p>
            <button class="buyNow">BUY NOW</button>
          <div>
        `)

        this.set("offerData", {
          slug,
          product: productData,
          variation,
          hasDimensions: !!product_dimensions?.length,
        })
      },
    },
  })
}

export default OfferProduct
