import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Spinner } from "reactstrap"
import withRouter from "components/Common/withRouter"
import { callPostApi } from "../../utils/api"
import { useLocation, useNavigate } from "react-router-dom"
import { saveStorage } from "../../utils/auth"

const AuthSecret = () => {
  const history = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const code = queryParams.get("code")

  const checkToken = token => {
    callPostApi(
      false,
      `/auth/login/token/${token}`,
      {},
      onAuthorizeSuccess,
      onAuthorizeFail,
      'auth'
    )
  }

  const onAuthorizeSuccess = data => {
    saveStorage(data)
    history("/all-funnels")
  }

  const onAuthorizeFail = () => {
    history("/login")
  }

  useEffect(() => {
    if (code) {
      checkToken(code)
    }
  }, [code])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5 d-flex justify-content-center">
        <Spinner />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AuthSecret)

AuthSecret.propTypes = {
  history: PropTypes.object,
}
