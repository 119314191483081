const ColorSelector = ({blockManager}) => {
    blockManager.add("color-selector", {
        id: "color-selector",
        label: "Color Selector",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="max-width: 420px; width: 100%; margin: 0 auto; justify-content: center; display: flex; align-items: center;">
        <div style="display: inline-flex;">
            <div style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
                <p style="margin: 0px 0px 5px 0px; font-size: 14px; line-height: 22px; padding: 0; color: black; text-align: center;  font-weight: 800;font-family: 'Arial'">
                    Color
                </p>
                <div>
                    <span style="border: 1px solid black; display: inline-block; width: 33px; height: 33px; border-radius: 50%; padding: 3px; margin: 0px 2px;">
                        <button type="button" style="width: 25px; height: 25px; border-radius: 50%; background: black; border: none;"></button>
                    </span>
                    <span style="border: 1px solid white; display: inline-block; width: 33px; height: 33px; border-radius: 50%; padding: 3px; margin: 0px 2px;">
                        <button type="button" style="width: 25px; height: 25px; border-radius: 50%; background: #efc0a0; border: none;"></button>
                    </span>
                </div>
            </div>
        </div>
    </div>`,
    })
}

export default ColorSelector