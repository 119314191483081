import Guarantee from "../../../assets/images/days.png"


const NewGuarantee = ({blockManager}) => {
    blockManager.add("new-gurantee", {
        id: "new-gurantee",
        label: "New Gurantee",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="width: 40%; margin: auto; background-color: #f7f7f7; display: flex; padding: 5px; border-radius: 8px;font-family: 'Arial'">
        <div>
            <img src="${Guarantee}" alt="" style="height: 35px;">
        </div>
        <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
            <span style="text-align: center; color: #292929; font-size: 14px; font-weight: 800; margin-right: 5px;">Our 60-Day Fit Guarantee</span>
            <span style="text-align: center; color: #292929c2; font-size: 14px;"> Free Exchange & No-Hassle Returns.</span>
        </div>
    </div>
    `,
      })
}

export default NewGuarantee