import PropTypes from "prop-types"
import React from "react"
import { Navigate } from "react-router-dom"
import { pathRoutes, routesByRole } from "routes"
import { getUserRoles } from "utils/auth"


const shouldAllowAccess = (path) => {
  const userRoles = getUserRoles()

  for (const userRole of userRoles) {
    if (path === pathRoutes.Subscriptions || path === pathRoutes.SubscriptionsTypes) {
      return true
    }

    const routeForRole = routesByRole[userRole]?.[path]

    if (routeForRole) {
      return true
    }
  }

  return false
}

const Authmiddleware = (props) => {
  if (!localStorage.getItem("authUser")) {
    return (<Navigate to={{ pathname: "/login", state: { from: props.location } }} />)
  }

  if (!shouldAllowAccess(props.path)) {
    return (<Navigate to={{ pathname: pathRoutes.Home, state: { from: props.location } }} />)
  }

  return <React.Fragment>{props.children}</React.Fragment>
}


Authmiddleware.propTypes = {
  children: PropTypes.any, path: PropTypes.string
}

export default Authmiddleware
