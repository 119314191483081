import { HTML5Backend } from 'react-dnd-html5-backend'

const isInsideQuizEditor = (domNode) => {
  return domNode.closest('quiz-editor')
}

const ModifiedBackend = (...args) => {
  const instance = new HTML5Backend(...args)

  const listeners = [
    'handleTopDragStart',
    'handleTopDragStartCapture',
    'handleTopDragEndCapture',
    'handleTopDragEnter',
    'handleTopDragEnterCapture',
    'handleTopDragLeaveCapture',
    'handleTopDragOver',
    'handleTopDragOverCapture',
    'handleTopDrop',
    'handleTopDropCapture',
  ]
  listeners.forEach((name) => {
    const original = instance[name]
    instance[name] = (e, ...extraArgs) => {
      if (isInsideQuizEditor(e.target)) {
        original(e, ...extraArgs)
      }
    }
  })

  return instance
}

// Decorate root elements with this
export default ModifiedBackend