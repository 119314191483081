import { useState } from "react"
import { Input, Label, Col, Row, Spinner, FormFeedback, Form } from "reactstrap"
import { useFormik } from "formik"
import { showSuccessToastr, showErrorToastr } from "components/Common/toastr"
import { callPostApi } from "utils/api"
import { initialShopEnv } from "../../../utils/shop"
import "./index.scss"
import { allShopsEnv } from "../../../utils/shop"
import { discountTypes, discountTargets } from "utils/coupons"
import * as Yup from "yup"

const CreateCouponModal = ({ setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(false)

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
      store: initialShopEnv[process.env.REACT_APP_ENV],
      discount_target: "shipping",
      discount_type: "percentage",
      discount_value: 0,
      start_date: "",
      end_date: "",
      rules: {
        uses: {
          total: "",
          uses_per_customer: 1,
        },
        purchase: { min_value: 0, min_items: 0 },
        products: [],
        locations: "[]",
        shipping_options: [],
      },

      owner: "",
      status: "active",
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required("Please enter coupon code."),
      start_date: Yup.date().required("Please enter a start date."),
      discount_value: Yup.number()
        .moreThan(0, "The value must be greater than 0.")
        .required("Please enter a value."),
      // rules: Yup.object().shape({
      //   locations: Yup.string()
      //     .test("is-json", "Invalid JSON format.", value => {
      //       try {
      //         JSON.parse(value)
      //         return true
      //       } catch {
      //         return false
      //       }
      //     })
      //     .required("Please enter a JSON string."),
      // }),
    }),
    onSubmit: values => {
      setIsLoading(true)
      if (values.owner === "") {
        values.owner = null
      }
      if (values.end_date === "") {
        values.end_date = null
      }
      if (values.rules.uses.total === 0 || values.rules.uses.total === "") {
        values.rules.uses.total = null
      }
      if (values.rules.uses.uses_per_customer === 0) {
        values.rules.uses.uses_per_customer = null
      }

      const formattedValues = {
        ...values,
        rules: {
          ...values.rules,
          locations: JSON.parse(values.rules.locations),
        },
      }
      callPostApi(
        true,
        "/coupons",
        formattedValues,
        onCreateCouponSuccess,
        onCreateCouponFail
      )
    },
  })

  const onCreateCouponSuccess = () => {
    showSuccessToastr("Coupon created Successfully!")
    setIsLoading(false)
    setIsOpen(false)
  }

  const onCreateCouponFail = e => {
    setIsLoading(false)
    showErrorToastr(e)
  }

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validationType.handleSubmit()
          return false
        }}
      >
        <div>
          <div className="mt-2 coupon-form">
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Code</Label>
                  <Input
                    name="code"
                    type="text"
                    value={validationType.values.code}
                    onChange={validationType.handleChange}
                    invalid={
                      validationType.touched.code && validationType.errors.code
                        ? true
                        : false
                    }
                  />
                  {validationType.touched.code && validationType.errors.code ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.code}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mt-2 coupon-form">
                  <Label className="mb-1">Store</Label>
                  <select
                    className="form-control"
                    value={validationType.values.store}
                    name={"store"}
                    onChange={e => {
                      validationType.setFieldValue("store", e.target.value)
                    }}
                  >
                    {allShopsEnv[process.env.REACT_APP_ENV].map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-2 coupon-form">
                  <Label className="mb-1">Discount type</Label>
                  <select
                    className="form-control"
                    name={"discount_type"}
                    value={validationType.values.discount_type}
                    onChange={e => {
                      validationType.setFieldValue(
                        "discount_type",
                        e.target.value
                      )
                    }}
                  >
                    {discountTypes.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mt-2 coupon-form">
                  <Label className="mb-1">Discount target</Label>
                  <select
                    className="form-control"
                    name={"discount_target"}
                    value={validationType.values.discount_target}
                    onChange={e => {
                      validationType.setFieldValue(
                        "discount_target",
                        e.target.value
                      )
                    }}
                  >
                    {discountTargets.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <Label className="form-label">Discount Value</Label>
                  <Input
                    name="discount_value"
                    type="text"
                    value={validationType.values.discount_value}
                    onChange={e =>
                      validationType.setFieldValue(
                        "discount_value",
                        isNaN(Number(e.target.value))
                          ? 0
                          : Number(e.target.value)
                      )
                    }
                    invalid={
                      validationType.touched.discount_value &&
                      validationType.errors.discount_value
                        ? true
                        : false
                    }
                  />
                  {validationType.touched.discount_value &&
                  validationType.errors.discount_value ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.discount_value}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Start Date</Label>
                  <Input
                    name="start_date"
                    type="date"
                    value={validationType.values.start_date}
                    onChange={validationType.handleChange}
                    invalid={
                      validationType.touched.start_date &&
                      validationType.errors.start_date
                        ? true
                        : false
                    }
                  />
                  {validationType.touched.start_date &&
                  validationType.errors.start_date ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.start_date}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">End Date</Label>
                  <Input
                    name="end_date"
                    type="date"
                    value={validationType.values.end_date}
                    onChange={validationType.handleChange}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Total uses</Label>
                  <Input
                    name="total_uses"
                    type="text"
                    value={validationType.values.rules.uses.total}
                    onChange={e => {
                      validationType.setFieldValue("rules", {
                        ...validationType.values.rules,
                        uses: {
                          ...validationType.values.rules.uses,
                          total:
                            e.target.value === "" ||
                            isNaN(Number(e.target.value))
                              ? ""
                              : Number(e.target.value),
                        },
                      })
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Total uses per customer</Label>
                  <Input
                    name="total_uses_per_customer"
                    type="text"
                    value={validationType.values.rules.uses.uses_per_customer}
                    onChange={e => {
                      validationType.setFieldValue("rules", {
                        ...validationType.values.rules,
                        uses: {
                          ...validationType.values.rules.uses,
                          uses_per_customer: isNaN(Number(e.target.value))
                            ? 0
                            : Number(e.target.value),
                        },
                      })
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Order minimum items</Label>
                  <Input
                    name="order_minimum_items"
                    type="text"
                    value={validationType.values.rules.purchase.min_items}
                    onChange={e => {
                      validationType.setFieldValue("rules", {
                        ...validationType.values.rules,
                        purchase: {
                          ...validationType.values.rules.purchase,
                          min_items: isNaN(Number(e.target.value))
                            ? 0
                            : Number(e.target.value),
                        },
                      })
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Order minimum value</Label>
                  <Input
                    name="order_minimum_value"
                    type="text"
                    value={validationType.values.rules.purchase.min_value}
                    onChange={e => {
                      validationType.setFieldValue("rules", {
                        ...validationType.values.rules,
                        purchase: {
                          ...validationType.values.rules.purchase,
                          min_value: isNaN(Number(e.target.value))
                            ? 0
                            : Number(e.target.value),
                        },
                      })
                    }}
                  />
                </div>
                {/* <div className="mb-3">
                  <Label className="form-label">Locations</Label>
                  <Input
                    name="rules.locations"
                    type="textarea"
                    style={{ height: "150px" }}
                    value={validationType.values.rules.locations}
                    onChange={validationType.handleChange}
                    invalid={
                      validationType.touched.rules &&
                      validationType.touched.rules.locations &&
                      validationType.errors.rules &&
                      validationType.errors.rules.locations
                        ? true
                        : false
                    }
                  />
                  {validationType.touched.rules &&
                  validationType.touched.rules.locations &&
                  validationType.errors.rules &&
                  validationType.errors.rules.locations ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.rules.locations}
                    </FormFeedback>
                  ) : null}
                </div> */}
                <div className="mb-3">
                  <Label className="form-label">Owner</Label>
                  <Input
                    name="owner"
                    type="text"
                    value={validationType.values.owner}
                    onChange={validationType.handleChange}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="modal-footer pb-0 px-0">
          <button type="submit" className="btn btn-primary">
            {isLoading ? (
              <Spinner size={"sm"} className="mx-3 mb-0 mt-1" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default CreateCouponModal
