import React, { useEffect, useState } from "react"
import {
  Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap"
import "./frequency.scss"
import swal from "sweetalert"
import { callPatchApi } from "../../../../../utils/api"
import { showErrorToastr, showSuccessToastr } from "../../../../../components/Common/toastr"
import { errorHandler } from "../../../../../utils/error"
import { getCategoryAndSubcategoryReasons, REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED } from "../modify"

const DropdownComponent = ({
                             index,
                             title,
                             valueKey,
                             dropdownOpen,
                             toggleDropdown,
                             selectedDropdownValues,
                             handleSelectedDropdownValues,
                             handleFormInput,
                             defaultValues,
                             handleCategoryChange,
                             categoriesKeysMap
                           }) => (<>
  <span>{title}</span>
  <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
    <DropdownToggle caret>
      {selectedDropdownValues[index] || "Select"} {}
    </DropdownToggle>
    <DropdownMenu>
      {defaultValues[valueKey] && defaultValues[valueKey].map(value => (<DropdownItem
        key={value}
        onClick={() => {
          handleSelectedDropdownValues(index, value)
          handleFormInput(valueKey, (valueKey === "reasonCategory" ? categoriesKeysMap[value] : value))
          if (handleCategoryChange) {
            handleCategoryChange(value)
          }
        }}
      >
        {value}
      </DropdownItem>))}
    </DropdownMenu>
  </Dropdown>
</>)

function SubscriptionFrequencyModal({
                                      isOpen, toggle, subscription, toggleIsUpdated, subscriptionDetails
                                    }) {
  const [defaultValues, setDefaultValues] = useState({
    frequencyValue: 0, frequencyUnit: [], reasonCategory: [], reasonSubcategory: []
  })
  let [formValues, setFormValues] = useState({
    frequencyValue: 0, frequencyUnit: "", reasonCategory: "", reasonSubcategory: ""
  })

  const [isDropdownOpen, setIsDropdownOpen] = useState({})
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({})

  const [subcategoriesLabelsMap, setSubcategoriesLabelsMap] = useState({})
  const [categoriesKeysMap, setCategoriesKeysMap] = useState({})
  const [subcategoriesKeysMap, setSubcategoriesKeysMap] = useState({})
  const [store, setStore] = useState({})

  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      return
    }

    getFrequencyUnits()
    setCategoryAndSubcategoryReasons()
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      return
    }

    setDefaultValues({
      frequencyValue: 0, frequencyUnit: [], reasonCategory: [], reasonSubcategory: []
    })
    setFormValues({
      frequencyValue: 0, frequencyUnit: "", reasonCategory: "", reasonSubcategory: ""
    })

    setIsDropdownOpen({})
    setSelectedDropdownValues({})
    setSubcategoriesLabelsMap({})
    setStore({})
    setIsFormValid(false)
  }, [isOpen])

  useEffect(() => {
    const isFrequencyValid = formValues.frequencyValue > 0
    const isFrequencyUnitValid = defaultValues.frequencyUnit.includes(formValues.frequencyUnit)

    let isFormValid = isFrequencyValid && isFrequencyUnitValid

    if (formValues.reasonSubcategory !== "") {
      const isReasonCategoryValid = !!store[formValues.reasonCategory]
      const isReasonSubcategoryValid = isReasonCategoryValid && store[formValues.reasonCategory]["subcategories"].some(subcategoryMap => subcategoryMap[formValues.reasonSubcategory])

      isFormValid = isFormValid && isReasonCategoryValid && isReasonSubcategoryValid
    }

    setIsFormValid(isFormValid)
  }, [formValues])

  const getFrequencyUnits = async () => {
    const frequencyUnits = ["Days", "Weeks", "Months"]

    setDefaultValues(prevState => ({
      ...prevState, frequencyUnit: frequencyUnits
    }))
  }

  const setCategoryAndSubcategoryReasons = () => {
    const result = getCategoryAndSubcategoryReasons(subscription)

    const store = result[0]
    const categoriesKeysMap = result[1]
    const subcategoriesKeysMap = result[2]
    const categoriesLabels = result[3]
    const subcategoriesLabelsMap = result[4]

    setStore(store)
    setCategoriesKeysMap(categoriesKeysMap)
    setSubcategoriesKeysMap(subcategoriesKeysMap)
    setSubcategoriesLabelsMap(subcategoriesLabelsMap)

    setDefaultValues(prevState => ({
      ...prevState, reasonCategory: categoriesLabels
    }))
  }

  const onUpdateFail = (error) => {
    showErrorToastr("Failed to update the frequency")

    errorHandler(error)
  }

  const onUpdateSuccess = () => {
    showSuccessToastr("The frequency was updated successfully")

    toggleIsUpdated()
  }

  const updateSubscription = async (subscriptionId, payload) => {
    callPatchApi(true, `/subscriptions/${subscriptionId}`, payload, onUpdateSuccess, onUpdateFail, "auth")
  }

  const toggleDropdown = (index) => {
    setIsDropdownOpen(prevState => ({ ...prevState, [index]: !prevState[index] }))
  }

  const handleSelectedDropdownValues = (index, value) => {
    setSelectedDropdownValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleFormInput = (index, value) => {
    if (index === "reasonSubcategory") {
      value = subcategoriesKeysMap[formValues.reasonCategory][value]
    }

    setFormValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleCategoryChange = (selectedCategory) => {
    const categoryKey = categoriesKeysMap[selectedCategory]
    const subcategories = subcategoriesLabelsMap[categoryKey]

    setDefaultValues(prevState => ({
      ...prevState, reasonSubcategory: subcategories
    }))

    handleSelectedDropdownValues(2, null)
  }

  const handleUpdateSubscription = async () => {
    if (!isFormValid) {
      return
    }

    const payload = getPayload(subscriptionDetails, formValues)

    const confirm = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to update this subscription?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true
    })

    if (!confirm) {
      return
    }

    await updateSubscription(subscription.id, payload)

    toggle()
  }

  const handleSubmit = async () => {
    await handleUpdateSubscription()
  }

  return (<Modal
    isOpen={isOpen}
    role="dialog"
    autoFocus={true}
    centered={true}
    className="subscription-frequency-modal"
    tabIndex="-1"
    toggle={toggle}
  >
    <ModalHeader toggle={toggle}>Frequency</ModalHeader>
    <ModalBody>
      <div className="flex-container wrap">
        <div className="item">
          <span>Frequency Value</span>
          <Input
            type="number"
            min="1"
            value={formValues.frequencyValue !== 0 || !subscriptionDetails?.frequency_value ? formValues.frequencyValue : subscriptionDetails.frequency_value}
            onChange={(event) => handleFormInput("frequencyValue", event.target.value)}
          />
        </div>

        <div className="item">
          <DropdownComponent
            index={0}
            title="Frequency Unit"
            valueKey="frequencyUnit"
            dropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            selectedDropdownValues={selectedDropdownValues}
            handleSelectedDropdownValues={handleSelectedDropdownValues}
            defaultValues={defaultValues}
            handleFormInput={handleFormInput}
          />
        </div>

        <div className="item">
          <DropdownComponent
            index={1}
            title="Reason Category"
            valueKey="reasonCategory"
            dropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            selectedDropdownValues={selectedDropdownValues}
            handleSelectedDropdownValues={handleSelectedDropdownValues}
            defaultValues={defaultValues}
            handleFormInput={handleFormInput}
            handleCategoryChange={handleCategoryChange}
            categoriesKeysMap={categoriesKeysMap}
          />
        </div>

        <div className="item">
          <DropdownComponent
            index={2}
            title="Reason Subcategory"
            valueKey="reasonSubcategory"
            dropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            selectedDropdownValues={selectedDropdownValues}
            handleSelectedDropdownValues={handleSelectedDropdownValues}
            defaultValues={defaultValues}
            handleFormInput={handleFormInput}
            categoriesKeysMap={categoriesKeysMap}
          />
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <Button
        className="btn-accept"
        onClick={handleSubmit}
        disabled={!isFormValid}
      >
        Accept
      </Button>

      <Button
        className="btn-cancel btn-danger"
        onClick={toggle}
      >
        Cancel
      </Button>
    </ModalFooter>
  </Modal>)
}

export default SubscriptionFrequencyModal

function getOldAndNewValues(oldValue, newValue) {
  return {
    old: oldValue, new: newValue
  }
}

function getModificationsFields(subscriptionDetails, form) {
  return {
    frequency_value: getOldAndNewValues(subscriptionDetails.frequency_value, parseInt(form.frequencyValue)),
    frequency_unit: getOldAndNewValues(subscriptionDetails.frequency_unit, form.frequencyUnit.toLowerCase())
  }
}

function getPayload(subscriptionDetails, form) {
  let modificationsReason = REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED
  if (form.reasonCategory !== "" && form.reasonSubcategory !== "") {
    modificationsReason = form.reasonCategory + " - " + form.reasonSubcategory
  }

  return {
    frequency_value: parseInt(form.frequencyValue),
    frequency_unit: form.frequencyUnit.toLowerCase(),
    modifications_reason: modificationsReason,
    modifications_fields: getModificationsFields(subscriptionDetails, form)
  }
}

