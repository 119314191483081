import DeleteModal from "components/Common/DeleteModal"
import { showErrorToastr } from "components/Common/toastr"
import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  Button,
  Label,
  Spinner,
} from "reactstrap"
import { getBundlePercentage } from "utils/pricing"
import CreateOrUpdateBundleDiscountModal from "../CreateOrUpdateBundleDiscountModal"

const BundleDiscounts = ({
  childrenData,
  setChildrenData,
  validationType,
  createDiscount,
  country,
  deleteDiscount,
  isDiscountLoading,
  isDeleteDiscountLoading,
}) => {
  const regex = /^[0-9.]*$/ // Regular expression to match numbers and "."
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenCreateOrUpdateModal, setIsOpenCreateOrUpdateModal] =
    useState(false)

  const onDeleteBunddleDiscount = () => {
    if (childrenData?.children[0].is_fallback_discount) {
      showErrorToastr(
        "Cannot delete fallback discount, try deleting US discount first"
      )
    } else {
      deleteDiscount(validationType.values.bundle_discounts[0].id)
    }
    setIsOpenDeleteModal(false)
  }

  const onSubmit = () => {
    //If there is some free product, we don't need to calculate the percentage, all the others products will be full price.
    let freeChild = false
    let totalPrice = 0
    childrenData.children.forEach(child => {
      if (!child.free_product) {
        totalPrice += parseFloat(child.default.price)
      } else {
        freeChild = true
      }
    })
    const percentageObject = {}
    childrenData.children.forEach(child => {
      const childDiscount = child.free_product
        ? 100
        : freeChild
        ? 0
        : getBundlePercentage(totalPrice, validationType.values.price)
      percentageObject[child.product_id] = childDiscount
    })
    const payload = {
      discount_type: "percentage",
      discount_definition_type: "bundle",
      discount_description: "Discount created from the UI",
      discount_definition:
        Object.keys(percentageObject).length > 0 ? percentageObject : null,
    }

    payload.country = country

    payload.product_id = validationType.values.product_id

    if (freeChild && totalPrice != validationType.values.price) {
      showErrorToastr(
        "Total price of the bundle must be equal to the product price."
      )
    } else {
      // check if discount is fallback or already axist and create or update the discount accordingly.
      createDiscount(payload)
    }
    setIsOpenCreateOrUpdateModal(false)
  }

  return (
    <div>
      <DeleteModal
        show={isOpenDeleteModal}
        onDeleteClick={onDeleteBunddleDiscount}
        onCloseClick={() => setIsOpenDeleteModal(false)}
        message="Are you sure you want to delete this bundle discount?"
      ></DeleteModal>

      <CreateOrUpdateBundleDiscountModal
        icon={
          validationType.values.bundle_discounts.length > 0
            ? "pencil-outline"
            : "plus-thick"
        }
        show={isOpenCreateOrUpdateModal}
        onClick={onSubmit}
        message={
          validationType.values.bundle_discounts.length > 0
            ? "Are you sure you want to edit this bundle discount?"
            : "Are you sure you want to create a new bundle discount for this product?"
        }
        onCloseClick={() => setIsOpenCreateOrUpdateModal(false)}
      ></CreateOrUpdateBundleDiscountModal>
      <Row>
        <Col lg={12}>
          <div className="mb-3">
            <Label className="form-label">Price</Label>
            <Input
              name="price"
              placeholder="Product price"
              value={validationType.values.price || ""}
              onChange={e => {
                if (regex.test(e.target.value)) {
                  validationType.setFieldValue("price", e.target.value)
                }
              }}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Compare at price</Label>
            <Input
              name="compare_at_price"
              placeholder="Compare at price"
              value={validationType.values.compare_at_price || ""}
              onChange={e => {
                if (regex.test(e.target.value)) {
                  validationType.setFieldValue(
                    "compare_at_price",
                    e.target.value
                  )
                }
              }}
            />
          </div>
          <Col lg={12}>
            <div className="d-flex flex-wrap gap-2 form-btn">
              <Button
                color="primary"
                style={{ marginTop: "20px" }}
                onClick={validationType.handleSubmit}
                disabled={country === "US"}
              >
                Change price
              </Button>
            </div>
          </Col>
          <div className="mb-3">
            <CardTitle className="h4">Bundle items</CardTitle>
          </div>
          <div className="mb-3">
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th className="text-center">Title</th>

                        <th className="text-center">Price</th>
                        <th className="text-center">Compare at price</th>
                        <th className="text-center">Free product</th>
                      </tr>
                    </thead>
                    <tbody>
                      {childrenData?.children?.map((child, index) => (
                        <tr key={index}>
                          <td style={{ padding: "0.5rem" }}>
                            <div
                              className="selected-product-item-list"
                              key={index}
                            >
                              <img src={child.images?.[0]?.src} />
                              <span className="bold-text">{child.title}</span>
                            </div>
                          </td>

                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {child.default_prices ? (
                              <b
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                Default
                              </b>
                            ) : (
                              <Input
                                name="compare"
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Price"
                                value={child.default.price || ""}
                                disabled={true}
                              />
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {child.default_prices ? (
                              <b>Default</b>
                            ) : (
                              <Input
                                name="compare"
                                placeholder="Compare at price"
                                value={child.default.compare_at_price || ""}
                                disabled={true}
                              />
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <Input
                              className="form-check-input display-checkbox"
                              type="checkbox"
                              style={{
                                height: "18px",
                                width: "18px",
                              }}
                              id={index}
                              onChange={() => {
                                const updatedChildren = [
                                  ...childrenData.children,
                                ]
                                updatedChildren[index].free_product =
                                  !updatedChildren[index].free_product
                                setChildrenData({
                                  ...childrenData,
                                  children: updatedChildren,
                                })
                              }}
                              checked={child.free_product}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="d-flex flex-wrap gap-2 form-btn">
            {validationType.values.bundle_discounts.length > 0 && (
              <Button
                color="danger"
                style={{ marginTop: "20px", marginRight: "20px" }}
                onClick={() => setIsOpenDeleteModal(true)}
                disabled={validationType.values.bundle_discounts.length === 0}
              >
                {isDeleteDiscountLoading ? (
                  <Spinner size={"sm"} className="mx-3 mb-0 mt-1" />
                ) : (
                  "Delete"
                )}
              </Button>
            )}

            <Button
              color="primary"
              style={{ marginTop: "20px" }}
              onClick={() => setIsOpenCreateOrUpdateModal(true)}
            >
              {isDiscountLoading ? (
                <Spinner size={"sm"} className="mx-3 mb-0 mt-1" />
              ) : validationType.values.bundle_discounts.length > 0 ? (
                "Save"
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default BundleDiscounts
