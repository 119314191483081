const Logo = ({blockManager, url}) => {
    blockManager.add("logo", {
        id: "logo",
        label: "Logo",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="display: flex; align-items: center; justify-content: center; padding: 20px 0; box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.15);margin-bottom: 2px;">
        <img src="${url}" style="height: 45px;">
      </div>
      `,
    })
}

export default Logo