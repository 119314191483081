const NoteText = ({blockManager}) => {
    blockManager.add("note-text", {
        id: "note-text",
        label: "Note Text",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="max-width: 420px; width: 100%; margin: 0 auto;">
        <p style="font-size: 14px; text-align: center; margin-bottom: 4px;font-family: 'Arial'; color: #0000009e">
          <span>
            <b style="color: black;">Note: </b>You can try this 100% risk-free and if you are not
            completely satisfied we will refund your money!
          </span>
          <span>will be added to cover shipping costs.</span>
        </p>
      </div>
      `,
      })
}

export default NoteText