export const toTitleCaseWithSpacing = snakeCaseString => {
  const spacedString = snakeCaseString.replace(/_/g, " ").trim()
  const titleCaseString = spacedString
    .toLowerCase()
    .replace(/(^|\s)\S/g, function (t) {
      return t.toUpperCase()
    })

  return titleCaseString
}

export const formatDate = dateString => {
  const date = new Date(dateString)

  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0") // January is 0
  const year = date.getFullYear()

  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")
  const seconds = String(date.getSeconds()).padStart(2, "0")

  return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`
}
