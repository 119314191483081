import DropdownSelector from "./DropdownSelector"
import ButtonSelector from "./ButtonSelector"
import ColorSelector from "./ColorSelector"

const VariantOption = editor => {
	DropdownSelector(editor)
	ButtonSelector(editor)
	ColorSelector(editor)

  editor.DomComponents.addType("variant-option", {
    isComponent: el => el.classList?.contains("variant-option"),
    model: {
      defaults: {
        tagName: "div",
        style: { width: "100%" },
        classes: ["variant-selector"],
				styles: `
					.variant-option {
						width: 100%;
						margin: 0 auto;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 1rem 0
					}

					@media (min-width: 1110px) {
            .variant-option {
							max-width: 420px;
            }
					}
				`,
				attributes: {name: null},
				variantOptions: [],
				variantType: 'button-selector',
				traits: [
					{
						name: 'variantType',
						label: 'Variant type',
						type: "select",
						changeProp: 1,
						placeholder: "Select control type",
						options: [
							{id:'color-selector', name: 'Color Picker'},
							{id:'dropdown-selector', name: 'Dropdown'},
							{id:'button-selector', name: 'Button Select'},
						],
					}
				]
      },

      loadVariant() {
				const variantType = this.get('variantType')
				const options = this.get('variantOptions')
				const {name} = this.getAttributes()
				this.components(`
					<div class="${variantType}"></div>
				`)
				this.find('.' + variantType)[0].set('data', {options, name})
      },
      init() {
        this.on("change:variantType", this.loadVariant)
        this.on("change:variantOptions", this.loadVariant)
      },
    },
  })
}

export default VariantOption