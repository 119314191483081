import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

const PaymentMethods = ({ validationType, allCountries }) => {
  const allPaymentMethods = [
    "AmazonExpress",
    "PayPalExpress",
    "PayPal",
    "CreditCard",
    "Klarna",
    "ApplePayExpress",
    "GooglePayExpress",
    "AfterPay"
  ]

  const handlePaymentMethodSelection = (method, country) => {
    const paymentMethods =
      validationType.values.paymentMethods?.length > 0
        ? validationType.values.paymentMethods?.map(el => el)
        : []
    const countryIndex = paymentMethods.findIndex(
      entry => entry.country === country
    )
    if (countryIndex !== -1) {
      const existingMethods =
        paymentMethods[countryIndex].paymentMethods.split(", ")
      const methodIndex = existingMethods.indexOf(method)
      if (methodIndex !== -1) {
        existingMethods.splice(methodIndex, 1)
      } else {
        existingMethods.push(method)
      }
      paymentMethods[countryIndex].paymentMethods = existingMethods.join(", ")
      let newPayments = paymentMethods.map((el, ind) => {
        if (ind == countryIndex) {
          return {
            ...el,
            paymentMethods: existingMethods.join(", "),
          }
        }
        return el
      })
      validationType.setValues({
        ...validationType.values,
        paymentMethods: newPayments,
      })
    } else {
      paymentMethods.push({
        country,
        paymentMethods: method,
      })
      validationType.setValues({
        ...validationType.values,
        paymentMethods: [...paymentMethods],
      })
    }
  }
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-3">PaymentMethods</CardTitle>
                {allCountries.map((country, ind) => {
                  const isSelectedCountry = validationType.values.countryCodes
                    .split(", ")
                    .includes(country)
                  const selectedPaymentMethods = isSelectedCountry
                    ? validationType.values.paymentMethods.find(
                        entry => entry.country === country
                      )
                    : null
                  return (
                    isSelectedCountry && (
                      <div key={country}>
                        <div className="d-flex">
                          <CardTitle className="mb-0">{country}</CardTitle>
                          <div
                            className="d-flex"
                            style={{
                              marginLeft: 15,
                              position: "relative",
                              bottom: -1,
                            }}
                          >
                            {allPaymentMethods.map(method => {
                              return (
                                <div
                                  className="form-check countries-checkbox"
                                  key={method}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`${country}-${method}`}
                                    checked={
                                      !!(
                                        selectedPaymentMethods &&
                                        selectedPaymentMethods.paymentMethods
                                          ?.split(", ")
                                          ?.includes(method)
                                      )
                                    }
                                    onChange={() =>
                                      handlePaymentMethodSelection(
                                        method,
                                        country
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`${country}-${method}`}
                                  >
                                    {method}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <div
                          className={
                            ind !== allCountries.length - 1
                              ? "payment-divider"
                              : ""
                          }
                        />
                      </div>
                    )
                  )
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default PaymentMethods
