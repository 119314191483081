import React, { useState, useEffect } from "react"
import {
  Container,
  Table,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { callGetApi, callDeleteApi, callPostApi } from "../../../utils/api"
import DeleteModal from "../../../components/Common/DeleteModal"
import { showSuccessToastr } from "../../../components/Common/toastr"
import { initialShopEnv, allShopsEnv } from "../../../utils/shop"
import { useNavigate } from "react-router-dom"
import "./all-funnels.scss"

const AllFunnels = () => {
  document.title = "All Funnels"
  const history = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false)
  const [allFunnels, setAllFunnels] = useState([])
  const [filterName, setFilterName] = useState("")
  const [filterStatus, setFilterStatus] = useState("")
  const [funnelId, setFunnelId] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [shop, setShop] = useState(initialShopEnv[process.env.REACT_APP_ENV])
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const filteredFunnel = allFunnels.filter(item => {
    const isNameMatch = item.name
      .toLowerCase()
      .includes(filterName.toLowerCase())
    const isStatusMatch =
      filterStatus === "" ||
      (filterStatus === true && item.isActive) ||
      (filterStatus === false && !item.isActive)
    return isNameMatch && isStatusMatch
  })

  const handleChangeNameFilter = e => {
    setCurrentPage(1)
    setFilterName(e.target.value)
  }

  const handleChangeStatusFilter = e => {
    setCurrentPage(1)
    setFilterStatus(e.target.value === "" ? "" : e.target.value === "true")
  }

  const onGetFunnelsSuccess = data => {
    setIsLoading(false)
    setAllFunnels(data)
  }

  const onGetFunnelsFail = () => {
    setIsLoading(false)
  }

  const loadFunnels = () => {
    setIsLoading(true)
    callGetApi(
      true,
      `/upselling/funnels?shop=${shop}`,
      onGetFunnelsSuccess,
      onGetFunnelsFail
    )
  }

  const onDeleteFunnelsSuccess = () => {
    showSuccessToastr("Funnel Deleted Successfully!")
    setIsDeleteLoading(false)
    const deletedFunnels = allFunnels.filter(funnel => funnel.id !== funnelId)
    setAllFunnels(deletedFunnels)
    setFunnelId(null)
  }

  const onDeleteFunnelsFail = () => {
    setIsDeleteLoading(false)
  }

  const onDeleteFunnel = () => {
    setIsDeleteLoading(true)
    setShowDeleteModal(false)
    callDeleteApi(
      true,
      `/upselling/funnels/${funnelId}?shop=${shop}`,
      onDeleteFunnelsSuccess,
      onDeleteFunnelsFail
    )
  }

  const onDuplicateFunnelsSuccess = data => {
    setAllFunnels([data, ...allFunnels])
    showSuccessToastr("Funnel Duplicated Successfully!")
    setIsDuplicateLoading(false)
    setFunnelId(null)
  }

  const onDuplicateFunnelsFail = () => {
    setIsDuplicateLoading(false)
  }

  const onDuplicateFunnel = funnelId_ => {
    setFunnelId(funnelId_)
    setIsDuplicateLoading(true)
    callPostApi(
      true,
      `/upselling/funnels/${funnelId_}?shop=${shop}`,
      {},
      onDuplicateFunnelsSuccess,
      onDuplicateFunnelsFail
    )
  }

  const onCancelDeleteFunnel = () => {
    setFunnelId(null)
    setShowDeleteModal(false)
  }

  useEffect(() => {
    setFilterName("")
    setFilterStatus("")
    loadFunnels()
  }, [shop])

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const displayedFunnels = filteredFunnel.slice(startIndex, endIndex)
  const totalPage = Math.ceil(filteredFunnel.length / itemsPerPage)

  const handlePageChange = newPage => {
    setCurrentPage(newPage)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Funnels"} breadcrumbItem={"All Funnels"} />
          <div>
            <Row>
              <Col lg={12}>
                <Card>
                  {isLoading ? (
                    <CardBody>
                      <div className="spinner-loader">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <div className="funnel-filter-container">
                        <Row>
                          <Col lg={4}>
                            <div>
                              <input
                                className="form-control"
                                type="search"
                                placeholder="Search..."
                                value={filterName}
                                onChange={handleChangeNameFilter}
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <select
                                className="form-control"
                                value={shop}
                                onChange={e => {
                                  setShop(e.target.value)
                                }}
                              >
                                {allShopsEnv[process.env.REACT_APP_ENV].map(
                                  option => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <select
                                className="form-control"
                                value={filterStatus}
                                onChange={handleChangeStatusFilter}
                              >
                                <option value="">All</option>
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="funnel-create-btn">
                              <Button
                                color="success"
                                className="btn btn-success waves-effect waves-light"
                                onClick={() => history("/shop-selection")}
                              >
                                Create Funnel
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-bordered mb-0">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Shop</th>
                              <th>Created</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayedFunnels?.map((funnel, index) => {
                              return (
                                <tr key={index}>
                                  <td>{funnel.name}</td>
                                  <td>{funnel.shop}</td>
                                  <td>
                                    {funnel.createdAt
                                      ? new Date(
                                          funnel.createdAt
                                        ).toLocaleString()
                                      : ""}
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        funnel.isActive
                                          ? "status-active"
                                          : "status-inactive"
                                      }
                                    >
                                      {funnel.isActive ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="funnel-actions">
                                      {isDuplicateLoading &&
                                      funnel.id == funnelId ? (
                                        <Spinner className="ms-2 duplicate-spinner" />
                                      ) : (
                                        <i
                                          className="mdi mdi-content-copy duplicate"
                                          style={{
                                            marginRight: "12px",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                          }}
                                          onClick={() => {
                                            onDuplicateFunnel(funnel.id)
                                          }}
                                        ></i>
                                      )}
                                      <i
                                        className="mdi mdi-pencil edit"
                                        style={{
                                          marginRight: "12px",
                                          cursor: "pointer",
                                          fontSize: "16px",
                                        }}
                                        onClick={() => {
                                          history(
                                            `/create-funnel?funnelId=${funnel.id}&shop=${funnel.shop}`
                                          )
                                        }}
                                      ></i>
                                      {isDeleteLoading &&
                                      funnel.id == funnelId ? (
                                        <Spinner className="ms-2 delete-spinner" />
                                      ) : (
                                        <i
                                          className="mdi mdi-delete delete"
                                          style={{
                                            marginRight: "12px",
                                            cursor: "pointer",
                                            fontSize: "16px",
                                          }}
                                          onClick={() => {
                                            setFunnelId(funnel.id)
                                            setShowDeleteModal(true)
                                          }}
                                        ></i>
                                      )}
                                    </span>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
            {!isLoading && (
              <Pagination className="pagination pagination-rounded justify-content-center  mb-4 pb-1">
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    to="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  />
                </PaginationItem>
                {Array(Math.ceil(filteredFunnel.length / itemsPerPage))
                  .fill()
                  .map((_, i) => (
                    <PaginationItem active={i + 1 === currentPage} key={i}>
                      <PaginationLink
                        onClick={() => handlePageChange(i + 1)}
                        to="#"
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                <PaginationItem disabled={currentPage === totalPage}>
                  <PaginationLink
                    next
                    to="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            )}
          </div>
        </Container>
        <DeleteModal
          show={showDeleteModal}
          onDeleteClick={onDeleteFunnel}
          onCloseClick={onCancelDeleteFunnel}
          message="Are you sure you want to delete this funnel?"
        />
      </div>
    </React.Fragment>
  )
}

export default AllFunnels
