const ExtraSpacing = ({blockManager}) => {
    blockManager.add("extra-spacing", {
        id: "extra-spacing",
        label: "Extra Spacing",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="margin: 35px 0px"></div>`,
      })
}

export default ExtraSpacing