const NoButton = ({blockManager}) => {
    blockManager.add("no-button", {
        id: "no-button",
        label: "No Button",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="display: flex; justify-content: center; align-items: center;">
        <button
          type="button"
          style="display: flex; justify-content: center; text-decoration: none; text-transform: unset; box-sizing: border-box; cursor: pointer; border: none; background: transparent; padding: 0; color: #3a3a3a; align-items: center; line-height: 24px; font-weight: 600; text-decoration-line: underline; font-size: 1rem;"
        >
          No thanks, I'd rather pay more money
        </button>
      </div>
      `,
      })
}

export default NoButton