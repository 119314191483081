export function errorHandler(error) {
  if (!error?.response?.status) {
    return
  }

  switch (error.response.status) {
    case 404:
      throw new Error("Sorry! the page you are looking for could not be found")

    case 500:
      throw new Error("Sorry! something went wrong, please contact our support team")

    case 401:
      throw new Error("Invalid credentials")

    default:
      throw new Error(`Status: ${error.response.status} Error: ${error.message}`)
  }
}
