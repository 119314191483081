const FeaturedIn = ({blockManager}) => {
    blockManager.add("featured-in", {
        id: "featured-in",
        label: "Featured In",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="background-color: #efefef; padding: 40px 0 70px; text-align: center;font-family: 'Arial'; width: 90%; margin: auto;">
        <div style="max-width: 420px; width: 100%; margin: 0 auto; border-radius: 12px;">
            <p style="margin: 0; color: #292929; line-height: 24px; font-weight: 800; font-size: 25px;">
                <span>Featured In</span>
            </p>
        </div>
        <div style="width: 30px; height: 30px; vertical-align: middle; display: block;"></div>
        <div>
            <img src="https://cdn.shopify.com/s/files/1/0021/4889/2732/files/Logos.svg?v=1635378539" sizes="100vw" loading="lazy" alt="" style="width: 828px; max-width: 90%;">
        </div>
    </div>
    `,
      })
}

export default FeaturedIn