/* eslint-disable react/no-unknown-property */
/* eslint-disable no-undef */
import { modalButtons } from "components/PageBuilder/utils"
import { callGetApi } from "utils/api"
import {
  CAN_EDIT,
  SELECTED_PRODUCT_CONFIG,
} from "../../shared/ProductSelector/constants"
import VariantSelectorOffer from "./VariantSelectorOffer"
import UniqueVariantOffer from "./UniqueVariantOffer"
import "../../shared/ProductSelector"
import { VARIANT_SELECTOR_MODE } from "constants/variantSelectorMode"

const OfferProduct = (editor, shop) => {
  VariantSelectorOffer(editor)
  UniqueVariantOffer(editor)

  editor.DomComponents.addType("variant-selector", {
    isComponent: el => el.classList?.contains("variant-selector"),
    model: {
      defaults: {
        tagName: "div",
        classes: ["variant-selector"],
        style: { width: "100%" },
      },
      editOffer() {
        const { modal, content } = modalButtons(editor, "product-selector")
        const actualProduct = this.find(
          ".offer-variant-product, .unique-variant-offer"
        )[0]?.get("product")
        const config = {
          shop,
          disabledShop: true,
          multiProducts: false,
          products: actualProduct ? [actualProduct] : [],
          variantSelectorMode: VARIANT_SELECTOR_MODE.CAN_SELECT_VARIANT,
        }
        localStorage.setItem(SELECTED_PRODUCT_CONFIG, JSON.stringify(config))
        modal.open({
          title: "Variant Selector",
          content,
        })

        editor.once("modal:close", () => {
          let canEdit = localStorage.getItem(CAN_EDIT)
          let products = localStorage.getItem(SELECTED_PRODUCT_CONFIG)

          if (!products || !products.length || canEdit !== "false") {
            this.remove()
            return
          }

          localStorage.removeItem(CAN_EDIT)
          localStorage.removeItem(SELECTED_PRODUCT_CONFIG)
          products = JSON.parse(products)

          if (!products?.length) {
            this.remove()
            return
          }

          const productData = products[0]

          if (productData.product_variations) {
            this.components('<div class="unique-variant-offer"></div>')
            this.find(".unique-variant-offer")[0].set("product", productData)
          } else {
            callGetApi(true, `/upselling/products/${products[0].id}`, data => {
              this.components('<div class="offer-variant-product"></div>')
              this.find(".offer-variant-product")[0].set("product", data)
            })
          }
        })
      },
      init() {
        this.editOffer()
      },
    },
  })
}

export default OfferProduct
