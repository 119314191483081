const loadExternalResource = `(function loadExternalResource(url, type, onload = () => {}) {
    if (type === "script") {
      if (document.querySelector(\`script[src="\${url}"]\`)) {
        return;
      }
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.onload = onload;
      document.body.appendChild(script);
    } else if (type === "style") {
      if (document.querySelector(\`link[href="\${url}"]\`)) {
        return;
      }
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = url;
      link.onload = onload;
      document.head.prepend(link);
    }
  })`

const loadSideCarLib = `(function loadSideCarLib(useSideCar, onload = () => {}) {
    const sideCarLib = "https://unpkg.com/funnel-builder-sidecar@1.0.13/dist/funnel-builder-sidecar.js";
  
    if (useSideCar && typeof FunnelBuilderSidecar === "undefined") {
      if (document.querySelector(\`script[src="\${sideCarLib}"]\`)) {
        onload();
        return;
      }
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = sideCarLib;
      script.onload = onload;
      document.body.appendChild(script);
    } else if (!useSideCar) {
      const sideCarScript = document.querySelector(\`script[src="\${sideCarLib}"]\`);
      sideCarScript && sideCarScript.remove();
    }
  })`

export function initializeUtils() {
  window.loadExternalResource = loadExternalResource
  window.loadSideCarLib = loadSideCarLib
}
