/* eslint-disable react/jsx-no-undef */
import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable, useGlobalFilter, useAsyncDebounce, useSortBy, useFilters, useExpanded, usePagination
} from "react-table"
import { Table, Row, Col, Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import { allShopsEnv, initialShopEnv } from "../../utils/shop"
import { subscriptionStatuses } from "../../utils/status"
import { setOrRemoveFilter } from "../../utils/filters"
import "./TableContainer.scss"
import { defaultPageSizes } from "../../utils/pages"

const DropdownComponent = ({ items, selectedItem, handleItemChange, title, defaultTitle, isHidden }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState)
  }

  const selectedDropdown = selectedItem === null ? {
    label: "All", value: null
  } : items.find(item => item.value === selectedItem)
  const displayText = selectedDropdown ? selectedDropdown.label : defaultTitle

  if (isHidden) {
    return null
  }

  return (
  <div className="dropdown-container">
    <span className="title">{title}</span>
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle caret>
        {displayText}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>
          {title}
        </DropdownItem>
        <DropdownItem
          onClick={() => handleItemChange(null)}>
          {"All"}
        </DropdownItem>
        {items.map((item) => (<DropdownItem
            key={item.value}
            onClick={() => handleItemChange(item.value)}>
            {item.label}
          </DropdownItem>))}
      </DropdownMenu>
    </Dropdown>
  </div>)
}

// Define a default UI for filtering
function GlobalFilter({
                        preGlobalFilteredRows, globalFilter, setGlobalFilter, isJobListGlobalFilter
                      }) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (<React.Fragment>
    <Col md={4}>
      <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
            <input
              onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value)
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
    </Col>
    {isJobListGlobalFilter && <JobListGlobalFilter />}
  </React.Fragment>)
}

const TableContainer = ({
                          columns,
                          data,
                          isGlobalFilter,
                          isJobListGlobalFilter,
                          isAddOptions,
                          isAddUserList,
                          handleOrderClicks,
                          handleUserClick,
                          handleCustomerClick,
                          isAddCustList,
                          customPageSize,
                          className,
                          customPageSizeOptions,
                          isManualPagination,
                          fetchDataHandler,
                          customPageCount,
                          customPageIndex,
                          filtersData,
                          isLoading,
                          isError,
                          error,
                          areDropdownsHidden
                        }) => {
  let tableConfiguration = {
    columns, data, defaultColumn: {
      Filter: DefaultColumnFilter
    }, initialState: {
      pageIndex: customPageIndex ? customPageIndex - 1 : 0, pageSize: customPageSize, sortBy: [{
        desc: true
      }]
    }, manualPagination: isManualPagination
  }
  if (customPageCount) {
    tableConfiguration = {
      ...tableConfiguration, pageCount: customPageCount
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize }
  } = useTable(tableConfiguration, useGlobalFilter, useFilters, useSortBy, useExpanded, usePagination)

  const defaultStore = initialShopEnv[process.env.REACT_APP_ENV]
  const defaultStatus = "active"

  const [store, setStore] = useState(defaultStore)
  const [status, setStatus] = useState(defaultStatus)

  useEffect(() => {
    CustomPagination(1, pageSize)
  }, [store, status])

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const CustomPagination = (pageVal, limitVal) => {
    try {
      filtersData = setOrRemoveFilter("store", store, filtersData)
      filtersData = setOrRemoveFilter("status", status, filtersData)

      fetchDataHandler(pageVal, limitVal, filtersData)
    } catch (error) {
      console.log(error)
    }
  }

  const handleStoreChange = (value) => {
    setStore(value)
  }

  const handleStatusChange = (value) => {
    setStatus(value)
  }

  const onChangeInSelect = size => {
    let limit = Number(size)
    setPageSize(limit)
    if (isManualPagination) {
      CustomPagination(pageIndex, limit)
    }
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) : 0
    if (isManualPagination) {
      CustomPagination(page, pageSize)
      gotoPage(page - 1)
    }
    gotoPage(page - 1)
  }
  return (<Fragment>
    <Row className="filters-container">
      <Col className="col-xs-12">
          <DropdownComponent
            items={defaultPageSizes().map(size => ({ value: size, label: size }))}
            selectedItem={pageSize}
            handleItemChange={onChangeInSelect}
            title="Show"
            defaultTitle="Show"
            isHidden={false}
          />
          <DropdownComponent
            items={allShopsEnv[process.env.REACT_APP_ENV]}
            selectedItem={store}
            handleItemChange={handleStoreChange}
            title="Store"
            defaultTitle="Store"
            isHidden={areDropdownsHidden}
          />
          <DropdownComponent
            items={subscriptionStatuses}
            selectedItem={status}
            handleItemChange={handleStatusChange}
            title="Status"
            defaultTitle="Status"
            isHidden={areDropdownsHidden}
          />
      </Col>
    </Row>
    <Row className="align-items-center">
      {isGlobalFilter && (<GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        isJobListGlobalFilter={isJobListGlobalFilter}
      />)}
      
      {isAddOptions && (<Col sm="7">
        <div className="text-sm-end">
          <Button
            type="button"
            color="success"
            className="btn-rounded  mb-2 me-2"
            onClick={handleOrderClicks}
            disabled={true}
            hidden={true}
          >
            <i className="mdi mdi-plus me-1" />
            Add New Subscription
          </Button>
        </div>
      </Col>)}
      {isAddUserList && (<Col sm="7">
        <div className="text-sm-end">
          <Button
            type="button"
            color="primary"
            className="btn mb-2 me-2"
            onClick={handleUserClick}
          >
            <i className="mdi mdi-plus-circle-outline me-1" />
            Create New User
          </Button>
        </div>
      </Col>)}
      {isAddCustList && (<Col sm="7">
        <div className="text-sm-end">
          <Button
            type="button"
            color="success"
            className="btn-rounded mb-2 me-2"
            onClick={handleCustomerClick}
          >
            <i className="mdi mdi-plus me-1" />
            New Customers
          </Button>
        </div>
      </Col>)}
    </Row>

    <div className="table-responsive react-table">
      <Table bordered hover {...getTableProps()} className={className}>
        <thead className="table-light table-nowrap">
        {headerGroups.map(headerGroup => (<tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (<th key={column.id}>
            <div className="mb-2" {...column.getSortByToggleProps()}>
              {column.render("Header")}
              {generateSortingIndicator(column)}
            </div>
            <Filter column={column} />
          </th>))}
        </tr>))}
        </thead>

        <tbody {...getTableBodyProps()}>
        {isLoading ? (<tr>
          <td colSpan={columns?.length}>
            <div style={{ height: "8rem" }} className="position-relative">
              <Spinner />
            </div>
          </td>
        </tr>) : isError ? (<tr>
          <td colSpan={columns?.length}>
            <div style={{ height: "8rem" }} className="position-relative">
              <Error error={error} />
            </div>
          </td>
        </tr>) : (page.map(row => {
          prepareRow(row)
          return (<Fragment key={row.getRowProps().key}>
            <tr>
              {row.cells.map(cell => {
                return (<td key={cell.id} {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </td>)
              })}
            </tr>
          </Fragment>)
        }))}
        </tbody>
      </Table>
    </div>

    <Row className="justify-content-md-end justify-content-center align-items-center">
      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button
            color="primary"
            onClick={() => {
              if (isManualPagination) {
                CustomPagination(1, pageSize)
              } else {
                gotoPage(0)
              }
            }}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (isManualPagination) {
                let pageOffset = pageIndex + 1
                let prevPageOffset = 1
                CustomPagination(pageOffset - prevPageOffset, pageSize)
                gotoPage(pageOffset - prevPageOffset)
              } else {
                previousPage()
              }
            }}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>
        </div>
      </Col>
      <Col className="col-md-auto d-none d-md-block">
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </Col>
      <Col className="col-md-auto">
        <Input
          type="number"
          min={1}
          style={{ width: 70 }}
          max={pageOptions.length}
          value={pageIndex + 1}
          onChange={onChangeInInput}
        />
      </Col>

      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button
            color="primary"
            onClick={() => {
              if (isManualPagination) {
                let pageOffset = pageIndex + 1
                let nextPageOffset = 1
                CustomPagination(pageOffset + nextPageOffset, pageSize)
              } else {
                nextPage()
              }
            }}
            disabled={!canNextPage}
          >
            {">"}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (isManualPagination) {
                CustomPagination(pageCount, pageSize)
              } else {
                gotoPage(pageCount - 1)
              }
            }}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </div>
      </Col>
    </Row>
  </Fragment>)
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any
}

export default TableContainer
