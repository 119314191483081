import { createPageBuilder } from "components/PageBuilder"
import { useEffect, useState } from "react"
import { initializeUtils } from "../components/PageBuilder/utils/index"

const usePageBuilder = (shop, pageType, funnelId) => {
  const [editorInstance, setEditorInstance] = useState(null)
  const [storeData, setStoreData] = useState(null)

  useEffect(() => {
    if (!shop) return
    initializeUtils()
    const editor = createPageBuilder(shop, pageType, funnelId)
    editor.on("storage:store", function (e) {
      setStoreData(e)
    })

    setEditorInstance(editor)

    return () => {
      editor.destroy()
    }
  }, [shop])

  return {
    editorInstance,
    storeData,
  }
}

export default usePageBuilder
