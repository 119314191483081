import React, { useEffect } from 'react'
import update from 'immutability-helper'
import { useCallback } from 'react'
import AddBtn from './AddBtn'
import Card from './Card'
import {
  useQuizEditor,
  SET_STATE,
  SET_QUESTIONS,
  ADD_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  SELECT_QUESTION,
} from '../state'
import useDargDrop from '../useDargDrop'

export const DargAndDropItem = ({ id, index, moveCard, title, selected, click, update, remove }) => {
  const {
    opacity,
    handlerId,
    ref,
  } = useDargDrop(id, index, moveCard)

  return (
    <Card
      title={title}
      update={update}
      remove={remove}
      click={click}
      selected={selected}
      ref={ref}
      opacity={opacity}
      handlerId={handlerId}
    />
  )
}

const DragDropContainer = ({questions, selectedQuestion, onClickItem, onReorderQuestion, onUpdateQuestion, onRemoveQuestion}) => {
  const moveCard = useCallback((dragIndex, hoverIndex) => {
      const orderedCards = update(questions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, questions[dragIndex]],
        ],
      })
      onReorderQuestion(orderedCards)
  }, [questions])

  return (
    <>
      <div>{questions.map((card, index) => (
        <DargAndDropItem
         key={card.id}
         index={index}
         id={card.id}
         title={card.title}
         moveCard={moveCard}
         selected={card.id === selectedQuestion}
         click={() => onClickItem(card)}
         update={(e) => onUpdateQuestion({...card, title: e.target.value})}
         remove={questions.length > 1 ? () => onRemoveQuestion(card.id) : null}
       />
      ))}</div>
    </>
  )
}

const QuestionList = () => {
  const { dispatch, state: { questions, selected } } = useQuizEditor()

  useEffect(() => {
    const data = localStorage.getItem('questions')
    if (data) {
      const questions = JSON.parse(data)

      dispatch({
        type: SET_STATE,
        state: {
          selected: questions[0].id,
          questions
        }
      })
    }

  },[])

  const handleSelectedQuestion = useCallback((question) => {
    dispatch({
      type: SELECT_QUESTION,
      questionId: question.id
    })
  }, [])

  const handleAddQuestion = useCallback(() => {
    dispatch({
      type: ADD_QUESTION,
      title: 'New Question'
    })
  }, [])

  const handleUpdateQuestion = useCallback((question) => {
    dispatch({
      type: UPDATE_QUESTION,
      question
    })
  }, [])

  const handleReorderQuestion = useCallback((questions) => {
    dispatch({
      type: SET_QUESTIONS,
      questions
    })
  }, [])

  const handleRemoveQuestion = useCallback((questionId) => {
    dispatch({
      type: DELETE_QUESTION,
      questionId
    })
  }, [])

  return (
    <div style={{flex: 1, padding: 10}}>
      {
        !questions.length ? (
          <h4>No questions found</h4>
        ) : (
          <DragDropContainer
            selectedQuestion={selected}
            questions={questions}
            onClickItem={handleSelectedQuestion}
            onReorderQuestion={handleReorderQuestion}
            onUpdateQuestion={handleUpdateQuestion}
            onRemoveQuestion={handleRemoveQuestion}
          />
        )
      }
      <AddBtn onClick={handleAddQuestion}>
          Add Question
      </AddBtn>
    </div>
  )
}

export default QuestionList