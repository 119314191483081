import React, { useEffect, useState } from "react"
import { Table, Spinner } from "reactstrap"
import { SubscriptionDiscount } from "./SubscriptionDiscount"
import { SubscriptionDiscountForm } from "./SubscriptionDiscountForm"
import { callGetApi } from "utils/api"

function SubscriptionDiscounts(props) {
  const { subscriptionType } = props

  const [recordDiscountsUpdated, setRecordDiscountsUpdated] = useState({})
  const [itemsUpdated, setDiscountsUpdated] = useState([])
  const [productModificationPeriod, setProductModificationPeriod] = useState(subscriptionType?.product_modification_period || "")
  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionDiscounts, setSubscriptionDiscounts] = useState([])

  const onRequestFail = (error) => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          throw new Error(
            "Sorry! the page you are looking for could not be found"
          )
        case 500:
          throw new Error(
            "Sorry! something went wrong, please contact our support team"
          )
        case 401:
          throw new Error("Invalid credentials")
  
        default:
          throw new Error(
            `Status: ${error.response.status} Error: ${error.message}`
          )
      }
    }
    setIsLoading(false)
    throw error
  }

  const onGetSubscriptionDiscountsSuccess = data => {
    setSubscriptionDiscounts(data)
    setIsLoading(false)
  }

  const getSubscriptionDiscounts = (subscriptionId) => {
    callGetApi(
      true, 
      `/subscription_types/${subscriptionId}/discount`,
      onGetSubscriptionDiscountsSuccess, 
      onRequestFail, 
      "auth"
    )
  }

  const onRefetchSubscriptionDiscounts = () => {
    console.log("Refetching subscriptions items...")
    getSubscriptionDiscounts(subscriptionType?.id)
  }

  useEffect(() => {
    getSubscriptionDiscounts(subscriptionType?.id)
  }, [])

  useEffect(() => {
    setProductModificationPeriod(subscriptionType?.product_modification_period || "")
  }, [subscriptionType?.product_modification_period])

  const handleSetDiscountsUpdated = (oldDiscount, newDiscount) => {
    setRecordDiscountsUpdated({
      ...recordDiscountsUpdated,
      [newDiscount?.id || ""]: {
        old: oldDiscount,
        new: newDiscount,
      },
    })
  }

  const handleSetDiscountRemoved = (item) => {
    setRecordDiscountsUpdated({
      ...recordDiscountsUpdated,
      [item?.id || ""]: null,
    })
  }

  useEffect(() => {
    setDiscountsUpdated(
      Object.keys(recordDiscountsUpdated)
        .filter(itemId => recordDiscountsUpdated[itemId])
        .map(itemId => recordDiscountsUpdated[itemId])
    )
  }, [recordDiscountsUpdated])

  return (
    <div className="d-flex flex-column gap-4">
      <SubscriptionDiscountForm
        subscriptionId={subscriptionType?.id}
        onRefetchDiscounts={() => onRefetchSubscriptionDiscounts()}
        onDiscountCreated={(newDiscount) => handleSetDiscountsUpdated(null, newDiscount)}
      />
      <div className="d-flex align-items-center gap-4">
        <h3>Product Modification Period:</h3>
        <h6>{productModificationPeriod || "Not Assigned"}</h6>
        <h3>Product Modification Date:</h3>
        <h6>{subscriptionType?.product_modification_date || "Not Assigned"}</h6>
      </div>
      <div className="position-relative mt-2">
        {isLoading ? (
          <div className="spinner-loader" style={{ minHeight: 300 }}>
            <Spinner className="ms-2" color="primary" />
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <Table className="table align-middle table-nowrap">
                <thead>
                <tr>
                    <th scope="col">Actions</th>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Target</th>
                    <th scope="col">Target Selection</th>
                    <th scope="col">Product ID</th>
                    <th scope="col">Type</th>
                    <th scope="col"># Scheduled Subscription</th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptionDiscounts?.map((item) => (
                    item.name == "free_gift" ? (
                    <SubscriptionDiscount
                      key={item?.id}
                      item={item}
                      onRefetchDiscounts={onRefetchSubscriptionDiscounts}
                      onDiscountUpdated={handleSetDiscountsUpdated}
                      onDiscountRemoved={handleSetDiscountRemoved}
                    />
                  ) : null ) ) }
                </tbody>
              </Table>
            </div>
            {itemsUpdated.length ? (
              <div className="d-flex flex-column gap-4 mt-4">
                <div className="d-flex flex-column gap-4">
                  <h4>SubscriptionType Discounts updated</h4>
                  {itemsUpdated.map(({ new: newDiscount, old: oldDiscount }, index) => (
                    <div key={index} className="card border border-secondary ounded-3">
                      <div className="card-header text-center">
                        <h5>SubscriptionType Discount ID: {newDiscount?.id}</h5>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <Table className="table align-middle table-nowrap">
                            <thead>
                              <tr>
                                <th scope="col">Property</th>
                                <th scope="col">Old</th>
                                <th scope="col">New</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Name</td>
                                <td>{oldDiscount?.name}</td>
                                <td>{newDiscount?.name}</td>
                              </tr>
                              <tr>
                                <td>Product ID</td>
                                <td>{oldDiscount?.target_lines}</td>
                                <td>{newDiscount?.target_lines}</td>
                              </tr>
                              <tr>
                                <td>Type</td>
                                <td>{oldDiscount?.discount_type}</td>
                                <td>{newDiscount?.discount_type}</td>
                              </tr>
                              <tr>
                                <td>Value</td>
                                <td>{oldDiscount?.discount_value}</td>
                                <td>{newDiscount?.discount_value}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}

export default SubscriptionDiscounts
