import ReplaceMenuProvider from 'bpmn-js/lib/features/popup-menu/ReplaceMenuProvider'
import { isAny, is } from 'bpmn-js/lib/features/modeling/util/ModelingUtil'

export default class CustomReplaceMenuProvider extends ReplaceMenuProvider {
  constructor(bpmnFactory, popupMenu, modeling, moddle, bpmnReplace, rules, translate) {
    super(bpmnFactory, popupMenu, modeling, moddle, bpmnReplace, rules, translate)
  }

  getPopupMenuEntries(element) {
    const originalEntries = super.getPopupMenuEntries(element)

    if (isAny(element, ['bpmn:Task'])) {
      return this.createTaskEntries(element)
    }

    if (isAny(element, ['bpmn:StartEvent', 'bpmn:EndEvent'])) {
      return {}
    }

    return originalEntries
  }

  createTaskEntries(element) {
    const taskTypes = [
      { label: 'Offer', type: 'offer' },
      { label: 'Quiz', type: 'quiz' },
      { label: 'Checkout', type: 'checkout' },
      { label: 'Upsell', type: 'upsell' },
      { label: 'Downsell', type: 'downsell' },
      { label: 'Popup', type: 'popup' },
      { label: 'Landing Page', type: 'landing' },
    ]

    const entries = {}

    taskTypes.forEach((task) => {
      const id = `${task.type}-task`
      entries[id] = {
        id,
        label: task.label,
        className: 'bpmn-icon-task',
        action: () => {
          this._bpmnReplace.replaceElement(element, {
            type: `mycustom:${task.type}`,
          })
        },
      }
    })

    return entries
  }
}

CustomReplaceMenuProvider.$inject = [
  'bpmnFactory',
  'popupMenu',
  'modeling',
  'moddle',
  'bpmnReplace',
  'rules',
  'translate',
]
