/* eslint-disable no-undef */
const TypeForm = editor => {
  editor.DomComponents.addType("lead-quizzes", {
    isComponent: el => el.classList?.contains("lead-quizzes"),
    extend: 'iframe',
    model: {
      defaults: {
        tagName: "div",
        classes: ["lead-quizzes"],
        styles: `
          .lead-quizzes {
              width: 100%;
              height: 50vh;
          }
          .lead-quizzes iframe {
              width: 100%;
              height: 100%;
          }
        `,
        url: '',
        trackingSrc: '',
        traits: [
          {
            type: "text",
            label: "Url",
            name: "url",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Tracking src",
            name: "trackingSrc",
            changeProp: 1,
          },
        ],
        components: `
          <iframe
            src=""
            frameborder="0"
            scrolling="no"
            style="width:100%;height:100%;"
          ></iframe>
        `,
        'script-props': ['trackingSrc'],
        script: function ({ trackingSrc }) {
          const script = document.createElement('script')
          script.src = trackingSrc
          document.head.appendChild(script)
        }
      },
      handleFormIdChange(_, url) {
        console.log('handleFormIdChange', this.components())
        this.components().models[0].setAttributes({src: url})
      },
      init() {
        this.on("change:url", this.handleFormIdChange)
      }
    },
  })
}

export default TypeForm
