import { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Modal,
  Label,
  CardTitle,
  Spinner,
  Input,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { callDeleteApi, callGetApi } from "../../utils/api"
import "./index.scss"
import CreateCouponModal from "./CreateCouponModal"
import { initialShopEnv, allShopsEnv } from "../../utils/shop"
import DeleteModal from "components/Common/DeleteModal"
import { showErrorToastr, showSuccessToastr } from "components/Common/toastr"
import UploadFileModal from "./UploadFileModal"
import EditCouponModal from "./EditCouponModal"
import { formatDate } from "utils/coupons"

const Coupons = () => {
  document.title = "Coupons"
  const [codeFilter, setCodeFilter] = useState("")
  const [store, setStore] = useState(initialShopEnv[process.env.REACT_APP_ENV])
  const [isOpenReimburstmentModal, setIsOpenReimburstmentModal] =
    useState(false)

  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [coupon, setCoupon] = useState(null)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [IsOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const onDeleteCoupon = () => {
    setIsDeleteLoading(true)
    callDeleteApi(
      true,
      `/coupons/${coupon.id}`,
      onDeleteCouponSuccess,
      onDeleteCouponFail
    )
    setIsOpenDeleteModal(false)
  }

  const onDeleteCouponSuccess = () => {
    showSuccessToastr("Coupon Deleted Successfully!")
    setIsOpenDeleteModal(false)
    setCoupon(null)
    setIsDeleteLoading(false)
  }

  const onDeleteCouponFail = e => {
    setIsDeleteLoading(false)
    showErrorToastr(e)
  }

  //rules accordeon
  const [open, setOpen] = useState("0")
  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  //rules accordeon2
  const [open2, setOpen2] = useState("0")
  const toggle2 = id => {
    if (open2 === id) {
      setOpen2()
    } else {
      setOpen2(id)
    }
  }
  //rules accordeon3
  const [open3, setOpen3] = useState("0")
  const toggle3 = id => {
    if (open3 === id) {
      setOpen3()
    } else {
      setOpen3(id)
    }
  }

  const handleChangeCountryFilter = e => {
    setCodeFilter(e.target.value)
  }

  const onGetCouponsSuccess = data => {
    setCoupon(data)
    setIsLoading(false)
  }

  const onGetCouponsFail = () => {
    setCoupon(null)
    setIsLoading(false)
  }

  const getCoupon = () => {
    setIsLoading(true)
    let path = "/coupons"
    if (codeFilter) {
      path += `?code=${codeFilter}`
    }
    if (store) {
      path += `&store=${store}`
    }
    callGetApi(
      true,
      path,
      data => onGetCouponsSuccess(data),
      onGetCouponsFail,
      "coupons"
    )
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Coupons"} breadcrumbItem={"Coupons"} />
          <div className="tax-table-container">
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="tax-filter-container">
                      <Row className="mb-3">
                        <Col lg={12}>
                          <Button
                            className="float-end"
                            color="success"
                            onClick={() => {
                              setIsOpenCreateModal(true)
                            }}
                          >
                            <i className="mdi mdi-plus me-2" />
                            Create new coupon
                          </Button>
                          <Button
                            className="float-end me-2"
                            color="primary"
                            onClick={() => setIsOpenReimburstmentModal(true)}
                          >
                            <i className="mdi mdi-plus me-2" />
                            Create reimbursement coupon
                          </Button>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={2}>
                          <div>
                            <Label
                              htmlFor="formrow-country-Input"
                              className="mb-1"
                            >
                              Code*
                            </Label>
                            <input
                              id="formrow-country-Input"
                              className="form-control"
                              type="search"
                              value={codeFilter}
                              onChange={handleChangeCountryFilter}
                            />
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div>
                            <Label
                              htmlFor="formrow-country-Input"
                              className="mb-1"
                            >
                              Store*
                            </Label>
                            <select
                              className="form-control"
                              value={store}
                              onChange={e => {
                                setStore(e.target.value)
                              }}
                            >
                              {allShopsEnv[process.env.REACT_APP_ENV].map(
                                option => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </Col>

                        <Col md={2} lg={2} xl={1}>
                          <Button
                            className="float-right"
                            style={{ marginTop: "1.8em", width: "100%" }}
                            color="primary"
                            onClick={() => getCoupon()}
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  {" "}
                  <CardTitle style={{ margin: "10px 10px" }}>
                    Coupon View
                  </CardTitle>
                  {isLoading || isDeleteLoading ? (
                    <CardBody>
                      <div className="spinner-loader">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody>
                      {coupon ? (
                        <div>
                          <DeleteModal
                            show={IsOpenDeleteModal}
                            onDeleteClick={onDeleteCoupon}
                            onCloseClick={() => setIsOpenDeleteModal(false)}
                            message="Are you sure you want to delete this coupon?"
                          />
                          <Modal
                            isOpen={isOpenEditModal}
                            toggle={() => {
                              setIsOpenEditModal(!isOpenEditModal)
                            }}
                            size="m"
                          >
                            <div className="modal-header">
                              <h5
                                className="modal-title mt-0"
                                id="myModalLabel"
                              >
                                Edit coupon
                              </h5>
                              <button
                                type="button"
                                onClick={() => {
                                  setIsOpenEditModal(false)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <EditCouponModal
                                setIsOpen={setIsOpenEditModal}
                                coupon={coupon}
                                setCodeFilter={setCodeFilter}
                                setCoupon={setCoupon}
                              />
                            </div>
                          </Modal>
                          <Row className="mb-3">
                            <Col lg={12}>
                              <Button
                                className="float-end"
                                color="warning"
                                onClick={() => setIsOpenEditModal(true)}
                              >
                                <i className="mdi mdi-plus me-2" />
                                Edit coupon
                              </Button>
                              <Button
                                className="float-end me-2"
                                color="danger"
                                onClick={() => setIsOpenDeleteModal(true)}
                              >
                                <i className="mdi mdi-plus me-2" />
                                Delete coupon
                              </Button>
                            </Col>
                          </Row>
                          <div className="mb-3">
                            <Label className="form-label">Code</Label>
                            <Input
                              disabled={true}
                              name="code"
                              type="text"
                              value={coupon.code}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Store</Label>
                            <Input
                              disabled={true}
                              name="store"
                              type="text"
                              value={coupon.store}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Discount type</Label>
                            <Input
                              disabled={true}
                              name="discount_type"
                              type="text"
                              value={coupon.discount_type}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Discount value</Label>
                            <Input
                              disabled={true}
                              name="discount_value"
                              type="text"
                              value={coupon.discount_value}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Discount target
                            </Label>
                            <Input
                              disabled={true}
                              name="discount_target"
                              type="text"
                              value={coupon.discount_target}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Start date</Label>
                            <Input
                              disabled={true}
                              name="start_date"
                              type="text"
                              value={formatDate(coupon.start_date)}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">End date</Label>
                            <Input
                              disabled={true}
                              name="end_date"
                              type="text"
                              value={
                                coupon.end_date
                                  ? formatDate(coupon.end_date)
                                  : ""
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Owner</Label>
                            <Input
                              disabled={true}
                              name="owner"
                              type="text"
                              value={coupon.owner}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Status</Label>
                            <Input
                              disabled={true}
                              name="status"
                              type="text"
                              value={coupon.status}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Rules</Label>
                            <Accordion open={open} toggle={toggle}>
                              <AccordionItem>
                                <AccordionHeader targetId="1">
                                  View details
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Locations
                                    </Label>
                                    <Input
                                      disabled={true}
                                      name="locations"
                                      type="textarea"
                                      style={{ height: "150px" }}
                                      value={JSON.stringify(
                                        coupon.rules.locations,
                                        null,
                                        2
                                      )}
                                    />
                                  </div>
                                  <Accordion open={open2} toggle={toggle2}>
                                    <AccordionItem>
                                      <AccordionHeader targetId="1">
                                        Uses
                                      </AccordionHeader>
                                      <AccordionBody accordionId="1">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Uses per customer
                                          </Label>
                                          <Input
                                            disabled={true}
                                            name="uses_per_customer"
                                            type="text"
                                            value={
                                              coupon.rules.uses
                                                .uses_per_customer
                                            }
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Total
                                          </Label>
                                          <Input
                                            disabled={true}
                                            name="total"
                                            type="text"
                                            value={coupon.rules.uses.total}
                                          />
                                        </div>
                                      </AccordionBody>
                                    </AccordionItem>
                                  </Accordion>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Products
                                    </Label>
                                    <Input
                                      disabled={true}
                                      name="products"
                                      type="text"
                                      value={coupon.rules.products}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label className="form-label">
                                      Shipping options
                                    </Label>
                                    <Input
                                      disabled={true}
                                      name="shipping_options"
                                      type="text"
                                      value={coupon.rules.shipping_options}
                                    />
                                  </div>
                                  <Accordion open={open3} toggle={toggle3}>
                                    <AccordionItem>
                                      <AccordionHeader targetId="1">
                                        Purchase
                                      </AccordionHeader>
                                      <AccordionBody accordionId="1">
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Min Value
                                          </Label>
                                          <Input
                                            disabled={true}
                                            name="uses_per_customer"
                                            type="text"
                                            value={
                                              coupon.rules.purchase.min_value
                                            }
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <Label className="form-label">
                                            Min Items
                                          </Label>
                                          <Input
                                            disabled={true}
                                            name="min_items"
                                            type="text"
                                            value={
                                              coupon.rules.purchase.min_items
                                            }
                                          />
                                        </div>
                                      </AccordionBody>
                                    </AccordionItem>
                                  </Accordion>
                                </AccordionBody>
                              </AccordionItem>
                            </Accordion>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Date created</Label>
                            <Input
                              disabled={true}
                              name="start_date"
                              type="text"
                              value={formatDate(coupon.date_created)}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Last updated</Label>
                            <Input
                              disabled={true}
                              name="end_date"
                              type="text"
                              value={formatDate(coupon.last_updated)}
                            />
                          </div>
                        </div>
                      ) : (
                        " No coupon found! ❌"
                      )}
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        <Modal
          isOpen={isOpenCreateModal}
          toggle={() => {
            setIsOpenCreateModal(!isOpenCreateModal)
          }}
          size="m"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Create coupon
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsOpenCreateModal(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <CreateCouponModal setIsOpen={setIsOpenCreateModal} />
          </div>
        </Modal>
        <UploadFileModal
          isOpen={isOpenReimburstmentModal}
          setOpen={setIsOpenReimburstmentModal}
        />
      </div>
    </>
  )
}

export default Coupons
