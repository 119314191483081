import React, { useState } from "react"
import swal from "sweetalert"
import { callPostApi } from "utils/api"

const initialState = {
  name: "free_gift",
  target: "free_gift",
  target_selection: "rule",
  target_lines: "",
  discount_type: "recurring",
  discount_value: ""
}

const SubscriptionDiscountForm = ({
  subscriptionId,
  onRefetchDiscounts,
  onDiscountCreated,
}) => {
  const [discount, setDiscount] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (event) => {
    setDiscount({
      ...discount,
      [event.target.name]:
        event.target.type === "number"
          ? Number(event.target.value)
          : event.target.value,
    })
  }

  const onRequestFail = (error) => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          throw new Error(
            "Sorry! the page you are looking for could not be found"
          )
        case 500:
          throw new Error(
            "Sorry! something went wrong, please contact our support team"
          )
        case 401:
          throw new Error("Invalid credentials")
  
        default:
          throw new Error(
            `Status: ${error.response.status} Error: ${error.message}`
          )
      }
    }
    setIsLoading(false)
    throw error
  }

  const onCreateSubscriptionDiscountFail = (error) => {
    swal({
      title: "Oops!", 
      text: "Something went wrong creating product!", 
      icon: "error", 
    })
    setIsLoading(false)
    onRequestFail(error)
  }

  const onCreateSubscriptionDiscountSuccess = (data) => {
    swal({
      title:"Created!", 
      text:"The product has been created!", 
      icon:"success"
    })
    
    if (typeof onRefetchDiscounts === "function") {
      onRefetchDiscounts()
    }
    if (typeof onDiscountCreated === "function") {
      onDiscountCreated(data)
    }
    
    setIsLoading(false)
    setDiscount(initialState)
  }

  const createSubscriptionDiscount = async (subscriptionTypeId, discount) => {
    callPostApi(
      true, 
      `/subscription_types/${subscriptionTypeId}/discount`,
      discount,
      onCreateSubscriptionDiscountSuccess, 
      onCreateSubscriptionDiscountFail, 
      "auth"
    )
  }

  const handleSend = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    createSubscriptionDiscount(subscriptionId, discount)
  }

  return (
    <form className="d-flex flex-column gap-4" onSubmit={handleSend}>
      <div>
        <label>
          Name <strong className="text-danger">*</strong>
        </label>
        <input
          required={true}
          disabled={true}
          name="name"
          value={discount.name}
          placeholder="name"
          onChange={handleChange}
          className="form-control"
        />
      </div>
      <div className="d-flex gap-4">
        <div className="w-100">
          <label>
            Product ID <strong className="text-danger">*</strong>
          </label>
          <input
            required={true}
            disabled={isLoading}
            name="target_lines"
            value={discount.target_lines}
            placeholder="product id"
            onChange={handleChange}
            className="form-control"
          />
        </div>
      </div>
      <div className="d-flex gap-4">
        <div className="w-100">
          <label>
            Type <strong className="text-danger">*</strong>
          </label>
          <input
            required={true}
            disabled={true}
            name="discount_type"
            value={discount.discount_type}
            placeholder="type"
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="w-100">
          <label>
            # Scheduled Subscription <strong className="text-danger">*</strong>
          </label>
          <input
            required={true}
            disabled={isLoading}
            name="discount_value"
            value={discount.discount_value}
            placeholder="value"
            type="number"
            onChange={handleChange}
            className="form-control"
          />
        </div>
      </div>
      <div>
        <button type="submit" className="btn btn-success" disabled={isLoading}>
          Create Subscription Discount
        </button>
      </div>
    </form>
  )
}

export default SubscriptionDiscountForm