export const pageTypes = {
  QUIZ: "quiz",
  OFFER: "offer",
}

export const NodeTypeEnum = {
  OFFER: "offer",
  QUIZ: "quiz",
  CROSS_SELL: "cross_sell",
  METRICS: "metrics",
  CHECKOUT: "checkout",
  POPUP: "popup",
  START: "start",
  END: "end",
  EXCLUSIVE_GATEWAY: "exclusiveGateway",
  PARALLEL_GATEWAY: "parallelGateway",
  SEQUENCE_FLOW: "flow",
}
