import React from "react"
import { Routes, Route } from "react-router-dom"
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
// import "@trafilea/afrodita-components/build/index.css"

const App = () => {
  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={<NonAuthLayout>{route.component}</NonAuthLayout>}
          key={idx}
          exact={true}
        />
      ))}

      {authProtectedRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            <Authmiddleware path={route.path}>
              <VerticalLayout>{route.component}</VerticalLayout>
            </Authmiddleware>
          }
          key={idx}
          exact={true}
        />
      ))}
    </Routes>
  )
}

export default App
