export const addBtnConfig = (el, content, className, btnStyles = "") => {
  const btn = document.createElement("button")
  btn.addEventListener("click", () => {})
  btn.style = `
      cursor: pointer;
      background: #fff;
      color: #2E5676;
      border: 1px dashed #D1D4F3;
      padding: 10px;
      margin: 10px;
      z-index: 1000;
      ${btnStyles}
    `
  btn.textContent = content
  btn.classList = [className]

  if (el) el.appendChild(btn)

  return btn
}
