/* eslint-disable react/no-unknown-property */
const ButtonSelector = editor => {
  editor.DomComponents.addType("button-selector", {
    isComponent: el => el.classList?.contains("button-selector"),
    model: {
      defaults: {
        tagName: "div",
        style: { display: "inline-flex" },
        classes: ["button-selector"],
        data: { options: [], name: "" },
        styles: `
          .button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
            border-radius: 0.375rem;
            box-sizing: border-box;
            font-size: 0.75rem;
            background: #fff;
            color: #292929;
            border: 0.063rem solid #a6a6a6;
            font-weight: 600;
            white-space: nowrap;
            margin: 0 0.5rem 0.625rem 0;
            min-width: 4rem;
            transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
          }

					.button-input {
            display: none;
          }

          .button-input ~ label {
            padding: 0.75rem 1rem 0.625rem;
            display: block;
            width: 100%;
            text-align: center;
            cursor: pointer;
          }

          .button-input:checked ~ label {
            color: white;
          }

          .button-input:checked {
            color: white;
          }

          .button-container:has(> .button-input:checked) {
            background-color: #292929;
          }
				`,
      },
      handleOptionsChange(_, { options, name }) {
        this.components(`
						<div style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
							<p style="margin: 0; font-size: 14px; line-height: 22px; padding: 0; color: black; text-align: center; font-weight: 800;font-family: 'Arial'">
								${name}
							</p>
							<div style="display: flex; flex-direction: row; flex-wrap: wrap; margin: 8px 0 0 0; justify-content: center; align-items: center;">
								${options
                  .map(
                    value => `
                    <div class="button-container">
                      <input
                        class="button-input"
                        name="size"
                        type="radio"
                        id="${value}"
                        value="${value}"
                      />
                      <label htmlFor="${value}">${value}</label>
                    </div>
                  `
                  )
                  .join("")}
							</div>
						</div>
				`)
      },
      init() {
        this.on("change:data", this.handleOptionsChange)
      },
    },
  })
}

export default ButtonSelector
