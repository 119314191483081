export const allShopsEnv = {
  dev: [{
    label: "Shapees", value: "shapees"
  }, {
    label: "Truekind", value: "truekind-dev"
  }, {
    label: "Revel", value: "revel-dev"
  }, {
    label: "TheSpaDr.", value: "thespadr-dev"
  }], staging: [{
    label: "Shapees", value: "shapees"
  }, {
    label: "Truekind", value: "truekind-beta"
  }, {
    label: "Revel", value: "revel-stage"
  }, {
    label: "TheSpaDr.", value: "thespadr-dev"
  }], prod: [{
    label: "Shapermint", value: "shapermint"
  }, {
    label: "Truekind", value: "truekind-store"
  }, {
    label: "Revel", value: "revel"
  }, {
    label: "TheSpaDr.", value: "the-spa-dr"
  }]
}

export const initialShopEnv = {
  dev: "shapees", staging: "shapees", prod: "shapermint"
}
