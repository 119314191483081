import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Label, Input } from "reactstrap"

const HcExtraFields = ({ validationType }) => {
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>HC Extra Fields</CardTitle>
                <div>
                  <div className="mb-3">
                    <Label className="form-label">Banner Text</Label>
                    <Input
                      name="bannerText"
                      type="text"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.bannerText}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Approve Button Text</Label>
                    <Input
                      name="approveButtonText"
                      type="text"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.approveButtonText}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Order Processing Text</Label>
                    <Input
                      name="orderProcessingText"
                      type="text"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.orderProcessingText}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Title Text</Label>
                    <Input
                      name="titleText"
                      type="text"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.titleText}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Subtitle Text</Label>
                    <Input
                      name="subtitleText"
                      type="text"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.subtitleText}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Copy Text</Label>
                    <Input
                      name="copyText"
                      type="text"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.copyText}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Components Config</Label>
                    <Input
                      name="componentsConfig"
                      type="textarea"
                      rows="5"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.componentsConfig}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default HcExtraFields
