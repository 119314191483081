import { createContext, useContext, useReducer } from 'react'
import quizEditorReducer from './reducer'

const initialState = {
    questions: [],
    selected: null
}

const QuizEditorContext = createContext({dispatch: () => {}, state: initialState})

export function QuizEditorProvider({children}) {
  const [state, dispatch] = useReducer(quizEditorReducer, initialState)

  return (
    <QuizEditorContext.Provider value={{ state, dispatch }}>
        {children}
    </QuizEditorContext.Provider>
  )
}

export const useQuizEditor = () => {
    const context = useContext(QuizEditorContext)
    if (context === undefined) {
      throw new Error('useQuizEditor must be used within a QuizEditorProvider')
    }
    return context
}