const GlobalScript = (editor) => {
    editor.DomComponents.addType('global-script', {
        model: {
            defaults: {
                tagName: 'div',
                removable: true,
                draggable: true,
                copyable: false,
                resizable: false,
                droppable: false,
                stylable: false,
                srcType: 'text/javascript',
                src: '',
                traits: [
                    {
                        name: 'src',
                        label: 'Sorce url',
                        placeholder: 'https://.../somelib.min.js',
                        changeProp: true
                    },
                    {
                        name: 'srcType',
                        label: 'Sorce type',
                        placeholder: 'text/javascript',
                        changeProp: true
                    }
                ],
                content: `<div style="text-align:center;color:#f6a908">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`,
                'script-props': ['src', 'srcType'],
                script: function({src, srcType}) {
                    const script = document.createElement('script')
                    script.type = srcType
                    script.src = src
                    document.body.appendChild(script)
                },
            },
            init() {},
            toHTML() {
                return `<div id="${this.getId()}" />`
            }
        },
    })
}

export default GlobalScript