import React, { useEffect, useState } from "react"
import {
  Container,
  Table,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { initialShopEnv, allShopsEnv } from "../../../utils/shop"
import { Link, useNavigate } from "react-router-dom"
import "./all-products.scss"
import { callGetApi, callDeleteApi } from "utils/api"
import DeleteModal from "../../../components/Common/DeleteModal"
import { showSuccessToastr } from "../../../components/Common/toastr"

const AllProducts = () => {
  document.title = "All Products"
  const history = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [shop, setShop] = useState(initialShopEnv[process.env.REACT_APP_ENV])
  const [allProducts, setAllProducts] = useState([])
  const [searchParam, setSearchParam] = useState({
    query: "",
    store_id: shop,
  })
  const [paginationParam, setPaginationParam] = useState({
    currentPage: 1,
    perPage: 100,
    total: 0,
  })
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false)

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const getProducts = async (paginationParam, searchParam) => {
    setIsLoading(true)
    if (!isNaN(searchParam.query) && searchParam.query) {
      callGetApi(
        true,
        `/products/vendors/shopify/stores/${shop}/products/${searchParam.query}`, //shop and store are basically the same i need to check why we have both
        data => onGetProductsSuccess(data, true),
        onGetProductsFail,
        "products"
      )
    } else {
      let params
      if (searchParam.query) {
        params = {
          ...searchParam,
          page_number: paginationParam.currentPage,
          page_size: paginationParam.perPage,
        }
      } else {
        params = {
          store_id: shop,
          page_number: paginationParam.currentPage,
          page_size: paginationParam.perPage,
        }
      }
      const query = Object.keys(params)
        .map(k => k + "=" + params[k])
        .join("&")
      callGetApi(
        true,
        `/products?${query}`,
        onGetProductsSuccess,
        onGetProductsFail,
        "products"
      )
    }
  }

  const onChangeShop = event => {
    let value = event.target.value
    let store = "shapees"
    let env = process.env.REACT_APP_ENV
    console.log("Valor", value)
    if (value.includes("truekind")) {
      if (env == "prod") {
        store = "truekind-store"
      } else if (env == "staging") {
        store = "truekind-beta"
      } else {
        store = "truekind-dev"
      }
    } else if (value.includes("revel")) {
      if (env == "prod") {
        store = "revel"
      } else if (env == "staging") {
        store = "revel-stage"
      } else {
        store = "revel-dev"
      }
    } else if (value.includes("spa")) {
      if (env == "prod") {
        store = "the-spa-dr"
      } else if (env == "staging") {
        store = "thespadr-dev"
      } else {
        store = "thespadr-dev"
      }
    } else {
      if (env == "prod") {
        store = "shapermint"
      } else if (env == "staging") {
        store = "shapees"
      } else {
        store = "shapees"
      }
    }

    setShop(store)
  }

  const deleteProduct = () => {
    setIsDeleteLoading(true)
    setShowDeleteModal(false)
    callDeleteApi(
      true,
      `/products/${selectedProduct.id}`,
      data => onDeleteProductSuccess(data),
      onDeleteProductFail
    )
  }
  // TODO uncomment when duplicate product endpoint is ready
  // const onDuplicateProductsSuccess = data => {
  //   setAllProducts([data, ...allProducts])
  //   showSuccessToastr("Product Duplicated Successfully!")
  //   setIsDuplicateLoading(false)
  //   setSelectedProduct(null)
  // }

  // const onDuplicateProductsFail = () => {
  //   setIsDuplicateLoading(false)
  // }

  const duplicateProduct = product => {
    setSelectedProduct(product)
    setIsDuplicateLoading(true)
    //TODO Create a duplicate product endpoint on Porducts API.
    console.log("Duplicate product!")
    // callPostApi(
    //   true,
    //   `/upselling/funnels/${funnelId_}?shop=${shop}`,
    //   {},
    //   onDuplicateProductsSuccess,
    //   onDuplicateProductsFail
    // )
  }

  const handlePageChange = pageNumber => {
    getProducts(
      {
        ...paginationParam,
        currentPage: pageNumber,
      },
      searchParam
    )
  }

  const onDeleteProductSuccess = () => {
    showSuccessToastr("Product Deleted Successfully!")
    setIsDeleteLoading(false)
    const activeProducts = allProducts.filter(
      product => product.id !== selectedProduct.id
    )
    setAllProducts(activeProducts)
    setSelectedProduct(null)
  }

  const onDeleteProductFail = () => {
    setIsDeleteLoading(false)
  }
  const onGetProductsFail = () => {
    setPaginationParam({
      ...paginationParam,
      currentPage: 1,
      total: 0,
    })
    setIsLoading(false)
    setAllProducts([])
  }

  const onCancelDeleteProduct = () => {
    setSelectedProduct(null)
    setShowDeleteModal(false)
  }

  const onGetProductsSuccess = (data, isShopifyId) => {
    if (isShopifyId) {
      setPaginationParam({
        ...paginationParam,
        currentPage: 1,
        total: 0,
      })
      setAllProducts([
        {
          ...data,
        },
      ])
      setIsLoading(false)
    } else {
      setPaginationParam({
        ...paginationParam,
        currentPage: data.pagination.page_number,
        total: data.pagination.total_pages,
      })
      setAllProducts(data.products)
      setIsLoading(false)
    }
  }

  const handleSearchProducts = event => {
    setSearchParam({ ...searchParam, query: event.target.value })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getProducts(
        {
          ...paginationParam,
          currentPage: 1,
        },
        searchParam
      )
    }, 1000)

    return () => clearTimeout(timer)
  }, [searchParam, shop])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Products"} breadcrumbItem={"All Products"} />
          <div>
            <Row>
              <Col lg={12}>
                <Card>
                  {isLoading ? (
                    <CardBody>
                      <div className="spinner-loader">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <div className="product-filter-container">
                        <Row>
                          <Col lg={4}>
                            <div>
                              <input
                                className="form-control"
                                type="search"
                                placeholder="Search..."
                                value={searchParam.query}
                                onChange={handleSearchProducts}
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <select
                                className="form-control"
                                value={shop}
                                onChange={e => {
                                  onChangeShop(e)
                                }}
                              >
                                {allShopsEnv[process.env.REACT_APP_ENV].map(
                                  option => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <select
                                className="form-control"
                                value={"test"}
                                onChange={null}
                              >
                                <option value="">All</option>
                                <option value={true}>Active</option>
                                <option value={false}>Draft</option>
                                <option value={false}>Archived</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="product-create-btn">
                              <Button
                                color="success"
                                className="btn btn-success waves-effect waves-light"
                                onClick={() => history("/shop-selection")}
                              >
                                Add Product
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-bordered mb-0 product-table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Status</th>
                              <th>Inventory</th>
                              <th>Type</th>
                              <th>Vendor</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allProducts?.map((product, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ padding: "0.5rem" }}>
                                    <div
                                      className="selected-product-item-list"
                                      key={index}
                                    >
                                      <img src={product.images?.[0]?.src} />
                                      <Label className="form-label">
                                        <Link
                                          to={`/product-details/${product.id}`}
                                        >
                                          {" "}
                                          {product.title}
                                        </Link>
                                      </Label>
                                    </div>
                                  </td>
                                  <td>{product.status}</td>
                                  <td>{product.inventory}</td>
                                  <td>{product.type}</td>
                                  <td>{product.vendor_product.vendor_id}</td>
                                  <td>
                                    <span className="product-actions">
                                      {isDuplicateLoading &&
                                      product.id == selectedProduct.id ? (
                                        <Spinner className="ms-2 duplicate-spinner" />
                                      ) : (
                                        <i
                                          className="mdi mdi-content-copy duplicate"
                                          onClick={() => {
                                            duplicateProduct(product)
                                          }}
                                        ></i>
                                      )}
                                      <i
                                        className="mdi mdi-pencil edit"
                                        onClick={() => {
                                          console.log("go to product details")
                                        }}
                                      ></i>
                                      {/* {isDeleteLoading &&
                                      product.id == selectedProduct.id ? (
                                        <Spinner className="ms-2 delete-spinner" />
                                      ) : (
                                        <i
                                          className="mdi mdi-delete delete"
                                          onClick={() => {
                                            setSelectedProduct(product)
                                            setShowDeleteModal(true)
                                          }}
                                        ></i>
                                      )} */}
                                    </span>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
            {!isLoading && (
              <Pagination className="pagination pagination-rounded justify-content-center  mb-4 pb-1">
                <PaginationItem disabled={paginationParam.currentPage === 1}>
                  <PaginationLink
                    previous
                    to="#"
                    onClick={() =>
                      handlePageChange(paginationParam.currentPage - 1)
                    }
                  />
                </PaginationItem>
                {Array(Math.ceil(paginationParam.total))
                  .fill()
                  .map((_, i) => (
                    <PaginationItem
                      active={i + 1 === paginationParam.currentPage}
                      key={i}
                    >
                      <PaginationLink
                        onClick={() => handlePageChange(i + 1)}
                        to="#"
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                <PaginationItem
                  disabled={
                    paginationParam.currentPage === paginationParam.total
                  }
                >
                  <PaginationLink
                    next
                    to="#"
                    onClick={() =>
                      handlePageChange(paginationParam.currentPage + 1)
                    }
                  />
                </PaginationItem>
              </Pagination>
            )}
            <DeleteModal
              show={showDeleteModal}
              onDeleteClick={deleteProduct}
              onCloseClick={onCancelDeleteProduct}
              message="Are you sure you want to delete this product?"
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllProducts
