import { CTAText } from "components/PageBuilder/utils"

const LayoutItem = editor => {
  editor.DomComponents.addType("layout-item", {
    isComponent: el =>
      el.classList && el.classList.contains("page-layout__item"),
    model: {
      defaults: {
        tagName: "div",
        classes: ["page-layout__item"],
        removable: true,
        draggable: false,
        resizable: false,
        ratio: 1,
        styles: `
            .page-layout__item {
                display: flex;
                align-items: top;
                justify-content: start;
            }
            .cta-text {
                color: #ccc;
            }
        `,
      },
      init() {
        this.on("component:update:components", () => {
          const actualLength = this.components().length
          const view = this.view.el
          if (actualLength) {
            view.querySelector(".cta-text")?.remove()
            view.border = "none"
            view.style.alignItems = null
            view.style.justifyContent = null
          }

          if (!actualLength) {
            view.innerHTML = CTAText()
            view.border = "1px dashed #ccc"
            view.style.justifyContent = "center"
            view.style.alignItems = "center"
          }
        })
      },
    },
    view: {
      onRender({ el }) {
        const style = el.style
        style.justifyContent = "center"
        style.alignItems = "center"
        if (!el.innerHTML.trim()) {
          style.border = "1px dashed #ccc"
          el.innerHTML = CTAText()
        }
      },
    },
  })
}

export default LayoutItem
