import { ProductList } from "../../../Common/SearchProducts"
import React, { useCallback, useState } from "react"
import ReactDOM from "react-dom"
import { Input } from "reactstrap"
import { allShopsEnv } from "../../../../utils/shop"
import { SELECTED_PRODUCT_CONFIG } from "./constants"

const allShops = allShopsEnv[process.env.REACT_APP_ENV]
const initialConfig = {
  shop: allShops? allShops[0]?.value : {},
  disabledShop: false,
  multiProducts: false,
  products: [],
}

const ProductSelector = ({
  shop: initialShop,
  disabledShop,
  multiProducts,
  products,
  variantSelectorMode
}) => {
  const [shop, setShop] = useState(initialShop)

  const handleShopsChange = useCallback(event => {
    setShop(event.target.value)
  }, [])

  const handleSelectedChange = useCallback(value => {
    localStorage.setItem(SELECTED_PRODUCT_CONFIG, JSON.stringify(value))
  }, [])

  return (
    <div className="dnd-product-selector">
      <div className="mb-3">
        <Input
          name="shopSelect"
          type="select"
          value={shop}
          onChange={handleShopsChange}
          disabled={disabledShop}
        >
          {allShops.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </Input>
      </div>
      <ProductList
        onSelectedChange={handleSelectedChange}
        multiProducts={multiProducts}
        shop={shop}
        prevSelection={products}
        maxSelection={3}
        variantSelectorMode={variantSelectorMode}
      />
    </div>
  )
}

class ProductSelectorWc extends HTMLElement {
  constructor() {
    super()
    // Do something more
  }

  connectedCallback() {
    let config = localStorage.getItem(SELECTED_PRODUCT_CONFIG)
    const { shop, disabledShop, multiProducts, products, variantSelectorMode } =
      config ? JSON.parse(config) : initialConfig
    // You can directly use shadow root as a mount point
    ReactDOM.render(
      <ProductSelector
        shop={shop}
        disabledShop={disabledShop}
        multiProducts={multiProducts}
        products={products}
        variantSelectorMode={variantSelectorMode}
      />,
      this
    )
  }
}

customElements.define("product-selector", ProductSelectorWc)
