export const mapPriceToPercentage = (unitPrice, volumePrice, itemQuantity) => {
  // Receive the unitPrice, volumePrice, and itemQuantity, and calculate the percentage discount based on the volumePrice
  const unitPriceWithDiscount = volumePrice / itemQuantity
  const discount = ((unitPrice - unitPriceWithDiscount) / unitPrice) * 100
  return discount
}

export const mapPercentageToPrice = (unitPrice, percentage, itemQuantity) => {
  // Receive the unitPrice, percentage, and itemQuantity, and calculate the volumePrice based on the percentage discount
  const unitPriceWithDiscount = unitPrice - (unitPrice * percentage) / 100

  const volumePrice = unitPriceWithDiscount * itemQuantity
  return volumePrice.toFixed(2) // Round the volumePrice to two decimal places
}

export const getBundlePercentage = (totalPrice, bundlePrice) => {
  // Receive the bundle price and the suma of the products price, and calculate the percentage discount
  return ((totalPrice - bundlePrice) / totalPrice) * 100
}
