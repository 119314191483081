export const colors = [
    { validator: 'Oatmeal', color: '#D9BDAC' },
    { validator: 'Lavender', color: '#E9CBFF' },
    { validator: 'Barely Black', color: '#3e3e3c' },
    { validator: 'Black Indigo Wash', color: '#332f59' },
    { validator: 'Off Black', color: '#575454' },
    { validator: 'Forest', color: '#057384' },
    { validator: 'Mulberry', color: '#8C387B' },
    { validator: 'Chai', color: '#E7D3C8' },
    { validator: 'Soft Sea', color: '#9EB4B8' },
    {
      validator: 'Heather Gray',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/Heather_Heather_Gray_with_Black_Trim_Silver_Filigree_Heather.jpg?v=1696266101'
      }
    },
    {
      validator: 'Navy with White Trim',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/Navy_with_white_trim.png?v=1680286637'
      }
    },
    {
      validator: 'Heather Gray with Black Trim',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/Heather_Gray_with_Black_Trim.png?v=1680286637'
      }
    },
    {
      validator: 'Black with White Trim',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/Black_with_White_Trim.png?v=1680286637'
      }
    },
    {
      validator: 'White with Black Trim',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/White_with_black_trim.png?v=1680286637'
      }
    },
    {
      validator: 'Black Jacquard',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/2_30x30_crop_center.jpg'
      }
    },
    { validator: 'black', color: '#000000' },
    { validator: 'Onyx', color: '#0f0f0f' },
    { validator: 'Sandshell and White Print', color: '#f8d3d3' },
    { validator: 'White Pattern', color: '#f9f9f9' },
    { validator: 'White', color: '#FFFFFF' },
    { validator: 'Ivory', color: '#f9dcc6' },
    { validator: 'Paris Nude', color: '#faefe9' },
    { validator: 'Nude Pattern', color: '#e8ccb0' },
    {
      validator: 'Nude Jacquard',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/color-jaguar_30x30_crop_center.jpg'
      }
    },
    { validator: 'Nude', color: '#efc0a0' },
    { validator: 'Blush', color: '#f6e4cd' },
    { validator: 'Blushing Pink', color: '#ffd6d2' },
    { validator: 'City Beige', color: '#e6cab3' },
    { validator: 'Beige', color: '#f3dfca' },
    { validator: 'Sandshell', color: '#e8d1c4' },
    { validator: 'Macaroon', color: '#f7e4c8' },
    { validator: 'Chocolate', color: '#553C36' },
    { validator: 'Dark Sand', color: '#e4bf91' },
    { validator: 'Sand', color: '#ffc883' },
    { validator: 'Latte Lift', color: '#efd1a0' },
    { validator: 'Latte', color: '#ae846d' },
    { validator: 'Burgundy', color: '#74303f' },
    { validator: 'Olive', color: '#646049' },
    { validator: 'Navy', color: '#0B0B45' },
    { validator: 'Silver Gray', color: '#c1b7b0' },
    { validator: 'Gray', color: '#616262' },
    { validator: 'Hush Pink', color: '#f7d5d0' },
    { validator: 'Dark Pink', color: '#D1295E' },
    { validator: 'Pink Bliss', color: '#f8e0cf' },
    {
      validator: 'Pinksicle',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/Pinksicle_30x30_crop_center.jpg'
      }
    },
    { validator: 'Atlantic Blue', color: '#325477' },
    {
      validator: 'Rosewood Animal Print',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/1_30x30_crop_center.jpg'
      }
    },
    { validator: 'Rosewood Swirl', color: '#eed7ca' },
    { validator: 'Rosewood', color: '#eecdc6' },
    { validator: 'Rose Tan', color: '#cf9693' },
    { validator: 'Rose Bisque', color: '#fadbdb' },
    { validator: 'Shiraz', color: '#54252f' },
    { validator: 'Midnight', color: '#2b2b47' },
    {
      validator: 'Ocean Blue Stripes',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/Ocean_Blue_Stripes_30x30_crop_center.jpg'
      }
    },
    { validator: 'Ocean Blue', color: '#325b74' },
    {
      validator: 'Elongated Polka Dots',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/Elongated_Polka_Dots_30x30_crop_center.jpg'
      }
    },
    {
      validator: 'Polka Dots',
      pattern: {
        url: 'https://cdn.shopify.com/s/files/1/0021/4889/2732/files/Polka_Dots_30x30_crop_center.jpg'
      }
    },
    { validator: 'Cappuccino', color: '#836f6e' },
    { validator: 'Barely There', color: '#ad8b6f' },
    { validator: 'Gentlebrown', color: '#634e37' },
    { validator: 'Transparent', color: '#d9ab8e' },
    { validator: 'Buff', color: '#d0a180' },
    { validator: 'Jet', color: '#262626' },
    { validator: 'Little Color', color: '#b99b77' },
    { validator: 'Blue', color: '#19284C' },
    { validator: 'Soft Taupe', color: '#edd9b8' },
    { validator: 'Sylver', color: '#dcd5d5' },
    { validator: 'Candlelight', color: '#eacfb8' },
    { validator: 'Platinum', color: '#efefef' },
    { validator: 'French Coffee', color: '#bd7c52' },
    { validator: 'Utopia', color: '#d79a68' },
    { validator: 'Champagne Shimmer', color: '#f3e0c9' },
    { validator: 'Cocoa', color: '#d2aca3' },
    { validator: 'Café au Lait', color: '#eeaa7c' },
    { validator: 'Clear', color: '#efe4b0' },
    { validator: 'Mint', color: '#30dfd2' },
    { validator: 'Pink', color: '#f7a08b' },
    { validator: 'Racing Red', color: '#BB3232' },
    { validator: 'Racer Red', color: '#BB3232' },
    { validator: 'Green', color: '#83CCA1' }
  ]