import React from "react"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { ThemeProvider } from "@trafilea/afrodita-components"
import shapermint from "@trafilea/afrodita-components/build/theme/shapermint.theme"
import store from "./epics/store"
import { FunnelDataProvider } from "./contexts/FunnelDataContext"
import ErrorBoundary from "./components/Common/ErrorBoundary"
import PropTypes from "prop-types"

const Providers = ({ children }) => (
  <Provider store={store}>
    <FunnelDataProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <ThemeProvider theme={shapermint}>{children}</ThemeProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </FunnelDataProvider>
  </Provider>
)

Providers.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Providers
