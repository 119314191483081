import moment from "moment-timezone"
import { useEffect, useState } from "react"
import { Table } from "reactstrap"

const NEXT_PAYMENT_DATE_FORMAT = "YYYY-MM-DD"
const BUSINESS_RULES = {
  SHAPERBOX_RECURRING_FREQUENCY: "SHAPERBOX_RECURRING_FREQUENCY",
}

const cancelledSubStatus = "cancelled"
const myAccountCancellationSource = "my_account_automatic_cancellation"
const categoryCancellationReasonType = "category"
const subCategoryCancellationReasonType = "subcategory"

const myAccountCancellationReasonMapper = Object.freeze({
  "Allergic reaction/ breakout issue": {
    [categoryCancellationReasonType]: "Product Dissatisfaction",
    [subCategoryCancellationReasonType]: "Allergic Reaction / Skin Irritation",
  },
  "Having more than one subscription": {
    [categoryCancellationReasonType]: "Multiple Subscription",
    [subCategoryCancellationReasonType]: "Multiple Subscription",
  },
})

const mapMyAccountCancellationReason = (
  cancellationType,
  cancellationReason,
  cancellationSource
) => {
  if (cancellationSource !== myAccountCancellationSource) {
    return cancellationType == categoryCancellationReasonType
      ? cancellationReason
      : ""
  }

  const reasonMapped =
    myAccountCancellationReasonMapper[cancellationReason]?.[cancellationType] ||
    ""

  if (reasonMapped !== "") {
    return reasonMapped
  }

  return cancellationType == categoryCancellationReasonType
    ? cancellationReason
    : ""
}

const TableDetailRow = ({ label, value }) => (
  <tr>
    <td colSpan="2">
      <h6 className="m-0 text-right">{label}</h6>
    </td>
    <td>
      <span>{value}</span>
    </td>
  </tr>
)

const getScheduleDetailRows = (scheduleStatus, lastPaymentDate) => {
  const rows = [
    {
      label: "Schedule Payment Status:",
      value: scheduleStatus?.schedule_payment_status || "",
    },
    {
      label: "Number of Attempts:",
      value: scheduleStatus?.number_of_attempts,
    },
    {
      label: "Next Payment Date:",
      value: scheduleStatus?.next_payment_date || "",
    },
    {
      label: "Last Attempt Date",
      value: scheduleStatus?.last_attempt_date || "",
    },
    {
      label: "Last Payment Date",
      value: lastPaymentDate || "",
    },
  ]

  return (
    <>
      {rows.map(row => (
        <TableDetailRow key={row.label} {...row} />
      ))}
    </>
  )
}

const getPriceModificationPeriodRow = (
  priceModification,
  priceModificationPeriod
) => {
  if (priceModification === null) {
    return null
  }

  const label = "Price Modification Period:"

  return (
    <TableDetailRow key={label} label={label} value={priceModificationPeriod} />
  )
}

const getCancellationDetailRows = (
  status,
  cancellationReason,
  cancellationSource
) => {
  if (status != cancelledSubStatus) {
    return null
  }

  const rows = [
    {
      label: "Cancellation Source: ",
      value: cancellationSource || "undefined",
    },
    {
      label: "Category Cancellation Reason: ",
      value: mapMyAccountCancellationReason(
        categoryCancellationReasonType,
        cancellationReason,
        cancellationSource
      ),
    },
    {
      label: "SubCategory Cancellation Reason: ",
      value: mapMyAccountCancellationReason(
        subCategoryCancellationReasonType,
        cancellationReason,
        cancellationSource
      ),
    },
  ]

  return (
    <>
      {rows.map(row => (
        <TableDetailRow key={row.label} {...row} />
      ))}
    </>
  )
}

const SubscriptionDetails = ({ subscription, ...otherProps }) => {
  const props = otherProps
  const [subscriptionDetails, setSubscriptionDetails] = useState({})
  const [nextDueDate, setNextDueDate] = useState(
    props.subscriptionDetails?.due_date
      ? moment
          .utc(props.subscriptionDetails.due_date)
          .format(NEXT_PAYMENT_DATE_FORMAT)
      : null
  )

  useEffect(() => {
    setSubscriptionDetails(props.subscriptionDetails)
  }, [props.subscriptionDetails])

  useEffect(() => {
    setNextDueDate(
      props.subscriptionDetails?.due_date
        ? moment
            .utc(props.subscriptionDetails.due_date)
            .format(NEXT_PAYMENT_DATE_FORMAT)
        : null
    )
  }, [props.subscriptionDetails?.due_date])

  return (
    <div className="table-responsive">
      <Table className="table align-middle table-nowrap">
        <thead>
          <tr>
            <th scope="col">Discount Target</th>
            <th scope="col">Type</th>
            <th scope="col">Value</th>
          </tr>
        </thead>
        <tbody>
          {subscriptionDetails.subscription_type?.discounts?.map(
            ({ discount_target, discount_type, discount_value }, index) => (
              <tr key={index}>
                <td>
                  <div>
                    <p className="text-muted mb-0">{discount_target}</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p className="text-muted mb-0">{discount_type}</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p className="text-muted mb-0">{discount_value}</p>
                  </div>
                </td>
              </tr>
            )
          )}
          <tr>
            <td colSpan="2">
              <h6 className="m-0 text-right">Shipping Method:</h6>
            </td>
            <td>
              <span>{subscriptionDetails.shipping_method}</span>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <h6 className="m-0 text-right">Frequency Unit:</h6>
            </td>
            <td>
              <span>{subscriptionDetails.frequency_unit}</span>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <h6 className="m-0 text-right">Frequency Value:</h6>
            </td>
            <td>
              <span>{subscriptionDetails.frequency_value}</span>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <h6 className="m-0 text-right">Free Trial Period:</h6>
            </td>
            <td>
              <span>
                {subscriptionDetails.free_trial_period ? "Yes" : "No"}
              </span>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <h6 className="m-0 text-right">Price Modification:</h6>
            </td>
            <td>
              <span>{subscriptionDetails.price_modification}</span>
            </td>
          </tr>
          {getPriceModificationPeriodRow(
            subscriptionDetails.price_modification,
            subscriptionDetails.price_modification_period
          )}
          <tr>
            <td colSpan="2">
              <h6 className="m-0 text-right">Modification Reason:</h6>
            </td>
            <td>
              <textarea
                rows="3"
                className="form-control"
                name="modifications_reason"
                placeholder={"Modification Reason"}
                value={subscriptionDetails.modifications_reason || ""}
              ></textarea>
            </td>
          </tr>
          {nextDueDate ? (
            <tr>
              <td colSpan="2">
                <h6 className="m-0 text-right"> Next Due Date:</h6>
              </td>
              <td>
                <span>{nextDueDate}</span>
              </td>
            </tr>
          ) : null}
          {getCancellationDetailRows(
            subscription.status,
            subscriptionDetails.cancellation_reason,
            subscriptionDetails.cancellation_source
          )}
          {getScheduleDetailRows(
            subscriptionDetails.schedule_status,
            subscriptionDetails.last_payment_date
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default SubscriptionDetails

export const getInitialDeliveryPeriod = businessRulesArray => {
  try {
    return getLatestAppliedShaperboxRecurringFrequencyRule(businessRulesArray)
  } catch (error) {
    console.error(error)
    return ""
  }
}

const isValidBusinessRulesArray = businessRulesArray => {
  return (
    businessRulesArray !== null &&
    Array.isArray(businessRulesArray) &&
    Object.keys(businessRulesArray).length > 0
  )
}

const getLatestAppliedShaperboxRecurringFrequencyRule = businessRulesArray => {
  if (!isValidBusinessRulesArray(businessRulesArray)) {
    return ""
  }

  let lastDate
  let shaperboxRecurringFrequency = ""

  for (const businessRule of businessRulesArray) {
    if (businessRule.rule !== BUSINESS_RULES.SHAPERBOX_RECURRING_FREQUENCY) {
      continue
    }

    const currentDate = new Date(businessRule.added_at)
    if (lastDate > currentDate) {
      continue
    }

    lastDate = currentDate

    shaperboxRecurringFrequency = businessRule.value
  }

  return getShaperboxRecurringFrequencyPeriod(shaperboxRecurringFrequency)
}

const getShaperboxRecurringFrequencyPeriod = period => {
  const days = parseInt(period, 10)
  if (isNaN(days)) {
    return ""
  }

  switch (days) {
    case 15:
      return "15 days"

    case 40:
      return "45 days"

    case 85:
      return "90 days"

    default:
      return `${days} days`
  }
}
