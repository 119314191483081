const css = `
  .remove-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: transparent;
    height: 100%;
    opacity: 0;
    transition: all 0.3s;
  }

  .sortable-list-item:hover .remove-btn {
    opacity: 0.7;
  }

  .question-type-select {
    margin-left: 10px;
    padding: 6px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 14px;
    color: inherit;
    border-radius: 4px;
  }
`

const createStyles = () => {
  const sheet = new CSSStyleSheet()
  sheet.replaceSync(css)
  return sheet
}

export default createStyles