import { useEffect, useState } from "react"
import { Table } from "reactstrap"
import './index.scss'
import { getFormattedDate } from "../../../../../utils/date"

const SubscriptionHistory = (props) => {
  const [subscriptionHistory, setSubscriptionHistory] = useState({})
  
  useEffect(() => {
    setSubscriptionHistory(props.subscriptionHistory)
  }, [subscriptionHistory])

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
      }}
    >
      <div className="table-responsive">
        {props.subscriptionHistory?.map(
        ({ datetime, user_email, reason, modifications, resource, resource_id }) => (
          <Table className="table align-middle table-nowrap">
            <thead>
              <tr>
                <th scope="col">Resource</th>
                <th scope="col">{resource}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <p className="text-muted mb-0">Resource ID</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p className="text-muted mb-0">{resource_id}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p className="text-muted mb-0">Reason</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p className="text-muted mb-0">{reason}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p className="text-muted mb-0">Email</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p className="text-muted mb-0">{user_email}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <p className="text-muted mb-0">Datetime</p>
                  </div>
                </td>
                <td>
                  <div>
                    <p className="text-muted mb-0">{getFormattedDate(datetime, "long")}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col">Field</th>
                <th scope="col">New Value</th>
              </tr>
              {JSON.parse(modifications)?.map(
              ({ field, new_value }) => (
                <tr>
                  <td>
                    <div>
                      <p className="text-muted mb-0">{ field }</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <p className="text-muted mb-0">{ new_value }</p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ))}
      </div>
    </form>
  )
}

export default SubscriptionHistory
