const SizeSelector = ({blockManager}) => {
    blockManager.add("size-selector", {
        id: "size-selector",
        label: "Size Selector",
        category: "Afrodita Components",
        activate: true,
        content: `
        <div style="max-width: 420px; width: 100%; margin: 0 auto; display: flex; align-items: center; justify-content: center;">
            <div style="display: inline-flex;">
                <div style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
                    <p style="margin: 0; font-size: 14px; line-height: 22px; padding: 0; color: black; text-align: center; font-weight: 800;font-family: 'Arial'">
                        Size
                    </p>
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; margin: 8px 0 0 0; justify-content: center; align-items: center;">
                        <button type="button" style="display: flex; justify-content: center; align-items: center; text-decoration: none; text-transform: uppercase; cursor: pointer; border-radius: 0.375rem; width: 4rem; box-sizing: border-box; font-size: 0.75rem; background: #fff; color: #292929; border: 0.063rem solid #a6a6a6; font-weight: 600; white-space: nowrap; padding: 0.75rem 1rem 0.625rem; margin: 0 0.5rem 0.625rem 0; min-width: 4rem; transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;"
                            onFocus="this.style.backgroundColor='#292929'; this.style.color='#fff';"
                            onBlur="this.style.backgroundColor='#fff'; this.style.color='#292929';"
                            onClick="this.style.backgroundColor='#292929'; this.style.color='#fff';">
                            S
                        </button>
                        <button type="button" style="display: flex; justify-content: center; align-items: center; text-decoration: none; text-transform: uppercase; cursor: pointer; border-radius: 0.375rem; width: 4rem; box-sizing: border-box; font-size: 0.75rem; background: #fff; color: #292929; border: 0.063rem solid #a6a6a6; font-weight: 600; white-space: nowrap; padding: 0.75rem 1rem 0.625rem; margin: 0 0.5rem 0.625rem 0; min-width: 4rem; transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;"
                            onFocus="this.style.backgroundColor='#292929'; this.style.color='#fff';"
                            onBlur="this.style.backgroundColor='#fff'; this.style.color='#292929';"
                            onClick="this.style.backgroundColor='#292929'; this.style.color='#fff';">
                            M
                        </button>
        
                        <button type="button" style="display: flex; justify-content: center; align-items: center; text-decoration: none; text-transform: uppercase; cursor: pointer; border-radius: 0.375rem; width: 4rem; box-sizing: border-box; font-size: 0.75rem; background: #fff; color: #292929; border: 0.063rem solid #a6a6a6; font-weight: 600; white-space: nowrap; padding: 0.75rem 1rem 0.625rem; margin: 0 0.5rem 0.625rem 0; min-width: 4rem; transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;"
                            onFocus="this.style.backgroundColor='#292929'; this.style.color='#fff';"
                            onBlur="this.style.backgroundColor='#fff'; this.style.color='#292929';"
                            onClick="this.style.backgroundColor='#292929'; this.style.color='#fff';">
                            L
                        </button>
                        <button type="button" style="display: flex; justify-content: center; align-items: center; text-decoration: none; text-transform: uppercase; cursor: pointer; border-radius: 0.375rem; width: 4rem; box-sizing: border-box; font-size: 0.75rem; background: #fff; color: #292929; border: 0.063rem solid #a6a6a6; font-weight: 600; white-space: nowrap; padding: 0.75rem 1rem 0.625rem; margin: 0 0.5rem 0.625rem 0; min-width: 4rem; transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;"
                            onFocus="this.style.backgroundColor='#292929'; this.style.color='#fff';"
                            onBlur="this.style.backgroundColor='#fff'; this.style.color='#292929';"
                            onClick="this.style.backgroundColor='#292929'; this.style.color='#fff';">
                            XL
                        </button><button type="button" style="display: flex; justify-content: center; align-items: center; text-decoration: none; text-transform: uppercase; cursor: pointer; border-radius: 0.375rem; width: 4rem; box-sizing: border-box; font-size: 0.75rem; background: #fff; color: #292929; border: 0.063rem solid #a6a6a6; font-weight: 600; white-space: nowrap; padding: 0.75rem 1rem 0.625rem; margin: 0 0.5rem 0.625rem 0; min-width: 4rem; transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;"
                            onFocus="this.style.backgroundColor='#292929'; this.style.color='#fff';"
                            onBlur="this.style.backgroundColor='#fff'; this.style.color='#292929';"
                            onClick="this.style.backgroundColor='#292929'; this.style.color='#fff';">
                            2XL
                        </button><button type="button" style="display: flex; justify-content: center; align-items: center; text-decoration: none; text-transform: uppercase; cursor: pointer; border-radius: 0.375rem; width: 4rem; box-sizing: border-box; font-size: 0.75rem; background: #fff; color: #292929; border: 0.063rem solid #a6a6a6; font-weight: 600; white-space: nowrap; padding: 0.75rem 1rem 0.625rem; margin: 0 0.5rem 0.625rem 0; min-width: 4rem; transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;"
                            onFocus="this.style.backgroundColor='#292929'; this.style.color='#fff';"
                            onBlur="this.style.backgroundColor='#fff'; this.style.color='#292929';"
                            onClick="this.style.backgroundColor='#292929'; this.style.color='#fff';">
                            3XL
                        </button><button type="button" style="display: flex; justify-content: center; align-items: center; text-decoration: none; text-transform: uppercase; cursor: pointer; border-radius: 0.375rem; width: 4rem; box-sizing: border-box; font-size: 0.75rem; background: #fff; color: #292929; border: 0.063rem solid #a6a6a6; font-weight: 600; white-space: nowrap; padding: 0.75rem 1rem 0.625rem; margin: 0 0.5rem 0.625rem 0; min-width: 4rem; transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;"
                            onFocus="this.style.backgroundColor='#292929'; this.style.color='#fff';"
                            onBlur="this.style.backgroundColor='#fff'; this.style.color='#292929';"
                            onClick="this.style.backgroundColor='#292929'; this.style.color='#fff';">
                            4XL
                        </button>
                    </div>
                </div>
            </div>
        </div>`,
      })
}

export default SizeSelector