import Flag from "../../../assets/images/usa-flag.png"
import GuaranteeImg from "../../../assets/images/days.png"

const Guarantee = ({blockManager}) => {
    blockManager.add("gurrantee", {
        id: "gurrantee",
        label: "Guarantee",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="max-width: 420px; width: 100%; margin: 0 auto;">
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 6px; background: #f7f7f7; border-radius: 8px; margin: auto;">
          <div style="border-radius: 0.5rem; padding: 0.5em 2em; text-align: left; width: 100%; display: flex; flex-direction: column; justify-content: center; gap: 8px;">
            <p style="display: flex; align-items: center; gap: 12px; margin: 0;font-family: 'Arial'">
              <img src="${GuaranteeImg}" style="width: 35px;" alt="Guarantee" />
              <b style="font-size: 16px; color: black; font-weight: 800"> Our 60-Day Fit Guarantee</b>
            </p>
            <p style="display: flex; align-items: center; gap: 12px; margin: 0;font-family: 'Arial'">Free Exchange &amp; No-Hassle Returns.</p>
            <p style="display: flex; align-items: center; gap: 12px; margin: 0;font-family: 'Arial'">
              <img src="${Flag}" style="width: 35px;" alt="Flag" />
              <b style="font-size: 16px; color: black; font-weight: 800"> Shipping from US facilities</b>
            </p>
          </div>
        </div>
      </div>
      `,
    })
}

export default Guarantee