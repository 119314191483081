const Title = ({blockManager}) => {
    blockManager.add("title", {
        id: "title",
        label: "Title",
        category: "Afrodita Components",
        activate: true,
        content: `<div>
        <h6 style="margin: 0; color: #292929; font-size: 20px; line-height: 28px; font-weight: 800; text-align: center; font-family: 'Arial'">
          Add the 2-Pack of our Daily Comfort Bra
        </h6>
      </div>`,
    })
}

export default Title