import moment from "moment-timezone"
import { useEffect, useState } from "react"
import {
  Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table
} from "reactstrap"
import swal from "sweetalert"
import { showErrorToastr, showSuccessToastr } from "../../../../../components/Common/toastr"
import { callGetApi, callPatchApi, callPostApi } from "../../../../../utils/api"
import { errorHandler } from "../../../../../utils/error"
import { removeWhitespaces } from "../../../../../utils/sanitizer"
import { SubscriptionItem } from "../../SubscriptionItemsModal/SubscriptionItems/SubscriptionItem"
import { REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED, getCategoryAndSubcategoryReasons } from "../modify"
import "./product.scss"

const DropdownComponent = ({
                             index,
                             title,
                             valueKey,
                             dropdownOpen,
                             toggleDropdown,
                             selectedDropdownValues,
                             handleSelectedDropdownValues,
                             handleFormInput,
                             defaultValues,
                             handleCategoryChange,
                             categoriesKeysMap
                           }) => (<>
  <span>{title}</span>
  <Dropdown isOpen={dropdownOpen[index]} toggle={() => toggleDropdown(index)}>
    <DropdownToggle caret>
      {selectedDropdownValues[index] || "Select"} {}
    </DropdownToggle>
    <DropdownMenu>
      {defaultValues[valueKey] && defaultValues[valueKey].map(value => (<DropdownItem
        key={value}
        onClick={() => {
          handleSelectedDropdownValues(index, value)
          handleFormInput(valueKey, (valueKey === "reasonCategory" ? categoriesKeysMap[value] : value))
          if (handleCategoryChange) {
            handleCategoryChange(value)
          }
        }}
      >
        {value}
      </DropdownItem>))}
    </DropdownMenu>
  </Dropdown>
</>)

const InputComponent = ({
                          title, type, valueKey, formValues, defaultValues, handleFormInput, readOnly = false
                        }) => (<>
  <span>{title}</span>
  <div className="input">
    <Input
      className="no-arrows"
      type={type}
      min="1"
      value={readOnly ? defaultValues[valueKey] : formValues[valueKey]}
      onChange={(event) => handleFormInput(valueKey, event.target.value)}
      readOnly={readOnly}
    />
  </div>
</>)

function SubscriptionProductModal({ isOpen, toggle, subscription, toggleIsUpdated }) {
  const [defaultValues, setDefaultValues] = useState({
    reasonCategory: [],
    reasonSubcategory: [],
    productId: "",
    variantId: "",
    quantity: "",
    productModificationPeriod: "",
    productModificationDate: ""
  })

  let [formValues, setFormValues] = useState({})

  const [isDropdownOpen, setIsDropdownOpen] = useState({})
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({})
  const [subcategoriesLabelsMap, setSubcategoriesLabelsMap] = useState({})

  const [categoriesKeysMap, setCategoriesKeysMap] = useState({})
  const [subcategoriesKeysMap, setSubcategoriesKeysMap] = useState({})

  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState(false)

  const [store, setStore] = useState({})
  let [isFormValid, setIsFormValid] = useState(false)

  const [subscriptionItems, setSubscriptionItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    if (!isOpen) {
      return
    }

    onRefetchSubscriptionItems()
    setProductDates()
    setCategoryAndSubcategoryReasons()
    toggleIsSubscriptionCancelled()
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      return
    }

    setDefaultValues({
      reasonCategory: [],
      reasonSubcategory: [],
      productId: "",
      variantId: "",
      quantity: "",
      productModificationPeriod: "",
      productModificationDate: ""
    })

    setFormValues({
      reasonCategory: "", reasonSubcategory: "", productId: "", variantId: "", quantity: ""
    })

    setIsDropdownOpen({})
    setSelectedDropdownValues({})
    setSubcategoriesLabelsMap({})
    setIsFormValid(false)
    setIsSubscriptionCancelled(true)
    setSubscriptionItems([])
    setStore({})
  }, [isOpen])

  useEffect(() => {
    const isProductIdValid = formValues.productId !== null && formValues.productId !== ""
    const isVariantIdValid = formValues.variantId !== null && formValues.variantId !== ""
    const isQuantityValid = formValues.quantity !== null && formValues.quantity > 0

    isFormValid = isProductIdValid && isVariantIdValid && isQuantityValid
    setIsFormValid(isFormValid)
  }, [formValues])

  useEffect(() => {
    if (selectedItem === null) {
      return
    }

    onRefetchSubscriptionItems()
    updateSubscriptionModificationFields(selectedItem.old, selectedItem.new)
    setSelectedItem(null)
    setSelectedDropdownValues({})
    setFormValues({
      reasonCategory: "", reasonSubcategory: "", productId: "", variantId: "", quantity: ""
    })
  }, [selectedItem])

  const setProductDates = () => {
    const productModificationDate = subscription.product_modification_date ? moment.utc(subscription.product_modification_date).format("YYYY-MM-DD") : "Not Assigned"
    const productModificationPeriod = subscription.product_modification_period ? moment.utc(subscription.product_modification_period).format("YYYY-MM-DD") : "Not Assigned"

    setDefaultValues(prevState => ({
      ...prevState,
      productModificationPeriod: productModificationPeriod,
      productModificationDate: productModificationDate
    }))
  }

  const setCategoryAndSubcategoryReasons = () => {
    const result = getCategoryAndSubcategoryReasons(subscription)

    const store = result[0]
    const categoriesKeysMap = result[1]
    const subcategoriesKeysMap = result[2]
    const categoriesLabels = result[3]
    const subcategoriesLabels = result[4]

    setStore(store)
    setCategoriesKeysMap(categoriesKeysMap)
    setSubcategoriesKeysMap(subcategoriesKeysMap)
    setSubcategoriesLabelsMap(subcategoriesLabels)

    setDefaultValues(prevState => ({
      ...prevState, reasonCategory: categoriesLabels
    }))
  }

  const onRefetchSubscriptionItems = () => {
    getSubscriptionItems(subscription.id)
  }

  const onGetItemsFail = (error) => {
    showErrorToastr("Failed to get the item")

    errorHandler(error)
  }

  const onCreateItemFail = (error) => {
    showErrorToastr("Failed to create the item")

    errorHandler(error)
  }

  const onUpdateModificationsReasonsAndFieldsFail = (error) => {
    errorHandler(error)
  }

  const onGetItemsSuccess = response => {
    setSubscriptionItems(response)
  }

  const onUpdateModificationsReasonsAndFieldsSuccess = () => {
    showSuccessToastr("The modifications reason and fields were updated")

    toggleIsUpdated()
  }

  const onCreateItemSuccess = data => {
    showSuccessToastr("The item was created successfully")

    const newItem = createItem(data)

    setSelectedItem(() => ({
      old: newItem, new: newItem
    }))

    toggleIsUpdated()
  }

  const updateSubscriptionModificationFields = async (oldItem, newItem) => {
    const payload = getSubscriptionModificationFieldsPayload(formValues, oldItem, newItem)

    callPatchApi(true, `/subscriptions/${subscription.id}`, payload, onUpdateModificationsReasonsAndFieldsSuccess, onUpdateModificationsReasonsAndFieldsFail, "auth")
  }

  const updateSubscription = async (payload) => {
    callPostApi(true, `/subscriptions/${payload.subscription_id}/subscription_items`, payload, onCreateItemSuccess, onCreateItemFail, "auth")
  }

  const getSubscriptionItems = () => {
    callGetApi(true, `/subscriptions/${subscription.id}/subscription_items`, onGetItemsSuccess, onGetItemsFail, "auth")
  }

  const toggleIsSubscriptionCancelled = async () => {
    if (!isSchedulePresent(subscription)) {
      return
    }

    setIsSubscriptionCancelled(false)
  }

  const toggleDropdown = (index) => {
    setIsDropdownOpen(prevState => ({ ...prevState, [index]: !prevState[index] }))
  }

  const handleSelectedDropdownValues = (index, value) => {
    setSelectedDropdownValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleFormInput = (index, value) => {
    if (index === "reasonSubcategory") {
      value = subcategoriesKeysMap[formValues.reasonCategory][value]
    }

    setFormValues(prevState => ({ ...prevState, [index]: value }))
  }

  const handleCategoryChange = (selectedCategory) => {
    const categoryKey = categoriesKeysMap[selectedCategory]
    const subcategories = subcategoriesLabelsMap[categoryKey]

    setDefaultValues(prevState => ({
      ...prevState, reasonSubcategory: subcategories
    }))

    handleSelectedDropdownValues(2, null)
  }

  const handleSetItemsUpdated = (oldItem, newItem) => {
    setSelectedItem(() => ({
      old: oldItem, new: newItem
    }))
  }

  const handleSetItemRemoved = (_) => {
  }

  const handleUpdateSubscription = async () => {
    if (!isFormValid) {
      return
    }

    if (isSubscriptionCancelled) {
      showErrorToastr("The subscription cannot be updated")
      return
    }

    const invalidField = findInvalidField(formValues)
    if (invalidField !== null) {
      showErrorToastr(`The field ${invalidField} got invalid characters`)
      return
    }

    const payload = getSubscriptionItemPayload(subscription, formValues)

    const confirm = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to update this subscription?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true
    })

    if (!confirm) {
      return
    }

    await updateSubscription(payload)
  }

  const handleSubmit = async () => {
    await handleUpdateSubscription()
  }

  return (<Modal
    isOpen={isOpen}
    role="dialog"
    autoFocus={true}
    centered={true}
    className="subscription-product-modal"
    tabIndex="-1"
    toggle={toggle}
  >
    <ModalHeader
      toggle={toggle}
    >
      Products
    </ModalHeader>
    <ModalBody>
      <div className="flex-container wrap">
        <div className="item">
          <InputComponent
            title="Product Modification Period"
            type="text"
            valueKey="productModificationPeriod"
            defaultValues={defaultValues}
            handleFormInput={handleFormInput}
            readOnly={true}
          />
        </div>

        <div className="item">
          <InputComponent
            title="Product Modification Date"
            valueKey="productModificationDate"
            defaultValues={defaultValues}
            handleFormInput={handleFormInput}
            readOnly={true}
          />
        </div>

        <div className="item">
          <DropdownComponent
            index={1}
            title="Reason Category"
            valueKey="reasonCategory"
            dropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            selectedDropdownValues={selectedDropdownValues}
            handleSelectedDropdownValues={handleSelectedDropdownValues}
            defaultValues={defaultValues}
            handleFormInput={handleFormInput}
            handleCategoryChange={handleCategoryChange}
            categoriesKeysMap={categoriesKeysMap}
          />
        </div>

        <div className="item">
          <DropdownComponent
            index={2}
            title="Reason Subcategory"
            valueKey="reasonSubcategory"
            dropdownOpen={isDropdownOpen}
            toggleDropdown={toggleDropdown}
            selectedDropdownValues={selectedDropdownValues}
            handleSelectedDropdownValues={handleSelectedDropdownValues}
            defaultValues={defaultValues}
            handleFormInput={handleFormInput}
            categoriesKeysMap={categoriesKeysMap}
          />
        </div>

        <div className="item">
          <InputComponent
            title="Product ID"
            type="text"
            valueKey="productId"
            formValues={formValues}
            handleFormInput={handleFormInput}
          />
        </div>

        <div className="item">
          <InputComponent
            title="Variant ID"
            type="text"
            valueKey="variantId"
            formValues={formValues}
            handleFormInput={handleFormInput}
          />
        </div>

        <div className="item">
          <InputComponent
            title="Quantity"
            type="number"
            valueKey="quantity"
            formValues={formValues}
            handleFormInput={handleFormInput}
          />
        </div>

        <div className="item box">
          <Button
            className="btn-accept box"
            onClick={handleSubmit}
            disabled={!isFormValid}
          >
            Create Item
          </Button>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <div className="table-responsive">
        <Table className="table align-middle table-nowrap">
          <thead>
          <tr>
            <th scope="col" className="actions-column">Actions</th>
            <th scope="col">ID</th>
            <th scope="col">Product Name</th>
            <th scope="col" className="px-5">Price</th>
            <th scope="col" className="px-5">Compare at Price</th>
            <th scope="col">SKU</th>
            <th scope="col" className="px-5">Quantity</th>
            <th scope="col">Product ID</th>
            <th scope="col">Variant ID</th>
          </tr>
          </thead>
          <tbody>
          {subscriptionItems?.map((item) => (<SubscriptionItem
            key={item?.id}
            item={item}
            onRefetchItems={onRefetchSubscriptionItems}
            onItemUpdated={handleSetItemsUpdated}
            onItemRemoved={handleSetItemRemoved}
          />))}
          </tbody>
        </Table>
      </div>
    </ModalFooter>
  </Modal>)
}

export default SubscriptionProductModal

function isSchedulePresent(subscriptionDetails) {
  return subscriptionDetails.due_date !== null && subscriptionDetails.due_date !== ""
}

function getOldAndNewValues(oldValue, newValue) {
  return {
    old: oldValue, new: newValue
  }
}

function getModificationsFields(oldItem, newItem) {
  if (newItem.quantity === null && newItem.price === null) {
    return null
  }

  let payload = {}

  if (oldItem.quantity !== newItem.quantity) {
    payload.item_quantity = getOldAndNewValues(oldItem.quantity, newItem.quantity)
  }

  if (oldItem.price !== newItem.price) {
    payload.item_price = getOldAndNewValues(oldItem.price, newItem.price)
  }

  return payload
}

function createItem(data) {
  return {
    product_id: data.product_id,
    variant_id: data.variant_id,
    sku: data.sku ? (data.sku !== "" ? data.sku : "TSDDE5") : "TSDDE5",
    quantity: data.quantity,
    price: data.price,
    compare_at_price: data.compare_at_price ? (data.compare_at_price > 0 ? data.compare_at_price : 1) : 1
  }
}

function getSubscriptionModificationFieldsPayload(form, oldItem, newItem) {
  let payload = {
    item: createItem(oldItem)
  }

  payload.modifications_reason = "reason_category_not_defined - reason_subcategory_not_defined"
  if (form.reasonCategory !== null && form.reasonSubcategory !== null && form.reasonCategory !== "" && form.reasonSubcategory !== "") {
    payload.modifications_reason = form.reasonCategory + " - " + form.reasonSubcategory
  }

  const modificationsFields = getModificationsFields(oldItem, newItem)

  if (modificationsFields !== null) {
    payload.modifications_fields = modificationsFields
  }

  return payload
}

function getSubscriptionItemPayload(subscription, form) {
  let modificationsReason = REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED
  if (form.reasonCategory !== "" && form.reasonSubcategory !== "") {
    modificationsReason = form.reasonCategory + " - " + form.reasonSubcategory
  }

  return {
    product_id: removeWhitespaces(form.productId),
    variant_id: removeWhitespaces(form.variantId),
    quantity: parseInt(form.quantity),
    subscription_id: removeWhitespaces(subscription.id),
    modifications_reason: modificationsReason
  }
}

function isFieldsValid(value) {
  const regex = /[^a-zA-Z0-9-]/g
  return !regex.test(value)
}

function findInvalidField(form) {
  const fieldsToValidate = ["productId", "variantId"]

  for (let field of fieldsToValidate) {
    if (!isFieldsValid(removeWhitespaces(form[field]))) {
      return field
    }
  }

  return null
}

