const Description = ({blockManager}) => {
    blockManager.add("description", {
        id: "description",
        label: "Description",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="max-width: 700px; width: 100%; margin: 0 auto; font-family: 'Arial';">
        <div style="background-color: #efefef; padding: 30px 120px; text-align: left;">
            <div style="color: #292929c2; font-size: 22px; line-height: 28px; font-weight: 700;">
                Description
            </div>
            <div style="margin-top: 18px; color: #292929c2; font-size: 16px; line-height: 27px;">
                The Empetua® All Day Every Day Scoop Neck Cami is the cami you never knew you needed. Its incredible shaping power gives you a smooth look whether you wear it by itself or as a base layer. You can even wear it under a dress.
                <br />
                The nylon-spandex blend material gives you soft, breathable support that gently smooths your curves. So you can say goodbye to muffin tops and love handles. Enjoy wide, adjustable straps that fit every body type and don’t dig into your skin. It also provides chest and posture support.
            </div>
            <div style="margin-top: 18px; margin-left: -11px; color: #292929c2; font-size: 16px; line-height: 27px;">
                <ul>
                    <li>Minimizes muffin tops and love handles.</li>
                    <li>Keeps your chest supported enough to wear with or without a bra.</li>
                    <li>Fits every body type thanks to wide, adjustable straps.</li>
                    <li>Read “How To Wear It” for easy on and off techniques.</li>
                    <li>This product has a High-Compression Level. For everyday comfort, we recommend visiting our Size and Fit Guide.</li>
                </ul>
            </div>
        </div>
    </div>
    `,
    })
}

export default Description