import { Card, CardBody, Col, Row } from "reactstrap"
import {
  Currency,
  Description,
  FreeTrialPeriod,
  FrequencyUnit,
  FrequencyValue,
  LegacyPlan,
  Name,
  PaymentSource,
  Plan,
  Price,
  Title
} from "./columns"
import React, { useEffect, useMemo, useState } from "react"

import Breadcrumbs from "components/Common/Breadcrumb"
import { SubscriptionDiscountsModal } from "./modals"
import TableContainer from "components/Common/TableContainer"
import { abortRequest, callGetApi } from "utils/api"
import { isSubscriptionAdminUser } from "../../utils/auth"
import Forbidden from "../Forbidden/forbidden"

function SubscriptionTypes() {
  document.title = "Subscriptions | Backoffice"

  const [isAdminUser, setIsAdminUser] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showSubscriptionDiscounts, setShowSubscriptionDiscounts] = useState(false)
  const [filters, setFilters] = useState({})
  const [subscriptionType, setSubscriptionType] = useState(null)
  const [subscriptionTypes, setSubscriptionTypes] = useState([])

  function parseArrayToQueryParams(params) {
    const omitQueryCaseSensitive = (key, value) => key === "user_email" && typeof value === "string" ? value.toLowerCase() : value

    return Object.entries(params)
      .filter(([key, value]) => key !== "limit" && key !== "page" && key !== "" && value !== "")
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(omitQueryCaseSensitive(key, value))}`)
      .join("&")
  }

  const onRequestFail = (error) => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          throw new Error("Sorry! the page you are looking for could not be found")
        case 500:
          throw new Error("Sorry! something went wrong, please contact our support team")
        case 401:
          throw new Error("Invalid credentials")

        default:
          throw new Error(`Status: ${error.response.status} Error: ${error.message}`)
      }
    }
    setIsLoading(false)
    throw error
  }

  const onGetSubscriptionsSuccess = (data) => {
    setSubscriptionTypes(data)
  }

  const getSubscriptionTypes = () => {
    abortRequest()

    callGetApi(true, `/subscription_types${parseArrayToQueryParams(filters)}`, onGetSubscriptionsSuccess, onRequestFail, "auth")
  }

  useEffect(() => {
    if (!isSubscriptionAdminUser()) {
      return
    }

    setIsAdminUser(true)

    getSubscriptionTypes()
  }, [isAdminUser])

  const toggleShowSubscriptionDiscounts = (subscriptionType) => {
    setSubscriptionType(!showSubscriptionDiscounts ? subscriptionType : null)
    setShowSubscriptionDiscounts(!showSubscriptionDiscounts)
  }

  const createCustomFilterHandler = (field) => (filterVal) => {
    console.log(`Setting filter ${field} to ${filterVal}...`)
    setFilters({
      ...filters, [field]: filterVal
    })
    getSubscriptionTypes()
  }

  const columns = useMemo(() => [{
    Header: "Name",
    accessor: "name",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.name || "",
    customFilterHandler: createCustomFilterHandler("name"),
    Cell: Name
  }, {
    Header: "Frequency Value",
    accessor: "frequency.value",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.frequency_value || "",
    customFilterHandler: createCustomFilterHandler("frequency_value"),
    Cell: FrequencyValue
  }, {
    Header: "Frequency Unit",
    accessor: "frequency.unit",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.frequency_unit || "",
    customFilterHandler: createCustomFilterHandler("frequency_unit"),
    Cell: FrequencyUnit
  }, {
    Header: "Free Trial",
    accessor: "free_trial_period",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.free_trial_period || "",
    customFilterHandler: createCustomFilterHandler("free_trial_period"),
    Cell: FreeTrialPeriod
  }, {
    Header: "Price",
    accessor: "price",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.price || "",
    customFilterHandler: createCustomFilterHandler("price"),
    Cell: Price
  }, {
    Header: "Currency",
    accessor: "currency",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.currency || "",
    customFilterHandler: createCustomFilterHandler("currency"),
    Cell: Currency
  }, {
    Header: "Plan",
    accessor: "plan",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.plan || "",
    customFilterHandler: createCustomFilterHandler("plan"),
    Cell: Plan
  }, {
    Header: "Title",
    accessor: "title",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.title || "",
    customFilterHandler: createCustomFilterHandler("title"),
    Cell: Title
  }, {
    Header: "Description",
    accessor: "description",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.description || "",
    customFilterHandler: createCustomFilterHandler("description"),
    Cell: Description
  }, {
    Header: "Legacy Plan",
    accessor: "legacy_plan",
    filterable: true,
    hasCustomFilterData: true,
    customValue: filters?.legacy_plan || "",
    customFilterHandler: createCustomFilterHandler("legacy_plan"),
    Cell: LegacyPlan
  },

    {
      Header: "Payment Source",
      accessor: "payment_source",
      filterable: true,
      hasCustomFilterData: true,
      customValue: filters?.payment_source || "",
      customFilterHandler: createCustomFilterHandler("payment_source"),
      Cell: PaymentSource
    }, {
      Header: "View Details", accessor: "view", disableFilters: true, Cell: cellProps => (<div className="d-flex gap-2">
        <button
          className="btn btn-sm btn-rounded btn-primary"
          onClick={() => toggleShowSubscriptionDiscounts(cellProps.row.original)}
        >
          Discounts
        </button>
      </div>)
    }], [filters])

  return (!isAdminUser ? <Forbidden /> : <>
    <SubscriptionDiscountsModal
      isOpen={showSubscriptionDiscounts}
      toggle={toggleShowSubscriptionDiscounts}
      subscriptionType={subscriptionType}
    />
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Backoffice" breadcrumbItem="Subscription Types" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={subscriptionTypes ?? []}
                  isAddOptions={true}
                  customPageSize={10}
                  isLoading={isLoading}
                  areDropdownsHidden={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  </>)
}

export default SubscriptionTypes
