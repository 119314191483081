export const discountTargets = [
  { label: "Shipping", value: "shipping" },
  { label: "Items", value: "items" },
]

export const discountTypes = [
  { label: "Percentage", value: "percentage" },
  { label: "Amount", value: "amount" },
]

export const formatDate = date =>
  `${new Date(date).getFullYear()}-${String(
    new Date(date).getMonth() + 1
  ).padStart(2, "0")}-${String(new Date(date).getUTCDate()).padStart(2, "0")}`
