import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import "./list-pages.scss"
import ConfirmModal from "../../../components/Common/ConfirmModal"
import { callGetApi, callDeleteApi } from "../../../utils/api"
import { formatDate, toTitleCaseWithSpacing } from "../../../utils/bpmn"
import { showErrorToastr, showSuccessToastr } from "components/Common/toastr"
import { useFunnelData } from "../../../contexts/FunnelDataContext"
import { getFriendlyErrorMessage } from "../../../utils/errorHandling"

const ListPages = () => {
  const [pages, setPages] = useState([])
  const [displayPage, setDisplayPage] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const { funnelData } = useFunnelData()
  const [confirmAction, setConfirmAction] = useState({
    action: null,
    pageData: null,
  })
  const { funnelId } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    const canAccess = process.env.REACT_APP_ENV !== "prod"
    setDisplayPage(canAccess)
    if (canAccess) {
      fetchPages()
    }
  }, [])

  const fetchPages = () => {
    const queryParams = new URLSearchParams({
      funnelId,
    })

    const paramsToSend = queryParams.toString()
    callGetApi(
      true,
      `/page-builder/pages?${paramsToSend}`,
      data => {
        setPages(data.pages)
      },
      errorMessage => {
        console.error("Failed to fetch pages:", errorMessage)
        showErrorToastr(getFriendlyErrorMessage(errorMessage))
        setPages([])
      }
    )
  }

  const deletePage = pageData => {
    if (!pageData?.id) {
      showErrorToastr("An error ocurred")
      return
    }

    const queryParams = new URLSearchParams({
      updatedBy: pageData.created_by,
    }).toString()

    callDeleteApi(
      true,
      `/page-builder/${pageData.id}?${queryParams}`,
      () => {
        fetchPages()
        showSuccessToastr("Page successfully deleted")
      },
      errorMessage => {
        console.log("Failed to delete page:", errorMessage)
        showErrorToastr(getFriendlyErrorMessage(errorMessage))
      }
    )
  }

  const editPage = () => {
    navigate(`/page-builder/${funnelId}`)
  }

  const confirmDeletePage = page => {
    if (!page?.id) {
      showErrorToastr("An error ocurred")
      return
    }

    setConfirmAction({
      action: "delete",
      pageData: { id: page.id, created_by: page.created_by },
    })
    setShowConfirmModal(true)
  }

  const handleModalConfirmation = () => {
    if (confirmAction?.action === "delete") {
      deletePage(confirmAction?.pageData)
    }
    setShowConfirmModal(false)
  }

  if (!displayPage) {
    return (
      <div className="list pages-list ">
        <h1>This page cannot be reached.</h1>
      </div>
    )
  }

  return (
    <>
      <div className="list pages-list ">
        <div className="flex flex-row action-bar">
          <div className="flex flex-row items-center justify-center width-1/2">
            <h2>{funnelData.title}</h2>
          </div>

          <button
            className="create-btn create-pages-btn"
            onClick={() => {
              navigate(`/page-builder/${funnelId}`)
            }}
            title="Create Page"
          >
            Create New Page
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Node</th>
              <th>Created By</th>
              <th>Created At</th>
              <th>Updated By</th>
              <th>Updated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pages?.length > 0 ? (
              pages.map(page => (
                <tr key={page.id}>
                  <td>{page.id}</td>
                  <td>{toTitleCaseWithSpacing(page.node)}</td>
                  <td>{page.created_by || "-"}</td>
                  <td>{formatDate(page.created_at) || "-"}</td>
                  <td>{page.updated_by || "-"}</td>
                  <td>{formatDate(page.updated_at) || "-"}</td>
                  <td>
                    <button onClick={() => editPage()} title="Edit">
                      <i className="fa fa-pen"></i>
                    </button>
                    <button
                      onClick={() => confirmDeletePage(page)}
                      title="Delete"
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                    <button
                      onClick={() => console.log("DEPLOYED!")}
                      title="Deploy"
                    >
                      <i className="fa fa-cloud-upload"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No results
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ConfirmModal
        show={showConfirmModal}
        onConfirmClick={handleModalConfirmation}
        onCloseClick={() => setShowConfirmModal(false)}
        message={
          confirmAction?.action === "delete"
            ? "Are you sure you want to delete this page?"
            : "Are you sure you want to duplicate this page?"
        }
      />
    </>
  )
}

export default ListPages
