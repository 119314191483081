import React, { useEffect, useState } from "react"
import {
  BsCloudUpload,
  BsFillPencilFill,
  BsFillTrashFill,
  BsXCircle,
} from "react-icons/bs"
import { AiFillSave } from "react-icons/ai"
import swal from "sweetalert"
import { callDeleteApi, callPatchApi } from "utils/api"

const SubscriptionDiscount = ({
  item,
  onRefetchDiscounts,
  onDiscountUpdated,
  onDiscountRemoved,
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFreeGiftDiscount, setIsFreeGiftDiscount] = useState(false)

  const [inputDiscount, setInputDiscount] = useState(item)

  const handleChangeDiscount = (event) => {
    setInputDiscount({
      ...inputDiscount,
      [event.target.name]:
        event.target.type === "number"
          ? Number(event.target.value)
          : event.target.value,
    })
  }

  const onRequestFail = (error) => {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 404:
          throw new Error(
            "Sorry! the page you are looking for could not be found"
          )
        case 500:
          throw new Error(
            "Sorry! something went wrong, please contact our support team"
          )
        case 401:
          throw new Error("Invalid credentials")
  
        default:
          throw new Error(
            `Status: ${error.response.status} Error: ${error.message}`
          )
      }
    }
    setIsLoading(false)
    throw error
  }

  const onUpdateSubscriptionDiscountFail = (error) => {
    swal({
      title: "Oops!", 
      text: "Something went wrong updating product!", 
      icon: "error", 
    })
    onRequestFail(error)
  }

  const onUpdateSubscriptionDiscountSuccess = () => {
    swal({
      title:"Updated!", 
      text:"The product has been updated!", 
      icon:"success"
    })
    
    setIsUpdated(false)
    if (typeof onRefetchDiscounts === "function") {
      onRefetchDiscounts()
    }
    if (typeof onDiscountUpdated === "function") {
      onDiscountUpdated(item, inputDiscount)
    }
    setIsLoading(false)
  }

  const updateSubscriptionDiscount = async (subscriptionId, subscriptionDiscountId, subscriptionDiscount) => {
    callPatchApi(
      true, 
      `/subscription_types/${subscriptionId}/discount/${subscriptionDiscountId}`,
      subscriptionDiscount,
      onUpdateSubscriptionDiscountSuccess, 
      onUpdateSubscriptionDiscountFail, 
      "auth"
    )
  }

  const handleUpdateDiscount = async () => {
    setIsLoading(true)
    const confirm = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to update this product?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    })

    if (!confirm) {
      setIsLoading(false)
      return
    }

    const payload = {
      name: inputDiscount.name,
      target: inputDiscount.target,
      target_selection: inputDiscount.target_selection,
      target_lines: inputDiscount.target_lines,
      discount_type: inputDiscount.discount_type,
      discount_value: inputDiscount.discount_value,
    }
    
    updateSubscriptionDiscount(
      inputDiscount.subscription_type_id,
      inputDiscount.id,
      payload
    )
  }

  const onDeleteSubscriptionDiscountFail = (error) => {
    swal({
      title: "Oops!", 
      text: "Something went wrong removing product!", 
      icon: "error",
    })
    onRequestFail(error)
  }
  
  const onDeleteSubscriptionDiscountSuccess = () => {
    if (typeof onRefetchDiscounts === "function") {
      onRefetchDiscounts()
    }
    if (typeof onDiscountRemoved === "function") {
      onDiscountRemoved(inputDiscount)
    }
    swal({
      title: "Deleted!", 
      text: "The product has been deleted!", 
      icon: "success",
    })

    setIsLoading(false)
  }

  const deleteSubscriptionDiscount = async (subscriptionTypeId, subscriptionDiscountId) => {
    callDeleteApi(
      true, 
      `/subscription_types/${subscriptionTypeId}/discount/${subscriptionDiscountId}`,
      onDeleteSubscriptionDiscountSuccess, 
      onDeleteSubscriptionDiscountFail, 
      "auth"
    )
  }

const handleDeleteDiscount = async () => {
    setIsLoading(true)
    const confirm = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to remove this product?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    })

    if (!confirm) {
      setIsLoading(false)
      return
    }

    deleteSubscriptionDiscount(inputDiscount.subscription_type_id, inputDiscount.id)
  }

  const handleSaveChanges = () => {
    setIsEditable(false)
    setIsUpdated(
      Object.keys(inputDiscount).reduce(
        (hasChangedDiscount, keyOfInputDiscount) =>
          hasChangedDiscount
            ? hasChangedDiscount
            : inputDiscount[keyOfInputDiscount] !== item[keyOfInputDiscount],
        false
      )
    )
  }

  const handleRestoreChanges = () => {
    setIsEditable(false)
    setInputDiscount(item)
    setIsUpdated(false)
  }

  const handleShowEdition = () => {
    setIsEditable(true)
  }

  useEffect(() => {
    setInputDiscount(item)
    setIsFreeGiftDiscount(item.name === "free_gift")
  }, [item])

  return (
    <tr>
      <td>
        <div className="btn-group">
          <button
            title="remove"
            disabled={isLoading || !isFreeGiftDiscount}
            onClick={event => (!isLoading ? handleDeleteDiscount(event) : null)}
            className="btn btn-danger"
          >
            <BsFillTrashFill />
          </button>
          {isEditable ? (
            <>
              <button
                title="save"
                disabled={isLoading}
                onClick={event =>
                  !isLoading ? handleSaveChanges(event) : null
                }
                className="btn btn-primary"
              >
                <AiFillSave />
              </button>
              <button
                title="restore"
                disabled={isLoading}
                onClick={event =>
                  !isLoading ? handleRestoreChanges(event) : null
                }
                className="btn btn-secondary"
              >
                <BsXCircle />
              </button>
            </>
          ) : (
            <button
              title="edit"
              disabled={isLoading || !isFreeGiftDiscount}
              onClick={event => (!isLoading ? handleShowEdition(event) : null)}
              className="btn btn-info"
            >
              <BsFillPencilFill />
            </button>
          )}
          {isUpdated ? (
            <button
              title="update"
              disabled={isLoading}
              onClick={event => (!isLoading ? handleUpdateDiscount(event) : null)}
              className="btn btn-success"
            >
              <BsCloudUpload />
            </button>
          ) : null}
        </div>
      </td>
      <td>
        <span className="text-muted mb-0">{inputDiscount.id}</span>
      </td>
      <td>
        {isEditable ? (
          <form
            onSubmit={event => {
              event.preventDefault()
              handleSaveChanges(event)
            }}
          >
            <input
              disabled={true}
              value={inputDiscount.name}
              name="name"
              type="text"
              onChange={handleChangeDiscount}
              className="form-control"
            />
          </form>
        ) : (
          <span className="text-muted mb-0">{inputDiscount.name}</span>
        )}
      </td>
      <td>
        {isEditable ? (
          <form
            onSubmit={event => {
              event.preventDefault()
              handleSaveChanges(event)
            }}
          >
            <input
              disabled={true}
              value={inputDiscount.target}
              name="target"
              type="text"
              onChange={handleChangeDiscount}
              className="form-control"
            />
          </form>
        ) : (
          <span className="text-muted mb-0">{inputDiscount.target}</span>
        )}
      </td>
      <td>
        {isEditable ? (
          <form
            onSubmit={event => {
              event.preventDefault()
              handleSaveChanges(event)
            }}
          >
            <input
              disabled={true}
              value={inputDiscount.target_selection}
              name="target_selection"
              type="text"
              onChange={handleChangeDiscount}
              className="form-control"
            />
          </form>
        ) : (
          <span className="text-muted mb-0">{inputDiscount.target_selection}</span>
        )}
      </td>
      <td>
        {isEditable ? (
          <form
            onSubmit={event => {
              event.preventDefault()
              handleSaveChanges(event)
            }}
          >
            <input
              value={inputDiscount.target_lines}
              name="target_lines"
              type="text"
              onChange={handleChangeDiscount}
              className="form-control"
            />
          </form>
        ) : (
          <span className="text-muted mb-0">{inputDiscount.target_lines}</span>
        )}
      </td>
      <td>
        {isEditable ? (
          <form
            onSubmit={event => {
              event.preventDefault()
              handleSaveChanges(event)
            }}
          >
            <input
              disabled={true}
              value={inputDiscount.discount_type}
              name="discount_type"
              type="text"
              onChange={handleChangeDiscount}
              className="form-control"
            />
          </form>
        ) : (
          <span className="text-muted mb-0">{inputDiscount.discount_type}</span>
        )}
      </td>
      <td>
        {isEditable ? (
          <form
            onSubmit={event => {
              event.preventDefault()
              handleSaveChanges(event)
            }}
          >
            <input
              value={inputDiscount.discount_value}
              name="discount_value"
              type="number"
              onChange={handleChangeDiscount}
              className="form-control"
            />
          </form>
        ) : (
          <span className="text-muted mb-0">{inputDiscount.discount_value}</span>
        )}
      </td>
    </tr>
  )
}

export default SubscriptionDiscount
