import React, { useEffect, useState, useRef } from "react"
import { Container, Label, Button, Spinner } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { callGetApi } from "../../../utils/api"
import OrderComponents from "./components"
import Select from "react-select"
import usePageBuilder from "../../../hooks/usePageBuilder"
import { PageBuilderDefaultContainer } from "components/PageBuilder"
import "grapesjs/dist/css/grapes.min.css"
import "./offer-dnd.scss"
import { useParams } from "react-router-dom"

import { initialShopEnv, allShopsEnv } from "../../../utils/shop"

const OfferDnd = () => {
  document.title = "Offer Dnd"
  const fileInputRefJson = useRef(null)
  const fileInputRefHtmlCss = useRef(null)
  const [shop, setShop] = useState(initialShopEnv[process.env.REACT_APP_ENV])
  const [selectedFunnel, setselectedFunnel] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOfferLoading, setIsOfferLoading] = useState(false)
  const [allFunnels, setAllFunnels] = useState([])
  // const [funnelDetails, setFunnelDetails] = useState(null)
  const [offersOptions, setOffersOptions] = useState([])
  const [selectedOffer, setSelectedOffer] = useState(null)
  const { pageType } = useParams()
  const { editorInstance, storeData } = usePageBuilder(shop, pageType)

  function handleSelectGroup(selectedFunnel) {
    loadFunnelDetails(selectedFunnel.value, selectedFunnel.shop)
    setselectedFunnel(selectedFunnel)
  }

  const handleSaveJsonData = () => {
    const fileName = window.prompt("Enter a file name:", "test")
    const jsonContent = JSON.stringify(storeData, null, 2)
    const blob = new Blob([jsonContent], { type: "application/json" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url

    a.download = `${fileName}.grapesjs`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const handleSaveHtmlCssData = () => {
    const htmlFileName = window.prompt("Enter a file name:", "index")
    const htmlContent = localStorage.getItem("gjs-html")
    const blobHtml = new Blob([htmlContent], { type: "text/html" })
    const aHtml = document.createElement("a")
    const urlHtml = URL.createObjectURL(blobHtml)
    aHtml.href = urlHtml
    aHtml.download = `${htmlFileName}.html`
    document.body.appendChild(aHtml)
    aHtml.click()
    document.body.removeChild(aHtml)
    URL.revokeObjectURL(urlHtml)

    const cssFileName = window.prompt("Enter a file name:", "style")
    const cssContent = localStorage.getItem("gjs-css")
    const blobCss = new Blob([cssContent], { type: "text/css" })
    const aCss = document.createElement("a")
    const urlCss = URL.createObjectURL(blobCss)
    aCss.href = urlCss
    aCss.download = `${cssFileName}.css`
    document.body.appendChild(aCss)
    aCss.click()
    document.body.removeChild(aCss)
    URL.revokeObjectURL(urlCss)
  }

  const handleJsonFileUpload = event => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = e => {
        const jsonData = JSON.parse(e.target.result)

        if (editorInstance && jsonData) {
          editorInstance.loadProjectData(jsonData)
        }
      }
      reader.readAsText(file)
    }
  }

  const handleFileUploadHtmlCss = event => {
    const files = event.target.files
    if (files.length > 0) {
      const htmlFile = Array.from(files).find(file =>
        file.name.endsWith(".html")
      )
      const cssFile = Array.from(files).find(file => file.name.endsWith(".css"))
      if (htmlFile && cssFile) {
        const htmlReader = new FileReader()
        htmlReader.onload = htmlEvent => {
          const htmlContent = htmlEvent.target.result
          const cssReader = new FileReader()
          cssReader.onload = cssEvent => {
            const cssContent = cssEvent.target.result
            if (editorInstance) {
              editorInstance.setComponents(htmlContent)
              editorInstance.setStyle(cssContent)
            }
          }
          cssReader.readAsText(cssFile)
        }
        htmlReader.readAsText(htmlFile)
      } else {
        alert("Please select both an HTML (.html) and a CSS (.css) file.")
      }
    }
  }

  const loadFunnelDetails = (id, shop_) => {
    setIsOfferLoading(true)
    callGetApi(
      true,
      `/upselling/funnels/${id}?shop=${shop_}`,
      onGetFunnelDetailsSuccess,
      onGetFunnelDetailsFail
    )
  }

  const onGetFunnelDetailsSuccess = data => {
    setIsOfferLoading(false)
    if (data?.offers?.length > 0) {
      setOffersOptions(
        data?.offers?.map(val => {
          return {
            ...val,
            value: val.id,
            label: val.name,
          }
        })
      )
    } else {
      setOffersOptions([])
    }
    // setFunnelDetails(data)
  }

  const onGetFunnelDetailsFail = () => {
    setIsOfferLoading(false)
  }

  const onGetFunnelsSuccess = data => {
    setIsLoading(false)
    setAllFunnels(
      data?.map(val => {
        return {
          label: val.name,
          value: val.id,
          shop: val.shop,
        }
      })
    )
  }

  const onGetFunnelsFail = () => {
    setIsLoading(false)
  }

  const loadFunnels = () => {
    setIsLoading(true)
    callGetApi(
      true,
      `/upselling/funnels?shop=${shop}`,
      onGetFunnelsSuccess,
      onGetFunnelsFail
    )
  }

  const handleEditorHydration = product => {
    setSelectedOffer(product)

    const jsonData = JSON.parse(product?.componentsConfig)
    if (editorInstance && jsonData) {
      editorInstance.loadProjectData(jsonData)
    }
  }

  useEffect(() => {
    setselectedFunnel(null)
    setOffersOptions([])
    // setFunnelDetails(null)
    loadFunnels()
  }, [shop])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Offer"} breadcrumbItem={"Drag and Drop"} />
          <OrderComponents />
          <div className="mb-3">
            <Label className="form-label">Shop</Label>
            <select
              className="form-control select-store"
              value={shop}
              onChange={e => {
                setShop(e.target.value)
              }}
            >
              {allShopsEnv[process.env.REACT_APP_ENV].map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {isLoading ? (
            <div
              className="spinner-loader"
              style={{
                margin: "33px 0px",
              }}
            >
              <Spinner className="ms-2" color="primary" />
            </div>
          ) : (
            <div className="mb-3">
              <Label className="form-label">Funnels</Label>
              <Select
                value={selectedFunnel}
                onChange={handleSelectGroup}
                options={allFunnels}
                className="select2-selection"
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#007BFF" : "#2a3042",
                    color: "#FFFFFF",
                  }),
                }}
              />
            </div>
          )}
          {isOfferLoading ? (
            <div
              className="spinner-loader"
              style={{
                margin: "33px 0px",
              }}
            >
              <Spinner className="ms-2" color="primary" />
            </div>
          ) : (
            offersOptions?.length > 0 && (
              <div className="mb-3">
                <Label className="form-label">Offers</Label>
                <div>
                  {offersOptions?.map((product, index) => {
                    return (
                      <div
                        className="selected-product-item"
                        key={index}
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img src={product.image} />
                          <Label className="form-label">{product.label}</Label>
                        </div>
                        <Button
                          style={{
                            background:
                              selectedOffer?.id == product.id
                                ? "#2a3042"
                                : "lightgray",
                            color:
                              selectedOffer?.id == product.id
                                ? "white"
                                : "black",
                            borderColor:
                              selectedOffer?.id == product.id
                                ? "#2a3042"
                                : "lightgray",
                          }}
                          onClick={() => handleEditorHydration(product)}
                        >
                          Select
                        </Button>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          )}
          <div
            className="store-actions"
            style={{
              display: "none",
            }}
          >
            <input
              ref={fileInputRefJson}
              type="file"
              accept=".grapesjs"
              onChange={handleJsonFileUpload}
              style={{ display: "none" }}
            />
            <Button
              onClick={() => {
                fileInputRefJson.current.click()
              }}
            >
              Upload JSON
            </Button>
            <input
              ref={fileInputRefHtmlCss}
              type="file"
              accept=".html, .css"
              onChange={handleFileUploadHtmlCss}
              style={{ display: "none" }}
              multiple
            />
            <Button
              onClick={() => {
                fileInputRefHtmlCss.current.click()
              }}
            >
              Upload Html/CSS
            </Button>

            <Button onClick={handleSaveHtmlCssData}>Save in HTML/CSS</Button>
            <Button onClick={handleSaveJsonData}>Save in JSON</Button>
          </div>
          <PageBuilderDefaultContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OfferDnd
