const CustomerReviews = ({blockManager}) => {
    blockManager.add("customer-reviews", {
        id: "customer-reviews",
        label: "Customers Reviews",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="width: 767px; max-width: 100%; margin: 0 auto;font-family: 'Arial'">
        <div style="display: flex; text-align: center; justify-content: center; flex-direction: column; align-items: center;">
            <div>
                <h4 style="font-size: 25px; font-weight: 700; color: black; margin-bottom: 13px;">What Happy Customers Are Saying</h4>
            </div>
            <div style="display: flex; gap: 10px; justify-content: center; align-items: center;">
                <div>
                    <p style="font-size: 32px; font-weight: 700; color: black; margin: 0;">4.5</p>
                </div>
                <div style="display: flex;">
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                </div>
            </div>
            <div>
                <p style="font-weight: 800; color: black; font-size: 16px;"><span>4041 reviews</span></p>
            </div>
        </div>
        <div style="display: flex; align-items: flex-start; justify-content: space-between; border-top: 1px solid #eee; padding-top: 20px;">
            <div style="width: calc(100% - 153px);">
                <span style="font-size: 17px; font-weight: 700; margin-bottom: 15px; display: inline-block; color: black;">Virginia R.</span>
                <div style="display: flex;">
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                    <div><img style="width: 22px;" src="https://checkout.shapees.com/_next/image?url=https%3A%2F%2Fcdn.jsdelivr.net%2Fgh%2Ftrafilea%2Fstorefront-static%40main%2Fimages%2Ficons%2Fstar.svg&w=32&q=75"></div>
                </div>
                <span style="font-size: 17px; font-weight: 700; margin-top: 15px; margin-bottom: 8px; display: inline-block; color: black;">Feeling sexy after pregnancy!</span>
                <div style="color: #292929b5; font-size: 14px; line-height: 24px; font-weight: 400; padding-right: 80px;">
                    I love this cami so much. I am just 2 months postpartum and had a c-section. This helped hold everything in place so that I feel confident again. I even told my husband that I wished O had this as I was recovering from my c-section to give me comfortable support.
                </div>
            </div>
            <div style="width: 153px; display: flex; flex-direction: column; align-items: end;">
                <span style="margin-bottom: 15px;color: #2929298f; font-size: 14px;">01/29/2021</span>
                <div><img style="width: 150px;" src="https://cdn.shopify.com/s/files/1/0021/4889/2732/files/52233_2420010975292_455f38dd_ba4f_467b_be62_6a7b150c0182.jpg?v=1635436259"></div>
            </div>
        </div>
    </div>
    `,
      })
}

export default CustomerReviews