import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Label, Input } from "reactstrap"

const OfferFallback = ({ validationType }) => {
  return (
    <div>
      <div>
        <CardTitle>OFFER FALLBACK SETTINGS</CardTitle>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Offer Fallback</CardTitle>
                <div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Product UUID to fallback to
                    </Label>
                    <Input
                      name="productFallbackUUID"
                      type="text"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.productFallbackUUID}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Minimun Stock Quantity to Fallback
                    </Label>
                    <Input
                      name="minStock"
                      type="number"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.minStock}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default OfferFallback
