import React from 'react'
import update from 'immutability-helper'
import { useCallback } from 'react'
import AddBtn from './AddBtn'
import Card from './Card'
import {
  useQuizEditor,
  SET_ANSWERS,
  ADD_ANSWER,
  UPDATE_ANSWER,
  DELETE_ANSWER,
  UPDATE_QUESTION,
  SET_SIDECAR_QUESTION,
} from '../state'
import useDargDrop from '../useDargDrop'
import { QUESTION_TYPES } from '../constants'

export const DargAndDropItem = ({ id, title, index, moveCard, update, remove }) => {
  const {
    opacity,
    handlerId,
    ref,
  } = useDargDrop(id, index, moveCard)

  return (
    <Card
      title={title}
      update={update}
      remove={remove}
      ref={ref}
      opacity={opacity}
      handlerId={handlerId}
    />
  )
}

const DragDropContainer = ({answers, onUpdateAnswer, onReorderAnswer, onRemoveAnswer}) => {
    const moveCard = useCallback((dragIndex, hoverIndex) => {
      const orderedCards = update(answers, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, answers[dragIndex]],
        ],
      })
      onReorderAnswer(orderedCards)
    }, [answers])

    return (
        <div>{answers.map((card, index) => (
            <DargAndDropItem
              key={card.id}
              index={index}
              id={card.id}
              title={card.title}
              moveCard={moveCard}
              update={(e) => onUpdateAnswer(card.id, e.target.value)}
              remove={answers.length > 2  ? () => onRemoveAnswer(card.id) : null}
            />
        ))}</div>
    )
  }
  
  const AnswerList = () => {
    const {dispatch, state: {
      questions,
      selected
    }} = useQuizEditor()
    const {answers, title, type, sidecar} = questions.find(q => q.id === selected) ||
    {
      answers: [],
      title: '',
      type: QUESTION_TYPES.MULTIPLE_CHOICE,
      sidecar: false
    }

    const handleAddAnswer = useCallback(() => {
      dispatch({
        type: ADD_ANSWER,
        questionId: selected,
        title: 'New Answer'
      })
    }, [selected])

    const handleReorderAnswer = useCallback((answers) => {
      dispatch({
          type: SET_ANSWERS,
          questionId: selected,
          answers,
      })
    }, [selected])

    const handleUpdateAnswer = useCallback((id, title) => {
      dispatch({
          type: UPDATE_ANSWER,
          questionId: selected,
          answerId: id,
          title,
      })
    }, [selected])

    const handleRemoveAnswer = useCallback((id) => {
      dispatch({
          type: DELETE_ANSWER,
          questionId: selected,
          answerId: id,
      })
    }, [selected])

    const handleQuestionType = useCallback((e) => {
      dispatch({
          type: UPDATE_QUESTION,
          question: {
            id: selected,
            type: e.target.value
          }
      })
    }, [selected])
  
    const handleSidecar = useCallback((e) => {
      dispatch({
          type: SET_SIDECAR_QUESTION,
          question: {
            id: selected,
            value: e.target.checked
          }
      })
    }, [selected])

    return (
      <div style={{flex: 2, padding: 10}}>
        <h3>{title}</h3>
        {
          !answers.length ? (
            <h4>No answers found</h4>
          ) : (
            <>
              <label for="question-type-input">Type:</label>
              <select id="question-type-input" className='question-type-select' value={type} onChange={handleQuestionType}>
                <option selected value={QUESTION_TYPES.MULTIPLE_CHOICE}>Multiple</option>
                <option value={QUESTION_TYPES.TEXT}>Text</option>
              </select>
              {
                type === QUESTION_TYPES.MULTIPLE_CHOICE ? (
                  <>
                    <div style={{marginTop: '1rem', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '0.5rem'}}>
                      <label for="sidecar">Use sidecar</label>
                      <input id="sidecar" type='checkbox' checked={sidecar} onChange={handleSidecar} />
                    </div>
                    <DragDropContainer answers={answers} onReorderAnswer={handleReorderAnswer} onUpdateAnswer={handleUpdateAnswer} onRemoveAnswer={handleRemoveAnswer} />
                  </>
                ): (
                  <Card
                    title={answers[0].title}
                    type={type}
                    update={(e) => handleUpdateAnswer(answers[0].id, e.target.value)}
                  />
                )
              }
            </>
          )
        }
        {type === QUESTION_TYPES.MULTIPLE_CHOICE ? (
          <AddBtn onClick={handleAddAnswer}>
            Add Answer
          </AddBtn>
        ): null}
      </div>
    )
  }

  export default AnswerList