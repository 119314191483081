import DeleteModal from "components/Common/DeleteModal"
import { showErrorToastr } from "components/Common/toastr"
import React, { useState, useEffect } from "react"
import { Row, Col, Input, Button, Label, Spinner } from "reactstrap"
import { mapPriceToPercentage, mapPercentageToPrice } from "utils/pricing"

const Discounts = ({
  validationType,
  updateDiscount,
  createDiscount,
  deleteDiscount,
  country,
  isDiscountLoading,
  isDeleteDiscountLoading,
}) => {
  const [discountPrices, setDiscountPrices] = useState([])
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const regex = /^[0-9.]*$/ // Regular expression to match numbers and "."

  const onDeleteVolumeDiscount = () => {
    setIsOpenDeleteModal(false)
    if (validationType.values.is_fallback_discount) {
      showErrorToastr(
        "Cannot delete fallback discount, try deleting US discount first"
      )
    } else {
      deleteDiscount(validationType.values.volume_discounts[0].id)
    }
  }

  const onSubmit = () => {
    if (!discountPrices.length) {
      showErrorToastr(
        "Please add at least one volume discount price to submit the form."
      )
    } else {
      let isInvalid = false
      //create payload

      const percentageObject = {}
      discountPrices.forEach(discount => {
        const newPrice = mapPriceToPercentage(
          parseFloat(validationType.values.price),
          parseFloat(discount.price),
          parseFloat(discount.quantity)
        )

        percentageObject[discount.quantity] = newPrice
        if (discount.price === "" || discount.quantity === "") {
          isInvalid = true
        }
      })

      const payload = {
        discount_type: "percentage",
        discount_definition_type: "volume",
        discount_description: "Discount created from the UI",
        discount_definition:
          Object.keys(percentageObject).length > 0 ? percentageObject : null,
      }
      if (isInvalid) {
        showErrorToastr(
          "Please add at least one valid volume discount price and quantity to submit the form."
        )
      } else if (
        validationType.values.volume_discounts.length > 0 &&
        !validationType.values.is_fallback_discount
      ) {
        updateDiscount(payload)
      } else {
        payload.country = country
        payload.product_id = validationType.values.product_id
        createDiscount(payload)
      }
    }
  }

  useEffect(() => {
    if (validationType.values.discounts) {
      const discountPrices = Object.keys(
        validationType.values.discounts[0].definition
      ).map(key => ({
        quantity: key,
        price: mapPercentageToPrice(
          validationType.values.price,
          validationType.values.discounts[0].definition[key],
          key
        ),
      }))
      setDiscountPrices(discountPrices)
    }
  }, [])

  return (
    <div>
      <DeleteModal
        show={isOpenDeleteModal}
        onDeleteClick={onDeleteVolumeDiscount}
        onCloseClick={() => setIsOpenDeleteModal(false)}
        message="Are you sure you want to delete this volume discount?"
      ></DeleteModal>
      <Row>
        <Col lg={12}>
          {validationType.values.bundle_discounts.length > 0 ? (
            <b style={{ margin: "10px" }}>
              This product has a <a style={{ color: "red" }}>bundle discount</a>{" "}
              associated with it, it is not possible to add a new discount.
            </b>
          ) : validationType.values.volume_discounts.length > 1 ? (
            <b style={{ margin: "10px" }}>
              This product has{" "}
              <a style={{ color: "red" }}>more than one volume discount</a>{" "}
              associated with it, it is not possible to add a new discount.
            </b>
          ) : (
            <div className="mb-3">
              <Row className="row-cols-lg-auto align-items-center">
                <Col lg={4} style={{ marginBottom: "20px" }}>
                  <Label className="form-label">Quantity</Label>
                  <Input name={`price-0`} value={1} disabled={true} />
                </Col>
                <Col lg={4} style={{ marginBottom: "20px" }}>
                  <Label className="form-label">Price</Label>
                  <Input
                    name={`price-0`}
                    value={validationType.values.price}
                    disabled={true}
                  />
                </Col>

                <Col lg={2} style={{ marginTop: "7px" }}>
                  <Label className="form-label"></Label>
                  <Button
                    onClick={() =>
                      setDiscountPrices([
                        ...discountPrices,
                        { quantity: "", price: "" },
                      ])
                    }
                    color="primary"
                  >
                    <i className="mdi mdi-plus-thick plus-thick"></i>
                  </Button>
                </Col>
              </Row>
              <Row className="row-cols-lg-auto  align-items-center">
                {discountPrices.map((discount, index) => (
                  <>
                    <Col lg={4} style={{ marginBottom: "10px" }}>
                      <Label className="form-label">Quantity</Label>
                      <Input
                        name={`price-${index}`}
                        value={discountPrices[index].quantity}
                        onChange={e => {
                          if (regex.test(e.target.value)) {
                            const updatedPrices = [...discountPrices]
                            updatedPrices[index].quantity = e.target.value
                            setDiscountPrices(updatedPrices)
                          }
                        }}
                      />
                    </Col>
                    <Col lg={4} style={{ marginBottom: "10px" }}>
                      <Label className="form-label">Price</Label>
                      <Input
                        name={`price-${index}`}
                        value={discountPrices[index].price}
                        onChange={e => {
                          if (regex.test(e.target.value)) {
                            const updatedPrices = [...discountPrices]
                            updatedPrices[index].price = e.target.value
                            setDiscountPrices(updatedPrices)
                          }
                        }}
                      />
                    </Col>
                    <Col lg={2} style={{ marginTop: "17px" }}>
                      <Label className="form-label"></Label>
                      <Button
                        onClick={() =>
                          setDiscountPrices(
                            discountPrices.filter((_, i) => i !== index)
                          )
                        }
                        disabled={
                          discountPrices.length === 1 &&
                          validationType.values.volume_discounts.length > 0
                        }
                        color="danger"
                      >
                        <i className="mdi mdi-close-thick close-thick"></i>
                      </Button>
                    </Col>
                  </>
                ))}
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="d-flex flex-wrap gap-2 form-btn">
                    {validationType.values.volume_discounts.length > 0 && (
                      <Button
                        color="danger"
                        style={{ marginTop: "20px", marginRight: "20px" }}
                        onClick={() => setIsOpenDeleteModal(true)}
                        disabled={
                          validationType.values.volume_discounts.length === 0
                        }
                      >
                        {isDeleteDiscountLoading ? (
                          <Spinner size={"sm"} className="mx-3 mb-0 mt-1" />
                        ) : (
                          "Delete"
                        )}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      style={{ marginTop: "20px" }}
                      onClick={onSubmit}
                      disabled={
                        validationType.values.bundle_discounts.length > 0
                      }
                    >
                      {isDiscountLoading ? (
                        <Spinner size={"sm"} className="mx-3 mb-0 mt-1" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>{" "}
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default Discounts
