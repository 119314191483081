import React, { useState, useEffect } from "react"
import {
  Input,
  Label,
  Form,
  Col,
  Row,
  Button,
  FormFeedback,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { taxType, additionalFeeTaxType } from "../../../utils/tax"
import TaxDeleteIcon from "../../../assets/images/taxes/tax-delete.svg"
import { showSuccessToastr, showErrorToastr } from "components/Common/toastr"
import { callPutApi, callDeleteApi } from "utils/api"
import DeleteModal from "components/Common/DeleteModal"
import { initialShopEnv, allShopsEnv } from "../../../utils/shop"
import "./index.scss"

const AddEditTaxModal = ({ setIsOpen, loadTaxes, editData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [allProductTypes, setAllProductTypes] = useState([])
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: "",
      state: "",
      zip_code: "",
      product_type: "all",
      store: initialShopEnv[process.env.REACT_APP_ENV],
      taxes: [
        {
          name: "",
          type: "gst",
          amount: 0,
          amount_type: "percentage",
          currency: "",
        },
      ],
      additional_fees: [
        {
          name: "",
          type: "rdf",
          amount: 0,
          amount_type: "percentage",
          currency: "",
        },
      ],
    },
    validationSchema: Yup.object().shape({
      country: Yup.string().required("Please Enter Country Code."),
      taxes: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Please enter name"),
        })
      ),
      additional_fees: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Please enter name"),
        })
      ),
    }),
    onSubmit: values => {
      if (values?.taxes?.length == 0) {
        showErrorToastr("Please select at least one tax before submitting.")
      } else {
        setIsLoading(true)
        callPutApi(true, "/taxes", values, onUpdateTaxSuccess, onUpdateTaxFail)
      }
    },
  })

  const onDeleteTaxSuccess = () => {
    showSuccessToastr("Tax Deleted Successfully!")
    setIsDeleteLoading(false)
    loadTaxes("")
    setIsOpen(false)
  }

  const onDeleteTaxFail = () => {
    setIsDeleteLoading(false)
  }

  const onDeleteTax = () => {
    setIsDeleteLoading(true)
    setShowDeleteModal(false)
    callDeleteApi(
      true,
      `/taxes?country=${validationType.values.country}&sk=${encodeURIComponent(
        editData.sk
      )}`,
      onDeleteTaxSuccess,
      onDeleteTaxFail
    )
  }

  const onUpdateTaxSuccess = () => {
    if (editData) {
      showSuccessToastr("Taxes Updated Successfully")
    } else {
      showSuccessToastr("Taxes Added Successfully")
    }
    loadTaxes("")
    setIsLoading(false)
    setIsOpen(false)
  }

  const onUpdateTaxFail = () => {
    setIsLoading(false)
  }

  const addNewTax = () => {
    const newTax = {
      name: "",
      type: "gst",
      amount: 0,
      amount_type: "percentage",
      currency: "",
    }
    validationType.setValues({
      ...validationType.values,
      taxes: [...validationType.values.taxes, newTax],
    })
  }
  const addNewAdditionalTax = () => {
    const newTax = {
      name: "",
      type: "rdf",
      amount: 0,
      amount_type: "percentage",
      currency: "",
    }
    validationType.setValues({
      ...validationType.values,
      additional_fees: [...validationType.values.additional_fees, newTax],
    })
  }

  const handleDeleteTax = index => {
    const updatedTaxes = [...validationType.values.taxes]
    updatedTaxes.splice(index, 1)
    validationType.setValues({
      ...validationType.values,
      taxes: updatedTaxes,
    })
  }
  const handleDeleteAdditionalTax = index => {
    const updatedTaxes = [...validationType.values.additional_fees]
    updatedTaxes.splice(index, 1)
    validationType.setValues({
      ...validationType.values,
      additional_fees: updatedTaxes,
    })
  }

  useEffect(() => {
    if (process.env.REACT_APP_PRODUCT_TYPES) {
      let envTypes = process.env.REACT_APP_PRODUCT_TYPES
      let allTypes = envTypes?.split(",")?.map(el => {
        return {
          value: el,
          label: el
            ?.replaceAll("_", " ")
            ?.split(" ")
            ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
            ?.join(" "),
        }
      })
      setAllProductTypes(allTypes)
    }
  }, [])

  useEffect(() => {
    if (editData) {
      validationType.setValues({
        country: editData?.country,
        state: editData?.state,
        zip_code: editData?.zip_code,
        product_type: editData?.product_type,
        store: editData?.store,
        taxes:
          editData?.taxes && editData?.taxes?.length > 0
            ? editData.taxes
            : [
                {
                  name: "",
                  type: "gst",
                  amount: 0,
                  amount_type: "percentage",
                  currency: "",
                },
              ],
        additional_fees:
          editData?.additional_fees && editData?.additional_fees?.length > 0
            ? editData.additional_fees
            : [],
      })
    }
  }, [editData])

  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validationType.handleSubmit()
          return false
        }}
      >
        <div>
          <div className="mt-2 country-form">
            <Row>
              <Col md={2}>
                <div className="mb-3">
                  <Label className="form-label mb-1">Country Code*</Label>
                  <Input
                    name="country"
                    type="text"
                    onChange={e => {
                      const inputValue = e.target.value
                      const onlyLetters = inputValue.replace(/[^A-Za-z]/g, "")
                      validationType.setFieldValue(
                        "country",
                        onlyLetters.toUpperCase()
                      )
                    }}
                    onBlur={validationType.handleBlur}
                    value={validationType.values.country}
                    maxLength={3}
                    invalid={
                      validationType.touched.country &&
                      validationType.errors.country
                        ? true
                        : false
                    }
                  />
                  {validationType.touched.country &&
                  validationType.errors.country ? (
                    <FormFeedback type="invalid">
                      {validationType.errors.country}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md={2}>
                <div className="mb-3">
                  <Label className="form-label mb-1">Product Type</Label>
                  <select
                    className="form-control"
                    name="product_type"
                    onChange={e => {
                      validationType.setFieldValue(
                        "product_type",
                        e.target.value
                      )
                    }}
                    onBlur={validationType.handleBlur}
                    value={validationType.values.product_type}
                  >
                    {allProductTypes?.map(el => {
                      return <option value={el.value}>{el?.label}</option>
                    })}
                  </select>
                </div>
              </Col>
              <Col md={2}>
                <div className="mb-3">
                  <Label className="form-label mb-1">Store</Label>
                  <select
                    className="form-control"
                    name="store"
                    onChange={e => {
                      validationType.setFieldValue("store", e.target.value)
                    }}
                    onBlur={validationType.handleBlur}
                    value={validationType.values.store}
                  >
                    {allShopsEnv[process.env.REACT_APP_ENV].map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={2}>
                <div className="mb-3">
                  <Label className="form-label mb-1">State Code</Label>
                  <Input
                    name="state"
                    type="text"
                    onChange={e => {
                      const inputValue = e.target.value
                      const onlyLetters = inputValue.replace(/[^A-Za-z]/g, "")
                      validationType.setFieldValue(
                        "state",
                        onlyLetters.toUpperCase()
                      )
                    }}
                    onBlur={validationType.handleBlur}
                    value={validationType.values.state}
                    maxLength={3}
                  />
                </div>
              </Col>
              <Col md={2}>
                <div className="mb-3">
                  <Label className="form-label mb-1">Zip Code</Label>
                  <Input
                    name="zip_code"
                    type="text"
                    onChange={e => {
                      validationType.setFieldValue(
                        "zip_code",
                        e.target.value.toLocaleUpperCase()
                      )
                    }}
                    onBlur={validationType.handleBlur}
                    value={validationType.values.zip_code}
                  />
                </div>
              </Col>
              {editData && (
                <Col md={2}>
                  <div className="delete-tax-btn-container">
                    <Button
                      color="danger"
                      type="button"
                      outline
                      onClick={() => setShowDeleteModal(true)}
                    >
                      {isDeleteLoading ? (
                        <Spinner size={"sm"} className="mx-3 mb-0 mt-1" />
                      ) : (
                        "Delete Tax"
                      )}
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          </div>
          <div className="country-form">
            <div className="tax-form-container">
              <div className="tax-title">Tax</div>
              <Button color="primary" outline onClick={addNewTax}>
                <i className="mdi mdi-plus me-2" />
                Add New Tax
              </Button>
            </div>
            <div className="taxes-list-container">
              {validationType.values.taxes?.map((tax, index) => {
                return (
                  <Row key={index}>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Name</Label>
                        <Input
                          name={`taxes[${index}].name`}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.name}
                          invalid={
                            validationType.errors.taxes &&
                            validationType.errors.taxes[index] &&
                            validationType.errors.taxes[index].name
                              ? true
                              : false
                          }
                        />
                        {validationType.errors.taxes &&
                        validationType.errors.taxes[index] &&
                        validationType.errors.taxes[index].name ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.taxes[index].name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Type</Label>
                        <select
                          name={`taxes[${index}].type`}
                          className="form-select"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.type}
                        >
                          {taxType.map(el => {
                            return (
                              <option value={el.value} key={el.value}>
                                {el.label}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Amount</Label>
                        <Input
                          name={`taxes[${index}].amount`}
                          type="number"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.amount}
                          min={0}
                          step="0.00001"
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Amount Type</Label>
                        <select
                          name={`taxes[${index}].amount_type`}
                          className="form-select"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.amount_type}
                        >
                          <option value={"percentage"}>Percentage</option>
                          <option value={"fixed"}>Fixed</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Currency</Label>
                        <Input
                          name={`taxes[${index}].currency`}
                          placeholder="USD"
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.currency}
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="multi-tax-delete">
                        <img
                          src={TaxDeleteIcon}
                          alt=""
                          height="22"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteTax(index)}
                        />
                      </div>
                    </Col>
                  </Row>
                )
              })}
            </div>
          </div>
          <div>
            <div className="tax-form-container">
              <div className="tax-title">Additional Fee</div>
              <Button color="primary" outline onClick={addNewAdditionalTax}>
                <i className="mdi mdi-plus me-2" />
                Add New Additional Fees
              </Button>
            </div>
            <div className="taxes-list-container">
              {validationType.values.additional_fees?.map((tax, index) => {
                return (
                  <Row key={index}>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Name</Label>
                        <Input
                          name={`additional_fees[${index}].name`}
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.name}
                          invalid={
                            validationType.errors.additional_fees &&
                            validationType.errors.additional_fees[index] &&
                            validationType.errors.additional_fees[index].name
                              ? true
                              : false
                          }
                        />
                        {validationType.errors.additional_fees &&
                        validationType.errors.additional_fees[index] &&
                        validationType.errors.additional_fees[index].name ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.additional_fees[index].name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Type</Label>
                        <select
                          name={`additional_fees[${index}].type`}
                          className="form-select"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.type}
                        >
                          {additionalFeeTaxType.map(el => {
                            return (
                              <option value={el.value} key={el.value}>
                                {el.label}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Amount</Label>
                        <Input
                          name={`additional_fees[${index}].amount`}
                          type="number"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.amount}
                          min={0}
                          step="0.00001"
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Amount Type</Label>
                        <select
                          name={`additional_fees[${index}].amount_type`}
                          className="form-select"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.amount_type}
                        >
                          <option value={"percentage"}>Percentage</option>
                          <option value={"fixed"}>Fixed</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="mb-3">
                        <Label className="form-label mb-1">Currency</Label>
                        <Input
                          name={`additional_fees[${index}].currency`}
                          placeholder="USD"
                          type="text"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={tax.currency}
                        />
                      </div>
                    </Col>
                    <Col md={2}>
                      <div className="multi-tax-delete">
                        <img
                          src={TaxDeleteIcon}
                          alt=""
                          height="22"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteAdditionalTax(index)}
                        />
                      </div>
                    </Col>
                  </Row>
                )
              })}
            </div>
          </div>
        </div>
        <div className="modal-footer pb-0 px-0">
          <button type="submit" className="btn btn-primary">
            {isLoading ? (
              <Spinner size={"sm"} className="mx-3 mb-0 mt-1" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={onDeleteTax}
        onCloseClick={() => setShowDeleteModal(false)}
        message="Are you sure you want to delete this tax?"
      />
    </div>
  )
}

export default AddEditTaxModal
