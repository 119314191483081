import { colors as colorsMap } from "../../../../../../constants/colorsMap"

const ColorSelector = editor => {
  editor.DomComponents.addType("color-selector", {
    isComponent: el => el.classList?.contains("color-selector"),
    model: {
      defaults: {
        tagName: "div",
        style: { display: "inline-flex" },
        classes: ["color-selector"],
        data: { options: [], name: "" },
        styles: `
					.color-selector-container {
						display: inline-flex;
						width: 33px;
						height: 33px;
						border-radius: 50%;
						padding: 3px;
						margin: 0px 2px;
						justify-content: center;
						align-items: center;
					}

					.color-input {
						display: none;
					}

					.color-input ~ label {
						display: block;
						border: 1px solid  rgb(158, 158, 158);
						overflow: hidden;
						width: 25px;
						height: 25px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
					}

					.color-selector-container:has(.color-input:checked) {
						border: 1px solid black;
					}
				`,
      },

      createColorItem(color) {
        let data = colorsMap.find(
          c => c.validator.toLocaleLowerCase() === color.toLocaleLowerCase()
        )

        data = {
          color: data?.color || "",
          img: data?.pattern ? `<img src="${data.pattern.url}">` : "",
        }

        return `
				<span class="color-selector-container">
					<input class="color-input" name="color" type="radio" id="${color}" value="${color}">
					<label for="${color}" style="background: ${data.color};">
						${data.img}
					</label>
				</span>
				`
      },

      handleColorsChange(_, { options: colors, name }) {
        this.components(`
          <div style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
            <p style="margin: 0px 0px 5px 0px; font-size: 14px; line-height: 22px; padding: 0; color: black; text-align: center;  font-weight: 800;font-family: 'Arial'">
              ${name}
            </p>
            <div>${colors.map(this.createColorItem).join("")}</div>
          </div>
        `)
      },
      init() {
        this.on("change:data", this.handleColorsChange)
      },
    },
  })
}

export default ColorSelector
