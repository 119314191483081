import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react"
import PropTypes from "prop-types"

const FUNNEL_DATA_KEY = "FUNNEL_DATA_KEY"

const FunnelDataContext = createContext()

export const useFunnelData = () => useContext(FunnelDataContext)

const loadDataFromLocalStorage = () => {
  let data = localStorage.getItem(FUNNEL_DATA_KEY)
  if (!data)
    return {
      title: "",
      description: "",
      email: "",
      xml: "",
    }

  return JSON.parse(data)
}

export const FunnelDataProvider = ({ children }) => {
  const [funnelData, setFunnelData] = useState(loadDataFromLocalStorage)

  const updateFunnelData = (data, reset = true) => {
    const newData = reset ? data : { ...funnelData, ...data }
    localStorage.setItem(FUNNEL_DATA_KEY, JSON.stringify(newData))

    setFunnelData(newData)
  }

  useEffect(() => {
    const data = localStorage.getItem(FUNNEL_DATA_KEY)
    if (data) {
      setFunnelData(JSON.parse(data))
    }
  }, [])

  const value = useMemo(() => ({ funnelData, updateFunnelData }), [funnelData])

  return (
    <FunnelDataContext.Provider value={value}>
      {children}
    </FunnelDataContext.Provider>
  )
}

FunnelDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
