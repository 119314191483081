import { v4 as uuidv4 } from "uuid"
import { QUESTION_TYPES } from "./QuizEditor/constants"

export const QUESTIONS_KEY = "questions"

export const defaultQuestionObj = {
  id: uuidv4(),
  title: `Default Question`,
  type: QUESTION_TYPES.TEXT,
  answers: [
    {
      id: uuidv4(),
      title: "Your answer",
    },
  ],
}

const createInputAnswer = question => `
    <div class="form__group">
        <input
            type="text"
            class="form__field answer-item-input"
            placeholder="${question.answers[0].title}"
            name="${question.id}"
            id="${question.answers[0].id}"
        >
        <label for="${question.id}" class="form__label answer-item-label">${question.answers[0].title}</label>
    </div>
`

const createMultipleAnswer = question => `
    <ul>
        ${question.answers.reduce(
          (prev, answer, index) =>
            prev +
            `
            <li class="answer-item">
                <input class="answer-item-input" name="${question.id}" type="radio" id="${answer.id}" value="${index}">
                <label class="answer-item-label" for="${answer.id}">${answer.title}</label>
            </li>
        `,
          ""
        )}
    </ul>
`

export const createQuiz = (question, isLastQuestion) => {
  let btnHtml = ""

  if (isLastQuestion)
    btnHtml =
      '<button class="question-btn question-input-submit"> Submit </button>'
  else if (question.type === QUESTION_TYPES.TEXT)
    btnHtml = `
            <button class="question-btn question-input-accept">
                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                Ok
            </button>
        `

  const answerFields =
    question.type === QUESTION_TYPES.TEXT
      ? createInputAnswer(question)
      : createMultipleAnswer(question)

  return `
        <div class="answer-section">
            <fieldset>
                <span class="question-label" style="font-size: 20px;">${question.title}</span>
                ${answerFields}
                ${btnHtml}
            </fieldset>
        </div>
    `
}
