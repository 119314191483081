import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
  Input,
  InputGroup,
} from "reactstrap"

const DisplayCriteria = ({
  validationType,
  setIsOpen,
  displayCriteriaProducts,
  setdisplayCriteriaProducts,
}) => {
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-3">Display Criteria</CardTitle>
                <div className="display-criteria-container">
                  <div>
                    <Label className="form-label display-criteria-label">
                      Show these offers when
                      <select
                        name="showCriteriaWhen"
                        className="form-select"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.showCriteriaWhen}
                      >
                        <option value={"any"}>Any</option>
                        <option value={"all"}>All</option>
                      </select>
                      of the following individual criteria are met.
                    </Label>
                  </div>
                  <div className="payment-divider" />
                  <div className="display-criteria-checkbox">
                    <div className="display-criteria-box">
                      <input
                        className="form-check-input display-checkbox"
                        type="checkbox"
                        id="displayCriteriaProduct.checked"
                        onChange={validationType.handleChange}
                        checked={
                          validationType.values.displayCriteriaProduct.checked
                        }
                      />
                      <Label className="form-label display-criteria-label m-0">
                        Show these offers when
                        <select
                          name="displayCriteriaProduct.comparator"
                          className="form-select"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          disabled={
                            !validationType.values.displayCriteriaProduct
                              .checked
                          }
                          value={
                            validationType.values.displayCriteriaProduct
                              .comparator
                          }
                        >
                          <option value={"any"}>Any</option>
                          <option value={"all"}>All</option>
                        </select>
                        of the following individual criteria are met.
                      </Label>
                    </div>
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => setIsOpen(true)}
                      disabled={
                        !validationType.values.displayCriteriaProduct.checked
                      }
                    >
                      Add Products
                    </Button>
                  </div>
                  {displayCriteriaProducts?.length > 0 && (
                    <div className="mt-3">
                      {displayCriteriaProducts?.map((product, index) => {
                        return (
                          <div
                            className="selected-product-item"
                            style={{ justifyContent: "space-between" }}
                            key={index}
                          >
                            <div>
                              <img src={product.images?.[0]?.src} />
                              <Label className="form-label">
                                {product.title}
                              </Label>
                            </div>
                            <i
                              className="mdi mdi-delete"
                              style={{ fontSize: 23, cursor: "pointer" }}
                              onClick={() => {
                                setdisplayCriteriaProducts(
                                  displayCriteriaProducts => {
                                    const updatedProducts =
                                      displayCriteriaProducts
                                        ? displayCriteriaProducts.filter(
                                            el => el.id !== product.id
                                          )
                                        : []
                                    return updatedProducts
                                  }
                                )
                              }}
                            ></i>
                          </div>
                        )
                      })}
                    </div>
                  )}
                  <div
                    className={`payment-divider ${
                      displayCriteriaProducts?.length > 0 ? "mt-0" : ""
                    }`}
                  />
                  <div className="display-criteria-checkbox">
                    <div className="display-criteria-box">
                      <input
                        className="form-check-input display-checkbox"
                        type="checkbox"
                        id="displayCriteriaOrder.checked"
                        onChange={validationType.handleChange}
                        checked={
                          validationType.values.displayCriteriaOrder.checked
                        }
                      />
                      <Label className="form-label display-criteria-label m-0">
                        When order value is...
                      </Label>
                    </div>
                    <div className="display-criteria-right-section">
                      <select
                        name="displayCriteriaOrder.comparator"
                        className="form-select"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        disabled={
                          !validationType.values.displayCriteriaOrder.checked
                        }
                        value={
                          validationType.values.displayCriteriaOrder.comparator
                        }
                      >
                        <option value="lt">Less than</option>
                        <option value="le">Less than or equal to</option>
                        <option value="gt">Greater than</option>
                        <option value="ge">Greater than or equal to</option>
                        <option value="eq">Equal to</option>
                        <option value="ne">Not equal to</option>
                      </select>
                      <InputGroup className="input-grp">
                        <div className="input-group-text">$</div>
                        <Input
                          name="displayCriteriaOrder.value"
                          disabled={
                            !validationType.values.displayCriteriaOrder.checked
                          }
                          type="number"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={
                            validationType.values.displayCriteriaOrder.value ||
                            ""
                          }
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div className="payment-divider" />
                  <div className="display-criteria-checkbox">
                    <div className="display-criteria-box">
                      <input
                        className="form-check-input display-checkbox"
                        type="checkbox"
                        id="displayCriteriaProductCount.checked"
                        onChange={validationType.handleChange}
                        checked={
                          validationType.values.displayCriteriaProductCount
                            .checked
                        }
                      />
                      <Label className="form-label display-criteria-label m-0">
                        When product count is...
                      </Label>
                    </div>
                    <div className="display-criteria-right-section">
                      <select
                        name="displayCriteriaProductCount.comparator"
                        className="form-select"
                        disabled={
                          !validationType.values.displayCriteriaProductCount
                            .checked
                        }
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={
                          validationType.values.displayCriteriaProductCount
                            .comparator
                        }
                      >
                        <option value="lt">Less than</option>
                        <option value="le">Less than or equal to</option>
                        <option value="gt">Greater than</option>
                        <option value="ge">Greater than or equal to</option>
                        <option value="eq">Equal to</option>
                        <option value="ne">Not equal to</option>
                      </select>
                      <Input
                        name="displayCriteriaProductCount.value"
                        type="number"
                        disabled={
                          !validationType.values.displayCriteriaProductCount
                            .checked
                        }
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={
                          validationType.values.displayCriteriaProductCount
                            .value || ""
                        }
                      />
                    </div>
                  </div>
                  <div className="payment-divider" />
                  <div className="display-criteria-checkbox">
                    <div className="display-criteria-box">
                      <input
                        className="form-check-input display-checkbox"
                        type="checkbox"
                        id="displayCriteriaTag.checked"
                        onChange={validationType.handleChange}
                        checked={
                          validationType.values.displayCriteriaTag.checked
                        }
                      />
                      <Label className="form-label display-criteria-label m-0">
                        When the tags includes...
                      </Label>
                    </div>
                    <div className="display-criteria-right-section">
                      <select
                        name="displayCriteriaTag.comparator"
                        className="form-select"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        disabled={
                          !validationType.values.displayCriteriaTag.checked
                        }
                        value={
                          validationType.values.displayCriteriaTag.comparator
                        }
                      >
                        <option value="any">Contains any</option>
                        <option value="all">Contains all</option>
                      </select>
                      <Input
                        name="displayCriteriaTag.value"
                        type="text"
                        placeholder="tag1;tag2;"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        disabled={
                          !validationType.values.displayCriteriaTag.checked
                        }
                        value={
                          validationType.values.displayCriteriaTag.value || ""
                        }
                      />
                    </div>
                  </div>
                  <div className="payment-divider" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default DisplayCriteria
