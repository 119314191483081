const OrderBar = ({blockManager, bgColor, color}) => {
    blockManager.add("order-bar", {
        id: "order-bar",
        label: "Order Bar",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="justify-content: center; background-color: ${bgColor}; padding: 0.95rem 0; display: flex; align-items: center;">
          <svg fill="#2F806A" height="1.275rem" width="1.25rem" class="css-s8uz" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title>actions/check</title>
            <path fill="#2F806A" d="M212 352L86 226l-42 42 168 168 298-298-42-42z"></path>
          </svg>
          <h1 style="margin: 0; font-size: 1rem; line-height: 1.5rem; font-weight: 600; color: ${color}; margin-left: 0.625rem; font-family: 'Arial'">
            We’re processing your order...
          </h1>
        </div>`,
      })
}

export default OrderBar