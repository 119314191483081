import React from 'react'

const AddBtn = ({children, ...props}) => {
    return (
    <button
        {...props}
        style={{
            color: 'inherit',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 2,
            padding: 6,
            marginTop: 10,
            cursor: 'pointer',
            border: '1px solid rgba(255, 255, 255, 0.3)',
        }}
    >
        {children}
    </button>
    )
}

export default AddBtn