import toastr from "toastr"
import "toastr/build/toastr.min.css"

toastr.options = {
  positionClass: "toast-bottom-left",
}

export const showSuccessToastr = message => {
  toastr.success(message)
  setTimeout(function () {
    toastr.clear()
  }, 3000)
}

export const showErrorToastr = message => {
  toastr.error(message)
  setTimeout(function () {
    toastr.clear()
  }, 3000)
}

export const showWarningToastr = message => {
  toastr.warning(message)
  setTimeout(function () {
    toastr.clear()
  }, 3000)
}

export const showInfoToastr = message => {
  toastr.info(message)
  setTimeout(function () {
    toastr.clear()
  }, 3000)
}
