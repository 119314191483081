import { TextFieldEntry, isTextFieldEntryEdited } from '@bpmn-io/properties-panel'
import { is } from 'bpmn-js/lib/util/ModelUtil'
import { html } from 'htm/preact'
import { useService } from 'bpmn-js-properties-panel'

function CustomPropertiesProvider(propertiesPanel, translate) {
  this.getGroups = function (element) {
    return function (groups) {
      const customGroup = createCustomGroup(element, translate)
      if (customGroup) {
        groups.push(customGroup)
      }
      return groups
    }
  }

  propertiesPanel.registerProvider(500, this)
}

CustomPropertiesProvider.$inject = ['propertiesPanel', 'translate']

function createCustomGroup(element, translate) {
  if (
    is(element, 'mycustom:offer')    ||
    is(element, 'mycustom:upsell')   ||
    is(element, 'mycustom:downsell') ||
    is(element, 'mycustom:landing')  ||
    is(element, 'mycustom:checkout')
    ) {
    const customGroup = {
      id: 'customGroup',
      label: translate('Custom Properties'),
      entries: []
    }

    const properties = getProperties(element)

    properties.forEach(property => {
      customGroup.entries.push({
        id: property.id,
        element,
        component: props => CustomTextFieldEntry({ ...props, property }),
        isEdited: isTextFieldEntryEdited
      })
    })

    return customGroup
  }
  return null
}

function getProperties(element) {
  if (is(element, 'mycustom:offer')) {
    return [
      { id: 'name', label: 'Name*' },
      { id: 'url', label: 'URL*' },
      { id: 'sku', label: 'SKU*' },
      { id: 'product', label: 'Product UUID' },
      { id: 'type', label: 'Type' },
      { id: 'price_first_payment', label: 'Price (1st Payment)' },
      { id: "subscription_type",  label: "Subscription Type" },
      { id: "price_subscription",  label: 'Price (Subscription)' },
      { id: "price_meta",  label: 'Price (Meta)' },
      { id: "retail_price",  label: 'Retail Price' },
      { id: "final_price",  label: 'Final Price' },
      { id: "discount",  label: 'Discount' },
      { id: "shipping",  label: 'Shipping' }
    ]
  } else if (is(element, 'mycustom:upsell') || is(element, 'mycustom:downsell')) {
    return [
      { id: 'retail_price', label: 'Retail Price*' },
      { id: 'final_price', label: 'Final Price*' },
      { id: 'discount', label: 'Discount' },
      { id: 'product', label: 'Product*' }
    ]
  } else if (is(element, 'mycustom:checkout')) {
    return [
      { id: 'hc_funnel', label: 'HC Funnel ID*' },
    ]
  } else if (is(element, 'mycustom:landing')) {
    return [
      { id: 'url', label: 'URL' },
    ]
  }
  return []
}

function CustomTextFieldEntry({ element, id, property }) {
  const modeling = useService('modeling')
  const translate = useService('translate')
  const debounce = useService('debounceInput')
  
  const { label } = property

  const getValue = () => {
    return element.businessObject[property.id] || ''
  }

  const setValue = (value) => {
    modeling.updateProperties(element, { [property.id]: value })
  }

  const highDebounce = (fn) => (...args) => setTimeout(() => fn(...args), 2000)

  return html`
    <${TextFieldEntry}
      id=${id}
      element=${element}
      label=${translate(label)}
      getValue=${getValue}
      setValue=${(value) => {}}
      debounce=${highDebounce}
      onBlur=${(e) => setValue(e.target.value)}
    />
  `
  
}

export default CustomPropertiesProvider
