import { combineReducers } from "redux"
import apiReducer from "./api"
import uiReducer from "./ui"

const rootReducer = combineReducers({
  api: apiReducer,
  ui: uiReducer,
})

export default rootReducer
