import getConfig from "../config/blockConfig"
import Banner from "./Banner"
import Banner2 from "./Banner2"
import DealPrice from "./DealPrice"
import DiscountTitle from "./DiscountTitle"
import ExclusiveText from "./ExclusiveText"
import ImageShop from "./ImageShop"
import Logo from "./Logo"
import OrderBar from "./OrderBar"
import Title from "./Title"
import VideShop from "./VideShop"
import RememberText from "./RememberText"
import ColorSelector from "./ColorSelector"
import SizeSelector from "./SizeSelector"
import YesButton from "./YesButton"
import NoButton from "./NoButton"
import HurryBanner from "./HurryBanner"
import NoteText from "./NoteText"
import Guarantee from "./Guarantee"
import Description from "./Description"
import FindYourSize from "./FindYourSize"
import NewGuarantee from "./NewGuarantee"
import CustomerReviews from "./CustomerReviews"
import FeaturedIn from "./FeaturedIn"
import ScrollToTop from "./ScrollToTop"
import Spacing from "./Spacing"
import ExtraSpacing from "./ExtraSpacing"
import { pageTypes } from "../../../constants/page-builder"

const createBlocks = ({ blockManager, shop, pageType }) => {
  if (!pageType || pageType === pageTypes.QUIZ) {
    blockManager.add("quiz", {
      id: "quiz",
      label: "Quiz",
      category: "Quiz",
      content: { type: "quiz" },
    })

    blockManager.add("type-form", {
      id: "type-form",
      label: "Type Form",
      category: "Quiz",
      content: { type: "type-form" },
    })

    blockManager.add("lead-quizzes", {
      id: "lead-quizzes",
      label: "Lead Quizzes",
      category: "Quiz",
      content: { type: "lead-quizzes" },
    })
  }
  if (!pageType || pageType === pageTypes.OFFER) {
    blockManager.add("variant-selector", {
      id: "variant-selector",
      label: "Variant Selector",
      category: "Offer",
      content: { type: "variant-selector" },
    })
    blockManager.add("product-selector", {
      id: "product-selector",
      label: "Product Selector",
      category: "Offer",
      content: { type: "product-selector" },
    })
    Logo({ blockManager, ...getConfig(shop, "logo") })
    OrderBar({ blockManager, ...getConfig(shop, "orderBanner") })
    Banner({ blockManager, ...getConfig(shop, "banner") })
    Title({ blockManager })
    DiscountTitle({ blockManager, ...getConfig(shop, "discountTitle") })
    ExclusiveText({ blockManager, ...getConfig(shop, "exclusive") })
    ImageShop({ blockManager, ...getConfig(shop, "image") })
    VideShop({ blockManager })
    Banner2({ blockManager, shop, ...getConfig(shop, "banner2") })
    DealPrice({ blockManager, ...getConfig(shop, "dealText") })
    RememberText({ blockManager, ...getConfig(shop, "rememberText") })
    ColorSelector({ blockManager })
    SizeSelector({ blockManager })
    YesButton({ blockManager, ...getConfig(shop, "yesButton") })
    NoButton({ blockManager })
    NoteText({ blockManager })
    HurryBanner({ blockManager })
    Guarantee({ blockManager })
    Description({ blockManager })
    FindYourSize({ blockManager })
    NewGuarantee({ blockManager })
    CustomerReviews({ blockManager })
    FeaturedIn({ blockManager })
    ScrollToTop({ blockManager, shop })
    Spacing({ blockManager })
    ExtraSpacing({ blockManager })
  }

  blockManager.add("global-script", {
    label: "Global Script",
    category: "Extra",
    content: { type: "global-script" },
  })

  blockManager.add("popup-script", {
    label: "Popup Script",
    category: "Extra",
    content: { type: "popup-script" },
  })

  blockManager.add("section", {
    id: "section",
    label: "Section",
    category: "Basic",
    content: `<section></section>`,
  })

  blockManager.add("layout", {
    id: "layout",
    label: "Layout",
    category: "Extra",
    content: { type: "layout" },
  })

  blockManager.add("hero-banner", {
    id: "hero-banner",
    label: "Hero Banner",
    category: "Extra",
    content: { type: "hero-banner" },
  })
}

export default createBlocks
