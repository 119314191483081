const Banner2 = ({blockManager, shop, bgColor}) => {
    blockManager.add("banner-2", {
        id: "banner-2",
        label: "Banner 2",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="max-width: 420px; width: 100%; margin: 15px auto 0; border-radius: 12px;">
        <div style="padding: 4px 0 4px 0; font-size: 14px; margin: 0; font-weight: 600; background-color: ${
          bgColor
        }; border-radius: 6px; display: flex; align-items: center; justify-content: center;">
          <span style="font-weight: 600; font-size: 14px; font-family: 'Arial'">${
            shop == "thespadr"
              ? "🚨 Last chance: you won't find this elsewhere"
              : "🔥 78% of our customers grabbed this deal!"
          }</span>
        </div>
      </div>`,
      })
}

export default Banner2