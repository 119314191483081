import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import Providers from "Providers"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Providers>
    <App />
  </Providers>
)

serviceWorker.unregister()
