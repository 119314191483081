import Breadcrumbs from "components/Common/Breadcrumb"
import { useCallback, useState } from "react"
import { Button, Col, Container, Input, Row, Spinner } from "reactstrap"
import { callGetApi, callPostApi } from "utils/api"
import {
  showErrorToastr,
  showInfoToastr,
  showWarningToastr,
} from "../../components/Common/toastr"
import { getFormattedDate } from "../../utils/date"
import "./index.scss"

const titleCase = s => {
  return `${s.charAt(0).toUpperCase()}${s.slice(1).toLowerCase()}`
}

const OperationAdd = "add"
const OperationRemove = "remove"

const Credits = () => {
  const [userSearchEmail, setUserSearchEmail] = useState("")
  const [store, setStore] = useState(process.env.REACT_APP_STORE)
  const [user, setUser] = useState(null)
  const [creditsAmount, setAmountToAdd] = useState(0)
  const [userLoading, setUserLoading] = useState(false)
  const [updatingCredits, setUpdatingCredits] = useState(false)
  const [movements, setMovements] = useState([])

  const bindUser = useCallback(e => setUserSearchEmail(e.target.value))
  const bindStore = useCallback(e => setStore(e.target.value))
  const bindAmountToAdd = useCallback(e => {
    const value = e.target.value

    if (value === "") {
      setUpdatingCredits(true)
      setAmountToAdd(e.target.value)
      return
    }

    const creditsQty = parseInt(value)
    const maximumCreditsQty = process.env.REACT_APP_MAXIMUM_CREDITS_QTY || 30
    if (creditsQty > maximumCreditsQty) {
      setUpdatingCredits(false)
      setAmountToAdd(maximumCreditsQty)

      showWarningToastr(
        `The maximum number of credits that you can add is ${maximumCreditsQty}`
      )
      return
    }

    setUpdatingCredits(false)
    setAmountToAdd(e.target.value)
  })

  const refreshUser = useCallback(() => {
    callGetApi(
      true,
      `/credits/users?email=${encodeURIComponent(user.email)}&store=${
        user.store
      }`,
      res => {
        setUser(res)
      },
      err => {
        setUser(null)
        window.alert(err)
      }
    )
  }, [user])

  const searchUser = useCallback(() => {
    setUserLoading(true)

    callGetApi(
      true,
      `/credits/users?email=${encodeURIComponent(
        userSearchEmail
      )}&store=${store}`,
      res => {
        setUser(res)
        setUserLoading(false)
      },
      err => {
        setUser(null)
        setUserLoading(false)
        window.alert(err)
      }
    )
  })

  const getMovements = useCallback(() => {
    callGetApi(
      true,
      `/credits/users/movements?email=${encodeURIComponent(
        userSearchEmail
      )}&store=${store}`,
      res => {
        if (Array.isArray(res)) {
          return setMovements(res)
        }

        setMovements([])
      },
      err => {
        console.error(err)
        showErrorToastr(
          `Couldn't retrieve user movements for user with email: ${userSearchEmail}`
        )
        setMovements([])
      }
    )
  }, [userSearchEmail, store])

  const addCredits = useCallback(() => {
    setUpdatingCredits(true)

    callPostApi(
      true,
      `/credits/users/${user.id}/movements`,
      { amount: parseInt(creditsAmount), operation: OperationAdd },
      () => {
        setUpdatingCredits(false)
        refreshUser()
        window.alert("Credits added successfully")
      },
      err => {
        setUpdatingCredits(false)
        window.alert(err)
      }
    )
  })

  const removeCredits = useCallback(() => {
    if (!user?.balance) {
      showErrorToastr(
        "Can not remove credits until the user balance is available"
      )
      return
    }

    if (user.balance < creditsAmount) {
      showErrorToastr("Can not remove more credits than the user balance")
      return
    }

    setUpdatingCredits(true)

    callPostApi(
      true,
      `/credits/users/${user.id}/movements`,
      { amount: parseInt(creditsAmount), operation: OperationRemove },
      () => {
        setUpdatingCredits(false)
        refreshUser()
        showInfoToastr("Credits removed successfully")
      },
      err => {
        console.error(err)
        setUpdatingCredits(false)
        showErrorToastr("Could not create the remove credits movement")
      }
    )
  })

  const UserContainer = ({ user }) => (
    <Row xs="1" lg="2" className="mt-4">
      <Col className="p-2">
        <h2>User information</h2>
        <Container fluid>
          <Row>
            <Col>
              <div className="fw-bold">First name</div>
              <div>{user.first_name}</div>
            </Col>
            <Col>
              <div className="fw-bold">Last name</div>
              <div>{user.last_name}</div>
            </Col>
          </Row>
          <Row className="mt-2">
            <div className="fw-bold">Email</div>
            <div>{user.email}</div>
          </Row>
          <Row className="mt-2">
            <Col>
              <div className="fw-bold">Current balance</div>
              <div>{user.balance}</div>
            </Col>
            <Col>
              <div className="fw-bold">Store</div>
              <div>{user.store}</div>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col className="p-2">
        <h2>Actions</h2>
        <Row>
          <Col>
            <Input
              placeholder="Amount"
              type="number"
              min="1"
              value={creditsAmount}
              onInput={bindAmountToAdd}
            />
          </Col>
          <Col>
            <Button
              block
              color="success"
              onClick={addCredits}
              disabled={updatingCredits}
            >
              Add credits
            </Button>
          </Col>
          <Col>
            <Button
              block
              color="danger"
              onClick={removeCredits}
              disabled={updatingCredits}
            >
              Remove credits
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <div className="page-content">
      <Breadcrumbs title="Backoffice" breadcrumbItem="Credits" />
      <Container fluid>
        <Row xs="1" lg="3">
          <Col>
            <Input
              placeholder="Search user by email"
              value={userSearchEmail}
              onInput={bindUser}
            />
          </Col>
          <Col>
            <Input
              placeholder="Store"
              value={store}
              onInput={bindStore}
              disabled
            />
          </Col>
          <Col>
            <Button
              block
              color="primary"
              onClick={() => {
                searchUser()
                getMovements()
              }}
              disabled={userLoading || !userSearchEmail}
            >
              Search
            </Button>
          </Col>
        </Row>
        {userLoading && (
          <div className="text-center mt-4">
            <Spinner color="primary" />
          </div>
        )}
        {!userLoading && user && <UserContainer user={user} />}
      </Container>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="cell-no-lines text-center">Type</th>
              <th className="cell-no-lines text-center">Amount</th>
              <th className="cell-no-lines text-center">Issued At</th>
              <th className="cell-no-lines text-center">Expired At</th>
            </tr>
          </thead>
          <tbody>
            {movements.map(({ id, type, amount, issued_at, expired_at }) => (
              <tr key={id}>
                <td className="cell-no-lines text-center">{titleCase(type)}</td>
                <td className="cell-no-lines text-center">{amount}</td>
                <td className="cell-no-lines text-center">
                  {issued_at ? getFormattedDate(issued_at, "long12h") : ""}
                </td>
                <td className="cell-no-lines text-left">
                  {expired_at
                    ? getFormattedDate(expired_at, "shortReverse")
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Credits
