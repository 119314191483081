import { createReducer } from "redux-create-reducer"

const initialState = {
  showRightSidebar: false,
}

export default createReducer(initialState, {
  [`ui.SET_SHOW_RIGHT_SIDEBAR`](state, action) {
    return {
      ...state,
      showRightSidebar: action.payload.showRightSidebar,
    }
  },
})
