import TopShapees from "../../../assets/images/top-shapees.svg"
import TopTruekind from "../../../assets/images/top-truekind.svg"
import TopThespadr from "../../../assets/images/top-thespadr.svg"

const ScrollToTop = ({blockManager, shop}) => {
    blockManager.add("scroll-to-top", {
        id: "scroll-to-top",
        label: "Scroll To Top",
        category: "Afrodita Components",
        activate: true,
        content: `
        <div style="display: flex; justify-content: center;">
          <button type="button" style="display: flex; align-items: center; gap: 12px; border: none; background-color: transparent; text-transform: uppercase; padding: 12px 32px; cursor: pointer; color: #292929;">
            <p style="margin: 0; color: #292929; font-size: 16px; line-height: 24px; font-weight: 800;font-family: 'Arial'">Scroll to top</p>
            <img src="${
              shop == "truekind"
                ? TopTruekind
                : shop == "thespadr"
                ? TopThespadr
                : TopShapees
            }" alt="Guarantee" />
          </button>
        </div>
      `,
      })
}

export default ScrollToTop