/* eslint-disable no-undef */
import ProductViewer from "./ProductViewer"
import VariantOption from "./VariantOption"

const OfferProduct = editor => {
  ProductViewer(editor)
  VariantOption(editor)

  const loadExternalResource = window.loadExternalResource

  editor.DomComponents.addType("offer-variant-product", {
    isComponent: el => el.classList?.contains("offer-variant-product"),
    model: {
      defaults: {
        tagName: "div",
        classes: ["offer-variant-product"],
        style: {},
        useSideCar: null,
        loadExternalResource: loadExternalResource,
        product: null,
        styles: `
          .offer-variant-product {
            width: fit-content;
            margin: 24px auto;
            fontFamily: Arial, sans-serif;
            display: flex;
            flex-direction: column;
          }

          .product-variations {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: fit-content;
            padding: 0 1rem;
          }

          .price-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: .5rem;
          }

          .price {
            font-weight: bold;
            font-size: 1.6rem;
          }

          .compare-price {
            font-weight: 400;
            color: #9e9e9e;
            text-decoration: line-through;
            font-size: 1.3rem;
          }

          .addToCard {
            background-color: #f7a08b;
            color: black;
            padding: 0.875rem 2rem;
            border: none;
            border-radius: 0.5rem;
            font-size: 1rem;
            font-weight: 600;
            transition: background-color 0.3s ease-in-out;
            width: 100%;
          }

          .addToCard:hover {
            background-color: #fcd9d1;
          }

          @media (min-width: 1110px) {
            .offer-variant-product {
              flex-direction: row;
            }

            .product-variations {
              justify-content: space-between;
              padding: 0 50px;
              min-height: 45rem;
            }

            .variations-container {
              width: min-content;
              min-width: 400px;
            }
          }

        `,
        "script-props": ["product", "loadExternalResource"],
        script: function (props) {
          const { product, loadExternalResource } = props
          const variationOptionValues = {}

          const selectImage = () => {
            const variations =
              product?.variations_definition?.product_variations
            const variation = variations?.find(variation => {
              variation.variation_attributes.every(attr => {
                const variantValue = variationOptionValues[attr.name]

                if (variantValue) return attr.value === variantValue

                return false
              })
            })

            if (!variation) return

            this.querySelector(
              `.compare-price`
            ).innerText = `$${variation.compare_at_price}`
            this.querySelector(`.price`).innerText = `$${variation.price}`

            new Promise(resolve => {
              const interval = setInterval(() => {
                // eslint-disable-next-line no-undef
                if (typeof productViewerSwiperController !== "undefined") {
                  resolve()
                  clearInterval(interval)
                }
              }, 100)
            }).then(() => {
              const swiperIndex =
                productViewerSwiperController.slides.findIndex(
                  slide => +slide.id === variation.images_id[0]
                )

              // eslint-disable-next-line no-undef
              productViewerSwiperController.slideTo(swiperIndex, 0)
            })
          }

          const variationInputHandler = e => {
            variationOptionValues[e.target.name] = e.target.value
            selectImage()
          }

          const variationInput = this.querySelectorAll(".variation-option")
          const addToCartButton = document.getElementById("add-to-cart")

          function onAddToCartClick() {
            // eslint-disable-next-line no-undef
            let vSelector = VariantSelector({
              base: {
                currency: "USD",
                domain: "shapees.com",
                vendor: "shopify",
                store: "shapees",
                environment: "development",
              },
              product: {
                vendorId: product?.vendor_product?.product_id,
                quantity: document.getElementById("quantity-select").value,
                attributes_definitions: Object.keys(variationOptionValues),
                attributes: variationOptionValues,
              },
              triggers: {
                buyNow: ".buyNow",
              },
            })
            console.log("vSelector", vSelector)
          }

          if (addToCartButton) addToCartButton.onclick = onAddToCartClick

          variationInput.forEach(
            input => (input.onchange = variationInputHandler)
          )

          const initialValues =
            product?.variations_definition?.product_variations[0]
              ?.variation_attributes

          if (initialValues) {
            initialValues.forEach(attr => {
              variationOptionValues[attr.name] = attr.value
            })
            selectImage()
          }

          const loadExternalResourceFn = eval(loadExternalResource)
          loadExternalResourceFn?.(
            "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@1.7.0/js/products-script.js",
            "script",
            () =>
              loadExternalResourceFn?.(
                "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@1.7.0/js/checkout-script.js",
                "script",
                () =>
                  loadExternalResourceFn?.(
                    "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@1.7.0/js/variant-selector-script.js",
                    "script"
                  )
              )
          )
        },
      },
      init() {
        this.on("change:product", this.renderComponent)
      },
      renderComponent(_, productData) {
        if (!productData) return

        const { title, variations_definition, images } = productData
        const { product_dimensions } = variations_definition.variatons_summary

        if (!product_dimensions) {
          alert("Product doesn't have variant options")
          return
        }

        const variantSelectorsSorted = product_dimensions
          .sort((a, b) => {
            if (a.name.includes("Color")) return -1
            if (b.name.includes("Color")) return 1

            return 0
          })
          .map(
            dimension =>
              `<div name="${dimension.name}" class="variant-option"></div>`
          )

        this.components(`
          <div class="product-viewer"></div>
          <div class="product-variations">
            <div class="variations-container">
              <h2 style="font-size: 30px; line-height: 38px">${title}</h2>
              <p class="price-container">
                <strong class="compare-price"></strong>
                <strong class="price"></strong>
              </p>
              ${variantSelectorsSorted.join("")}
            </div>
            <div style="display: flex; gap: 0.5rem; width: 100%">
              <select id="quantity-select" value="1">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              <button class="addToCard" id="add-to-cart">ADD TO CARD</button>
            </div>
          </div>
        `)
        const productViewer = this.find(".product-viewer")[0]
        productViewer.set("images", images ?? [])

        const variantSelectors = this.find(".variant-option")
        variantSelectors.map(variantSelector => {
          const { values, name } =
            product_dimensions.find(
              dimension =>
                dimension.name === variantSelector.attributes.attributes.name
            ) ?? {}
          variantSelector.set("variantOptions", values ?? [])
          name?.includes("Color") &&
            variantSelector.set("variantType", "color-selector")
        })
      },
    },
  })
}

export default OfferProduct
