import PropTypes from "prop-types"
import React from "react"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import withRouter from "components/Common/withRouter"
import logo from "assets/images/dark-logo.jpg"
import { callPostApi } from "../../utils/api"

const Login = () => {
  const handleSignIn = () => {
    callPostApi(
      false,
      `/auth/login/authorize`,
      {},
      onAuthorizeSuccess,
      onAuthorizeFail,
      "auth"
    )
  }

  const onAuthorizeSuccess = data => {
    window.open(data, "_self")
  }

  const onAuthorizeFail = () => {}

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody
                  className="pt-0"
                  style={{
                    background: "#233951",
                  }}
                >
                  <div
                    className="mt-3 d-flex"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <img src={logo} />
                  </div>
                  <div>
                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        type="button"
                        onClick={handleSignIn}
                        style={{
                          background: "white",
                          color: "#233951",
                          borderColor: "#233951",
                          fontSize: "20px",
                          fontWeight: 800,
                        }}
                      >
                        Log In
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
