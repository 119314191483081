const keyCustomCode = "custom-code-plugin__code"

const CustomCode = editor => {
  editor.DomComponents.addType("custom-code", {
    model: {
      defaults: {
        droppable: true,
      },
    },
    view: {
      onComponentsChange() {
        const { model, el } = this
        const content = model.get(keyCustomCode) || ""

        const firstTag = "<script>"
        const endTag = "</script>"
        const endTagLength = endTag.length
        if (
          content.indexOf(firstTag) === 0 &&
          content.indexOf(endTag) === content.length - endTagLength
        ) {
          el.innerHTML = "Custom Code"
        }
      },
    },
  })
}

export default CustomCode
