import inherits from "inherits"
import BpmnPaletteProvider from "bpmn-js/lib/features/palette/PaletteProvider"

export default function CustomPaletteProvider(
  palette,
  create,
  elementFactory,
  spaceTool,
  lassoTool,
  handTool,
  globalConnect,
  translate
) {
  BpmnPaletteProvider.call(
    this,
    palette,
    create,
    elementFactory,
    spaceTool,
    lassoTool,
    handTool,
    globalConnect,
    translate
  )

  this.getPaletteEntries = function (element) {
    const actions = BpmnPaletteProvider.prototype.getPaletteEntries.call(
      this,
      element
    )

    const removeEntries = [
      "create.task",
      "create.data-object",
      "create.data-store",
      "create.subprocess-expanded",
      "create.participant-expanded",
      "create.group",
      "create.intermediate-event",
    ]

    const filteredActions = Object.keys(actions)
      .filter(key => !removeEntries.includes(key))
      .reduce((obj, key) => {
        obj[key] = actions[key]
        return obj
      }, {})

    filteredActions["create.quiz"] = {
      group: "activity",
      className: "bpmn-icon-task",
      title: translate("Create Task"),
      action: {
        dragstart: createQuiz,
        click: createQuiz,
      },
    }

    return filteredActions
  }

  function createQuiz(event) {
    const shape = elementFactory.createShape({ type: "mycustom:quiz" })
    create.start(event, shape)
  }
}

inherits(CustomPaletteProvider, BpmnPaletteProvider)

CustomPaletteProvider.$inject = [
  "palette",
  "create",
  "elementFactory",
  "spaceTool",
  "lassoTool",
  "handTool",
  "globalConnect",
  "translate",
]
