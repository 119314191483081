/* eslint-disable no-undef */
const TypeForm = editor => {
  const loadExternalResource = window.loadExternalResource
  const loadSideCarLib = window.loadSideCarLib
  editor.DomComponents.addType("type-form", {
    isComponent: el => el.classList?.contains("type-form"),
    extend: 'iframe',
    model: {
      defaults: {
        tagName: "div",
        classes: ["type-form"],
        styles: `
          .type-form {
              width: 100%;
              height: 50vh;
          }
          .type-form iframe {
              width: 100%;
              height: 100%;
          }
        `,
        formId: "",
        useSideCar: false,
        questionId: null,
        loadExternalResource: loadExternalResource,
        loadSideCarLib: loadSideCarLib,
        traits: [
          {
            type: "text",
            label: "Form ID",
            name: "formId",
            changeProp: 1,
          },
          {
            name: "useSideCar",
            label: "Use SideCar",
            type: "checkbox",
            changeProp: 1,
          },
        ],
        "script-props": [
          "formId",
          "questionId",
          "useSideCar",
          "loadExternalResource",
          "loadSideCarLib",
        ],
        script: function ({
          formId,
          useSideCar,
          questionId,
          loadExternalResource,
          loadSideCarLib,
        }) {
          const el = this
          function initLib() {
            function onSubmit(e) {
              if (useSideCar) {
                fetch(
                  `http://localhost:3002/api/page-builder?formId=${e.formId}&responseId=${
                    e.responseId
                  }`,
                  {
                    method: "GET",
                  }
                )
                  .then(res => res.json())
                  .then(({form, answers}) => {
                    const index = questionId - 1
                    const answer = answers[index]
                    if (answer.type === "choice") {
                      const label = answer.choice.label
                      const value = form[index].properties.choices.findIndex(
                        c => c.label === label
                      )

                      setQuizValue(value + 1)
                      customEventEmitter.emit(EventsEnum.NEXT_NODE)
                    }
                  })
              }
            }
            tf?.createWidget(formId, {
              container: el,
              width: "100%",
              height: "100%",
              onSubmit,
            })
          }

          if (typeof tf == "undefined") {
            const loadExternalResourceFn = eval(loadExternalResource)
            loadExternalResourceFn(
              "https://embed.typeform.com/next/embed.js",
              "script",
              initLib
            )
            loadExternalResourceFn(
              "https://embed.typeform.com/next/css/popup.css",
              "style"
            )
          } else {
            initLib()
          }
          const loadSideCarLibFn = eval(loadSideCarLib)
          loadSideCarLibFn(useSideCar)
        },
      },
      createQuestionTrait() {
        this.addTrait({
          name: 'questionId',
          label: 'Select question',
          type: 'number',
          min: 1,
          changeProp: 1,
        })
      },
      handleUseSideCarChange(_, useSideCar) {
        if (!useSideCar) {
          this.removeTrait('questionId')
          this.set('questionId', null)
          return
        }

        this.createQuestionTrait()

        if (this.get('questionId') == null) {
          this.set('questionId', 1)
        }
      },
      init() {
        this.on("change:useSideCar", this.handleUseSideCarChange)

        if (this.get("useSideCar")) {
          this.createQuestionTrait()
        }
      }
    },
    view: {
      onRender({ el }) {
        el.style.padding = "10px"
      },
    },
  })
}

export default TypeForm
