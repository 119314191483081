import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { getUserRoles, userRoles } from "utils/auth"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import withRouter from "components/Common/withRouter"
import MetisMenu from "metismenujs"
import { Link } from "react-router-dom"
import SidebarContentSection from "./SidebarContentSection"

const sectionByUserRole = Object.freeze({
  [userRoles.CreditAdmin]: [
    {
      name: "Credits",
      groups: [
        {
          name: "Credits",
          boxicon: "bx bx-money",
          items: [
            {
              name: "All Credits",
              path: "/credits",
            },
          ],
        },
      ],
    },
  ],
})

const getSectionByUserRole = userRoles => {
  const sections = []
  for (let userRole of userRoles) {
    const sectionByRole = sectionByUserRole[userRole]
    if (!sectionByRole || sectionByRole.length === 0) {
      continue
    }

    sections.push(...sectionByRole)
  }

  return sections
}

const SidebarContent = () => {
  const ref = useRef()

  const userRoles = getUserRoles()

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const sections = [
    {
      name: "Subscriptions",
      groups: [
        {
          name: "Subscriptions",
          boxicon: "bx bx-layout",
          items: [
            {
              name: "All Subscriptions",
              path: "/subscriptions",
            },
            {
              name: "All Subscription Types",
              path: "/subscription-types",
            },
          ],
        },
      ],
    },
  ]

  const sectionByRoles = getSectionByUserRole(userRoles)
  sections.push(...sectionByRoles)

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Upsell</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>Funnels</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/all-funnels">All Funnels</Link>
                </li>
                <li>
                  <Link to="/shop-selection">Create Funnel</Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="/offer-dnd">
                <i className="mdi mdi-selection-drag"></i>
                <span>Drag & Drop</span>
              </Link>
            </li> */}
            <li className="menu-title">Taxes</li>
            <li>
              <Link to="/taxes" className="has-arrow">
                <i className="bx bx-layout"></i>
                <span>Taxes</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/taxes">All Taxes</Link>
                </li>
              </ul>
            </li>
            <li className="menu-title">Products</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-barcode"></i>
                <span>Products</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/all-products">All Products</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Coupons</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-layout"></i>
                <span>Coupons</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/coupons">All Coupons</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Funnel Builder</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-layout"></i>
                <span>Funnel Builder</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/funnels">All funnels</Link>
                </li>
              </ul>
            </li>

            {sections.map(section => (
              <SidebarContentSection
                key={section.name}
                title={section.name}
                groups={section.groups}
              />
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(SidebarContent)
