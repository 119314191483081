import { Badge } from "reactstrap"
import {
  showErrorToastr,
  showInfoToastr,
} from "../../../components/Common/toastr"
import { getFormattedDate } from "../../../utils/date"
import "./index.scss"

export const UserEmail = cell => {
  return cell.value ? cell.value : ""
}

export const SubscriptionTypeName = cell => {
  return cell.value ? cell.value : ""
}

export const NextPaymentDate = cell => {
  return cell.value ? getFormattedDate(cell.value, "short") : ""
}

export const Price = cell => (cell.value ? cell.value.toFixed(2) : "")

export const Status = cell => {
  return (
    <Badge
      className={
        "status-container font-size-12 badge-soft-" +
        (cell.value === "active"
          ? "success"
          : "danger" && cell.value === "pending"
          ? "warning"
          : "danger")
      }
    >
      {cell.value}
    </Badge>
  )
}

export const SubscriptionId = cell => (
  <div className="d-flex gap-2 subscription-id-container">
    <span className="subscription-actions">
      <box-icon
        name="copy"
        className="icon-button"
        onClick={() => {
          navigator.clipboard
            .writeText(cell.value)
            .then(() => {
              showInfoToastr("Subscription Id copied")
            })
            .catch(() => {
              showErrorToastr(
                `Couldn't copy the subscription id: ${cell.value}`
              )
            })
        }}
      ></box-icon>
    </span>
  </div>
)

const schedulePaymentStatusBadgeColor = Object.freeze({
  skipped: "warning",
  open: "success",
  done: "success",
  in_progress: "warning",
  cancelled: "danger",
  paused: "danger",
  retry: "warning",
})

export const SchedulePaymentStatus = cell => {
  if (!cell.value?.schedule_payment_status) {
    return ""
  }

  return (
    <Badge
      className={`font-size-12 badge-soft-${
        schedulePaymentStatusBadgeColor[cell.value.schedule_payment_status] ||
        "warning"
      }`}
    >
      {cell.value?.schedule_payment_status || ""}
    </Badge>
  )
}

export const CreatedAtColumn = cell => {
  return (
    <div className="date-column">{getFormattedDate(cell.value, "short")}</div>
  )
}

export const UpdatedAtColumn = cell => {
  return (
    <div className="date-column">{getFormattedDate(cell.value, "short")}</div>
  )
}
