import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Button, Input } from "reactstrap"
import { showErrorToastr } from "../../../../components/Common/toastr"

const ShippingMethodsOverride = ({
  validationType,
  shippingMethodsUs,
  shippingMethodsCa,
  shippingMethodsGb,
  shippingMethodsUy,
  shippingMethodsAu,
  shippingMethodsSg,
  shippingMethodsNz,
  selectedShippingMethods,
  setSelectedShippingMethods,
}) => {
  const handleAddShippingOverride = (
    selectedMethod,
    setFieldValue,
    trimCountry
  ) => {
    if (selectedMethod) {
      const methodExists = validationType.values.shippingOverrides.some(
        override => override.shippingId === selectedMethod.id
      )
      if (!methodExists) {
        const newShippingOverride = {
          shippingId: selectedMethod.id,
          name: selectedMethod.name,
          country: trimCountry,
          price: selectedMethod.price,
          minimumOverride: 0,
          maximumOverride: selectedMethod.price,
          type: selectedMethod.type,
        }

        setFieldValue("shippingOverrides", [
          ...validationType.values.shippingOverrides,
          newShippingOverride,
        ])
      } else {
        showErrorToastr("Shipping Method already added")
      }
    } else {
      showErrorToastr("Please Select Shipping Methods")
    }
  }

  const handleShippingOverrideChange = (
    event,
    setFieldValue,
    ind,
    fieldName
  ) => {
    const { value } = event.target
    if (fieldName === "minimumOverride") {
      const minimumOverride = parseFloat(value)
      const maximumOverride = parseFloat(
        validationType.values.shippingOverrides[ind].maximumOverride
      )
      if (minimumOverride > maximumOverride) {
        return
      }
    }
    setFieldValue(`shippingOverrides[${ind}].${fieldName}`, value)
  }

  const handleDeleteShippingOverride = (setFieldValue, ind) => {
    const updatedOverrides = validationType.values.shippingOverrides.filter(
      (_, i) => i !== ind
    )
    setFieldValue("shippingOverrides", updatedOverrides)
  }

  return (
    <div>
      <div>
        <Row>
          {validationType.values.countryCodes &&
            validationType.values.countryCodes?.split(",")?.map(country => {
              let trimCountry = country?.trim()
              let allShippingMethods =
                trimCountry == "CA"
                  ? shippingMethodsCa
                  : trimCountry == "UY"
                  ? shippingMethodsUy
                  : trimCountry == "AU"
                  ? shippingMethodsAu
                  : trimCountry == "GB"
                  ? shippingMethodsGb
                  : trimCountry == "SG"
                  ? shippingMethodsSg
                  : trimCountry == "NZ"
                  ? shippingMethodsNz
                  : shippingMethodsUs
              let isIncludeShippingOverride = false
              validationType.values.shippingOverrides.map(override => {
                if (override.country == trimCountry) {
                  isIncludeShippingOverride = true
                }
              })
              return (
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>
                        Shipping Methods (Override) for {trimCountry}
                      </CardTitle>
                      <div>
                        <Row>
                          <Col lg={6}>
                            <div
                              className="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <select
                                name="selectedShippingMethod"
                                className="form-select"
                                onChange={e =>
                                  setSelectedShippingMethods(e.target.value)
                                }
                                value={selectedShippingMethods}
                              >
                                <option value={""}>
                                  Select Shipping Method
                                </option>
                                {allShippingMethods.map((shipping, index) => {
                                  return (
                                    <option value={shipping.id} key={index}>
                                      {shipping.name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <Button
                              type="button"
                              color="primary"
                              onClick={() =>
                                handleAddShippingOverride(
                                  allShippingMethods?.filter(
                                    el => el.id == selectedShippingMethods
                                  )?.[0],
                                  validationType.setFieldValue,
                                  trimCountry
                                )
                              }
                            >
                              Add Override
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      {isIncludeShippingOverride && (
                        <div>
                          {validationType.values.shippingOverrides.length >
                            0 && (
                            <div className="mt-4">
                              <Row>
                                <Col md={3}>
                                  <p className="m-0">
                                    <strong>Shipping Method Name</strong>
                                  </p>
                                </Col>
                                <Col md={2}>
                                  <p className="m-0">
                                    <strong>Price</strong>
                                  </p>
                                </Col>
                                <Col md={2}>
                                  <p className="m-0">
                                    <strong>Min Cart Total Value</strong>
                                  </p>
                                </Col>
                                <Col md={2}>
                                  <p className="m-0">
                                    <strong>Max Cart Total Value</strong>
                                  </p>
                                </Col>
                                <Col md={2}>
                                  <p className="m-0">
                                    <strong>Country/Min/Max</strong>
                                  </p>
                                </Col>
                                <Col md={1}>
                                  <p className="m-0">
                                    <strong>Action</strong>
                                  </p>
                                </Col>
                              </Row>
                              {validationType.values.shippingOverrides.map(
                                (override, ind) => {
                                  if (trimCountry == override.country) {
                                    return (
                                      <Row className="mt-2" key={ind}>
                                        <Col md={3}>
                                          <p className="m-0">{override.name}</p>
                                        </Col>
                                        <Col md={2}>
                                          <Input
                                            name={`shippingOverrides[${ind}].price`}
                                            value={override.price}
                                            onChange={e =>
                                              handleShippingOverrideChange(
                                                e,
                                                validationType.setFieldValue,
                                                ind,
                                                "price"
                                              )
                                            }
                                            type="text"
                                          />
                                        </Col>
                                        <Col md={2}>
                                          <Input
                                            type="text"
                                            name={`shippingOverrides[${ind}].minimumOverride`}
                                            value={override.minimumOverride}
                                            onChange={e =>
                                              handleShippingOverrideChange(
                                                e,
                                                validationType.setFieldValue,
                                                ind,
                                                "minimumOverride"
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col md={2}>
                                          <Input
                                            type="text"
                                            name={`shippingOverrides[${ind}].maximumOverride`}
                                            value={override.maximumOverride}
                                            onChange={e =>
                                              handleShippingOverrideChange(
                                                e,
                                                validationType.setFieldValue,
                                                ind,
                                                "maximumOverride"
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col md={2}>
                                          <Input
                                            type="text"
                                            disabled
                                            value={`${override.country}/${override.minimumOverride}/${override.maximumOverride}`}
                                          />
                                        </Col>

                                        <Col md={1}>
                                          <Button
                                            type="button"
                                            color="primary"
                                            onClick={() =>
                                              handleDeleteShippingOverride(
                                                validationType.setFieldValue,
                                                ind
                                              )
                                            }
                                          >
                                            Remove
                                          </Button>
                                        </Col>
                                      </Row>
                                    )
                                  }
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
        </Row>
      </div>
    </div>
  )
}

export default ShippingMethodsOverride
