import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

const ShippingMethods = ({
  validationType,
  allCountries,
  shippingMethodsUs,
  shippingMethodsCa,
  shippingMethodsGb,
  shippingMethodsUy,
  shippingMethodsAu,
  shippingMethodsSg,
  shippingMethodsNz,
  funnelId,
}) => {
  console.log("aaaa", validationType.values.shippingReplacements)

  const handleShippingMethodSelection = (method, country, event) => {
    const isChecked = event.target.checked
    const { setFieldValue, values } = validationType
    let updatedShippingReplacements = values.shippingReplacements || []

    const existingIndex = updatedShippingReplacements.findIndex(
      item =>
        item.shippingId === method.id && item.country === country && item?.id
    )

    if (existingIndex !== -1) {
      setFieldValue(
        "shippingReplacements",
        values?.shippingReplacements?.map(el => {
          if (el.shippingId === method.id && el.country === country && el?.id) {
            return {
              ...el,
              active: isChecked,
            }
          } else {
            return el
          }
        })
      )
    } else {
      const newEntry = {
        shippingId: method.id,
        funnelId: funnelId,
        name: method.name,
        country: country,
        active: isChecked,
      }

      if (isChecked) {
        updatedShippingReplacements = [...updatedShippingReplacements, newEntry]
      } else {
        updatedShippingReplacements = updatedShippingReplacements.filter(
          item => !(item.shippingId === method.id && item.country === country)
        )
      }
      setFieldValue("shippingReplacements", updatedShippingReplacements)
    }
  }

  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-3">Shipping Methods</CardTitle>
                {allCountries.map((country, ind) => {
                  const isSelectedCountry = validationType.values.countryCodes
                    .split(", ")
                    .includes(country)
                  const allShippingMethods =
                    country == "CA"
                      ? shippingMethodsCa
                      : country == "GB"
                      ? shippingMethodsGb
                      : country == "UY"
                      ? shippingMethodsUy
                      : country == "AU"
                      ? shippingMethodsAu
                      : country == "SG"
                      ? shippingMethodsSg
                      : country == "NZ"
                      ? shippingMethodsNz
                      : shippingMethodsUs
                  return (
                    isSelectedCountry && (
                      <div key={country}>
                        <div className="d-flex">
                          <CardTitle className="mb-0">{country}</CardTitle>
                          <div
                            className="d-flex"
                            style={{
                              marginLeft: 15,
                              position: "relative",
                              bottom: -1,
                            }}
                          >
                            {allShippingMethods.map(method => {
                              return (
                                <div
                                  className="form-check countries-checkbox"
                                  key={method?.id}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`${country}-${method?.id}`}
                                    checked={validationType.values.shippingReplacements.some(
                                      item =>
                                        item.shippingId === method.id &&
                                        item.country === country &&
                                        item.active
                                    )}
                                    onChange={event =>
                                      handleShippingMethodSelection(
                                        method,
                                        country,
                                        event
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`${country}-${method?.id}`}
                                  >
                                    {method?.name}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <div
                          className={
                            ind !== allCountries.length - 1
                              ? "payment-divider"
                              : ""
                          }
                        />
                      </div>
                    )
                  )
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ShippingMethods
