import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { SubscriptionItems } from "./SubscriptionItems"

function SubscriptionItemsModal({
                                  isOpen, toggle, subscription, onRefetchSubscriptions
                                }) {
  return (<Modal
    isOpen={isOpen}
    role="dialog"
    autoFocus={true}
    centered={true}
    tabIndex="-1"
    toggle={toggle}
    size="lg"
  >
    <ModalHeader toggle={toggle}>Subscription Items Details</ModalHeader>
    <ModalBody>
      <p className="mb-4">
        Subscription ID:{" "}
        <span className="text-primary">{subscription?.id}</span>
      </p>
      {subscription?.id ? (<SubscriptionItems
        subscription={subscription}
        onRefetchSubscriptions={onRefetchSubscriptions}
        isDisabled={true}
      />) : null}
    </ModalBody>
    <ModalFooter>
      <Button type="button" color="secondary" onClick={toggle}>
        Close
      </Button>
    </ModalFooter>
  </Modal>)
}

export default SubscriptionItemsModal
