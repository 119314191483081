import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"

const CrossSellingProducts = ({
  validationType,
  setIsOpenCrossSelling,
  crossSellingProducts,
}) => {
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="priority-btn-section">
                    Cross Selling Products At Checkout
                  </div>
                </CardTitle>
                <div>
                  <Row>
                    <Col xs={12} lg={12}>
                      <div className="mb-3">
                        <Label className="form-label">Status</Label>
                        <select
                          name="active"
                          className="form-select"
                          onChange={e => {
                            const active = e.target.value === "true"
                            validationType.setFieldValue(
                              "checkoutOffers.active",
                              active
                            )
                          }}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.checkoutOffers.active.toString()}
                        >
                          <option value={true}>Active</option>
                          <option value={false}>Inactive</option>
                        </select>
                      </div>
                    </Col>
                    {validationType.values.checkoutOffers.active && (
                      <Col xs={12} lg={12}>
                        <Label className="form-label">Type</Label>
                        <div>
                          <select
                            name="checkoutOffers.checkoutOfferType"
                            className="form-select"
                            onChange={validationType.handleChange}
                            value={
                              validationType.values.checkoutOffers
                                .checkoutOfferType
                            }
                          >
                            <option value={""}>Select Type</option>
                            <option value={"PRODUCT"}>Product</option>
                            <option value={"ACCESSORY"}>Accessory</option>
                            {/* <option value={"GIFT"}>Gift</option> */}
                          </select>
                        </div>
                      </Col>
                    )}
                    {validationType.values.checkoutOffers.active &&
                      validationType.values.checkoutOffers
                        .checkoutOfferType && (
                        <Col xs={12} lg={12}>
                          <div className="mt-3">
                            <Label className="form-label">
                              Cross Selling Product
                            </Label>
                            {crossSellingProducts?.length > 0 && (
                              <div>
                                {crossSellingProducts?.map((product, index) => {
                                  return (
                                    <div
                                      className="selected-product-item"
                                      key={index}
                                    >
                                      <img src={product.images?.[0]?.src} />
                                      <Label className="form-label">
                                        {product.title}
                                      </Label>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                            <div className="offer-product-container">
                              <Button
                                color="primary"
                                type="button"
                                data-toggle="modal"
                                data-target="#myModal"
                                onClick={() => {
                                  setIsOpenCrossSelling(true)
                                }}
                              >
                                Select A Product
                              </Button>
                            </div>
                          </div>
                        </Col>
                      )}
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CrossSellingProducts