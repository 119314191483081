import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Tooltip,
  Button,
} from "reactstrap"
import { callPostApi } from "../../../../utils/api"

const Priority = ({ validationType, funnelId, shop }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const toggleTooltip = () => {
    setIsOpen(!isOpen)
  }

  const onGetDefaultOfferSetSuccess = data => {
    setIsLoading(false)
    validationType.setFieldValue("offerSets", [
      ...validationType.values.offerSets,
      {
        ...data,
      },
    ])
  }

  const onGetDefaultOfferSetFail = () => {
    setIsLoading(false)
  }

  const addOfferSets = () => {
    const highestOrder = validationType.values.offerSets.reduce(
      (maxOrder, item) => {
        return item.order > maxOrder ? item.order : maxOrder
      },
      0
    )
    let payload = {
      funnelId: funnelId,
      order: highestOrder + 1,
    }
    setIsLoading(true)
    callPostApi(
      true,
      `/upselling/funnels/offer-sets/default?shop=${shop}`,
      payload,
      onGetDefaultOfferSetSuccess,
      onGetDefaultOfferSetFail
    )
  }

  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>
                  <div className="priority-btn-section">
                    Priority
                    {validationType.values.priority == "1" && (
                      <Button
                        type="button"
                        color="primary"
                        onClick={addOfferSets}
                        disabled={isLoading}
                      >
                        Add New Offers Set
                      </Button>
                    )}
                  </div>
                </CardTitle>
                <div>
                  <Row>
                    <Col lg={6}>
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <select
                          name="priority"
                          className="form-select"
                          onChange={validationType.handleChange}
                          value={validationType.values.priority}
                        >
                          <optgroup label="Tier 1">
                            <option value={"1"}>A/B testing</option>
                          </optgroup>
                          <optgroup label="Tier 2">
                            <option value={"2"}>Product ID</option>
                          </optgroup>
                          <optgroup label="Tier 3">
                            <option value={"3"}>Acquisition WP</option>
                            <option value={"4"}>Store WP</option>
                            <option value={"5"}>Acquisition CAT</option>
                            <option value={"6"}>Store CAT</option>
                            <option value={"7"}>General</option>
                          </optgroup>
                        </select>
                        <div className="priority-info">
                          <i
                            className="mdi mdi-information"
                            id="priority-tooltip"
                          ></i>
                          <Tooltip
                            placement="top"
                            isOpen={isOpen}
                            target="priority-tooltip"
                            toggle={toggleTooltip}
                          >
                            <span>
                              Determine which funnel to display if multiple
                              funnels share the same criteria. For example, if
                              Funnel A and Funnel 8 are both set to display if
                              your customer buys a particular product, you can
                              choose which one of the funnels has priority. Drag
                              the funnel you want to display first to the top.
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Priority
