import customPalette from "./palette/customPaletteModule"
import customReplaceMenu from "./replace-menu/customReplaceMenuModule"
import customContextPad from "./context-pad/customContextPadModule"
import customRenderer from "./renderer/customRendererModule"
import customProperties from "./properties/customPropertiesProvider"

export const customPaletteModule = customPalette
export const customReplaceMenuModule = customReplaceMenu
export const customContextPadModule = customContextPad
export const customRendererModule = customRenderer
export const customPropertiesProvider = customProperties
