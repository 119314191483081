import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Modal,
  CardTitle,
  Input,
  Label,
  FormFeedback,
  Spinner,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SearchProducts from "../../../components/Common/SearchProducts"
import {
  callGetApi,
  callPostApi,
  callPutApi,
  callDeleteApi,
} from "../../../utils/api"
import * as Yup from "yup"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import {
  showSuccessToastr,
  showErrorToastr,
} from "../../../components/Common/toastr"
import "./add-autoship-criteria.scss"

const AddAutoshipCriteria = () => {
  document.title = "Add Autoship Criteria"
  const history = useNavigate()
  const { funnelId } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const shop = queryParams.get("shop")
  const [allShippingTypes, SetAllShippingTypes] = useState([])
  const [isOpenStraighChange, setIsOpenStraighChange] = useState(false)
  const [modalMode, setModalMode] = useState("parent")
  const [currentParentProduct, setCurrentParentProduct] = useState(null)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [autoshipCriteriaDetails, setAutoshipCriteriaDetails] = useState(null)
  const [autoshipCriteriaProducts, setAutoshipCriteriaProducts] = useState([])
  const isTruekindShop = shop.includes("truekind")
  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      subscriptionName: "",
      threshold: "",
      straightChangeProducts: [],
      active: true,
    },
    validationSchema: Yup.object().shape({
      subscriptionName: Yup.string().required("Subscription Name is required"),
      threshold: Yup.number()
        .required("Threshold is required")
        .min(1, "Threshold must be greater than 1"),
      straightChangeProducts: Yup.array().of(
        Yup.object().shape({
          priority: Yup.number()
            .required("Priority is required")
            .min(1, "Priority must be greater than 0"),
        })
      ),
    }),
    onSubmit: values => {
      if (values.straightChangeProducts.length === 0) {
        showErrorToastr("Please select at least one product.")
        return
      }
      if (!isTruekindShop) {
        const hasMissingGiftProduct = values.straightChangeProducts.some(
          product => !product.giftProduct
        )

        if (hasMissingGiftProduct) {
          showErrorToastr("Please select a gift product for each product.")
          return
        }
      }
      let autoshipCriteriaPayload = {
        funnelId: funnelId,
        subscriptionName: values?.subscriptionName,
        active: values?.active,
        threshold: values?.threshold,
        shop: shop,
      }
      setIsUpdateLoading(true)
      if (autoshipCriteriaDetails?.id) {
        callPutApi(
          true,
          `/upselling/criterias/autoship/${autoshipCriteriaDetails?.id}`,
          autoshipCriteriaPayload,
          data => onCreateAutoshipCriteriaSuccess(data, values),
          onCreateAutoshipCriteriaFail
        )
      } else {
        callPostApi(
          true,
          `/upselling/criterias/autoship`,
          autoshipCriteriaPayload,
          data => onCreateAutoshipCriteriaSuccess(data, values),
          onCreateAutoshipCriteriaFail
        )
      }
    },
  })

  const onCreateAutoshipCriteriaSuccess = (data, values) => {
    let autoshipCriteriaProductsPayload = []
    values?.straightChangeProducts?.map(el => {
      let productPayload = {
        autoshipCriteriaId: data?.id,
        productId: el?.id,
        giftProduct: el?.giftProduct?.id,
        priority: Number(el?.priority),
      }
      if (!isTruekindShop) {
        productPayload.giftProduct = el?.giftProduct?.id
      }
      if (el?.autoshipId) {
        productPayload.id = el?.autoshipId
      }
      autoshipCriteriaProductsPayload.push(productPayload)
    })
    if (autoshipCriteriaDetails?.id) {
      let addProduct = []
      let updatedProducts = []
      autoshipCriteriaProductsPayload?.map(el => {
        if (el?.id) {
          updatedProducts.push(el)
        } else {
          addProduct.push(el)
        }
      })
      if (updatedProducts?.length > 0) {
        callPutApi(
          true,
          `/upselling/criterias/autoship/product/${data?.id}?funnelId=${funnelId}`,
          updatedProducts,
          onCreateAutoshipCriteriaProductsSuccess,
          onCreateAutoshipCriteriaProductsFail
        )
      }
      if (addProduct?.length > 0) {
        callPostApi(
          true,
          `/upselling/criterias/autoship/product?funnelId=${funnelId}`,
          addProduct,
          updatedProducts?.length == 0
            ? onCreateAutoshipCriteriaProductsSuccess
            : () => {},
          () => {}
        )
      }
    } else {
      callPostApi(
        true,
        `/upselling/criterias/autoship/product?funnelId=${funnelId}`,
        autoshipCriteriaProductsPayload,
        onCreateAutoshipCriteriaProductsSuccess,
        onCreateAutoshipCriteriaProductsFail
      )
    }
  }

  const onCreateAutoshipCriteriaFail = () => {
    setIsUpdateLoading(false)
  }

  const onCreateAutoshipCriteriaProductsSuccess = data => {
    setIsUpdateLoading(false)
    if (autoshipCriteriaDetails?.id) {
      showSuccessToastr("Autoship Criteria Products Updated Successfully!")
    } else {
      showSuccessToastr("Autoship Criteria Products Created Successfully!")
    }
    history(`/create-funnel?funnelId=${funnelId}&shop=${shop}`)
  }

  const onCreateAutoshipCriteriaProductsFail = () => {
    setIsUpdateLoading(false)
  }

  console.log("validationType", validationType.values)

  const returnSaveStraightChangeProducts = selectedProducts => {
    if (modalMode === "parent") {
      const duplicateProducts = [
        ...selectedProducts,
        ...validationType.values.straightChangeProducts,
      ]
      const uniqueReplacementProducts = duplicateProducts.reduce((acc, obj) => {
        const existingProducts = acc.find(item => item.id === obj.id)
        if (!existingProducts) {
          acc.push(obj)
        }
        return acc
      }, [])
      validationType.setFieldValue(
        "straightChangeProducts",
        uniqueReplacementProducts
      )
    } else if (modalMode === "gift") {
      handleGiftProductSelect(selectedProducts[0]) // Only one product can be selected as a gift
    }

    setIsOpenStraighChange(false)
  }

  const handlePriorityChange = (e, productId) => {
    const { value } = e.target
    const updatedProducts = validationType.values.straightChangeProducts.map(
      product =>
        product.id === productId ? { ...product, priority: value } : product
    )
    validationType.setFieldValue("straightChangeProducts", updatedProducts)
  }

  const handleGiftProductSelect = giftProduct => {
    const updatedProducts = validationType.values.straightChangeProducts.map(
      product =>
        product.id === currentParentProduct.id
          ? { ...product, giftProduct }
          : product
    )
    validationType.setFieldValue("straightChangeProducts", updatedProducts)
  }

  const onGetAllShippingTypesSuccess = data => {
    let modifiedShipping = []
    data?.map(el => {
      if (el.name?.includes("autoship")) {
        modifiedShipping.push(el)
      }
    })
    SetAllShippingTypes(modifiedShipping)
  }

  const onGetAllShippingTypesFail = () => {}

  const loadAllShippingTypes = () => {
    callGetApi(
      true,
      `/upselling/subscription_types`,
      onGetAllShippingTypesSuccess,
      onGetAllShippingTypesFail
    )
  }

  const onGetAutoshipProductDetailsSuccess = data => {
    setAutoshipCriteriaProducts(prevProducts => [...prevProducts, data])
  }

  const onGetAutoshipProductDetailsFail = () => {}

  const loadAutoshipProductDetails = id => {
    callGetApi(
      true,
      `/upselling/products/${id}`,
      onGetAutoshipProductDetailsSuccess,
      onGetAutoshipProductDetailsFail
    )
  }

  const onGetAutoshipCriteriaSuccess = data => {
    if (data?.products?.length > 0) {
      data?.products?.forEach(el => {
        if (el.giftProduct) {
          loadAutoshipProductDetails(el.giftProduct)
        }
        loadAutoshipProductDetails(el.productId)
      })
    }
    validationType.setValues({
      ...validationType.values,
      subscriptionName: data?.subscriptionName,
      active: data?.active,
      threshold: data?.threshold,
    })
    setAutoshipCriteriaDetails(data)
  }

  const onGetAutoshipCriteriaFail = () => {}

  useEffect(() => {
    if (funnelId) {
      callGetApi(
        true,
        `/upselling/criterias/autoship/${funnelId}`,
        onGetAutoshipCriteriaSuccess,
        onGetAutoshipCriteriaFail
      )
    }
  }, [funnelId])

  useEffect(() => {
    if (
      autoshipCriteriaDetails?.products?.length > 0 &&
      autoshipCriteriaProducts?.length > 0
    ) {
      let allProducts = []
      autoshipCriteriaDetails?.products?.map(el => {
        let product = null
        let giftProduct = null
        autoshipCriteriaProducts?.map(ol => {
          if (ol?.id == el.productId) {
            product = ol
          }
          if (ol?.id == el.giftProduct) {
            giftProduct = ol
          }
        })

        let finalProduct = {
          ...product,
          priority: el?.priority,
          autoshipId: el?.id,
          giftProduct: {
            ...giftProduct,
          },
        }
        allProducts.push(finalProduct)
      })
      validationType.setValues({
        ...validationType.values,
        straightChangeProducts: allProducts,
      })
    }
  }, [autoshipCriteriaProducts?.length, autoshipCriteriaDetails])

  useEffect(() => {
    loadAllShippingTypes()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Funnels"}
            breadcrumbItem={
              autoshipCriteriaDetails?.id
                ? "Edit Autoship Criteria"
                : "Add Autoship Criteria"
            }
          />
          <div>
            <div
              className="back-button"
              onClick={() => {
                history(`/create-funnel?funnelId=${funnelId}&shop=${shop}`)
              }}
            >
              <i className="bx bx-arrow-back"></i>
            </div>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validationType.handleSubmit()
                return false
              }}
            >
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>Subscription</CardTitle>
                      <div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Subscription Name
                          </Label>
                          <select
                            name="subscriptionName"
                            className={`form-select ${
                              validationType.touched.subscriptionName &&
                              validationType.errors.subscriptionName
                                ? "is-invalid"
                                : ""
                            }`}
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.subscriptionName}
                          >
                            <option value="">Select Subscription Name</option>
                            {allShippingTypes?.map((el, ind) => {
                              return (
                                <option value={el.name} key={ind}>
                                  {el.name} - {el.title}
                                </option>
                              )
                            })}
                          </select>
                          {validationType.touched.subscriptionName &&
                          validationType.errors.subscriptionName ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.subscriptionName}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Subscription Threshold
                          </Label>
                          <Input
                            name="threshold"
                            type="number"
                            onChange={validationType.handleChange}
                            onBlur={validationType.handleBlur}
                            value={validationType.values.threshold}
                            invalid={
                              validationType.touched.threshold &&
                              validationType.errors.threshold
                                ? true
                                : false
                            }
                          />
                          {validationType.touched.threshold &&
                          validationType.errors.threshold ? (
                            <FormFeedback type="invalid">
                              {validationType.errors.threshold}
                            </FormFeedback>
                          ) : null}
                        </div>
                        {/* <div className="mb-3">
                          <Label className="form-label">Status</Label>
                          <select
                            name="active"
                            className={`form-select`}
                            onChange={event => {
                              validationType.setFieldValue(
                                event.target.name,
                                JSON.parse(event.target.value)
                              )
                            }}
                            onBlur={validationType.handleBlur}
                            value={validationType.values?.active?.toString()}
                          >
                            <option value={true.toString()}>Active</option>
                            <option value={false.toString()}>Inactive</option>
                          </select>
                        </div> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {validationType.values.straightChangeProducts?.length > 0 && (
                  <div>
                    {validationType.values.straightChangeProducts?.map(
                      (product, index) => {
                        return (
                          <Col lg={12} key={index}>
                            <Card>
                              <CardBody>
                                <CardTitle style={{ margin: 0 }}>
                                  Product #{index + 1}
                                </CardTitle>
                                <div>
                                  <Row>
                                    <Col xs={12} lg={12}>
                                      <div className="mt-3">
                                        <div
                                          className="selected-product-item"
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "97%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <img
                                                src={product.images?.[0]?.src}
                                              />
                                              <Label className="form-label">
                                                {product.title}
                                              </Label>
                                            </div>
                                            <div>
                                              <div>Priority</div>
                                              <Input
                                                type="number"
                                                value={product.priority || ""}
                                                placeholder="Priority"
                                                onChange={e =>
                                                  handlePriorityChange(
                                                    e,
                                                    product.id
                                                  )
                                                }
                                                style={{
                                                  width: "250px",
                                                }}
                                                invalid={
                                                  validationType.touched
                                                    .straightChangeProducts &&
                                                  validationType.errors
                                                    .straightChangeProducts &&
                                                  validationType.errors
                                                    .straightChangeProducts[
                                                    index
                                                  ]?.priority
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {validationType.touched
                                                .straightChangeProducts &&
                                              validationType.errors
                                                .straightChangeProducts &&
                                              validationType.errors
                                                .straightChangeProducts[index]
                                                ?.priority ? (
                                                <FormFeedback type="invalid">
                                                  {
                                                    validationType.errors
                                                      .straightChangeProducts[
                                                      index
                                                    ]?.priority
                                                  }
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <i
                                            className="mdi mdi-delete"
                                            style={{
                                              fontSize: 23,
                                              cursor: "pointer",
                                              position: "relative",
                                              top: "10px",
                                            }}
                                            onClick={() => {
                                              if (product?.autoshipId) {
                                                callDeleteApi(
                                                  true,
                                                  `/upselling/criterias/autoship/product/remove/${product?.autoshipId}?funnelId=${funnelId}`,
                                                  () => {},
                                                  () => {}
                                                )
                                                validationType.setFieldValue(
                                                  "straightChangeProducts",
                                                  validationType.values.straightChangeProducts.filter(
                                                    el => el.id !== product.id
                                                  )
                                                )
                                              } else {
                                                validationType.setFieldValue(
                                                  "straightChangeProducts",
                                                  validationType.values.straightChangeProducts.filter(
                                                    el => el.id !== product.id
                                                  )
                                                )
                                              }
                                            }}
                                          ></i>
                                        </div>
                                        {/* Gift Product Section */}
                                        {!isTruekindShop && (
                                          <div className="mt-3">
                                            <CardTitle
                                              style={{
                                                margin: "0px 0px 15px 0px",
                                              }}
                                            >
                                              Gift Product
                                            </CardTitle>
                                            {product.giftProduct ? (
                                              <div className="selected-product-item">
                                                <img
                                                  src={
                                                    product.giftProduct
                                                      .images?.[0]?.src
                                                  }
                                                />
                                                <Label className="form-label">
                                                  {product.giftProduct.title}
                                                </Label>
                                              </div>
                                            ) : (
                                              <Button
                                                color="primary"
                                                type="button"
                                                onClick={() => {
                                                  setCurrentParentProduct(
                                                    product
                                                  )
                                                  setModalMode("gift")
                                                  setIsOpenStraighChange(true)
                                                }}
                                              >
                                                Select Gift Product
                                              </Button>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )
                      }
                    )}
                  </div>
                )}
                <Col lg={12}>
                  <div className="offer-product-container">
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        setModalMode("parent")
                        setIsOpenStraighChange(true)
                      }}
                    >
                      Select A Product
                    </Button>
                  </div>
                </Col>
                <Col lg={12}>
                  <div>
                    <div className="d-flex flex-wrap gap-2 form-btn mt-4">
                      <Button type="submit" color="primary">
                        {isUpdateLoading ? (
                          <Spinner size={"sm"} className="mx-3 mb-0 mt-1" />
                        ) : autoshipCriteriaDetails?.id ? (
                          "Update"
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>
      </div>
      <Modal
        isOpen={isOpenStraighChange}
        toggle={() => {
          setIsOpenStraighChange(!isOpenStraighChange)
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {modalMode === "parent" ? "Add Product" : "Add Gift Product"}
          </h5>
          <button
            type="button"
            onClick={() => {
              setIsOpenStraighChange(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <SearchProducts
            setIsOpen={setIsOpenStraighChange}
            returnSaveProducts={returnSaveStraightChangeProducts}
            multiProducts={modalMode === "parent"}
          />
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AddAutoshipCriteria
