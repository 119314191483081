import GeneralButton from "./GeneralButton"
import GlobalScript from "./GlobalScript"
import Quiz from "./Quiz"
import TypeForm from "./TypeForm"
import LeadQuizzes from "./LeadQuizzes"
import PopupScript from "./PopupScript"
import Layout from "./Layout"
import HeroBanner from "./HeroBanner"
import DragYourComponent from "./DragYourComponent"
import VariantSelector from "./VariantSelector"
import ProductSelector from "./ProductSelector"
import Script from "./Script"
import CustomCode from "./CustomCode"

const CustomComponents = (editor, shop) => {
  Script(editor)
  CustomCode(editor)
  GeneralButton(editor)
  GlobalScript(editor)
  PopupScript(editor)
  Quiz(editor)
  TypeForm(editor)
  LeadQuizzes(editor)
  Layout(editor)
  HeroBanner(editor)
  DragYourComponent(editor)
  VariantSelector(editor, shop)
  ProductSelector(editor, shop)
}

export default CustomComponents
