import inherits from "inherits"
import ContextPadProvider from "bpmn-js/lib/features/context-pad/ContextPadProvider"

export default function CustomContextPadProvider(injector) {
  injector.invoke(ContextPadProvider, this)

  const cachedGetContextPadEntries = this.getContextPadEntries

  this.getContextPadEntries = function (element) {
    const actions = cachedGetContextPadEntries?.call(this, element)

    const removeEntries = [
      "append.intermediate-event",
      "append.text-annotation",
    ]

    const filteredActions = Object.keys(actions)
      .filter(actionKey => !removeEntries.includes(actionKey))
      .reduce((obj, key) => {
        obj[key] = actions[key]
        return obj
      }, {})

    return filteredActions
  }
}

inherits(CustomContextPadProvider, ContextPadProvider)

CustomContextPadProvider.$inject = ["injector"]
