const HurryBanner = ({blockManager}) => {
    blockManager.add("hurry-banner", {
        id: "hurry-banner",
        label: "Hurry Banner",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="max-width: 420px; width: 100%; margin: 0 auto; border-radius: 12px;">
        <div style="padding: 10px 0; background-color: #fff6ef; min-width: 280px; align-items: center; justify-content: center; gap: 0 4px; border-radius: 0.5rem; display: flex;">
          <label style="margin: 0; color: #292929; font-size: 14px; line-height: 18px; font-weight: 600;font-family: 'Arial';">
            <span style="display: flex; align-items: center;">
              <span>Hurry! This special sale ends in</span>
              <span style="margin-left: 5px;">
                <div>1m 53s</div>
              </span>
            </span>
          </label>
        </div>
      </div>
      `,
      })
}

export default HurryBanner