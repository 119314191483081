const HeroBanner = editor => {
    editor.DomComponents.addType("hero-banner", {
      isComponent: el => el.classList?.contains("hero-banner"),
      model: {
        defaults: {
          tagName: "div",
          style: {padding: '100px 0', position: 'relative', color: 'white'},
          classes: ["hero-banner"],
          styles: `
            .hero-banner__img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .hero-banner__content {
                width: 100%;
                padding: 0 2rem;
                text-align: center;
            }
          `,
          align: 'left',
          traits: [
            {
              type: "select",
              options: [
                {id:'left', name: 'Left'},
                {id:'center', name: 'Center'},
                {id:'right', name: 'Right'}],
              label: "Align",
              name: "align",
              changeProp: 1,
            },
          ],
          components: `
            <img class="hero-banner__img" src="https://ucarecdn.com/5e27d43d-eb1e-4e71-b7e1-4158f3071bb6/-/format/auto/" alt="Hero Banner">
            <div class="page-layout">
                <div class="page-layout__item">
                    <div class="hero-banner__content">
                        <h1 class="hero-banner__title">Hero Banner</h1>
                        <p class="hero-banner__subtitle">Hero banner description</p>
                    </div>
                </div>
                <div class="page-layout__item" />
            </div>
          `
        },
        getPageLayout() {
            return this.components().models[1]
        },
        addOrRemove(item, content) {
            if (content.trim()) {
                item.components(content)
                return
            }            
            
            item.components().models[0].remove()
        },
        switchContent(layout) {
            const leftItem = layout.components().models[0]
            const rightItem = layout.components().models[1]
            const collectionLeft = leftItem.getInnerHTML()
            const collectionRight = rightItem.getInnerHTML()

            this.addOrRemove(leftItem, collectionRight)
            this.addOrRemove(rightItem, collectionLeft)
            
        },
        mergeContent(layout) {
            const leftItem = layout.components().models[0]
            const rightItem = layout.components().models[1]
            const collectionLeft = leftItem.getInnerHTML()
            const collectionRight = rightItem.getInnerHTML()
            layout.components(`
                <div class="page-layout__item" style="grid-column: 1 / span 2">
                    ${collectionLeft + collectionRight}
                </div>
            `)
        },
        splitContent(layout, align) {
            const content = layout.components().models[0].getInnerHTML()

            layout.components(`
                <div class="page-layout__item">${align === 'left' ? content: ''}</div>
                <div class="page-layout__item">${align === 'right' ? content: ''}</div>
            `)
        },
        handleAlignChanged(props) {
            const {changed: {align}} = props
            const layout = this.getPageLayout()
            const layoutItems = layout.components()

            if (layoutItems.length > 1 && (align === 'left' || align === 'right')) {
                this.switchContent(layout)
            }
            if (layoutItems.length > 1 && align === 'center') {
                this.mergeContent(layout)
            }
            if (layoutItems.length < 2 && align !== 'center') {
                this.splitContent(layout, align)
            }
        },
        init() {
          const layout = this.getPageLayout()
          layout.removeTrait('columns')
          layout.set('removable', false)
          layout.set('copyable', false)
          layout.set('draggable', false)

          this.on('change:align', this.handleAlignChanged)
        },
      },
    })
  }
  
  export default HeroBanner
  