const config = {
  shapees: {
    logo: {
      url: "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@main/images/shapermint/shapermint_logo_black.svg",
    },
    orderBanner: {
      color: "black",
      bgColor: "#dfefeb",
    },
    banner: {
      color: "white",
      bgColor: "#c64844",
    },
    discountTitle: {
      color: "#c64844",
    },
    image: {
      url: "https://cdn.shopify.com/s/files/1/2679/8624/products/camis-tanks-black-s-offer-empetua-all-day-every-day-scoop-neck-cami-65-percent-off-29605521555590.jpg?v=1644250608",
    },
    exclusive: {
      color: "#29292999",
      fontWeight: 400,
    },
    banner2: {
      bgColor: "#fff6ef",
    },
    dealText: {
      bgColor: "#c64844",
      bgOffColor: "#c64844",
    },
    rememberText: {
      color: "#00000087",
    },
    yesButton: {
      borderRadius: "0.5rem",
      color: "#292929",
      bgColor: "#f7a08b",
      hoverBgColor: "#fcd9d1",
    },
  },
  truekind: {
    logo: {
      url: "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@main/images/truekind/tkd_logo_checkout.svg",
    },
    orderBanner: {
      color: "black",
      bgColor: "#f7f4f2",
    },
    banner: {
      color: "white",
      bgColor: "#cc4125",
    },
    discountTitle: {
      color: "#cc4125",
    },
    image: {
      url: "https://cdn.shopify.com/s/files/1/2679/8624/products/bra-black-s-truekind-modern-bra-wear-test-29267252936838.jpg?v=1644254067",
    },
    exclusive: {
      color: "black",
      fontWeight: 700,
    },
    banner2: {
      bgColor: "#fff6ef",
    },
    dealText: {
      bgColor: "#cc4125",
      bgOffColor: "#cc4125",
    },
    rememberText: {
      color: "black",
    },
    yesButton: {
      borderRadius: "1.5rem",
      color: "#FFFFFF",
      bgColor: "#CC4125",
      hoverBgColor: "#910F23",
    },
  },
  thespadr: {
    logo: {
      url: "https://cdn.shopify.com/s/files/1/0912/0596/files/thespadr_logo_new.svg?v=1696594843",
    },
    orderBanner: {
      color: "white",
      bgColor: "#457a41",
    },
    banner: {
      color: "black",
      bgColor: "#ba5350",
    },
    discountTitle: {
      color: "#ba5350",
    },
    image: {
      url: "https://cdn.shopify.com/s/files/1/0730/9131/8055/products/DOWNSELL_4a84c9e2-ad1c-4c7b-b20d-47d9ee5e0259.png?v=1697049006",
    },
    exclusive: {
      color: "#29292999",
      fontWeight: 400,
    },
    banner2: {
      bgColor: "transparent",
    },
    dealText: {
      bgColor: "#8BB948",
      bgOffColor: "#CCC485",
    },
    rememberText: {
      color: "#00000087",
    },
    yesButton: {
      borderRadius: "8px",
      color: "#fff",
      bgColor: "#457A41",
      hoverBgColor: "#D1E3CA",
    },
  },
  revel: {
    logo: {
      url: "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@main/images/revel/revelbeauty.svg",
    },
    orderBanner: {
      color: "black",
      bgColor: "#faf7f2",
    },
    banner: {
      color: "white",
      bgColor: "#2f806a",
    },
    discountTitle: {
      color: "#ba5350",
    },
    image: {
      url: "https://cdn11.bigcommerce.com/s-hzbce6z4ei/product_images/attribute_rule_images/41_source_1672253255.jpg",
    },
    exclusive: {
      color: "#29292999",
      fontWeight: 400,
    },
    banner2: {
      bgColor: "#fff6ef",
    },
    dealText: {
      bgColor: "#da918a",
      bgOffColor: "#da918a",
    },
    rememberText: {
      color: "black",
    },
    yesButton: {
      borderRadius: "30px",
      color: "#fff",
      bgColor: "#da918a",
      hoverBgColor: "#fcd9d1",
    },
  },
}

const shopConfigMap = {
  shapees: "shapees",
  "truekind-dev": "truekind",
  "truekind-beta": "truekind",
  "truekind-store": "truekind",
  "revel-dev": "revel",
  "revel-stage": "revel",
  revel: "revel",
  "thespadr-dev": "thespadr",
  "the-spa-dr": "thespadr",
}

const getConfig = (shop, prop) => {
  const normalizedShop = shopConfigMap[shop] || shop
  const shopConfig = config[normalizedShop]
  if (!shopConfig) {
    console.log(
      `No config found for shop: ${shop} (normalized to ${normalizedShop})`
    )
    return {}
  }
  return shopConfig[prop]
}

export default getConfig
