import React, { useState, useEffect } from "react"
import {
  Container,
  Table,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  Spinner,
  Modal,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { callGetApi } from "../../utils/api"
import "./index.scss"
import TaxEditIcon from "../../assets/images/taxes/tax-edit.svg"
import UploadModal from "./UploadModal"
import AddEditTaxModal from "./AddEditTaxModal"
import { initialShopEnv, allShopsEnv } from "../../utils/shop"

const Taxes = () => {
  document.title = "Taxes"
  const [countryFilter, setCountryFilter] = useState("US")
  const [stateFilter, setStateFilter] = useState("")
  const [zipcodeFilter, setZipcodeFilter] = useState("")
  const [productType, setProductType] = useState("all")
  const [allProductTypes, setAllProductTypes] = useState([])
  const [shop, setShop] = useState(initialShopEnv[process.env.REACT_APP_ENV])
  const [taxes, setTaxes] = useState([])
  const [allTaxes, setAllTaxes] = useState([])
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [editData, setEditData] = useState(null)
  const [nextToken, setNextToken] = useState("")

  const handleChangeCountryFilter = e => {
    setCountryFilter(e.target.value.toLocaleUpperCase())
  }

  const handleChangeStateFilter = e => {
    setStateFilter(e.target.value.toUpperCase())
  }

  const handleChangeZipCodeFilter = e => {
    setZipcodeFilter(e.target.value.toUpperCase())
  }

  const onGetTaxesSuccess = (data, isLoadMore) => {
    if (data?.next_token && data?.Taxes?.length == 0) {
      setNextToken(data?.next_token)
      loadTaxes(data?.next_token, isLoadMore)
      return
    }
    setNextToken(data?.next_token)
    if (isLoadMore) {
      console.log("ssss", data, taxes)
      setTaxes(prev => [...prev, ...processTaxes(data?.Taxes)])
      setAllTaxes(prev => [...prev, ...processTaxes(data?.Taxes)])
    } else {
      setTaxes(processTaxes(data?.Taxes))
      setAllTaxes(data?.Taxes)
    }
    setIsLoading(false)
  }

  const onGetTaxesFail = () => {
    setIsLoading(false)
  }

  const processTaxes = taxesJSON => {
    const processedTaxes = []

    taxesJSON.forEach(taxBundle => {
      const taxRow = {
        countryCode: taxBundle.country,
        stateCode: taxBundle.state,
        zipcode: taxBundle.zip_code,
        country: taxBundle.country,
        state: taxBundle.state,
        product_type: taxBundle.product_type,
        store: taxBundle.store,
      }
      const taxRowCategories = []
      taxBundle.taxes?.forEach(tax => {
        taxRowCategories.push({
          category: "Tax",
          name: tax.name,
          type: tax.type.toUpperCase(),
          amount: tax.amount,
          amountType: tax.amount_type,
          currency: tax.currency,
        })
      })
      taxBundle.additional_fees?.forEach(additionalFee => {
        taxRowCategories.push({
          category: "Additional Fee",
          name: additionalFee.name,
          type: additionalFee.type.toUpperCase(),
          amount: additionalFee.amount,
          amountType: additionalFee.amount_type,
          currency: additionalFee.currency,
        })
      })
      taxRow.categories = taxRowCategories
      processedTaxes.push(taxRow)
    })
    return processedTaxes
  }

  const loadTaxes = (nextToken_, isLoadMore) => {
    setIsLoading(true)
    let path = "/taxes"
    if (countryFilter) {
      path += `?country=${countryFilter}`
    }
    if (stateFilter) {
      path += `&state=${stateFilter}`
    }
    if (zipcodeFilter) {
      path += `&zip_code=${zipcodeFilter}`
    }
    if (nextToken_) {
      path += `&next_token=${encodeURIComponent(nextToken_)}`
    }
    path += `&store=${shop}&product_type=${productType}`
    callGetApi(
      true,
      path,
      data => onGetTaxesSuccess(data, isLoadMore),
      onGetTaxesFail,
      "taxes"
    )
  }

  useEffect(() => {
    loadTaxes(nextToken)
    if (process.env.REACT_APP_PRODUCT_TYPES) {
      let envTypes = process.env.REACT_APP_PRODUCT_TYPES
      let allTypes = envTypes?.split(",")?.map(el => {
        return {
          value: el,
          label: el
            ?.replaceAll("_", " ")
            ?.split(" ")
            ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
            ?.join(" "),
        }
      })
      setAllProductTypes(allTypes)
    }
  }, [])

  return (
    <>
      <UploadModal
        isOpen={openUploadModal}
        loadTaxes={loadTaxes}
        setOpen={setOpenUploadModal}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Taxes"} breadcrumbItem={"Taxes"} />
          <div className="tax-table-container">
            <Row>
              <Col lg={12}>
                <Card>
                  {isLoading ? (
                    <CardBody>
                      <div className="spinner-loader">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <div className="tax-filter-container">
                        <Row className="mb-3">
                          <Col lg={12}>
                            <Button
                              className="float-end"
                              color="success"
                              onClick={() => {
                                setIsOpen(true)
                                setEditData(null)
                              }}
                            >
                              <i className="mdi mdi-plus me-2" />
                              Add New Tax
                            </Button>
                            <Button
                              className="float-end me-2"
                              color="primary"
                              onClick={() => setOpenUploadModal(true)}
                              outline
                            >
                              <i
                                className="bx bx bx-cloud-upload me-2"
                                style={{
                                  fontSize: "20px",
                                  position: "relative",
                                  top: "5px",
                                  marginTop: "-3px",
                                }}
                              />
                              Upload
                            </Button>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={2}>
                            <div>
                              <Label
                                htmlFor="formrow-country-Input"
                                className="mb-1"
                              >
                                Country Code*
                              </Label>
                              <input
                                id="formrow-country-Input"
                                className="form-control"
                                type="search"
                                value={countryFilter}
                                onChange={handleChangeCountryFilter}
                                maxLength={3}
                              />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div>
                              <Label
                                htmlFor="formrow-country-Input"
                                className="mb-1"
                              >
                                Store*
                              </Label>
                              <select
                                className="form-control"
                                value={shop}
                                onChange={e => {
                                  setShop(e.target.value)
                                }}
                              >
                                {allShopsEnv[process.env.REACT_APP_ENV].map(
                                  option => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div>
                              <Label
                                htmlFor="formrow-country-Input"
                                className="mb-1"
                              >
                                Product Type*
                              </Label>
                              <select
                                className="form-control"
                                value={productType}
                                onChange={e => {
                                  setProductType(e.target.value)
                                }}
                              >
                                {allProductTypes?.map(el => {
                                  return (
                                    <option value={el.value}>
                                      {el?.label}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div>
                              <Label
                                htmlFor="formrow-state-Input"
                                className="mb-1"
                              >
                                State Code
                              </Label>
                              <input
                                id="formrow-zipcode-Input"
                                className="form-control"
                                type="search"
                                value={stateFilter}
                                onChange={handleChangeStateFilter}
                                maxLength={3}
                              />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div>
                              <Label
                                htmlFor="formrow-zipcode-Input"
                                className="mb-1"
                              >
                                Zip Code
                              </Label>
                              <input
                                id="formrow-zipcode-Input"
                                className="form-control"
                                type="search"
                                value={zipcodeFilter}
                                onChange={handleChangeZipCodeFilter}
                              />
                            </div>
                          </Col>
                          <Col md={2} lg={2} xl={1}>
                            <Button
                              className="form-control float-right"
                              style={{ marginTop: "1.9em" }}
                              color="primary"
                              onClick={() => loadTaxes("")}
                              disabled={!countryFilter}
                            >
                              Search
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-bordered mb-0 tax-table">
                          <thead>
                            <tr>
                              <th>Country Code</th>
                              <th>State Code</th>
                              <th>Zip Code</th>
                              <th>Product Type</th>
                              <th>Store</th>
                              <th>Category</th>
                              <th>Name</th>
                              <th>Type</th>
                              <th>Amount</th>
                              <th>Amount Type</th>
                              <th>Currency</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {taxes?.map((tax, index) => {
                              return (
                                <>
                                  <tr key={index}>
                                    <td
                                      rowSpan={tax.categories.length}
                                      className="centered-cell"
                                    >
                                      <div className="centered-content">
                                        {tax.country}
                                      </div>
                                    </td>
                                    <td
                                      rowSpan={tax.categories.length}
                                      className="centered-cell"
                                    >
                                      <div className="centered-content">
                                        {tax.state}
                                      </div>
                                    </td>
                                    <td
                                      rowSpan={tax.categories.length}
                                      className="centered-cell"
                                    >
                                      <div className="centered-content">
                                        {tax.zipcode}
                                      </div>
                                    </td>
                                    <td
                                      rowSpan={tax.categories.length}
                                      className="centered-cell"
                                    >
                                      <div className="centered-content">
                                        {tax.product_type}
                                      </div>
                                    </td>
                                    <td
                                      rowSpan={tax.categories.length}
                                      className="centered-cell"
                                    >
                                      <div className="centered-content">
                                        {tax.store}
                                      </div>
                                    </td>
                                    <td>{tax.categories[0].category}</td>
                                    <td>{tax.categories[0].name}</td>
                                    <td>{tax.categories[0].type}</td>
                                    <td>{tax.categories[0].amount}</td>
                                    <td>{tax.categories[0].amountType}</td>
                                    <td>{tax.categories[0].currency}</td>
                                    <td
                                      rowSpan={tax.categories.length}
                                      className="centered-cell"
                                    >
                                      <div className="centered-content">
                                        <img
                                          src={TaxEditIcon}
                                          alt=""
                                          height="22"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setEditData(allTaxes[index])
                                            setIsOpen(true)
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  {tax.categories
                                    .slice(1)
                                    .map((category, categoryIndex) => {
                                      return (
                                        <tr key={categoryIndex}>
                                          <td>{category.category}</td>
                                          <td>{category.name}</td>
                                          <td>{category.type}</td>
                                          <td>{category.amount}</td>
                                          <td>{category.amountType}</td>
                                          <td>{category.currency}</td>
                                        </tr>
                                      )
                                    })}
                                </>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      {nextToken && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 30,
                          }}
                        >
                          <Button
                            color="primary"
                            onClick={() => {
                              loadTaxes(nextToken, true)
                            }}
                          >
                            Load More...
                          </Button>
                        </div>
                      )}
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            setIsOpen(!isOpen)
          }}
          size="xl"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {editData ? "Edit" : "Add New "} Tax
            </h5>
            <button
              type="button"
              onClick={() => {
                setIsOpen(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AddEditTaxModal
              setIsOpen={setIsOpen}
              loadTaxes={loadTaxes}
              editData={editData}
            />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Taxes
