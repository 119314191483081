import GrapesjsPluginExport from "grapesjs-plugin-export"

export default {
  "gjs-blocks-basic": { flexGrid: true },
  "grapesjs-tui-image-editor": {
    script: [
      // 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/1.6.7/fabric.min.js',
      "https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js",
      "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js",
      "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js",
    ],
    style: [
      "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css",
      "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css",
    ],
  },
  "grapesjs-tabs": {
    tabsBlock: { category: "Extra" },
  },
  "grapesjs-typed": {
    block: {
      category: "Extra",
      content: {
        type: "typed",
        "type-speed": 40,
        strings: ["Text row one", "Text row two", "Text row three"],
      },
    },
  },
  "grapesjs-preset-webpage": {
    modalImportTitle: "Import Template",
    modalImportLabel:
      '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
    modalImportContent: function (editor) {
      return editor.getHtml() + "<style>" + editor.getCss() + "</style>"
    },
  },
  [GrapesjsPluginExport]: {
    root(editor) {
      const all = editor.Pages.getAll()
      const pages = {}
      const css = {}
      all.map(page => {
        const pageOriginalName = page.get("isIndex")
          ? "index"
          : page.get("name") || page.id

        const hasSwiper = page.get("hasOfferGallery") || page.get("hasQuiz")
        const pageName = pageOriginalName.replace(/ /g, "-").toLowerCase()
        const component = page.getMainComponent()

        pages[pageName + ".html"] = `
          <!doctype html>
          <html lang="en">
          <head>
          <meta charset="utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          ${
            hasSwiper
              ? `<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css">`
              : ""
          }
          ${
            hasSwiper
              ? `<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js"></script>`
              : ""
          }
          <link rel="stylesheet" href="./css/style-${pageName}.css">
          </head>
          <body>
          ${editor.getHtml({ component })}
          </body>
          </html>`
        css["style-" + pageName + ".css"] = editor.getCss({
          component,
          keepUnusedStyles: 1,
        })
      })
      return {
        css: {
          ...css,
        },
        ...pages,
      }
    },
  },
}
