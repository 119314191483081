import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Label, Input } from "reactstrap"

const ClubSettings = ({
  validationType,
  selectedOption,
  setSelectedOption,
  offerIndex,
}) => {
  const handleRadioChange = event => {
    const { value } = event.target
    validationType.setValues({
      ...validationType.values,
      clubShipping: {
        subscription_name: "",
      },
      clubPlacement: {
        version: "v1",
        placement: "coupon",
        subscription_name: "",
      },
    })
    setSelectedOption(value)
  }

  const handleClubShippingInputChange = event => {
    const { value } = event.target
    validationType.setFieldValue("clubShipping.subscription_name", value)
  }

  const handleClubPlacementInputChange = event => {
    const { name, value } = event.target
    validationType.setFieldValue(`clubPlacement.${name}`, value)
  }
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Club Settings</CardTitle>
                <div className="d-flex">
                  <div className="form-check countries-checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="clubSettings"
                      value="none"
                      checked={selectedOption === "none"}
                      onChange={e => handleRadioChange(e)}
                    />
                    <label className="form-check-label">None</label>
                  </div>
                  <div className="form-check countries-checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="clubSettings"
                      value="shipping"
                      checked={selectedOption === "shipping"}
                      onChange={e => handleRadioChange(e)}
                    />
                    <label className="form-check-label">Shipping</label>
                  </div>
                  <div className="form-check countries-checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="clubSettings"
                      value="placement"
                      checked={selectedOption === "placement"}
                      onChange={e => handleRadioChange(e)}
                    />
                    <label className="form-check-label">Placement</label>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {selectedOption == "shipping" || selectedOption == "placement" ? (
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>
                    Club{" "}
                    {selectedOption === "shipping" ? "Shipping" : "Placement"}
                  </CardTitle>
                  <div>
                    <Row>
                      {selectedOption === "shipping" && (
                        <Col lg={12}>
                          <div>
                            <Label className="form-label">
                              Subscription Name
                            </Label>
                            <Input
                              name={`clubShipping.subscription_name`}
                              placeholder="Enter Subscription Name"
                              type="text"
                              value={
                                validationType.values.clubShipping
                                  .subscription_name
                              }
                              onChange={e =>
                                handleClubShippingInputChange(e, offerIndex)
                              }
                            />
                          </div>
                        </Col>
                      )}
                      {selectedOption == "placement" && (
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Subscription Name
                            </Label>
                            <Input
                              placeholder="Enter Subscription Name"
                              type="text"
                              name={`subscription_name`}
                              value={
                                validationType.values.clubPlacement
                                  .subscription_name
                              }
                              onChange={e =>
                                handleClubPlacementInputChange(e, offerIndex)
                              }
                            />
                          </div>
                        </Col>
                      )}
                      {selectedOption == "placement" && (
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">Club Version</Label>
                            <select
                              className="form-select"
                              name={`version`}
                              value={
                                validationType.values.clubPlacement.version
                              }
                              onChange={e =>
                                handleClubPlacementInputChange(e, offerIndex)
                              }
                            >
                              <option value={"v1"}>v1</option>
                              <option value={"v2"}>v2</option>
                            </select>
                          </div>
                        </Col>
                      )}

                      {selectedOption == "placement" && (
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">Club Placement</Label>
                            <select
                              className="form-select"
                              name={`placement`}
                              value={
                                validationType.values.clubPlacement.placement
                              }
                              onChange={e =>
                                handleClubPlacementInputChange(e, offerIndex)
                              }
                            >
                              <option value={"coupon"}>Coupon</option>
                              <option value={"billingaddress"}>
                                Billing Address
                              </option>
                            </select>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
    </div>
  )
}

export default ClubSettings
