import { Row, Col, Card, CardBody, CardTitle, Label, Input } from "reactstrap"

const Pricing = ({ validationType, variations, setVariations }) => {
  const regex = /^[0-9.]*$/ // // Regular expression to match numbers and "."
  return (
    <div>
      <Row>
        <Col lg={12}>
          <div className="mb-3">
            <Label className="form-label">Price</Label>
            <Input
              name="price"
              placeholder="Product price"
              value={validationType.values.price || ""}
              onChange={e => {
                if (regex.test(e.target.value)) {
                  validationType.setFieldValue("price", e.target.value)
                }
              }}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Compare at price</Label>
            <Input
              name="compare_at_price"
              placeholder="Compare at price"
              value={validationType.values.compare_at_price || ""}
              onChange={e => {
                if (regex.test(e.target.value)) {
                  validationType.setFieldValue(
                    "compare_at_price",
                    e.target.value
                  )
                }
              }}
            />
          </div>

          <div className="mb-3">
            <CardTitle className="h4">Variations</CardTitle>
          </div>
          <div className="mb-3">
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th className="text-center">Title</th>
                        <th className="text-center">Default</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Compare at price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {variations.map((variation, index) => (
                        <tr key={index}>
                          <td style={{ padding: "0.5rem" }}>
                            <div
                              className="selected-product-item-list"
                              key={index}
                            >
                              <img src={variation.images?.[0]?.src} />
                              <b>{variation.title}</b>
                            </div>
                          </td>

                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <Input
                              className="form-check-input display-checkbox"
                              type="checkbox"
                              style={{
                                height: "18px",
                                width: "18px",
                              }}
                              id={index}
                              onChange={() => {
                                const updatedVariations = [...variations]
                                updatedVariations[index].default_prices =
                                  !updatedVariations[index].default_prices
                                setVariations(updatedVariations)
                              }}
                              checked={variation.default_prices}
                            />
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {variation.default_prices ? (
                              <b
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                Default
                              </b>
                            ) : (
                              <Input
                                name="compare"
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Price"
                                value={variation.price || ""}
                                disabled={variation.default_prices}
                                onChange={e => {
                                  if (regex.test(e.target.value)) {
                                    const updatedVariations = [...variations]
                                    updatedVariations[index].price =
                                      e.target.value

                                    setVariations(updatedVariations)
                                  }
                                }}
                              />
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {variation.default_prices ? (
                              <b>Default</b>
                            ) : (
                              <Input
                                onChange={e => {
                                  if (regex.test(e.target.value)) {
                                    const updatedVariations = [...variations]
                                    updatedVariations[index].compare_at_price =
                                      e.target.value
                                    setVariations(updatedVariations)
                                  }
                                }}
                                name="compare"
                                placeholder="Compare at price"
                                value={variation.compare_at_price || ""}
                                disabled={variation.default_prices}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Pricing
