import { useEffect, useState } from "react"
import { AiFillSave } from "react-icons/ai"
import {
  BsCloudUpload, BsFillPencilFill, BsFillTrashFill, BsXCircle
} from "react-icons/bs"
import swal from "sweetalert"
import { callDeleteApi, callPatchApi } from "utils/api"

const SubscriptionItem = ({
                            item, onRefetchItems, onItemUpdated, onItemRemoved, isDisabled = false
                          }) => {
  const [isEditable, setIsEditable] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [inputItem, setInputItem] = useState(item)

  const handleChangeItem = (event) => {
    setInputItem({
      ...inputItem,
      [event.target.name]: event.target.type === "number" ? Number(event.target.value) : event.target.value
    })
  }

  const onRequestFail = (error) => {
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 404:
          throw new Error("Sorry! the page you are looking for could not be found")
        case 500:
          throw new Error("Sorry! something went wrong, please contact our support team")
        case 401:
          throw new Error("Invalid credentials")

        default:
          throw new Error(`Status: ${error.response.status} Error: ${error.message}`)
      }
    }
    setIsLoading(false)
    throw error
  }

  const onUpdateSubscriptionItemFail = (error) => {
    swal({
      title: "Oops!", text: "Something went wrong updating product!", icon: "error"
    })
    onRequestFail(error)
  }

  const onUpdateSubscriptionItemSuccess = () => {
    swal({
      title: "Updated!", text: "The product has been updated!", icon: "success"
    })

    setIsUpdated(false)
    console.log("update sucess")
    if (typeof onRefetchItems === "function") {
      console.log("refetch")
      onRefetchItems(inputItem.subscription_id)
    }
    if (typeof onItemUpdated === "function") {
      console.log("itemupdated")
      onItemUpdated(item, inputItem)
    }
    setIsLoading(false)
  }

  const updateSubscriptionItem = async (subscriptionId, subscriptionItemId, subscriptionItem) => {
    console.log("entrou update")
    callPatchApi(true, `/subscriptions/${subscriptionId}/subscription_items/${subscriptionItemId}`, subscriptionItem, onUpdateSubscriptionItemSuccess, onUpdateSubscriptionItemFail, "auth")
  }

  const handleUpdateItem = async () => {
    console.log("entrou handle")
    setIsLoading(true)
    const confirm = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to update this product?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true
    })

    if (!confirm) {
      setIsLoading(false)
      return
    }

    const payload = {
      price: inputItem.price, quantity: inputItem.quantity
    }

    updateSubscriptionItem(inputItem.subscription_id, inputItem.id, payload)
  }

  const onDeleteSubscriptionItemFail = (error) => {
    swal("Oops!", "Something went wrong removing product!", "error")
    onRequestFail(error)
  }

  const onDeleteSubscriptionItemSuccess = () => {
    if (typeof onRefetchItems === "function") {
      onRefetchItems(inputItem.subscription_id)
    }
    if (typeof onItemRemoved === "function") {
      onItemRemoved(inputItem)
    }
    swal({
      title: "Deleted!", text: "The product has been deleted!", icon: "success"
    })

    setIsLoading(false)
  }

  const deleteSubscriptionItem = async (subscriptionId, subscriptionItemId) => {
    callDeleteApi(true, `/subscriptions/${subscriptionId}/subscription_items/${subscriptionItemId}`, onDeleteSubscriptionItemSuccess, onDeleteSubscriptionItemFail, "auth")
  }

  const handleDeleteItem = async () => {
    if (isDisabled) {
      return
    }

    setIsLoading(true)
    const confirm = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to remove this product?",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true
    })

    if (!confirm) {
      setIsLoading(false)
      return
    }

    deleteSubscriptionItem(inputItem.subscription_id, inputItem.id)
  }

  const handleSaveChanges = () => {
    setIsEditable(false)
    setIsUpdated(Object.keys(inputItem).reduce((hasChangedItem, keyOfInputItem) => hasChangedItem ? hasChangedItem : inputItem[keyOfInputItem] !== item[keyOfInputItem], false))
  }

  const handleRestoreChanges = () => {
    setIsEditable(false)
    setInputItem(item)
    setIsUpdated(false)
  }

  const handleShowEdition = () => {
    if (isDisabled) {
      return
    }
    setIsEditable(true)
  }

  useEffect(() => {
    setInputItem(item)
  }, [item])

  return (<tr>
    <td>
      <div className="btn-group">
        <button
          title="remove"
          disabled={isLoading}
          onClick={event => (!isLoading ? handleDeleteItem(event) : null)}
          className="btn btn-danger"
        >
          <BsFillTrashFill />
        </button>
        {isEditable ? (<>
          <button
            title="save"
            disabled={isLoading}
            onClick={event => !isLoading ? handleSaveChanges(event) : null}
            className="btn btn-primary"
          >
            <AiFillSave />
          </button>
          <button
            title="restore"
            disabled={isLoading}
            onClick={event => !isLoading ? handleRestoreChanges(event) : null}
            className="btn btn-secondary"
          >
            <BsXCircle />
          </button>
        </>) : (<button
          title="edit"
          disabled={isLoading}
          onClick={event => (!isLoading ? handleShowEdition(event) : null)}
          className="btn btn-info"
        >
          <BsFillPencilFill />
        </button>)}
        {isUpdated ? (<button
          title="update"
          disabled={isLoading}
          onClick={event => (!isLoading ? handleUpdateItem(event) : null)}
          className="btn btn-success"
        >
          <BsCloudUpload />
        </button>) : null}
      </div>
    </td>
    <td>
      <span className="text-muted mb-0">{item.id}</span></td>
      <td>
        <span className="text-muted mb-0">{item.product?.name || ""}</span>
    </td>
    <td>
      {isEditable ? (<form
        onSubmit={event => {
          event.preventDefault()
          handleSaveChanges(event)
        }}
      >
        <input
          value={inputItem.price}
          name="price"
          type="number"
          onChange={handleChangeItem}
          className="form-control"
        />
      </form>) : (<span className="text-muted mb-0">{inputItem.price}</span>)}
    </td>
    <td>
      <span className="text-muted mb-0">{inputItem.compare_at_price}</span>
    </td>
    <td>
      <span className="text-muted mb-0">{inputItem.sku}</span>
    </td>
    <td>
      {isEditable ? (<form
        onSubmit={event => {
          event.preventDefault()
          handleSaveChanges(event)
        }}
      >
        <input
          value={inputItem.quantity}
          name="quantity"
          type="number"
          onChange={handleChangeItem}
          className="form-control"
        />
      </form>) : (<span className="text-muted mb-0">{inputItem.quantity}</span>)}
    </td>
    <td>
      {isEditable ? (<form
        onSubmit={event => {
          event.preventDefault()
          handleSaveChanges(event)
        }}
      >
        <input
          value={inputItem.product_id}
          name="product_id"
          type="text"
          onChange={handleChangeItem}
          className="form-control"
        />
      </form>) : (<span className="text-muted mb-0">{inputItem.product_id}</span>)}
    </td>
    <td>
      {isEditable ? (<form
        onSubmit={event => {
          event.preventDefault()
          handleSaveChanges(event)
        }}
      >
        <input
          value={inputItem.variant_id}
          name="variant_id"
          type="text"
          onChange={handleChangeItem}
          className="form-control"
        />
      </form>) : (<span className="text-muted mb-0">{inputItem.variant_id}</span>)}
    </td>
  </tr>)
}

export default SubscriptionItem
