import React from "react"
import ReactDOM from "react-dom"
import { DndProvider } from "react-dnd"
import { QuestionList, AnswerList } from "./components"
import { QuizEditorProvider } from "./state"
import ModifiedBackend from "./Html5Backend"
import createStyles from "./styles"

const Component = ({ data }) => (
  <div
    style={{
      width: "100%",
      minHeight: "55vh",
      display: "flex",
    }}
  >
    <AnswerList />
    <div style={{ backgroundColor: "#372E30", width: 1 }} />
    <QuestionList data={data} />
  </div>
)

const QuizEditor = () => (
  <QuizEditorProvider>
    <DndProvider key={1} backend={ModifiedBackend}>
      <Component />
    </DndProvider>
  </QuizEditorProvider>
)

class QuizEditorWc extends HTMLElement {
  constructor() {
    super()
    // Do something more
  }

  connectedCallback() {
    // Create a mount element
    const mountPoint = document.createElement("div")

    if (!this.shadowRoot) {
      // Create a ShadowDOM
      const root = this.attachShadow({ mode: "open" })

      root.appendChild(mountPoint)
      root.adoptedStyleSheets = [createStyles()]
    }

    // const data = JSON.parse(this.getAttribute('data'))
    // console.log(data)

    // You can directly use shadow root as a mount point
    ReactDOM.render(<QuizEditor />, mountPoint)
  }
}

customElements.define("quiz-editor", QuizEditorWc)
