const GeneralButton = (editor) => {
    editor.DomComponents.addType('GeneralButton', {
      isComponent: el => el.tagName === 'BUTTON',
      extend: 'button',
      model: {
        init() {
          return null
        }
      }
    })
}

export default GeneralButton