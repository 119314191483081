const Container = () => (
  <div>
    <div className="panel__top">
      <div className="panel__basic-actions"></div>
    </div>
    <div id="gjs"></div>
  </div>
)

export default Container
