import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Card,
  CardBody,
  Button,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  CardTitle,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Pricing from "./Sections/Pricing"
import "./product-pricing.scss"
import { useFormik } from "formik"
import {
  callGetApi,
  callPutPricingApi,
  callPostApi,
  callDeleteApi,
} from "utils/api"
import { mapCountryCodeToCurrencyCode } from "utils/currency"
import { showSuccessToastr, showErrorToastr } from "components/Common/toastr"
import { useParams } from "react-router-dom"
import Discounts from "./Sections/Discounts"
import BundleDiscounts from "./Sections/BundleDiscounts"

const ProductPricing = () => {
  document.title = "Product pricing"
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [isDiscountLoading, setIsDiscountLoading] = useState(false)
  const [isDeleteDiscountLoading, setIsDeleteDiscountLoading] = useState(false)

  const { productId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [productError, setProductError] = useState(false) // This is a workaround to avoid the error "Failed to fetch product pricing, please refresh the page!" when the page is loaded
  const [variations, setVariations] = useState([])
  const [childrenData, setChildrenData] = useState({
    children: [],
    commonDiscount: null,
  })
  const [country, setCountry] = useState("US")
  const [currency, setCurrency] = useState("USD")
  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      images: [],
      product_id: "",
      variation_id: null,
      country: "US",
      price: 0,
      is_fallback_discount: false,
      is_fallback_price: false,
      slug: "",
      compare_at_price: 0,
      currency: "USD",
      discounts: null,
      bundle_discounts: [],
      volume_discounts: [],
      isParent: false,
    },

    onSubmit: values => {
      setIsUpdateLoading(true)
      let payload = []
      let validValues = values.price && values.compare_at_price
      values.price = parseFloat(values.price)
      values.compare_at_price = parseFloat(values.compare_at_price)
      const defaultPrice = {
        price: values.price,
        compare_at_price: values.compare_at_price,
        currency: currency,
        variation_id: null,
      }
      payload.push(defaultPrice)

      variations.forEach(variation => {
        validValues =
          validValues && variation.price && variation.compare_at_price
        const variationPayload = {
          variation_id: variation.id,
          currency: currency,
        }

        if (!variation.default_prices) {
          variationPayload.price = parseFloat(variation.price)
          variationPayload.compare_at_price = parseFloat(
            variation.compare_at_price
          )
        } else {
          variationPayload.price = values.price
          variationPayload.compare_at_price = values.compare_at_price
        }
        payload.push(variationPayload)
      })

      if (!validValues) {
        showErrorToastr("Prices cannot be empty")
        setIsUpdateLoading(false)
      } else {
        callPutPricingApi(
          true,
          `/product/${productId}/pricing?country=${country}`,
          payload,
          onUpdateProductlSuccess,
          onUpdateProductFail
        )
      }
    },
  })

  const getChildren = () => {
    callGetApi(
      true,
      `/product/${productId}/children/pricing?country=${country}`,
      data => onGetChildrenSuccess(data),
      onGetChildrenFail
    )
  }

  const onGetChildrenSuccess = data => {
    // Create an array that has all the same elements as data, but in each element, we add a new key "free_product" with value "false"
    let bundle_discounts = data.commonDiscount
    if (bundle_discounts) {
      const children = data.children.map(child => {
        return {
          ...child,
          free_product:
            bundle_discounts.length === 0
              ? false
              : bundle_discounts.definition[child.product_id] === 100,
        }
      })
      setChildrenData({ ...data, children: children })
    } else {
      setChildrenData({ ...data, children: data.children })
    }

    validationType.setFieldValue(
      "bundle_discounts",
      bundle_discounts ? [bundle_discounts] : []
    )
    setIsLoading(false)
  }
  const onGetChildrenFail = e => {
    setIsLoading(false)

    showErrorToastr(e)
  }

  const getPricing = () => {
    setIsLoading(true)
    callGetApi(
      true,
      `/product/pricing/?product_id=${productId}&country=${country}`,
      data => onGetProductPricingSucces(data),
      onGetProductFail
    )
  }

  const onUpdateProductlSuccess = () => {
    showSuccessToastr("Product Updated Successfully!")
    getPricing()
    setIsUpdateLoading(false)
  }

  const onUpdateProductFail = e => {
    setIsUpdateLoading(false)
    showErrorToastr(e)
  }

  const onGetProductPricingSucces = data => {
    setProductError(false)

    validationType.setValues({
      ...validationType.values,
      title: data.title,
      images: data.images,
      price: data.default.price,
      compare_at_price: data.default.compare_at_price,
      is_fallback_discount: data.is_fallback_discount,
      is_fallback_price: data.is_fallback_price,
      slug: data.slug,
      discounts: data.default.discounts,
      bundle_discounts: data.default.bundle_discounts,
      volume_discounts: data.default.volume_discounts,

      product_id: data.product_id,
      isParent: data.isParent,
    })

    setActiveTab(data.isParent ? "3" : activeTab)
    setVariations(data.variations)
    if (data.isParent) {
      //get children and set it
      getChildren()
    } else {
      setIsLoading(false)
    }
  }

  const onGetProductFail = () => {
    setProductError(true)
    setIsLoading(false)
    showErrorToastr("Failed to fetch product pricing")
  }

  const updateDiscount = discounts => {
    setIsDiscountLoading(true)
    callPutPricingApi(
      true,
      `/product/${productId}/discount/${
        validationType.values.volume_discounts?.length > 0
          ? validationType.values.volume_discounts[0].id
          : null
      }?country=${country}`,
      discounts,
      onUpdateDiscountSuccess,
      onUpdateDiscounttFail
    )
  }
  const onUpdateDiscounttFail = () => {
    setIsDiscountLoading(false)
    showErrorToastr("Failed to update product discount")
  }

  const onUpdateDiscountSuccess = () => {
    getPricing()
    setIsDiscountLoading(false)

    showSuccessToastr("Product discount updated!")
  }

  const createDiscount = discounts => {
    setIsDiscountLoading(true)
    callPostApi(
      true,
      `/product/discounts/`,
      discounts,
      onCreateDiscounttSuccess,
      onCreateDiscountFail
    )
  }

  const onCreateDiscountFail = () => {
    setIsDiscountLoading(false)
    showErrorToastr("Failed to create product discount")
  }

  const onCreateDiscounttSuccess = () => {
    getPricing()
    setIsDiscountLoading(false)
    showSuccessToastr("Product discount created!")
  }

  const deleteDiscount = discountId => {
    setIsDeleteDiscountLoading(true)
    callDeleteApi(
      true,
      `/product/${productId}/discount/${discountId}?country=${country}`,
      ondeleteDiscountSuccess,
      onDeleteDiscounttFail
    )
  }
  const onDeleteDiscounttFail = () => {
    setIsDeleteDiscountLoading(false)
    showErrorToastr("Failed to delete product discount")
  }

  const ondeleteDiscountSuccess = () => {
    getPricing()
    setIsDeleteDiscountLoading(false)
    showSuccessToastr("Product discount deleted!")
  }

  useEffect(() => {
    getPricing()
  }, [country])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Product Information"}
            breadcrumbItem={"Product information"}
          />

          <>
            <div className="mb-3">
              <Label className="form-label">Title</Label>
              <div className="selected-product-item-list">
                <img src={validationType.values.images?.[0]?.src} />
                <a
                  active
                  href={
                    country === "US"
                      ? `${process.env.REACT_APP_STORE_URL}/products/${validationType.values.slug}`
                      : `${process.env.REACT_APP_STORE_URL}/en-${country}/products/${validationType.values.slug}`
                  }
                  target="_blank"
                >
                  {validationType.values.title}
                </a>
              </div>
            </div>
            <div className="mb-3">
              <Label className="form-label">Country</Label>
              <select
                name="country"
                className="form-select"
                onChange={e => {
                  setCountry(e.target.value)
                  setCurrency(mapCountryCodeToCurrencyCode(e.target.value))
                  validationType.setValues({
                    ...validationType.values,
                    country: e.target.value,
                    currency: mapCountryCodeToCurrencyCode(e.target.value),
                  })
                }}
              >
                <option value={"US"}>United States</option>
                <option value={"GB"}>United Kingdom</option>
                <option value={"CA"}>Canada</option>
                <option value={"AU"}>Australia</option>
                <option value={"NZ"}>New Zeland</option>
                <option value={"SG"}>Singapore</option>
              </select>
            </div>

            <div>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validationType.handleSubmit()
                  return false
                }}
              >
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        toggle("1")
                      }}
                    >
                      Pricing
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        toggle("2")
                      }}
                    >
                      Volume discounts
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        toggle("3")
                      }}
                      disabled={!validationType.values.isParent}
                    >
                      Bundle discounts
                    </NavLink>
                  </NavItem>
                </Nav>

                <Row>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px 0px 20px 0px",
                          }}
                        >
                          <Spinner size={"lg"} className="mx-3 mb-0 mt-1" />
                        </div>
                      ) : productError ? (
                        <b
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px 0px 20px 0px",
                          }}
                        >
                          Failed to fetch product pricing, please refresh the
                          page!{" "}
                        </b>
                      ) : (
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <CardTitle>
                                Pricing{" "}
                                {validationType.values.is_fallback_price && (
                                  <i className="mdi mdi-alpha-f-circle"></i>
                                )}
                              </CardTitle>
                              <Pricing
                                validationType={validationType}
                                variations={variations}
                                setVariations={setVariations}
                              />
                              <div>
                                <div className="d-flex flex-wrap gap-2 form-btn">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    disabled={country === "US"}
                                  >
                                    {isUpdateLoading ? (
                                      <Spinner
                                        size={"sm"}
                                        className="mx-3 mb-0 mt-1"
                                      />
                                    ) : (
                                      "Submit"
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                    </TabPane>

                    <TabPane tabId="2">
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px 0px 20px 0px",
                          }}
                        >
                          <Spinner size={"lg"} className="mx-3 mb-0 mt-1" />
                        </div>
                      ) : productError ? (
                        <b
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px 0px 20px 0px",
                          }}
                        >
                          Failed to fetch product discounts, please refresh the
                          page!{" "}
                        </b>
                      ) : (
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <CardTitle>
                                Volume discounts{" "}
                                {validationType.values.is_fallback_discount && (
                                  <i className="mdi mdi-alpha-f-circle"></i>
                                )}
                              </CardTitle>
                              <Discounts
                                validationType={validationType}
                                createDiscount={createDiscount}
                                updateDiscount={updateDiscount}
                                deleteDiscount={deleteDiscount}
                                country={country}
                                isDiscountLoading={isDiscountLoading}
                                isDeleteDiscountLoading={
                                  isDeleteDiscountLoading
                                }
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                    </TabPane>

                    <TabPane tabId="3">
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px 0px 20px 0px",
                          }}
                        >
                          <Spinner size={"lg"} className="mx-3 mb-0 mt-1" />
                        </div>
                      ) : productError ? (
                        <b
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px 0px 20px 0px",
                          }}
                        >
                          Failed to fetch product pricing, please refresh the
                          page!{" "}
                        </b>
                      ) : (
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <CardTitle>
                                {childrenData.commonDiscount
                                  ? "Edit bundle discount"
                                  : "Create bundle discount"}
                                {childrenData?.children.length > 0 &&
                                  childrenData?.children[0]
                                    .is_fallback_discount && (
                                    <i className="mdi mdi-alpha-f-circle"></i>
                                  )}{" "}
                              </CardTitle>
                              <BundleDiscounts
                                childrenData={childrenData}
                                setChildrenData={setChildrenData}
                                validationType={validationType}
                                createDiscount={createDiscount}
                                country={country}
                                deleteDiscount={deleteDiscount}
                                isDiscountLoading={isDiscountLoading}
                                isDeleteDiscountLoading={
                                  isDeleteDiscountLoading
                                }
                              ></BundleDiscounts>
                            </CardBody>
                          </Card>
                        </Col>
                      )}
                    </TabPane>
                  </TabContent>
                </Row>
              </Form>
            </div>
          </>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductPricing
