import React from "react"
import { Card, CardBody, Container, Row, Col } from "reactstrap"
import "./forbidden.scss"

const Forbidden = () => {
  document.title = "Unauthorized | Backoffice"

  const redirectToHelpDesk = () => {
    window.open("https://help.trafilea.com", "_self")
  }

  return (<>
    <div className="forbidden account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody className="pt-0 text-box">
                <div className="mt-3 d-flex">
                  <span>
                    Unauthorized. To gain access, please go to the help desk.
                  </span>
                </div>
              </CardBody>
              <CardBody className="pt-0">
                <div className="mt-3 d-grid">
                  <button
                    className="btn btn-primary btn-block help-desk-button"
                    type="button"
                    onClick={redirectToHelpDesk}
                  >
                    Help Desk
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </>)
}

export default Forbidden