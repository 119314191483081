import { CTAText } from "components/PageBuilder/utils"
import LayoutItem from "./LayoutItem"

const gridTemplateStyleProp = "grid-template-columns"
const gridGapStyleProp = "grid-gap"
const gridItemStyle = "minmax(20px,1fr)"

const Layout = editor => {
  LayoutItem(editor)
  editor.DomComponents.addType("layout", {
    isComponent: el => el.classList && el.classList.contains("page-layout"),
    model: {
      defaults: {
        tagName: "div",
        style: {
          "grid-template-columns": "minmax(20px,1fr)",
          "grid-gap": "10px",
        },
        classes: ["page-layout"],
        droppable: false,
        columns: 1,
        gap: 10,
        traits: [
          {
            name: "columns",
            label: "Columns",
            type: "number",
            min: 1,
            changeProp: 1,
          },
          {
            name: "gap",
            label: "Gap",
            type: "number",
            changeProp: 1,
          },
        ],
        styles: `
          .page-layout {
            position: relative;
            display: grid;
            width: 100%;
          }

          @media (max-width: 768px) {
            .page-layout {
              grid-template-columns: minmax(20px,12fr) !important;
            }
          }
        `,
        components: [{ type: "layout-item" }],
      },

      setGridStyles(prop, value) {
        const styles = this.getStyle()
        this.setStyle({
          ...styles,
          [prop]: value,
        })
      },

      getTemplateGridStyle() {
        const style = this.getStyle()
        return style[gridTemplateStyleProp]?.split(" ") ?? []
      },

      addColumnTrait() {
        const nextIndex = this.traits.length - 1
        this.addTrait({
          name: `column${nextIndex}`,
          label: `Column ${nextIndex}`,
          type: "number",
          min: 1,
          changeProp: 1,
        })

        if (this.get(`column${nextIndex}`) == null) {
          this.set(`column${nextIndex}`, 1)
        }
      },

      removeColumnTrait(index) {
        this.removeTrait(`column${index + 1}`)
      },

      updateColumnRatio(key, columnRatio) {
        const index = parseInt(key.replace("column", "")) - 1
        const columnStyle = this.getTemplateGridStyle()
        columnStyle[index] = `minmax(20px,${columnRatio}fr)`
        this.setGridStyles(gridTemplateStyleProp, columnStyle.join(" "))
      },

      handleColumns(columns) {
        const columnStyle = this.getTemplateGridStyle()
        const actualLength = this.components().length

        if (columns > actualLength) {
          for (let i = actualLength; i < columns; i++) {
            this.append('<div class="page-layout__item" />')
            this.addColumnTrait()
            columnStyle.push(gridItemStyle)
          }
        } else {
          for (let i = actualLength; i > columns; i--) {
            const index = i - 1
            this.getChildAt(index).remove()
            this.removeColumnTrait(index)
            columnStyle.pop()
          }
        }

        this.setGridStyles(gridTemplateStyleProp, columnStyle.join(" "))
      },

      handleGap(gap) {
        this.setGridStyles(gridGapStyleProp, `${gap}px`)
      },

      handleComponentsUpdate(...params) {
        const [, , data] = params
        const { index, action } = data
        if (action === "remove-component" || action === "clone-component") {
          const columnStyle = this.getTemplateGridStyle()
          if (action === "remove-component") {
            columnStyle.splice(index, 1)
            this.removeColumnTrait(index)
          }

          if (action === "clone-component") {
            columnStyle.push(gridItemStyle)
            this.addColumnTrait()
          }
          this.setGridStyles(gridTemplateStyleProp, columnStyle.join(" "))
          this.set("columns", this.components().length)
        }
      },

      handleChanged(event) {
        const { changed } = event
        if (changed.columns) this.handleColumns(changed.columns)
        if (changed.gap) this.handleGap(changed.gap)

        const ratioChanged = Object.keys(changed).filter(key =>
          /column\d+/.test(key)
        )
        if (ratioChanged.length) {
          ratioChanged.forEach(key => {
            this.updateColumnRatio(key, changed[key])
          })
        }
      },

      init() {
        if (!this.components().length)
          throw new Error("Layout must have at least one column")
        this.on("change", this.handleChanged)
        this.on("component:update:components", this.handleComponentsUpdate)
        this.components().forEach(() => this.addColumnTrait())
        this.set("columns", this.components().length)
      },
    },
    view: {
      domEvents: ["DOMNodeInserted", "DOMNodeRemoved"],
      removeMinHeight() {
        let hasColumnFilled = false
        this.el
          ?.querySelectorAll(".page-layout > .page-layout__item")
          .forEach(el => (hasColumnFilled ||= el.innerHTML !== CTAText()))
        if (!hasColumnFilled) this.el.style.height = "20vh"
        else this.el.style.height = "auto"
      },
      init() {
        this.removeMinHeight = this.removeMinHeight.bind(this)
        this.domEvents.forEach(event => {
          this.el.addEventListener(event, this.removeMinHeight)
        })
      },
      removed() {
        this.domEvents.forEach(event => {
          this.el.removeEventListener(event, this.removeMinHeight)
        })
      },
      onRender({ el }) {
        el.style["padding-top"] = "10px"
        el.style["padding-bottom"] = "10px"
        el.style.height = "20vh"
      },
    },
  })
}

export default Layout
