/* eslint-disable react/no-unknown-property */
/* eslint-disable no-undef */
import { modalButtons } from "components/PageBuilder/utils"
import { callGetApi } from "utils/api"
import {
  CAN_EDIT,
  SELECTED_PRODUCT_CONFIG,
} from "../../shared/ProductSelector/constants"
import "../../shared/ProductSelector"
import ProductOffer from "./ProductOffer"
import { VARIANT_SELECTOR_MODE } from "constants/variantSelectorMode"

const ProductSelector = (editor, shop) => {
  const loadExternalResource = window.loadExternalResource
  ProductOffer(editor)

  editor.DomComponents.addType("product-selector", {
    isComponent: el => el.classList?.contains("product-selector"),
    model: {
      defaults: {
        tagName: "div",
        classes: ["product-selector"],
        productList: [],
        slugs: [],
        loadExternalResource: loadExternalResource,
        styles: `
          .product-selector {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
          }
          .product-offer {
            cursor: pointer;
          }

          .product-offer.selected {
            border: 1px solid black;
            border-radius: 5px;
          }

          .buyNow {
            background-color: #f7a08b;
            color: black;
            padding: 0.875rem 2rem;
            border: none;
            border-radius: 0.5rem;
            font-size: 1rem;
            font-weight: 600;
            transition: background-color 0.3s ease-in-out;
            width: 100%;
            max-width: 500px;
            cursor: pointer;
          }

          .buyNow:hover {
            background-color: #fcd9d1;
          }
        `,
        "script-props": ["slugs", "loadExternalResource"],
        script: function ({ slugs, loadExternalResource }) {
          if (!slugs?.length) return

          function initLib() {
            const ids = [...this.querySelectorAll(".product-offer")].map(
              el => el.id
            )
            // eslint-disable-next-line no-undef
            ProductSelector.init({
              base: {
                currency: "USD",
                domain: "shapees.com",
                vendor: "shopify",
                store: "shapees",
                environment: "development",
              },
              products: {
                slugs,
                triggers: ids,
                elementsToMark: ids,
                extra_products: [],
                extras: [],
              },
              triggered_by: ".buyNow",
            })
          }

          if (typeof ProductSelector !== "undefined") {
            initLib()
            return
          }

          const loadExternalResourceFn = eval(loadExternalResource)
          loadExternalResourceFn?.(
            "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@1.7.0/js/products-script.js",
            "script",
            () =>
              loadExternalResourceFn?.(
                "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@1.7.0/js/checkout-script.js",
                "script",
                () =>
                  loadExternalResourceFn?.(
                    "https://cdn.jsdelivr.net/gh/trafilea/storefront-static@1.7.0/js/product-selector-script.js",
                    "script",
                    initLib.bind(this)
                  )
              )
          )
        },
      },
      removeProduct() {
        const wrapper = this.find(".drag-your-component")[0]
        wrapper.set("components", null)
        this.set("productList", [])
      },
      async getProductDetail(productId) {
        return new Promise((resolve, reject) =>
          callGetApi(true, `/upselling/products/${productId}`, resolve, reject)
        )
      },
      editOffer() {
        const { modal, content } = modalButtons(editor, "product-selector")
        const productList = this.get("productList")
        const config = {
          shop,
          disabledShop: true,
          multiProducts: true,
          products: productList,
          variantSelectorMode: VARIANT_SELECTOR_MODE.SHOULD_SELECT_VARIANT,
        }

        localStorage.setItem(SELECTED_PRODUCT_CONFIG, JSON.stringify(config))
        modal.open({
          title: "Product Selector",
          content,
        })

        editor.once("modal:close", async () => {
          let canEdit = localStorage.getItem(CAN_EDIT)
          let products = localStorage.getItem(SELECTED_PRODUCT_CONFIG)

          if (!products || !products.length || canEdit !== "true") {
            this.remove()
            return
          }

          localStorage.removeItem(CAN_EDIT)
          localStorage.removeItem(SELECTED_PRODUCT_CONFIG)
          products = JSON.parse(products)

          if (!products?.length) {
            this.remove()
            return
          }

          const hasProductsWithoutVariant = products.some(
            product => !product.selectVariant
          )

          if (hasProductsWithoutVariant) {
            alert("You should select a variant for each product")
            this.remove()
            return
          }

          if (!products || !products.length || !canEdit) {
            this.remove()
            return
          }

          this.components(null)

          this.append({
            type: "layout",
            components: products.map(product => ({
              type: "layout-item",
              components: {
                type: "product-offer",
                product,
              },
            })),
          })

          this.append(`<button class="buyNow">BUY NOW</button>`)

          this.set("productList", products)

          this.set(
            "slugs",
            products.map(product => product.slug)
          )
        })
      },
      init() {
        this.editOffer()
      },
    },
  })
}

export default ProductSelector
