const ImageShop = ({blockManager, url}) => {
    blockManager.add("image-shop", {
        id: "image-shop",
        label: "Image",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="max-width: 400px; width: 100%; margin: 0 auto; padding: 0 16px;">
        <img src="${url}" style="max-width: 100%; border-radius: 20px;">
      </div>`,
      })
}

export default ImageShop