import Question from "./Question"
import { defaultQuestionObj, createQuiz, QUESTIONS_KEY } from "./utils"
import { SubmitCheckHtml, SubmitCheckCss } from "./SubmitCheck"
import "./QuizEditor"
import {
  addBtnConfig,
  modalButtons,
  getLibEnv,
} from "components/PageBuilder/utils"
import { CAN_EDIT } from "components/PageBuilder/shared/ProductSelector/constants"

const SHOW_CHECK_PAGE = "Show Check Page"
const SHOW_QUIZ_PAGE = "Show Quiz Page"

const Quiz = editor => {
  const loadSideCarLib = window.loadSideCarLib
  Question(editor)

  editor.DomComponents.addType("quiz", {
    model: {
      defaults: {
        tagName: "div",
        classes: ["quiz"],
        useSideCar: null,
        environment: getLibEnv(),
        [QUESTIONS_KEY]: [defaultQuestionObj],
        loadSideCarLib: loadSideCarLib,
        "script-props": [
          "useSideCar",
          "environment",
          "loadExternalResource",
          "loadSideCarLib",
        ],
        styles: `
          ${SubmitCheckCss}
          .quiz {
              position: relative;
              height: 100vh;
              max-height: 500px;
              width: 100%;
          }
          .swiper-container {
              position: relative;
              overflow: hidden;
              transition: all 0.2s;
              z-index: 2;
              height: 100%;
          }
          .swiper-wrapper {
              height: 100%;
          }
          .swiper-container.hide {
              transform: translateX(-100%);
              opacity: 0;
          }
          .submit-check {
              position: absolute;
              z-index: 1;
              top: 0;
              opacity: 0;
              display: none;
              height: 100%;
              width: 100%;
              transition: all 0.2s;
              flex-direction: column;
              justify-content: center;
              align-items: center;
          }
          .submit-check.show {
              opacity: 1;
          }
          .swiper-slide {
              height: 100%;
              overflow-y: auto;
              overflow-x: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-bottom: 50px;
          }
          .swiper-pagination-bullet-active {
              background: #C54844
          }
          .nav-container {
              position: -webkit-sticky;
              position: sticky;
              bottom: 0;
              padding: 10px;
              background: #ffffffd5;
              z-index: 100;
          }
          .nav-direction-container {
              position: relative;
              margin-left: auto;
              display: flex;
              width: calc(30px * 2 + 1px);
              transition: background 0.3s;
              border-radius: 8px;
              overflow: hidden;
              z-index: 200;
          }
          .nav-direction-container div:nth-child(2) {
              height: 30px;
              width: 1px;
              background: #c45f5e;
          }
          .nav-quiz-button {
              background: #C54844;
              color: white;
              font-size: 20px;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
          }
          .nav-quiz-button:hover {
              cursor: pointer;
              background: #A63F3B;
          }
          .nav-quiz-button-next svg {
              fill: white;
              transform: rotate(90deg);
          }
          .nav-quiz-button-prev svg {
              fill: white;
              transform: rotate(-90deg);
          }
        `,
        components: `
          <div class="submit-check">
              ${SubmitCheckHtml}
          </div>
          <div class="swiper-container">
              <div class="swiper-wrapper"></div>
              <div class="nav-container">
                <div style="max-width: 855px; margin: auto">
                  <div class="swiper-pagination"></div>
                  <div class="nav-direction-container">
                      <div class="nav-quiz-button nav-quiz-button-prev">
                          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>
                      </div>
                      <div></div>
                      <div class="nav-quiz-button nav-quiz-button-next">
                          <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        `,
        script: function (props) {
          const { environment, useSideCar, loadSideCarLib } = props
          function initSwiper() {
            let results = {}
            // eslint-disable-next-line no-undef
            const swiper = new Swiper(".swiper-container", {
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
              navigation: {
                nextEl: ".nav-quiz-button-next",
                prevEl: ".nav-quiz-button-prev",
              },
            })

            swiper.on("slideChange", function (swiper) {
              const currentSlide = swiper.slides[swiper.activeIndex]
              currentSlide.scrollTo({ top: 0 })
            })

            const setupEvents = async () => {
              const { customEventEmitter, EventsEnum, setQuizValue } =
                useSideCar
                  ? // eslint-disable-next-line no-undef
                    await FunnelBuilderSidecar({
                      environment,
                    })
                  : {}

              useSideCar && customEventEmitter.emit(EventsEnum.QUIZ_STARTED)

              const answerItemInput =
                document.querySelectorAll(".answer-item-input")

              answerItemInput.forEach(input => {
                if (input.type === "radio" && !input.checked) return

                results[input.name] = input.value
              })

              const questionInputAccept = document.querySelectorAll(
                ".question-input-accept"
              )
              const questionInputSubmit = document.querySelector(
                ".question-input-submit"
              )

              const answerItemInputHandler = e => {
                results[e.target.name] = e.target.value

                if (
                  e.target.type === "radio" &&
                  swiper.activeIndex !== swiper.slides.length - 1
                ) {
                  setTimeout(() => swiper.slideNext(), 200)
                } else if (e.target.type === "radio") {
                  const currentSlide = swiper.slides[swiper.activeIndex]
                  currentSlide.scrollTo({ top: 100000, behavior: "smooth" })
                }
              }

              const questionInputAcceptEventCb = e => {
                const { name } = e.target
                  .closest(".question")
                  .querySelector(".answer-item-input")
                if (results[name]?.trim()) {
                  swiper.slideNext()
                  return
                }

                alert("Please enter your answer")
              }

              const questionInputSubmitEventCb = e => {
                const answerNodes = e.target
                  .closest(".quiz")
                  ?.querySelectorAll(".answer-item-input")

                for (const { name } of answerNodes) {
                  if (!results[name]) {
                    alert("Please answer all questions")
                    return
                  }
                }

                const submitCheck = e.target
                  .closest(".quiz")
                  .querySelector(".submit-check")
                const swiperContainer = e.target
                  .closest(".quiz")
                  .querySelector(".swiper-container")
                submitCheck.style.display = "flex"
                swiperContainer.classList.add("hide")
                setTimeout(() => {
                  swiperContainer.style.display = "none"
                  submitCheck.style.position = "relative"
                  submitCheck.classList.add("show")
                }, 200)

                if (useSideCar != null) {
                  customEventEmitter.emit(EventsEnum.QUIZ_COMPLETED)
                  setTimeout(() => {
                    setQuizValue(parseInt(results[useSideCar]) + 1)
                    customEventEmitter.emit(EventsEnum.NEXT_NODE)
                  }, [1500])
                }
              }

              answerItemInput.forEach(
                input => (input.onchange = answerItemInputHandler)
              )
              questionInputAccept.forEach(
                btn => (btn.onclick = questionInputAcceptEventCb)
              )
              questionInputSubmit.onclick = questionInputSubmitEventCb
            }

            eval(loadSideCarLib)(useSideCar, setupEvents)

            if (window.location !== window.parent.location) {
              const events = ["DOMNodeInserted", "DOMNodeRemoved"]
              events.forEach(event => {
                document
                  .querySelector(".swiper-container")
                  .addEventListener(event, function () {
                    setupEvents()
                    if (swiper) {
                      swiper.update()
                      swiper.slideTo(swiper.slides.length - 1, 200)
                      results = {}
                    }
                  })
              })
            }
          }
          initSwiper()
        },
      },
      createSlides(questions) {
        let htmlFromQuestions = ""
        questions.forEach((question, index) => {
          htmlFromQuestions += `
            <div class="swiper-slide question">
              ${createQuiz(question, index === questions.length - 1)}
            </div>
          `
        })

        this.get("components")
          .find(component =>
            component.classes.some(
              selector => selector.attributes.name === "swiper-container"
            )
          )
          .get("components")
          .find(component =>
            component.classes.some(
              selector => selector.attributes.name === "swiper-wrapper"
            )
          )
          .components(htmlFromQuestions)
      },
      setQuizFlag(value) {
        const currentPage = editor.Pages.getSelected()
        currentPage.set("hasQuiz", value)
      },
      init() {
        this.createSlides(this.get(QUESTIONS_KEY))
        this.setQuizFlag(true)
      },
      remove() {
        this.setQuizFlag(false)
      },
    },
    view: {
      tagName: "div",
      events: {
        "click .edit-btn": "editQuestion",
        "click .switch-btn": "switchLastPage",
      },
      addViewOptions(el) {
        const editBtn = addBtnConfig(null, "Edit Quiz", "edit-btn")
        const toggleBtn = addBtnConfig(null, SHOW_CHECK_PAGE, "switch-btn")

        const container = document.createElement("div")
        container.style = "display: flex; justify-content: flex-end;"
        container.appendChild(editBtn)
        container.appendChild(toggleBtn)
        el.appendChild(container)
      },
      editQuestion() {
        const { modal, content } = modalButtons(editor, "quiz-editor")
        localStorage.setItem(
          QUESTIONS_KEY,
          JSON.stringify(this.model.get(QUESTIONS_KEY))
        )

        modal.open({
          title: "Edit Quiz",
          content,
        })

        editor.once("modal:close", () => {
          let canEdit = localStorage.getItem(CAN_EDIT)
          let questionsFromEditor = localStorage.getItem(QUESTIONS_KEY)

          if (canEdit !== "true") return
          localStorage.removeItem(CAN_EDIT)

          if (!questionsFromEditor) return
          localStorage.removeItem(QUESTIONS_KEY)

          questionsFromEditor = JSON.parse(questionsFromEditor)

          this.model.createSlides(questionsFromEditor)

          const useSideCar =
            questionsFromEditor.find(question => question.sidecar)?.id ?? null
          this.model.set("useSideCar", useSideCar)
          this.model.set(QUESTIONS_KEY, questionsFromEditor)
        })
      },
      switchLastPage() {
        this.el.querySelector(".switch-btn").innerText === SHOW_CHECK_PAGE
          ? this.showCheckPage()
          : this.showQuizPage()
      },
      showCheckPage() {
        const submitCheck = this.el.querySelector(".submit-check")
        const swiperContainer = this.el.querySelector(".swiper-container")
        submitCheck.style.display = "flex"
        swiperContainer.classList.add("hide")
        setTimeout(() => {
          swiperContainer.style.display = "none"
          submitCheck.style.position = "relative"
          submitCheck.classList.add("show")
        }, 200)
      },
      showQuizPage() {
        const submitCheck = this.el.querySelector(".submit-check")
        const swiperContainer = this.el.querySelector(".swiper-container")
        submitCheck.classList.remove("show")
        setTimeout(() => {
          submitCheck.style.display = "none"
          submitCheck.style.position = "absolute"
          swiperContainer.style.display = "block"
          swiperContainer.classList.remove("hide")
        }, 200)
      },
      changeTextWhenSubmit() {
        const submitCheck = this.el.querySelector(".submit-check")
        const switchBtn = this.el.querySelector(".switch-btn")
        const observer = new MutationObserver(mutations => {
          mutations.forEach(({ target }) => {
            if (target.classList.contains("show"))
              switchBtn.innerText = SHOW_QUIZ_PAGE
            else switchBtn.innerText = SHOW_CHECK_PAGE
          })
        })
        observer.observe(submitCheck, {
          attributes: true,
          attributeFilter: ["class"],
        })
      },
      onRender({ el }) {
        this.addViewOptions(el)
        this.changeTextWhenSubmit()
      },
    },
  })
}

export default Quiz
