import grapesjs from "grapesjs"
import GjsBlocksBasic from "grapesjs-blocks-basic"
import GrapesjsPluginForms from "grapesjs-plugin-forms"
import GrapesjsComponentCountdown from "grapesjs-component-countdown"
import GrapesjsTabs from "grapesjs-tabs"
import GrapesjsCustomCode from "grapesjs-custom-code"
import GrapesjsTouch from "grapesjs-touch"
import GrapesjsTooltip from "grapesjs-tooltip"
import GrapesjsTuiImageEditor from "grapesjs-tui-image-editor"
import GrapesjsTyped from "grapesjs-typed"
import GrapesScriptEditor from "grapesjs-script-editor"
import GrapesjsPluginExport from "grapesjs-plugin-export"
import GrapesjsParserPostcss from "grapesjs-parser-postcss"
import GrapesjsStyleBg from "grapesjs-style-bg"
import GrapesjsProjectManager from "grapesjs-project-manager"
import GrapesjsPresetWebpage from "grapesjs-preset-webpage"
import { storageManager, styleManager, pluginsOpts } from "./config"
import createBlocks from "./Blocks"
import CustomComponentTypes from "./ComponentTypes"
import { initializeUtils } from "./utils/index"

const createPageBuilder = (shop, pageType, dbName) => {
  initializeUtils()

  const dbConfig = dbName ? { [GrapesjsProjectManager]: { dbName } } : {}
  const initConfig = {
    container: "#gjs",
    allowScripts: 1,
    plugins: [
      GrapesScriptEditor,
      GjsBlocksBasic,
      GrapesjsPluginForms,
      GrapesjsComponentCountdown,
      GrapesjsPluginExport,
      GrapesjsTabs,
      GrapesjsCustomCode,
      GrapesjsTouch,
      GrapesjsParserPostcss,
      GrapesjsTooltip,
      GrapesjsTuiImageEditor,
      GrapesjsTyped,
      GrapesjsStyleBg,
      GrapesjsPresetWebpage,
      GrapesjsProjectManager,
      editor => CustomComponentTypes(editor, shop),
    ],
    storageManager,
    styleManager,
    pluginsOpts: {
      ...pluginsOpts,
      ...dbConfig,
    },
    canvas: {
      scripts: ["https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js"],
      styles: ["https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"],
    },
  }

  const pageBuilder = grapesjs.init(initConfig)

  createBlocks({
    blockManager: pageBuilder.BlockManager,
    shop,
    pageType,
  })

  setupPanels(pageBuilder.Panels)
  setupCommands(pageBuilder)
  setupCodeViewer(pageBuilder)

  return pageBuilder
}

const setupPanels = pn => {
  pn.addPanel({
    id: "panel-top",
    el: ".panel__top",
  })

  pn.addButton("options", [
    {
      className: "fa fa-file-code",
      id: "show-json",
      context: "show-json",
      command(pageBuilder) {
        handleOpenJsonView(pageBuilder)
      },
    },
  ])

  pn.addButton("views", {
    id: "open-pages",
    className: "fa fa-file",
    command: "open-pages",
    togglable: false,
  })
}

const setupCommands = pageBuilder => {
  const pages = pageBuilder.Pages
  const cm = pageBuilder.Commands

  cm.add("core:canvas-clear", ed => {
    console.log(ed)
    pages.getSelected().getMainComponent().empty()
  })
}

const setupCodeViewer = pageBuilder => {
  pageBuilder.on("storage:load", function (e) {
    console.log("storage:load", e)
  })

  pageBuilder.on("storage:store", function (e) {
    localStorage.setItem("json-dnd-data", JSON.stringify(e))
  })

  pageBuilder.on("run:gjs-open-import-webpage", () => {
    const modalContent = pageBuilder.Modal.getContentEl()
    const pfx = pageBuilder.getConfig("stylePrefix")
    const btn = modalContent.querySelector(`.${pfx}btn-prim.${pfx}btn-import`)
    const codeViewer = pageBuilder.Commands.get(
      "gjs-open-import-webpage"
    ).codeViewer
    if (btn) {
      btn.onclick = () => {
        const component = pageBuilder.Pages.getSelected().getMainComponent()
        component.empty()
        pageBuilder.setComponents(codeViewer.getContent().trim())
        pageBuilder.Modal.close()
      }
    }
  })
}

const handleOpenJsonView = pageBuilder => {
  pageBuilder.Modal.setTitle("Components JSON")
    .setContent(
      `<textarea style="width:100%; height: 250px;">
        ${localStorage.getItem("json-dnd-data")}
      </textarea>`
    )
    .open()
}

export default createPageBuilder
