import { useEffect, useState } from "react"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import { getStoredKey } from "../../../../utils/store"
import categoriesAndSubcategoriesReasons from "./categories_subcategories.json"
import SubscriptionDueDateModal from "./due-date/due-date"
import SubscriptionFrequencyModal from "./frequency/frequency"
import "./modify.scss"
import SubscriptionPriceModal from "./price/price"
import SubscriptionProductModal from "./product/product"
import SubscriptionShippingAddressModal from "./shipping-address/shipping-address"
import SubscriptionStatusModal from "./status/status"

export const REASON_CATEGORY_SUBCATEGORY_NOT_DEFINED =
  "reason_category_not_defined - reason_subcategory_not_defined"

const shippingAddressValue = "shipping-address"

function SubscriptionModifyModal({
  isOpen,
  toggle,
  subscription,
  subscriptionDetails,
  onRefetchSubscriptions,
}) {
  const [localSubscription, setLocalSubscription] = useState(subscription)
  const [localSubscriptionDetails, setLocalSubscriptionDetails] =
    useState(subscriptionDetails)

  const [isFrequencyModalOpen, setIsFrequencyModalOpen] = useState(false)
  const [isDueDateModalOpen, setIsDueDateModalOpen] = useState(false)
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [isShippingAddressModalOpen, setIsShippingAddressModalOpen] =
    useState(false)

  const [isUpdated, setIsUpdated] = useState(false)

  useEffect(() => {
    setLocalSubscription(subscription)
  }, [subscription])

  useEffect(() => {
    setLocalSubscriptionDetails(subscriptionDetails)
  }, [subscriptionDetails])

  useEffect(() => {
    if (isUpdated) {
      onRefetchSubscriptions()
      toggleIsUpdated()
    }
  }, [isUpdated])

  const handleContentChange = content => {
    switch (content) {
      case "frequency":
        setIsFrequencyModalOpen(true)
        break

      case "due-date":
        setIsDueDateModalOpen(true)
        break

      case "product":
        setIsProductModalOpen(true)
        break

      case "price":
        setIsPriceModalOpen(true)
        break

      case "status":
        setIsStatusModalOpen(true)
        break

      case shippingAddressValue:
        setIsShippingAddressModalOpen(true)
        return

      default:
    }
  }

  const toggleIsUpdated = () => {
    setIsUpdated(prevState => !prevState)
  }

  const toggleFrequencyModal = () => {
    setIsFrequencyModalOpen(false)
  }

  const toggleProductModal = () => {
    setIsProductModalOpen(false)
  }

  const togglePriceModal = () => {
    setIsPriceModalOpen(false)
  }

  const toggleStatusModal = () => {
    setIsStatusModalOpen(false)
  }

  const toggleDueDateModal = () => {
    setIsDueDateModalOpen(false)
  }

  const dismissShippingAddressModal = () => {
    setIsShippingAddressModalOpen(false)
  }

  const renderButton = (buttonText, descriptionText, content) => (
    <div className="subscription-modify-container">
      <Button
        type="button"
        color="primary"
        className="btn btn-md btn-rounded"
        onClick={() => handleContentChange(content)}
      >
        {buttonText}
      </Button>
      <span className="description">{descriptionText}</span>
    </div>
  )

  const renderModalContent = () => {
    return (
      <>
        {renderButton(
          "Frequency",
          "Modify Frequency: Adjust the recurring schedule of a subscription.",
          "frequency"
        )}
        {renderButton(
          "Due Date",
          "Update Expiration Date: Update next due date of a subscription.",
          "due-date"
        )}
        {renderButton(
          "Products",
          "Manage Subscription Items: Review and update the subscription items.",
          "product"
        )}
        {renderButton(
          "Price",
          "Adjust Subscription Price: Update the subscription price.",
          "price"
        )}
        {renderButton(
          "Status",
          "Status Subscription: Pause or cancel a subscription.",
          "status"
        )}
        {renderButton(
          "Address",
          "Shipping Address: Change a subscription shipping address.",
          shippingAddressValue
        )}
      </>
    )
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="subscription-modify-modal"
        tabIndex="-1"
        toggle={toggle}
      >
        <ModalHeader
          toggle={() => {
            toggle()
          }}
        >
          Edit Subscription
        </ModalHeader>
        <ModalBody>{renderModalContent()}</ModalBody>
      </Modal>

      <SubscriptionFrequencyModal
        isOpen={isFrequencyModalOpen}
        toggleIsUpdated={toggleIsUpdated}
        toggle={toggleFrequencyModal}
        subscription={localSubscription}
        subscriptionDetails={localSubscriptionDetails}
      />

      <SubscriptionDueDateModal
        isOpen={isDueDateModalOpen}
        toggleIsUpdated={toggleIsUpdated}
        toggle={toggleDueDateModal}
        subscription={localSubscription}
        subscriptionDetails={localSubscriptionDetails}
      />

      <SubscriptionProductModal
        isOpen={isProductModalOpen}
        toggleIsUpdated={toggleIsUpdated}
        toggle={toggleProductModal}
        subscription={localSubscription}
        subscriptionDetails={localSubscriptionDetails}
      />

      <SubscriptionPriceModal
        isOpen={isPriceModalOpen}
        toggleIsUpdated={toggleIsUpdated}
        toggle={togglePriceModal}
        subscription={localSubscription}
        subscriptionDetails={localSubscriptionDetails}
      />

      <SubscriptionStatusModal
        isOpen={isStatusModalOpen}
        toggleIsUpdated={toggleIsUpdated}
        toggle={toggleStatusModal}
        subscription={localSubscription}
      />

      <SubscriptionShippingAddressModal
        isOpen={isShippingAddressModalOpen}
        toggleIsUpdated={toggleIsUpdated}
        dismissModal={dismissShippingAddressModal}
        subscriptionValue={localSubscription}
      />
    </>
  )
}

export default SubscriptionModifyModal

export function getCategoryAndSubcategoryReasons(subscription) {
  const store = getStoredKey(subscription.store)
  const categoriesMap = categoriesAndSubcategoriesReasons[store]

  if (categoriesMap === undefined) {
    return [{}, {}, {}, [], []]
  }

  const categoryLabels = Object.values(categoriesMap).map(
    category => category.label
  )
  const categoryKeys = Object.keys(categoriesMap)

  const categoryKeysMap = {}
  categoryLabels.forEach((label, index) => {
    categoryKeysMap[label] = categoryKeys[index]
  })

  const subcategoryLabelsMap = []
  const subcategoryKeysMap = {}

  for (const categoryKey in categoriesMap) {
    const subcategories = categoriesMap[categoryKey].subcategories

    const subcategoryLabels = []
    const subcategoryKeys = {}

    subcategories.forEach(subcategory => {
      const subcategoryKey = Object.keys(subcategory)[0]
      const subcategoryLabel = subcategory[subcategoryKey].label
      subcategoryLabels.push(subcategoryLabel)
      subcategoryKeys[subcategoryLabel] = subcategoryKey
    })

    subcategoryLabelsMap[categoryKey] = subcategoryLabels
    subcategoryKeysMap[categoryKey] = subcategoryKeys
  }

  return [
    categoriesMap,
    categoryKeysMap,
    subcategoryKeysMap,
    categoryLabels,
    subcategoryLabelsMap,
  ]
}
