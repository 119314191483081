/* eslint-disable no-undef */

const ProductOffer = editor => {  
    editor.DomComponents.addType("product-offer", {
      isComponent: el => el.classList?.contains("product-offer"),
      model: {
        defaults: {
          tagName: "div",
          classes: ["product-offer"],
          style: {
            width: "fit-content",
            padding: "0 24px",
            fontFamily: "Arial, sans-serif",
          },
          product: null,
          styles: `
            .product-offer-container {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
            }

            .offer-title {
              max-width: 700px;
              font-size: 1.2rem;
              text-align: center;
            }

            .price-container {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: .5rem;
              margin-top: 0;
            }

            .price {
              font-weight: bold;
              font-size: 1.6rem;
            }

            .compare-price {
              font-weight: 400;
              color: #9e9e9e;
              text-decoration: line-through;
              font-size: 1.3rem;
            }
          `,
        },
        init() {
          const product = this.get('product')

          if (product) this.renderComponent(null, product)

					this.addClass(this.getId())
        },
        renderComponent(_, productData) {
          const { title, images, product_variations, product_dimensions } =
            productData
  
          let variation = null
  
          if (product_variations.length === 1) {
            variation = product_variations[0]
          } else {
            variation = product_variations.find(v =>
              product_dimensions.every(dim =>
                v.variation_attributes.some(
                  item => dim.selected === item.value && dim.name === item.name
                )
              )
            )
          }
  
          if (!variation) {
            console.error("No variation found")
            return
          }
  
          const { price, compare_at_price, images_id } = variation
          const comparePrice = compare_at_price
            ? `<strong class="compare-price">$${compare_at_price}</strong>`
            : ""
  
          let imageSrc = images[0].src
          if (images_id.length)
            imageSrc = images.find(img => images_id.includes(img.id))?.src
  
          const imageTag = imageSrc
            ? `<img style="max-height: 21rem;" src=${imageSrc} >`
            : ""
  
          this.components(`
            <div class="product-offer-container">
              ${imageTag}
              <h1 class="offer-title">${title}</h1>
              <p class="price-container">
                ${comparePrice}
                <strong class="price">$${price}</strong>
              </p>
            <div>
          `)
        },
      },
    })
  }
  
  export default ProductOffer