import { createQuiz, defaultQuestionObj } from "./utils"

const Question = editor => {
  editor.DomComponents.addType("question", {
    isComponent: el => el.classList && el.classList.contains("question"),
    model: {
      defaults: {
        tagName: "div",
        classes: ["swiper-slide"],
        styles: `
                .form__group {
                    position: relative;
                    padding: 15px 0 0;
                    margin: 20px 0;
                    width: 100%
                  }
                  
                  .form__field {
                    position: relative;
                    z-index: 1;
                    font-family: inherit;
                    width: 90vw;
                    min-width: 300px;
                    max-width: 855px;
                    border: 0;
                    border-bottom: 1px solid #212121;
                    outline: 0;
                    font-size: 16px;
                    color: #212121;
                    padding: 7px 0;
                    background: transparent;
                    transition: border-color 0.2s;
                  }
                  
                  .form__field::placeholder {
                    color: transparent;
                  }
                  
                  .form__field:placeholder-shown ~ .form__label {
                    font-size: 16px;
                    cursor: text;
                    top: 20px;
                  }
                  
                  .form__label,
                  .form__field:focus ~ .form__label {
                    position: absolute;
                    top: 0;
                    display: block;
                    transition: 0.2s;
                    font-size: 12px;
                    color: #9b9b9b;
                  }
                  
                  .form__field:focus ~ .form__label {
                    color: #C54844;
                  }
                  
                  .form__field:focus {
                    padding-bottom: 6px;
                    border-bottom: 2px solid #C54844;
                  }
                .answer-section {
                    margin: auto;
                    display: flex;
                    color: black;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Arial';
                    padding: 20px;
                    max-width: 855px;
                }
                .answer-section > fieldset {
                    border: none;
                    height: auto;
                }
                .answer-section ul {
                    list-style: none;
                }
                .answer-item [type="radio"] {
                    display: none;
                }
                .answer-item + .answer-item {
                    margin-top: 15px;
                }
                .answer-item label {
                    display: block;
                    padding: 20px 60px;
                    background: #fff6ef;
                    border: 2px solid #d69595;
                    border-radius: 8px;
                    cursor: pointer;
                    font-size: 18px;
                    font-weight: 400;
                    min-width: 250px;
                    white-space: nowrap;
                    position: relative;
                    transition: 0.3s ease-in-out 0s;
                }
                .answer-item label:after,
                .answer-item label:before {
                    content: "";
                    position: absolute;
                    border-radius: 50%;
                }
                .answer-item label:after {
                    height: 19px;
                    width: 19px;
                    border: 2px solid #c45f5e;
                    left: 19px;
                    top: calc(50% - 12px);
                }
                .answer-item label:before {
                    background: #c45f5e;
                    height: 20px;
                    width: 20px;
                    left: 21px;
                    top: calc(50%-5px);
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.3s ease-in-out 0s;
                }
                .answer-item [type="radio"]:checked ~ label {
                    border-color: #c45f5e;
                }
                .answer-item [type="radio"]:checked ~ label::before {
                    opacity: 1;
                    visibility: visible;
                }
                .question-btn {
                    outline: none;
                    border: none;
                    margin-left: auto;
                    background: #C54844;
                    color: white;
                    font-size: 18px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    cursor: pointer;
                }
                .question-btn.question-input-multiple {
                    width: 60px;
                }
                .question-btn svg {
                    margin-right: 5px;
                    fill: white;
                    font-size: 1.6rem;
                }
                .nav-quiz-button:hover {
                    background: #A63F3B;
                }

                `,
        components: createQuiz(defaultQuestionObj),
      },
    },
  })
}

export default Question
