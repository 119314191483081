import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"

const Pricing = ({ validationType, pricingTypes }) => {
  return (
    <div>
      <div>
        <CardTitle>Offer Settings</CardTitle>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Pricing</CardTitle>
                <div>
                  <div className="mb-3">
                    <Label className="form-label">Pricing option</Label>
                    <select
                      name="pricingTypeId"
                      className="form-select"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.pricingTypeId}
                    >
                      {pricingTypes?.map((types, index) => {
                        return (
                          <option key={index} value={types.id}>
                            {types.description}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  {validationType.values.pricingTypeId != "1" && (
                    <div className="mb-3">
                      <Label className="form-label">Pricing value</Label>
                      <InputGroup className="input-grp">
                        <div className="input-group-text">
                          {validationType.values.pricingTypeId == "3"
                            ? "$"
                            : "%"}
                        </div>
                        <Input
                          name="pricingTypeValue"
                          type="number"
                          onChange={validationType.handleChange}
                          onBlur={validationType.handleBlur}
                          value={validationType.values.pricingTypeValue}
                          invalid={
                            validationType.touched.pricingTypeValue &&
                            validationType.errors.pricingTypeValue
                              ? true
                              : false
                          }
                        />
                        {validationType.touched.pricingTypeValue &&
                        validationType.errors.pricingTypeValue ? (
                          <FormFeedback type="invalid">
                            {validationType.errors.pricingTypeValue}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Pricing
