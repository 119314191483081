const VideShop = ({blockManager}) => {
    blockManager.add("video-shop", {
        id: "video-shop",
        label: "Video",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="display: flex; align-items: center; justify-content: center;">
        <iframe width="545" height="350" src="https://www.youtube.com/embed/Q1yOI8ANSJQ?si=28hApqNoksAH-zdJ" style="width: 400px; height: 250px;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    `,
      })
}

export default VideShop