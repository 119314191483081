const FindYourSize = ({blockManager}) => {
    blockManager.add("find-your-size", {
        id: "find-your-size",
        label: "Find Your Size",
        category: "Afrodita Components",
        activate: true,
        content: `<div style="padding-top: 30px; width: 40%; margin: auto; font-family: 'Arial';">
        <div style="text-align: center; color: #292929c2; font-size: 20px; padding-bottom: 20px; font-weight: 800;">
          Find your size
        </div>
        <div>
          <table style="border-spacing: 0; font-size: 14px; line-height: 50px; width: 100%; color: #292929c2; text-align: center;">
            <tr style="background-color: #fff; border-radius: 10px; font-weight: 400;">
              <th style="border-radius: 10px 0 0 0; font-weight: 100; border: 1px solid #e5e5e5;">Size</th>
              <th style="font-weight: 100; border: 1px solid #e5e5e5;">Dress Size</th>
              <th style="font-weight: 100; border: 1px solid #e5e5e5;">Bust (in)</th>
              <th style="font-weight: 100; border: 1px solid #e5e5e5;">Waist (in)</th>
              <th style="border-radius: 0 10px 0 0; font-weight: 100; border: 1px solid #e5e5e5;">Hip (in)</th>
            </tr>
            <tbody>
              <tr style="background-color: #fff; border-top: 1px solid #e5e5e5; color: #292929c2; border-radius: 10px;">
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">S</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">0-2</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">29-32</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">24-28</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">32-36</td>
              </tr>
              <tr style="background-color: #f8f8f8; border-top: 1px solid #e5e5e5; color: #292929c2; border-radius: 10px;">
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">M</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">4-6</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">32-36</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">28-32</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">36-40</td>
              </tr>
              <tr style="background-color: #fff; border-top: 1px solid #e5e5e5; color: #292929c2; border-radius: 10px;">
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">L</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">8-10</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">36-39</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">32-35</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">40-43</td>
              </tr>
              <tr style="background-color: #f8f8f8; border-top: 1px solid #e5e5e5; color: #292929c2; border-radius: 10px;">
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">XL</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">12-14</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">39-42</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">35-38</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">43-45</td>
              </tr>
              <tr style="background-color: #fff; border-top: 1px solid #e5e5e5; color: #292929c2; border-radius: 10px;">
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">2XL</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">16-18</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">42-45</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">35-38</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">43-45</td>
              </tr>
              <tr style="background-color: #f8f8f8; border-top: 1px solid #e5e5e5; color: #292929c2; border-radius: 10px;">
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">3XL</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">20</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">45-48</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">41-44</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">48-51</td>
              </tr>
              <tr style="background-color: #fff; border-top: 1px solid #e5e5e5; color: #292929c2; border-radius: 10px;">
                <td style="border-radius: 0 0 0 10px;font-weight: 100; border: 1px solid #e5e5e5;">4XL</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">22</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">48-52</td>
                <td style="font-weight: 100; border: 1px solid #e5e5e5;">44-47</td>
                <td style="border-radius: 0 0 10px 0;font-weight: 100; border: 1px solid #e5e5e5;">51-54</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      `,
      })
}

export default FindYourSize