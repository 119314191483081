const GlobalScript = (editor) => {
    editor.DomComponents.addType('popup-script', {
        model: {
            defaults: {
                tagName: 'div',
                removable: true,
                draggable: true,
                copyable: false,
                resizable: false,
                droppable: false,
                stylable: false,
                popupId: '',
                traits: [
                    {
                        name: 'popupId',
                        label: 'Popup ID',
                        placeholder: 'abcdef',
                        changeProp: true
                    },
                ],
                content: `<div style="text-align:center;color:#f6a908">This is a placeholder and will not be rendered. Toggle visibility in layers</div>`,
                'script-props': ['popupId'],
                script: function({popupId}) {
                    const src = `https://cdn2.irontive.com/ironpops/${popupId}/ironpops.js`
                    const scriptElement = document.querySelector(`script[src="${src}"]`)
                    if (!scriptElement && popupId) {
                        const script = document.createElement('script')
                        script.src = src
                        document.body.appendChild(script)
                    }
                },
            },
            init() {},
            toHTML() {
                return `<div id="${this.getId()}" />`
            }
        },
    })
}

export default GlobalScript