export const taxType = [
  {
    value: "gst",
    label: "GST",
  },
  {
    value: "hst",
    label: "HST",
  },
  {
    value: "pst",
    label: "PST",
  },
  {
    value: "qst",
    label: "QST",
  },
  {
    value: "vat",
    label: "STATE",
  },
  {
    value: "shipping",
    label: "SHIPPING",
  },
]

export const additionalFeeTaxType = [
  {
    value: "rdf",
    label: "Retail Delivery Fee",
  },
]
