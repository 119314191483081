export const SET_STATE = 'SET_STATE'
export const SET_QUESTIONS = 'SET_QUESTIONS'
export const ADD_QUESTION = 'ADD_QUESTION'
export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const DELETE_QUESTION = 'DELETE_QUESTION'
export const SET_ANSWERS = 'SET_ANSWERS'
export const ADD_ANSWER = 'ADD_ANSWER'
export const UPDATE_ANSWER = 'UPDATE_ANSWER'
export const DELETE_ANSWER = 'DELETE_ANSWER'
export const SELECT_QUESTION = 'SELECT_QUESTION'
export const SET_SIDECAR_QUESTION = 'SET_SIDECAR_QUESTION'