const DiscountTitle = ({blockManager, color}) => {
    blockManager.add("discount-title", {
        id: "discount-title",
        label: "Discount Title",
        category: "Afrodita Components",
        activate: true,
        content: `<div>
        <h6 style="margin: 0; font-size: 34px; line-height: 28px; font-weight: 800; text-align: center; color: ${color}; font-family: 'Arial'">
          At 55% OFF
        </h6>
      </div>`,
    })
}

export default DiscountTitle