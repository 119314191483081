import PropTypes from "prop-types"
import { Link } from "react-router-dom"

const SectionGroupMenu = ({ items }) => (
  <ul className="sub-menu">
    {items.map(item => (
      <li key={item.name}>
        <Link to={item.path}>{item.name}</Link>
      </li>
    ))}
  </ul>
)

const SectionGroup = ({ group }) => (
  <li key={group.name}>
  <Link to="/#" className="has-arrow">
    <i className={group.boxicon}></i>
    <span>{group.name}</span>
  </Link>
  <SectionGroupMenu items={group.items} />
  </li>
)

const SidebarContentSection = ({ title, groups }) => (
  <>
    <li className="menu-title">{title}</li>
    {groups.map(group => <SectionGroup key={group.name} group={group} />)}
  </>
)

SidebarContentSection.propTypes = {
  title: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    boxicon: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
}

export default SidebarContentSection
