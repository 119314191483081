export const Name = cell => {
  return cell.value ? cell.value : ""
}

export const Title = cell => {
  return cell.value ? cell.value : ""
}

export const Description = cell => {
  return cell.value ? cell.value : ""
}

export const FrequencyValue = cell => {
  return cell.value ? cell.value : ""
}

export const FrequencyUnit = cell => {
  return cell.value ? cell.value : ""
}

export const FreeTrialPeriod = cell => {
  return cell.value ? cell.value : ""
}

export const Price = cell => {
  return cell.value ? cell.value : ""
}

export const Currency = cell => {
  return cell.value ? cell.value : ""
}

export const Plan = cell => {
  return cell.value ? cell.value : ""
}

export const LegacyPlan = cell => {
  return cell.value ? cell.value : ""
}

export const PaymentSource = cell => {
  return cell.value ? cell.value : ""
}
